import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Button, AnchorButton } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { User } from '@/types/user'
import { Company } from '@/types/company'
import { DisplayItem, TabDisplayItems } from '@/types/displayItem'
import { RequiredItem } from '@/types/requiredItem'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Table } from '@/components/Table'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import Tooltip from '@mui/material/Tooltip'
import type { Property } from '@/types/property'
import { cammedFormat, formatNumber } from '@/utils/cammedFormat'
import { buildingStructureType } from '@/config/langs/building_structure'
import { format, formatDistanceStrict, parseISO } from 'date-fns'
import { ClassNames } from '@emotion/react'
import { InfomationIcon } from '@/components/SvgIcon'

type Props = {
  current_user?: User
  property?: Property
  display_item_data?: DisplayItem[]
  tab_display_items?: TabDisplayItems
  source_display?: { [key: number]: boolean }
  required_item_data?: RequiredItem[]
  authenticity_token?: string
  error_messages?: string[]
  company?: Company
}
export const PropertyBusinessInfomation: React.FC<Props> = ({
  property,
  current_user,
  display_item_data,
  tab_display_items = {},
  source_display,
  required_item_data,
  authenticity_token,
  error_messages,
  company,
}) => {
  // 岩田TODO = display_item_dataに渡す値に注意して送る
  const isPropertyLand = property?.property_type === '土地'
  const [isViewUseDisplayItemsFlg, setIsViewUseDisplayItemsFlg] = React.useState(false)
  const is_property_business_infomaion_item_names = display_item_data
    .filter((item) => tab_display_items[item.display_item_id] === false)
    .map((item) => item.name)

  let suggested_total_price_width = 'w-full'
  let suggested_total_price_column_width = 'w-full'
  let purchase_price_width = 'w-full'
  let purchase_price_column_width = 'w-full'
  let number_of_buildings_column_width = 'w-full'
  let number_of_units_column_width = 'w-full'
  let unit_price_per_unit_yield_column_width = 'w-full'
  if (
    is_property_business_infomaion_item_names.includes('売主希望坪単価') &&
    is_property_business_infomaion_item_names.includes('売主希望一種単価')
  ) {
    suggested_total_price_width += ' md:w-full'
    suggested_total_price_column_width += ' md:w-2/12'
  } else if (
    is_property_business_infomaion_item_names.includes('売主希望坪単価') ||
    is_property_business_infomaion_item_names.includes('売主希望一種単価')
  ) {
    suggested_total_price_width += ' md:w-1/2'
    suggested_total_price_column_width += ' md:w-1/3'
  } else {
    suggested_total_price_width += ' md:w-1/3'
  }
  if (
    is_property_business_infomaion_item_names.includes('買取坪単価') &&
    is_property_business_infomaion_item_names.includes('買取一種単価')
  ) {
    purchase_price_width += ' md:w-full'
    purchase_price_column_width += ' md:w-2/12'
  } else if (
    is_property_business_infomaion_item_names.includes('買取坪単価') ||
    is_property_business_infomaion_item_names.includes('買取一種単価')
  ) {
    purchase_price_width += ' md:w-1/2'
    purchase_price_column_width += ' md:w-1/3'
  } else {
    purchase_price_width += ' md:w-1/3'
  }
  if (
    is_property_business_infomaion_item_names.includes('戸数') &&
    is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
  ) {
    number_of_buildings_column_width += ' md:w-2/12'
  } else if (
    is_property_business_infomaion_item_names.includes('戸数') ||
    is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
  ) {
    number_of_buildings_column_width += ' md:w-1/3'
  }
  if (
    is_property_business_infomaion_item_names.includes('棟数（区画）') &&
    is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
  ) {
    number_of_units_column_width += ' md:w-2/12'
  } else if (
    is_property_business_infomaion_item_names.includes('棟数（区画）') ||
    is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
  ) {
    number_of_units_column_width += ' md:w-1/3'
  }
  if (
    is_property_business_infomaion_item_names.includes('棟数（区画）') &&
    is_property_business_infomaion_item_names.includes('戸数')
  ) {
    unit_price_per_unit_yield_column_width += ' md:w-2/12'
  } else if (
    is_property_business_infomaion_item_names.includes('棟数（区画）') ||
    is_property_business_infomaion_item_names.includes('戸数')
  ) {
    unit_price_per_unit_yield_column_width += ' md:w-1/3'
  }

  React.useEffect(() => {
    const url = window.location.href

    if (url.includes('display_items')) {
      setIsViewUseDisplayItemsFlg(true)
    } else {
      setIsViewUseDisplayItemsFlg(false)
    }
  }, [])
  return (
    <>
      <div className="flex justify-between items-center bg-primary text-white py-1 px-4 text-sm">
        事業情報
      </div>
      <Table
        className={`border-t border-b border-[#3885B0] ${
          isViewUseDisplayItemsFlg
            ? window.innerHeight > 920
              ? 'h-[710px] md:h-[710px]'
              : 'h-[calc(90vh_-_182px)] md:h-[calc(85vh_-_195px)]'
            : ''
        }`}
      >
        <div className={`${isViewUseDisplayItemsFlg ? 'text-xs' : ''}`}>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('現況')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('事業形態') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('現況') ? 'md:w-2/12' : ''
                  }`}
                >
                  事業形態
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '事業形態1'
                  : property?.business_type?.name}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('事業形態')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('現況') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('事業形態')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  現況
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '更地'
                  : property?.local_situation}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                  ${suggested_total_price_width}
                  ${
                    is_property_business_infomaion_item_names.includes('売主希望総額')
                      ? 'hidden'
                      : ''
                  }
                `}
              label={
                <Th left={true} column={3} className={suggested_total_price_column_width}>
                  売主希望総額
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '100,000,000円'
                  : property?.ongoing_proposal?.suggested_total_price
                  ? `${formatNumber(property?.ongoing_proposal?.suggested_total_price)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('売主希望一種単価')
                    ? 'md:w-1/2'
                    : 'w-full md:w-1/3'
                }
                ${
                  is_property_business_infomaion_item_names.includes('売主希望坪単価')
                    ? 'hidden'
                    : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={3}
                  className={`${
                    is_property_business_infomaion_item_names.includes('売主希望一種単価')
                      ? 'md:w-1/3'
                      : ''
                  }`}
                >
                  売主希望坪単価
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '3,005,710円'
                  : property?.ongoing_proposal?.suggested_unit_price
                  ? `${formatNumber(property?.ongoing_proposal.suggested_unit_price)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('売主希望坪単価')
                    ? 'md:w-1/2'
                    : 'w-full md:w-1/3'
                }
                ${
                  is_property_business_infomaion_item_names.includes('売主希望一種単価')
                    ? 'hidden'
                    : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={3}
                  className={`${
                    is_property_business_infomaion_item_names.includes('売主希望坪単価')
                      ? 'md:w-1/3'
                      : ''
                  }`}
                >
                  売主希望一種単価
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '10,019,033円'
                  : property?.ongoing_proposal?.suggested_primary_unit_price && isPropertyLand
                  ? `${formatNumber(property?.ongoing_proposal?.suggested_primary_unit_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                  ${
                    is_property_business_infomaion_item_names.includes('目安価格')
                      ? 'hidden'
                      : 'w-full'
                  }
                `}
              label={<Th left={true}>目安価格</Th>}
            >
              <Td>
                {window.location.href.includes('display_items')
                  ? '100,000,000円'
                  : property?.ongoing_proposal?.approx_price
                  ? `${formatNumber(property?.ongoing_proposal?.approx_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${purchase_price_width} 
                ${is_property_business_infomaion_item_names.includes('買取金額') ? 'hidden' : ''}
              `}
              label={
                <Th left={true} column={3} className={purchase_price_column_width}>
                  買取金額
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '100,000,000円'
                  : property?.ongoing_proposal?.purchase_price
                  ? `${formatNumber(property?.ongoing_proposal?.purchase_price)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                  ${
                    is_property_business_infomaion_item_names.includes('買取一種単価')
                      ? 'md:w-1/2'
                      : 'w-full md:w-1/3'
                  }
                  ${
                    is_property_business_infomaion_item_names.includes('買取坪単価') ? 'hidden' : ''
                  }
                `}
              label={
                <Th
                  left={true}
                  column={3}
                  className={`${
                    is_property_business_infomaion_item_names.includes('買取一種単価')
                      ? 'md:w-1/3'
                      : ''
                  }`}
                >
                  買取坪単価
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '3,005,710円'
                  : property?.ongoing_proposal?.purchase_unit_price
                  ? `${formatNumber(property?.ongoing_proposal?.purchase_unit_price)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                  ${
                    is_property_business_infomaion_item_names.includes('買取坪単価')
                      ? 'md:w-1/2'
                      : 'w-full md:w-1/3'
                  }
                  ${
                    is_property_business_infomaion_item_names.includes('買取一種単価')
                      ? 'hidden'
                      : ''
                  }
                `}
              label={
                <Th
                  left={true}
                  column={3}
                  className={`${
                    is_property_business_infomaion_item_names.includes('買取坪単価')
                      ? 'md:w-1/3'
                      : ''
                  }`}
                >
                  買取一種単価
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '10,019,033円'
                  : property?.ongoing_proposal?.purchase_primary_unit_price && isPropertyLand
                  ? `${formatNumber(property?.ongoing_proposal?.purchase_primary_unit_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <Row
            className={`
                ${
                  is_property_business_infomaion_item_names.includes('諸経費合計(仕入)')
                    ? 'hidden'
                    : 'w-full'
                }
              `}
            label={<Th left={true}>諸経費合計(仕入)</Th>}
          >
            <Td>
              {window.location.href.includes('display_items')
                ? '100,000,000円'
                : property?.total_expense_cost_purchase
                ? `${formatNumber(property?.total_expense_cost_purchase)}円`
                : ''}
            </Td>
          </Row>
          <div className="flex flex-wrap">
            <Row
              className={`
                  ${
                    is_property_business_infomaion_item_names.includes('現況表面利回り')
                      ? 'w-full'
                      : 'md:w-1/2'
                  }
                  ${
                    is_property_business_infomaion_item_names.includes('現況賃料（税込）/ 月額')
                      ? 'hidden'
                      : ''
                  }
                `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('現況表面利回り')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  現況賃料（税込）/ 月額
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '100,000円'
                  : property?.current_rent
                  ? `${formatNumber(property?.current_rent)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                  ${
                    is_property_business_infomaion_item_names.includes('現況表面利回り')
                      ? 'hidden'
                      : 'w-full md:w-1/2'
                  }
                `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('現況賃料（税込）/ 月額')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  <span className="flex items-center gap-1">
                    <span>現況表面利回り</span>
                    <Tooltip
                      title={
                        <span>
                          買取金額が設定されている場合は、その「買取金額」で計算を行います。それ以外の場合は、「売主希望総額」を使用します。
                        </span>
                      }
                    >
                      <span className="cursor-help">
                        <InfomationIcon size={10} />
                      </span>
                    </Tooltip>
                  </span>
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '1.20%'
                  : property?.current_prospective_yield
                  ? `${Number(property?.current_prospective_yield).toFixed(2)}%`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('現況NOI利回り')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('現況NOI / 月額')
                    ? 'hidden'
                    : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('現況NOI利回り')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  現況NOI / 月額
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '100,000円'
                  : property?.net_income
                  ? `${formatNumber(property?.net_income)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('現況NOI利回り')
                    ? 'hidden'
                    : 'w-full md:w-1/2'
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('現況NOI / 月額')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  現況NOI利回り
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '1.20%'
                  : property?.current_prospective_noi_yield
                  ? `${Number(property?.current_prospective_noi_yield).toFixed(2)}%`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('計画容積率')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('計画建ぺい率') ? 'hidden' : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('計画容積率')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  計画建ぺい率
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '20.0%'
                  : property?.coverage_ratio
                  ? `${property?.coverage_ratio}%`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('計画建ぺい率')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('計画容積率') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('計画建ぺい率')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  計画容積率
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '30.0%'
                  : property?.volume_rate
                  ? `${property?.volume_rate}%`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                
                ${
                  is_property_business_infomaion_item_names.includes('戸数') &&
                  is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
                    ? 'w-full'
                    : is_property_business_infomaion_item_names.includes('戸数') ||
                      is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
                    ? 'md:w-1/2'
                    : 'md:w-1/3'
                }
                ${
                  is_property_business_infomaion_item_names.includes('棟数（区画）') ? 'hidden' : ''
                }
              `}
              label={
                <Th left={true} column={3} className={`${number_of_buildings_column_width}`}>
                  棟数（区画）
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '10棟（区画）'
                  : property?.number_of_buildings
                  ? `${formatNumber(property?.number_of_buildings)}棟（区画）`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('棟数（区画）') &&
                  is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
                    ? 'w-full'
                    : is_property_business_infomaion_item_names.includes('棟数（区画）') ||
                      is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
                    ? 'md:w-1/2'
                    : 'md:w-1/3'
                }
                ${is_property_business_infomaion_item_names.includes('戸数') ? 'hidden' : ''}
                `}
              label={
                <Th left={true} column={3} className={`${number_of_units_column_width}`}>
                  戸数
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '100戸'
                  : property?.number_of_units
                  ? `${formatNumber(property?.number_of_units)}戸`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('棟数（区画）')
                    ? ' w-full md:w-1/2'
                    : 'w-full md:w-1/3'
                }
                ${
                  is_property_business_infomaion_item_names.includes('仕入戸当たり単価')
                    ? 'hidden'
                    : ''
                }
              `}
              label={
                <Th left={true} column={3} className={`${unit_price_per_unit_yield_column_width}`}>
                  仕入戸当たり単価
                </Th>
              }
            >
              <Td column={3}>
                {window.location.href.includes('display_items')
                  ? '10,000,000円'
                  : property?.number_of_units
                  ? `${formatNumber(property?.unit_price_per_unit_yield)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                
                ${
                  is_property_business_infomaion_item_names.includes('建物階数')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('建物構造') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('建物階数')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  建物構造
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? 'WRC造:壁式鉄筋コンクリート造'
                  : property?.building_structure
                  ? buildingStructureType[property?.building_structure]
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('建物構造')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('建物階数') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('建物構造')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  建物階数
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items') ? (
                  '地上 10 階 地下 10 階'
                ) : (
                  <>
                    {property?.floors_above_ground && `地上 ${property?.floors_above_ground} 階`}
                    {property?.floors_under_ground && `地下 ${property?.floors_under_ground} 階`}
                  </>
                )}
              </Td>
            </Row>
          </div>
          <Row
            className={`
              ${
                is_property_business_infomaion_item_names.includes('仕入延床坪単価') ? 'hidden' : ''
              }
            `}
            label={<Th left={true}>仕入延床坪単価</Th>}
          >
            <Td>
              {window.location.href.includes('display_items')
                ? '2,754,820円'
                : property?.total_floor_area
                ? `${cammedFormat(property?.building_unit_price_yield, 0)}円`
                : ''}
            </Td>
          </Row>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('工事費坪単価')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('本体工事費') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('工事費坪単価')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  本体工事費
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '10,000,000円'
                  : property?.building_construction_cost
                  ? `${formatNumber(property?.building_construction_cost)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('本体工事費')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('工事費坪単価') ? 'hidden' : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('本体工事費')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  <span className="flex items-center gap-1">
                    <span>工事費坪単価</span>
                    <Tooltip
                      title={
                        <span>
                          企業毎に計算式を変更できます。 <br />
                          現在の計算式は{' '}
                          {(property?.user?.is_custom_construction_unit_price ||
                            company?.is_custom_construction_unit_price) === true
                            ? '本体工事費 ÷ 専有面積(坪)'
                            : '本体工事費 ÷ 延床面積(坪)'}{' '}
                          です。
                        </span>
                      }
                    >
                      <span className="cursor-help">
                        <InfomationIcon size={10} />
                      </span>
                    </Tooltip>
                  </span>
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '10,000円'
                  : property?.construction_unit_price
                  ? `${formatNumber(property?.construction_unit_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('検査済証')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('竣工日') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('検査済証')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  竣工日
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '2023/01/01'
                  : property?.build_completed_at
                  ? format(parseISO(property?.build_completed_at), 'yyyy/MM/dd')
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('竣工日')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('検査済証') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('竣工日') ? 'md:w-2/12' : ''
                  }`}
                >
                  検査済証
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '有'
                  : property?.certificate_of_inspection || ''}
              </Td>
            </Row>
          </div>
          <Row
            className={`
              ${is_property_business_infomaion_item_names.includes('売上日') ? 'hidden' : ''}
            `}
            label={<Th left={true}>売上日</Th>}
          >
            <Td>
              {window.location.href.includes('display_items')
                ? '2024/10/01'
                : property?.selled_at
                ? format(parseISO(property?.selled_at), 'yyyy/MM/dd')
                : ''}
            </Td>
          </Row>
          <Row
            className={`
              ${is_property_business_infomaion_item_names.includes('買主') ? 'hidden' : ''}
            `}
            label={<Th left={true}>買主</Th>}
          >
            <Td>{window.location.href.includes('display_items') ? '買主' : property?.buyer}</Td>
          </Row>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売建物価格')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('販売土地価格') ? 'hidden' : ''
                }
                `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('販売建物価格')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  販売土地価格
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '100,000,000円'
                  : property?.land_price
                  ? `${formatNumber(property.land_price)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売土地価格')
                    ? 'w-full'
                    : 'md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('販売建物価格') ? 'hidden' : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('販売土地価格')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  販売建物価格
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '100,000,000円'
                  : property?.building_price
                  ? `${formatNumber(property?.building_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('税込売上高')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('税抜売上高') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('税込売上高')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  税抜売上高
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '200,000,000円'
                  : property?.selling_price_without_tax
                  ? `${formatNumber(property?.selling_price_without_tax)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('税抜売上高')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('税込売上高') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('税抜売上高')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  税込売上高
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '210,000,000円'
                  : property?.selling_price
                  ? `${formatNumber(property?.selling_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売一種単価')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('販売坪単価') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('販売一種単価')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  販売坪単価
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '5,340,793円'
                  : property?.selling_unit_price
                  ? `${formatNumber(property?.selling_unit_price)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売坪単価')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('販売一種単価') ? 'hidden' : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('販売坪単価')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  販売一種単価
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '1,780,264円'
                  : property?.selling_primary_unit_price
                  ? `${formatNumber(property?.selling_primary_unit_price)}円`
                  : ''}
              </Td>
            </Row>
          </div>
          <Row
            className={`
              ${
                is_property_business_infomaion_item_names.includes('諸経費合計(販売)')
                  ? 'hidden'
                  : ''
              }
            `}
            label={<Th left={true}>諸経費合計(販売)</Th>}
          >
            <Td>
              {window.location.href.includes('display_items')
                ? '10,000,000円'
                : property?.total_expense_cost_price
                ? `${formatNumber(property?.total_expense_cost_price)}円`
                : ''}
            </Td>
          </Row>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('事業収支率')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('事業収支') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('事業収支率')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  事業収支
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '10,000,000円'
                  : property?.business_income
                  ? `${formatNumber(property?.business_income)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('事業収支')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${is_property_business_infomaion_item_names.includes('事業収支率') ? 'hidden' : ''}
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('事業収支')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  事業収支率
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '5.00%'
                  : property?.business_income_yield
                  ? `${Number(property?.business_income_yield).toFixed(2)}%`
                  : ''}
              </Td>
            </Row>
          </div>
          <Row
            className={`
              ${
                is_property_business_infomaion_item_names.includes('稼働状況') ? 'hidden' : 'w-full'
              }
            `}
            label={<Th left={true}>稼働状況</Th>}
          >
            <Td>
              {window.location.href.includes('display_items')
                ? '100%'
                : property?.tenancy_rate && Number(property?.tenancy_rate) !== 0
                ? `${(Number(property?.tenancy_rate) * 10000) / 100}%`
                : ''}
            </Td>
          </Row>
          <Row
            className={`
              ${
                is_property_business_infomaion_item_names.includes('平均賃料（坪）') ? 'hidden' : ''
              }
            `}
            label={<Th left={true}>平均賃料（坪）</Th>}
          >
            <Td>
              {window.location.href.includes('display_items')
                ? '100,000円'
                : property?.rent_per_tsubo
                ? `${formatNumber(property?.rent_per_tsubo)}円`
                : ''}
            </Td>
          </Row>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売表面利回り')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('総額賃料（税込）/ 月額')
                    ? 'hidden'
                    : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('販売表面利回り')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  総額賃料（税込）/ 月額
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '1,000,000円'
                  : property?.total_rent
                  ? `${formatNumber(property?.total_rent)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売表面利回り')
                    ? 'hidden'
                    : 'w-full md:w-1/2'
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('総額賃料（税込）/ 月額')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  販売表面利回り
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '5.71%'
                  : property?.prospective_yield
                  ? `${Number(property?.prospective_yield).toFixed(2)}%`
                  : ''}
              </Td>
            </Row>
          </div>
          <div className="flex flex-wrap">
            <Row
              className={`
                ${
                  is_property_business_infomaion_item_names.includes('販売想定NOI利回り')
                    ? 'w-full'
                    : 'w-full md:w-1/2'
                }
                ${
                  is_property_business_infomaion_item_names.includes('想定NOI（税込）/ 月額')
                    ? 'hidden'
                    : ''
                }
              `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('販売想定NOI利回り')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  想定NOI（税込）/ 月額
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '1,000,000円'
                  : property?.prospective_noi
                  ? `${formatNumber(property?.prospective_noi)}円`
                  : ''}
              </Td>
            </Row>
            <Row
              className={`
                  ${
                    is_property_business_infomaion_item_names.includes('販売想定NOI利回り')
                      ? 'hidden'
                      : 'w-full md:w-1/2'
                  }
                `}
              label={
                <Th
                  left={true}
                  column={2}
                  className={`${
                    is_property_business_infomaion_item_names.includes('想定NOI（税込）/ 月額')
                      ? 'md:w-2/12'
                      : ''
                  }`}
                >
                  販売想定NOI利回り
                </Th>
              }
            >
              <Td column={2}>
                {window.location.href.includes('display_items')
                  ? '5.71%'
                  : property?.prospective_noi_yield
                  ? `${Number(property?.prospective_noi_yield).toFixed(2)}%`
                  : ''}
              </Td>
            </Row>
          </div>
        </div>
      </Table>
    </>
  )
}
