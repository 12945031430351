import * as React from 'react'
import Base from '@/components/Layout/Base'
import { User } from '@/types/user'
import { DisplayItem , UserDisplayItem } from '@/types/displayItem'
import { RequiredItem } from '@/types/requiredItem'
import { Breadcrumb } from '@/components/Breadcrumb'
import { MarketDatum } from '@/types/marketDatum'
import type { Property } from '@/types/property'
import PropertyDetailSetting from '@/components/Page/DisplayItems/PropertyDetailSetting'
import BaloonSetting from '@/components/Page/DisplayItems/BaloonSetting'

type Props = {
  current_user: User
  property?: Property
  display_item_data: DisplayItem[]
  balloon_dummy: MarketDatum
  required_item_data: RequiredItem[]
  authenticity_token: string
  error_messages: string[]
}

const DisplayItemsShowPage: React.FC<Props> = ({
  property,
  current_user,
  display_item_data,
  balloon_dummy,
  required_item_data,
  authenticity_token,
  error_messages,
}) => {
  const isEnableBalloonSetting = current_user.company.pricing_type === 'enterprise' || current_user.company.pricing_type === 'owner'
  const title = '表示項目設定'
  const [selectedOption, setSelectedOption] = React.useState('detail')
  React.useEffect(() => {
    if (window.location.href.includes('display_name=marketdatum')) {
      setSelectedOption("surrounding");
    } else {
      setSelectedOption("detail");
    }
  }, []);
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <h1
        className={`${
          window.location.href.includes('for_property&land') ? 'mt-5 font-bold' : 'hidden'
        }`}
      >
        土地
      </h1>
      <h1
        className={`${
          window.location.href.includes('for_property&building') ? 'mt-5 font-bold' : 'hidden'
        }`}
      >
        土地＋建物（一棟）
      </h1>
      <h1
        className={`${
          window.location.href.includes('for_property&classification') ? 'mt-5 font-bold' : 'hidden'
        }`}
      >
        土地＋建物（区分）
      </h1>
      <div className="mb-6">
      {isEnableBalloonSetting && (
        <select
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
          className="w-full md:w-64 p-2 border rounded-lg focus:outline-none"
        >
          <option value="detail">物件詳細画面</option>
          <option value="surrounding">周辺事例画面(バルーン)</option>
        </select>
      )}
      </div>
      {error_messages.length > 0 && (
        <ul className="mb-6 text-sm text-red">
          {error_messages.map((message, i) => (
            <li key={i} className="mb-2 break-all">
              {message}
            </li>
          ))}
        </ul>
      )}
      {selectedOption === 'detail' && (
        <PropertyDetailSetting
          current_user={current_user}
          display_item_data={display_item_data}
          required_item_data={required_item_data}
          authenticity_token={authenticity_token}
          error_message={error_messages}
        />
      )}
      {isEnableBalloonSetting && selectedOption === 'surrounding' && (
        <BaloonSetting
          current_user={current_user}
          display_item_data={display_item_data}
          balloon_dummy={balloon_dummy}
          authenticity_token={authenticity_token}
          error_message={error_messages}
        />
      )}
    </Base>
  )
}

export default DisplayItemsShowPage
