// -----------------------------------------
// 一覧表示の定数ファイル
// -----------------------------------------

/////////////////////////
// (HOME/設定/帳票フォーム設定)
// (HOME/物件管理/帳票作成/周辺事例)
/////////////////////////

// 固定カラムのラベル定義
export const COLUMN_LABELS = {
  no: 'No.',
  name: '物件名',
}

// 固定カラムの配列
export const FIXED_COLUMNS = [COLUMN_LABELS.no, COLUMN_LABELS.name]

// サンプル値（表示確認用）
export const SAMPLE_VALUES = ['1', 'テストビル']

// 非表示項目の初期値配列
export const INITIAL_NOT_DISPLAY_ITEMS = Array(Object.keys(COLUMN_LABELS).length).fill(false)

// 非表示設定用のキー配列
export const HIDDEN_SEND_COLUMNS = Object.keys(COLUMN_LABELS)

// ヘッダーの固定スタイル
export const HEAD_STICKY_STYLES = {
  0: 'md:sticky md:left-0 px-12 z-10',
  1: 'md:sticky md:left-[100px] px-12 z-10',
}

// ボディの固定スタイル
export const BODY_STICKY_STYLES = {
  0: 'md:sticky left-0 z-10',
  1: 'md:sticky left-[100px] z-10',
}
