import * as React from 'react'
import { Row, Th, Td } from '@/components/Table'
import { Button } from '@/components/Button'
import { usageOptions, altitudeAreaOptions, firePreventionAreaOptions } from './usage'
import {Input} from "@/components/Input";
import {dataPrecisionSetup} from "@/utils/cammedFormat";

type Props = {
  GoogleMapVolumeRef
  usageList
  setUsageList
  drawnPolygons
  addRefresh
  parametersChanged
  shadeValue
}


const UsageForm3: React.FC<Props> = ({
    GoogleMapVolumeRef,
    usageList,
    setUsageList,
    drawnPolygons,
    addRefresh,
    parametersChanged,
    shadeValue,
}) => {
  const isAdd = React.useCallback(() => {
    const isSelectUsage = usageList.filter((item) => item.usage === '0')
    const isSelectBuildingRatio = usageList.filter((item) => Number(item.buildingRatio) === 0)
    const isSelectVolumeRatio = usageList.filter((item) => Number(item.volumeRatio) === 0)
    const allIdsExist: boolean = usageList.every(item1 =>
        drawnPolygons.current.some(item2 => item2.id === item1.id)
    );
    return !(isSelectBuildingRatio.length === 0 && isSelectVolumeRatio.length === 0 && isSelectUsage.length === 0 && usageList.length === drawnPolygons.current.length && allIdsExist)
  }, [usageList, addRefresh])
  return (
    <>
      <Row label={<Th>日影規制</Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
              <Input
                  className="md:w-[314px]"
                  id={`beyond5`}
                  name={`beyond5`}
                  prefix='日影範囲5M超'
                  suffix="時間"
                  value={shadeValue.current.beyond5}
                  onChange={(e) => {
                    shadeValue.current.beyond5 = e.target.value
                    const updatedList = usageList.map(item => ({
                      ...item,
                      beyond5: e.target.value,
                    }));
                    setUsageList(updatedList)
                    parametersChanged()
                  }}
                  onBlur={(e) => {
                    const value = dataPrecisionSetup(e.target.value, 12, 1)
                    shadeValue.current.beyond5 = value
                    const updatedList = usageList.map(item => ({
                      ...item,
                      beyond5: value,
                    }));
                    setUsageList(updatedList)
                    parametersChanged()
                  }}
              />
            </div>
            <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
              <Input
                  className="md:w-[314px]"
                  id={`beyond10`}
                  name={`beyond10`}
                  prefix='日影範囲10M超'
                  suffix="時間"
                  value={shadeValue.current.beyond10}
                  onChange={(e) => {
                    shadeValue.current.beyond10 = e.target.value
                    const updatedList = usageList.map((item) => ({
                      ...item,
                      beyond10: e.target.value,
                    }));
                    setUsageList(updatedList)
                    parametersChanged()
                  }}
                  onBlur={(e) => {
                    const value = dataPrecisionSetup(e.target.value, 12, 1)
                    shadeValue.current.beyond10 = value
                    const updatedList = usageList.map(item => ({
                      ...item,
                      beyond10: value,
                    }));
                    setUsageList(updatedList)
                    parametersChanged()
                  }}
              />
            </div>
          </div>
        </Td>
      </Row>
      <Row label={<Th></Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
              <Input
                  className="md:w-[314px]"
                  id={`waterPlan`}
                  name={`waterPlan`}
                  prefix='日影測定水平面'
                  suffix="m"
                  value={shadeValue.current.waterPlan}
                  onChange={(e) => {
                    shadeValue.current.waterPlan = e.target.value
                    const updatedList = usageList.map((item) => ({
                      ...item,
                      waterPlan: e.target.value,
                    }));
                    setUsageList(updatedList)
                    parametersChanged()
                  }}
                  onBlur={(e) => {
                    const value = dataPrecisionSetup(e.target.value, 12, 1)
                    shadeValue.current.waterPlan = value
                    const updatedList = usageList.map((item) => ({
                      ...item,
                      waterPlan: value,
                    }));
                    setUsageList(updatedList)
                    parametersChanged()
                  }}
              />
            </div>
          </div>
        </Td>
      </Row>
      {
        usageList.map((item, i) => (
          <React.Fragment key={item.id}>
            <Row label={<Th>領域{i + 1}</Th>}>
              <Td>
                <div className="flex gap-4 flex-col md:flex-row">
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <span className={[styles.box, 'rounded-r-none'].join(' ')}>用途地域</span>
                    <select
                        id={`usage_${item.id}`}
                        name={`usage_${item.id}`}
                        className={[
                          styles.backSelect,
                          styles.size['default'],
                          'md:w-[314px]',
                          styles.prefix,
                        ].join(' ')}
                        value={item.usage}
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        onChange={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = { ...updatedList[i], usage: e.target.value };
                          setUsageList(updatedList);
                          GoogleMapVolumeRef?.current.mapAreaChange(item.id, 'usage', e.target.value)
                          parametersChanged()
                        }}
                    >
                      {usageOptions.map((option, i) => (
                          <option key={item.id + option.value} value={option.value}>
                            {option.text}
                          </option>
                      ))}
                    </select>
                    <Button
                        outline
                        size="small"
                        type="button"
                        className={usageList.length > 1 ? "border-orange-400 text-orange-400 ml-2": 'row_hidden'}
                        onClick={(e) => {
                          e.stopPropagation()
                          GoogleMapVolumeRef?.current.removeDrawButton(i, item.id)
                        }}
                    >削除</Button>
                  </div>
                </div>
              </Td>
            </Row>
            <Row label={<Th></Th>}>
              <Td>
                <div className="flex gap-4 flex-col md:flex-row">
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <Input
                        className="md:w-[314px]"
                        id={`buildingRatio_${item.id}`}
                        name={`buildingRatio_${item.id}`}
                        prefix='建ペい率'
                        suffix="%"
                        value={item.buildingRatio}
                        onChange={(e) => {
                          const value = e.target.value
                          if (/^\d*$/.test(value)) {
                            const updatedList = [...usageList];
                            updatedList[i] = {...updatedList[i], buildingRatio: value};
                            setUsageList(updatedList)
                            parametersChanged()
                          }
                        }}
                        onBlur={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = {...updatedList[i], buildingRatio: Number(e.target.value)};
                          setUsageList(updatedList)
                          parametersChanged()
                        }}
                    />
                  </div>
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <Input
                        className="md:w-[314px]"
                        id={`volumeRatio_${item.id}`}
                        name={`volumeRatio_${item.id}`}
                        prefix='容積率'
                        suffix="%"
                        value={item.volumeRatio}
                        onChange={(e) => {
                          const value = e.target.value
                          if (/^\d*$/.test(value)) {
                            const updatedList = [...usageList];
                            updatedList[i] = {...updatedList[i], volumeRatio: value};
                            setUsageList(updatedList)
                            parametersChanged()
                          }
                        }}
                        onBlur={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = {...updatedList[i], volumeRatio: Number(e.target.value)};
                          setUsageList(updatedList)
                          parametersChanged()
                        }}
                    />
                  </div>
                </div>
              </Td>
            </Row>
            <Row label={<Th></Th>}>
              <Td>
                <div className="flex gap-4 flex-col md:flex-row">
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <span className={[styles.box, 'rounded-r-none'].join(' ')}>防火地域</span>
                    <select
                        id={`firePreventionArea_${item.id}`}
                        name={`firePreventionArea_${item.id}`}
                        className={[
                          styles.backSelect,
                          styles.size['default'],
                          'md:w-[314px]',
                          styles.prefix,
                        ].join(' ')}
                        value={item.firePreventionArea}
                        onChange={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = { ...updatedList[i], firePreventionArea: e.target.value };
                          setUsageList(updatedList);
                          parametersChanged()
                        }}
                    >
                      {firePreventionAreaOptions.map((option, i) => (
                          <option key={option.value + item.id} value={option.value}>
                            {option.text}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Td>
            </Row>
            <Row label={<Th></Th>}>
              <Td>
                <div className="flex gap-4 flex-col md:flex-row">
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <span className={[styles.box, 'rounded-r-none'].join(' ')}>高度地区</span>
                    <select
                        id={`altitudeArea_${item.id}`}
                        name={`altitudeArea_${item.id}`}
                        className={[
                          styles.backSelect,
                          styles.size['default'],
                          'md:w-[314px]',
                          styles.prefix,
                        ].join(' ')}
                        value={item.altitudeArea}
                        onChange={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = { ...updatedList[i], altitudeArea: e.target.value };
                          setUsageList(updatedList);
                          parametersChanged()
                        }}
                    >
                      {altitudeAreaOptions.map((option, i) => (
                          <option key={item.id + option.value} value={option.value}>
                            {option.text}
                          </option>
                      ))}
                    </select>
                  </div>
                </div>
              </Td>
            </Row>
            <Row label={<Th></Th>}>
              <Td>
                <div className="flex gap-4 flex-col md:flex-row">
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <Input
                        className="md:w-[314px]"
                        id={`maxHeight_${item.id}`}
                        name={`maxHeight_${item.id}`}
                        prefix='最高高度'
                        suffix="m"
                        value={item.maxHeight}
                        onChange={(e) => {
                          const value = e.target.value
                          if (/^\d*$/.test(value)) {
                            const updatedList = [...usageList];
                            updatedList[i] = {...updatedList[i], maxHeight: value};
                            setUsageList(updatedList)
                            parametersChanged()
                          }
                        }}
                        onBlur={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = {...updatedList[i], maxHeight: Number(e.target.value)};
                          setUsageList(updatedList)
                          parametersChanged()
                        }}
                    />
                  </div>
                  <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
                    <Input
                        className="md:w-[314px]"
                        id={`minHeight_${item.id}`}
                        name={`minHeight_${item.id}`}
                        prefix='最低高度'
                        suffix="m"
                        value={item.minHeight}
                        onChange={(e) => {
                          const value = e.target.value
                          if (/^\d*$/.test(value)) {
                            const updatedList = [...usageList];
                            updatedList[i] = {...updatedList[i], minHeight: value};
                            setUsageList(updatedList)
                            parametersChanged()
                          }
                        }}
                        onBlur={(e) => {
                          const updatedList = [...usageList];
                          updatedList[i] = {...updatedList[i], minHeight: Number(e.target.value)};
                          setUsageList(updatedList)
                          parametersChanged()
                        }}
                    />
                  </div>
                </div>
              </Td>
            </Row>
          </React.Fragment>
        ))
      }
      <Row label={<Th></Th>}>
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <div className={[styles.inputWrap, 'md:w-[314px]'].join(' ')}>
              <Button
                  outline
                  size="small"
                  type="button"
                  disabled={isAdd()}
                  onClick={() => {
                    GoogleMapVolumeRef?.current.handleAddNewPolygon()
                  }}
              >
                用途地域追加
              </Button>
            </div>
          </div>
        </Td>
      </Row>
    </>
  )
}

export default UsageForm3

const styles = {
  select: `
    w-full
    py-2
    pl-4
    pr-10
    text-sm
    appearance-none
    bg-no-repeat
    bg-center
    border
    rounded-sm
    bg-white
    outline-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    bg-[url('~icons/caret_down.svg')]
    bg-[center_right_12px]
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
  checkbox: `
    relative
    inline-block
    appearance-none
    h-3.5
    w-3.5
    border
    focus:outline-none
    mt-0.5
    text-base
    align-top
    bg-no-repeat
    bg-center
    mr-2
    cursor-pointer
    rounded-sm
  `,
  checked: `
    bg-primary
    border-primary
    bg-[url('~icons/checkbox_icon.svg')]
  `,
  box: `
    flex
    items-center
    border
    border-gray-border
    rounded-sm
    bg-gray-100
    py-2
    px-4
    text-sm
    leading-normal
    text-black-base
    whitespace-nowrap
  `,
  inputWrap: `
    relative
    flex
    items-strech
  `,
  prefix: `
    rounded-l-none
    border-l-0
  `,
  size: {
    default: `
      py-2
      pl-4
      pr-10
      text-sm
    `,
  },
  backSelect: `
    w-full
    appearance-none
    bg-no-repeat
    bg-center
    border
    rounded-sm
    bg-white
    outline-none
    leading-normal
    text-black-base
    border-gray-border
    placeholder-gray-300
    bg-[url('~icons/caret_down.svg')]
    bg-[center_right_12px]
    disabled:text-gray-300
    disabled:bg-gray-light
    disabled:cursor-not-allowed
    disabled:opacity-70
  `,
}

