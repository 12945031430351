import { AnchorButton, Button } from '@/components/Button'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Table } from '@/components/Table'
import { tagColorCodes } from '@/models/tagColorCodes'
import { Tag } from '@/types/tag'
import type { User } from '@/types/user'
import { Dialog, Transition } from '@headlessui/react'
import ClearIcon from '@material-ui/icons/Clear'
import * as React from 'react'
import { Modal } from '../Modal'

const columnNames = {
  ongoing_proposal_id: 'ステータス',
  tag_name: 'タグ',
  management_code: '管理コード',
  name: '案件名',
  address_location: '所在地',
  chiban: '丁目以降（地番）',
  map_information: '地図情報（建物名）',
  property_type: '物件種目',
  area_m3: '敷地面積',
  total_floor_area: '延床面積',
  occupied_area: '建物賃貸可能面積（専有面積）',
  location_of_division: '区分の場所',
  effective_area: '有効敷地面積',
  purchace_applied_at: '買付提出日',
  purchace_contract_at: '仕入契約日',
  suggested_unit_price: '売主希望坪単価',
  suggested_primary_unit_price: '売主希望一種単価',
  seller_prospective_yield: '売主希望表面利回り',
  seller_prospective_noi_yield: '売主希望NOI利回り',
  purchase_unit_price: '買取坪単価',
  purchase_primary_unit_price: '買取一種単価',
  purchase_prospective_yield: '買取表面利回り',
  purchase_prospective_noi_yield: '買取NOI利回り',
  business_type_name: '事業形態',
  coverage_ratio: '計画建ぺい率',
  volume_rate: '計画容積率',
  selling_unit_price: '販売坪単価',
  selling_primary_unit_price: '販売一種単価',
  prospective_yield: '販売表面利回り',
  prospective_noi_yield: '販売NOI利回り',
  transportations_display: '交通機関',
  road_transports_display: '交通機関（IC）',
  road_accesses_display: '道路',
  rent_per_tsubo: '平均賃料（坪）',
}

type Props = {
  open?: boolean
  property: any
  current_user: User
  closeModal: (removeMarker: boolean, reload?: boolean) => void
}

// 外部からpropsを受け取ってモーダル表示
const MapModal: React.FC<Props> = ({ open = false, property, current_user, closeModal }) => {
  // タグを一度変更したら閉じるまで保持
  const [tagChanged, setTagChanged] = React.useState(false)
  const [tagChangeModal, setTagChangeModal] = React.useState(false)
  const tagNullColor = 'bg-gray-400'
  const [tagColor, setTagColor] = React.useState(tagNullColor)
  const [tag, setTag] = React.useState<Tag | undefined>()
  const tagChangeFormRef = React.useRef<HTMLFormElement>(null)
  const overviewColumns =
    property.id === 0
      ? ['address_location', 'map_information']
      : ['management_code','name', 'address_location', 'chiban', 'map_information', 'property_type']
  const areaColumns =
    property.property_type === '土地'
      ? ['area_m3', 'effective_area', '', 'occupied_area']
      : ['area_m3', 'effective_area', 'total_floor_area', 'occupied_area']
  const proposalColumns = [
    'purchace_applied_at',
    'purchace_contract_at',
    'suggested_unit_price',
    'suggested_primary_unit_price',
    'seller_prospective_yield',
    'seller_prospective_noi_yield',
    'purchase_unit_price',
    'purchase_primary_unit_price',
    'purchase_prospective_yield',
    'purchase_prospective_noi_yield',
  ]
  const businessColumns = [
    'coverage_ratio',
    'volume_rate',
    'selling_unit_price',
    'selling_primary_unit_price',
    'prospective_yield',
    'prospective_noi_yield',
    'rent_per_tsubo',
    '',
  ]

  if (property.property_type === '土地') {
    columnNames['occupied_area'] = '計画建物賃貸可能面積（専有面積）'
  } else {
    columnNames['occupied_area'] = '建物賃貸可能面積（専有面積）'
  }

  React.useEffect(() => {
    setTagColor(
      (property['tag_id'] && tagColorCodes[Number(property.tag_color)]?.bg) || tagNullColor
    )
  }, [property])

  const changeTagCallback = React.useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const tagId = Number(event.target.value)
      const tag = current_user.company?.tags?.find((t) => t.id === tagId)
      setTagColor((tag && tagColorCodes[tag.color_before_type_cast - 1]?.bg) || tagNullColor)
      setTag(tag)
      setTagChangeModal(true)
      setTagChanged(true)
    },
    [tagColor]
  )

  const onTagChangeModalClose = React.useCallback(() => {
    setTagChangeModal(false)
    setTagColor(
      (property['tag_id'] && tagColorCodes[Number(property.tag_color)]?.bg) || tagNullColor
    )
  }, [tagChangeModal])

  // モーダルを開いた時にモーダルの上部が表示されるように指定しています
  // 絞り込みのモーダルでは指定していないので本来は不要かもしれません
  const topRef = React.useRef(null)

  const onClose = () => {
    if (!tagChangeModal) {
      closeModal(property.id === 0, tagChanged)
    }
  }

  const isPc = window.innerWidth > 768
  const isCreate = property.id === 0
  const immedRegister = current_user?.company
    ? ['owner', 'enterprise', 'standard'].includes(current_user.company.pricing_type)
    : false

  return (
    <>
      <Transition appear show={open} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={onClose} initialFocus={topRef}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto" ref={topRef}>
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    className="text-base font-medium p-4 leading-6 text-gray-700"
                    ref={topRef}
                  >
                    物件情報
                    <span className="empty:hidden inline-flex text-sm font-bold ml-2 px-3 py-[1px] rounded-xl border-[2px] text-primary border-primary">
                      {property['ongoing_proposal_id'] ? '検討中' : ''}
                    </span>
                    {!isCreate && property.editable && (
                      <select
                        name="property[tag_id]"
                        onChange={changeTagCallback}
                        className={[
                          'empty:hidden inline-flex text-sm font-bold ml-2 px-3 py-[4px] rounded-full border-[2px] border-transparent text-white',
                          tagColor,
                        ].join(' ')}
                      >
                        <option value="">タグ未設定</option>
                        {current_user.company?.tags?.map((t) => (
                          <option
                            key={t.id}
                            value={t.id}
                            className={tagColorCodes[t.id]?.bg}
                            selected={property.tag_id === t.id}
                          >
                            {t.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {!property.editable && (
                      <span
                        className={[
                          'empty:hidden inline-flex text-sm font-bold ml-2 px-3 py-[1px] rounded-xl border-[2px] border-transparent text-white',
                          property['tag_id']
                            ? tagColorCodes[property['tag_color']]?.bg
                            : tagNullColor,
                        ].join(' ')}
                      >
                        {property['tag_id'] ? property['tag_name'] : 'タグ未設定'}
                      </span>
                    )}
                    <ClearIcon className="float-right cursor-pointer" onClick={onClose} />
                  </Dialog.Title>
                  <div className="border-t border-gray-150"></div>

                  <div className="mt-2 p-4">
                    <div className="bg-primary text-white text-sm font-medium py-2 px-4">概要</div>
                    <Table className="border-b border-[#3885B0] mb-4">
                      <div className="flex flex-wrap">
                        {overviewColumns.map((column) => (
                          <Row
                            key={column}
                            className="w-full"
                            label={
                              <Th left={true} column={4}>
                                {columnNames[column]}
                              </Th>
                            }
                          >
                            <Td column={7}>
                              {column === 'address_location' ? (
                                <>
                                  <span>{property['prefecture']}</span>
                                  <span>{property['city']}</span>
                                  <span>{property['town']}</span>
                                  <span>{property['chome']}</span>
                                </>
                              ) : (
                                <span>{property[column]}</span>
                              )}
                            </Td>
                          </Row>
                        ))}
                        {property.id !== 0 &&
                          areaColumns.map((column) => (
                            <Row
                              key={column}
                              className="w-full md:w-1/2"
                              label={
                                <Th left={true} column={5}>
                                  {columnNames[column]}
                                </Th>
                              }
                            >
                              <Td column={8}>{property[`${column}_with_tsubo`]}</Td>
                            </Row>
                          ))}
                      </div>
                      {property.id !== 0 && property.property_type === '土地＋建物（区分）' && (
                        <Row
                          className="w-full"
                          label={
                            <Th left={true} column={4}>
                              {columnNames['location_of_division']}
                            </Th>
                          }
                        >
                          <Td column={7}>{property['location_of_division']}</Td>
                        </Row>
                      )}
                      {property.id !== 0 && (
                        <Row
                          className="w-full"
                          label={
                            <Th left={true} column={4}>
                              {columnNames['transportations_display']}
                            </Th>
                          }
                        >
                          <Td column={7}>
                            {property &&
                              property['transportations_display'] &&
                              property['transportations_display'].map((transportation, index) => (
                                <div
                                  key={index}
                                  className={['w-full', index > 0 && 'mt-2'].join(' ')}
                                >
                                  {transportation}
                                </div>
                              ))}
                          </Td>
                        </Row>
                      )}
                      {property.id !== 0 && (
                        <Row
                          className="w-full"
                          label={
                            <Th left={true} column={4}>
                              {columnNames['road_transports_display']}
                            </Th>
                          }
                        >
                          <Td column={7}>
                            {property &&
                              property['road_transports_display'] &&
                              property['road_transports_display'].map((road_transport, index) => (
                                <div
                                  key={index}
                                  className={['w-full', index > 0 && 'mt-2'].join(' ')}
                                >
                                  {road_transport}
                                </div>
                              ))}
                          </Td>
                        </Row>
                      )}
                      {property.id !== 0 && (
                        <Row
                          className="w-full"
                          label={
                            <Th left={true} column={4}>
                              {columnNames['road_accesses_display']}
                            </Th>
                          }
                        >
                          <Td column={7}>
                            {property &&
                              property['road_accesses_display'] &&
                              property['road_accesses_display'].map((road_access, index) => (
                                <div
                                  key={index}
                                  className={['w-full', index > 0 && 'mt-2'].join(' ')}
                                >
                                  {road_access}
                                </div>
                              ))}
                          </Td>
                        </Row>
                      )}
                    </Table>
                    {property.id !== 0 && (
                      <>
                        <div className="mb-4">
                          <div className="bg-primary text-white text-sm font-medium md:text-sm py-2 px-4">
                            仕入情報
                          </div>
                          <Table className="border-b border-[#3885B0] mb-4">
                            <div className="flex flex-wrap">
                              {proposalColumns.map((column) => (
                                <Row
                                  key={column}
                                  className="w-full md:w-1/2"
                                  label={
                                    <Th left={true} column={5}>
                                      {columnNames[column]}
                                    </Th>
                                  }
                                >
                                  <Td column={8}>{property[column]}</Td>
                                </Row>
                              ))}
                            </div>
                          </Table>
                        </div>

                        <div>
                          <div className="bg-primary text-white text-sm font-medium py-2 px-4">
                            事業状況
                          </div>
                          <Table className="border-b border-[#3885B0] mb-4">
                            <div className="flex flex-wrap">
                              <Row
                                className="w-full"
                                label={
                                  <Th left={true} column={4}>
                                    {columnNames['business_type_name']}
                                  </Th>
                                }
                              >
                                <Td column={7}>{property['business_type_name']}</Td>
                              </Row>
                              {businessColumns.map((column) => (
                                <Row
                                  key={column}
                                  className="w-full md:w-1/2"
                                  label={
                                    <Th left={true} column={5}>
                                      {columnNames[column]}
                                    </Th>
                                  }
                                >
                                  <Td column={8}>{property[column]}</Td>
                                </Row>
                              ))}
                            </div>
                          </Table>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="border-t border-gray-150"></div>
                  <div className="modal-footer">
                    <div className="flex flex-col md:flex-row md:justify-between">
                      <div className="px-6 md:py-3 py-0 w-full">
                        {(() => {
                          if (isCreate) {
                            return (
                              <div className={'md:flex py-2 md:py-0 w-full md:justify-between'}>
                                {immedRegister ? (
                                  isPc ? (
                                    <AnchorButton
                                      className="text-white md:w-[120px] w-full h-[36px]"
                                      size="small"
                                      variant="primary"
                                      href={`${property.url}&instant_create=true`}
                                      target="_blank"
                                    >
                                      即時登録
                                    </AnchorButton>
                                  ) : (
                                    <AnchorButton
                                      className="text-white md:w-[120px] w-full h-[36px]"
                                      size="small"
                                      variant="primary"
                                      href={property.url}
                                      target="_blank"
                                    >
                                      {property.text}
                                    </AnchorButton>
                                  )
                                ) : (
                                  <div className={'w-full'} />
                                )}
                                <div className="flex py-2 md:py-0 gap-2 md:justify-end justify-between">
                                  <Button
                                    className="text-primary-deep border-white md:w-[120px] w-full h-[36px]"
                                    outline
                                    size="small"
                                    variant="primary"
                                    onClick={onClose}
                                  >
                                    閉じる
                                  </Button>

                                  {immedRegister ? (
                                    isPc ? (
                                      <AnchorButton
                                        className="text-white md:w-[120px] w-full h-[36px]"
                                        size="small"
                                        variant="primary"
                                        href={property.url}
                                        target="_blank"
                                      >
                                        {property.text}
                                      </AnchorButton>
                                    ) : (
                                      <AnchorButton
                                        className="text-white md:w-[120px] w-full h-[36px]"
                                        size="small"
                                        variant="primary"
                                        href={`${property.url}&instant_create=true`}
                                        target="_blank"
                                      >
                                        即時登録
                                      </AnchorButton>
                                    )
                                  ) : (
                                    <AnchorButton
                                      className="text-white md:w-[120px] w-full h-[36px]"
                                      size="small"
                                      variant="primary"
                                      href={property.url}
                                      target="_blank"
                                    >
                                      {property.text}
                                    </AnchorButton>
                                  )}
                                </div>
                              </div>
                            )
                          } else {
                            return (
                              <div className="flex w-full md:w-auto gap-2 md:py-0 py-2 md:justify-end justify-between">
                                <Button
                                  className="text-primary-deep border-white md:w-[120px] w-full h-[36px]"
                                  outline
                                  size="small"
                                  variant="primary"
                                  onClick={onClose}
                                >
                                  閉じる
                                </Button>
                                <AnchorButton
                                  className="text-white md:w-[120px] w-full h-[36px]"
                                  size="small"
                                  variant="primary"
                                  href={property.url}
                                  target="_blank"
                                >
                                  {property.text}
                                </AnchorButton>
                              </div>
                            )
                          }
                        })()}
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Modal open={tagChangeModal} onClose={onTagChangeModalClose}>
        <div className="px-6 py-6 leading-10">
          タグを{property.tag_id && `${property.tag_name}から`}
          {tag?.name}変更します。よろしいですか？
        </div>

        <form ref={tagChangeFormRef}>
          <input
            type="hidden"
            name="authenticity_token"
            value={document.querySelector<HTMLMetaElement>('meta[name=csrf-token]').content}
          />
          <input type="hidden" name="_method" value={'patch'} />
          <input type="hidden" name="property[tag_id]" value={tag?.id} />
          <input
            type="hidden"
            name="redirect_to"
            value={window.location.pathname + window.location.search}
          />
        </form>

        <AnchorButton
          className="text-white md:w-[120px] w-full h-[36px]"
          size="small"
          variant="primary"
          type="button"
          onClick={() => {
            const formData = new FormData(tagChangeFormRef.current)
            fetch(`/properties/${property.hashid}`, {
              method: 'PATCH',
              body: formData,
              headers: {
                'X-CSRF-Token':
                  document.querySelector<HTMLMetaElement>('meta[name=csrf-token]').content,
              },
            }).then(() => {
              setTagChangeModal(false)
            })
          }}
        >
          はい
        </AnchorButton>
        <Button
          className="text-primary-deep border-white md:w-[120px] w-full h-[36px]"
          outline
          size="small"
          variant="primary"
          onClick={onTagChangeModalClose}
        >
          閉じる
        </Button>
      </Modal>
    </>
  )
}

const areEqual = (prevProps: Props, nextProps: Props) => {
  return prevProps.open == nextProps.open
}

export default React.memo(MapModal, areEqual)
