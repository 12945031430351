import * as React from 'react'
import { ItemTypes } from './ItemTypes'
import { Button } from '@/components/Button'
import DragIndicatorOutlined from '@material-ui/icons/DragIndicatorOutlined'
import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import { roleType } from '@/config/langs/user'
import type { User } from '@/types/user'
import { ConfirmationState } from './ConfirmationState'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export interface CardProps {
  id: string
  index: number
  user: User
  current_user: User
  checkedDefault: boolean
  confirmationUserIds: number[]
  setConfirmationUserIds: (confirmationUserIds: number[]) => void
  clearAfterConfirmation: boolean
}

export const Card: React.FC<CardProps> = ({
  id,
  index,
  user,
  current_user,
  checkedDefault,
  confirmationUserIds,
  setConfirmationUserIds,
  clearAfterConfirmation,
}) => {
  const {
    isDragging,
    setActivatorNodeRef,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: user.id.toString() })

  const userLink =
    current_user.role == 'admin' && user.role == 'system_admin'
      ? `/users/${user.id}`
      : `/users/${user.id}/edit`

  const formatDateTime = (date: string): string => {
    const d = new Date(date)
    return `${d.toLocaleString()}`
  }

  return (
    <tr
      key={id}
      ref={setNodeRef}
      style={{
        transform: transform ? CSS.Transform.toString(transform) : undefined,
        transition: transition || undefined,
      }}
      className={`${index % 2 ? 'bg-gray-light' : 'bg-white'} hover:bg-targetLine cursor-pointer ${
        isDragging ? 'z-10' : ''
      }`}
    >
      {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
        <td className="py-2 px-4 text-sm">
          <Button
            className={`text-primary-font ${isDragging ? 'cursor-grabbing' : 'cursor-grab'}`}
            prefix={<DragIndicatorOutlined fontSize="small" />}
            outline
            size="small"
            variant="primary"
            ref={setActivatorNodeRef}
            {...attributes}
            {...listeners}
          >
            <span className="text-primary-font  hidden md:block">移動</span>
          </Button>
        </td>
      )}
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {user.proxy_company ? `${user.proxy_company.name}（代理）` : user.company.name}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {user.department?.name || '　'}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {user.name || '　'}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {user.name_kana || '　'}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {user.email}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {formatDateTime(user.created_at)}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {formatDateTime(user.updated_at)}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {roleType[user.role]}
        </a>
      </td>
      <td>
        <a href={userLink} className="block w-full h-full py-2 px-4 text-sm">
          {user.is_volume_check ? 'あり' : ''}
        </a>
      </td>
      {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
        <td>
          <a
            href={
              current_user.role == 'admin' && user.role == 'system_admin'
                ? `/users/${user.id}`
                : `/users/${user.id}/edit`
            }
            className="block w-full h-full py-2 px-4 text-sm"
          >
            {user.locked_at ? formatDateTime(user.locked_at) : '　'}
          </a>
        </td>
      )}
      {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
        <td>
          <ConfirmationState
            user={user}
            current_user={current_user}
            checkedDefault={checkedDefault}
            confirmationUserIds={confirmationUserIds}
            setConfirmationUserIds={setConfirmationUserIds}
            clearAfterConfirmation={clearAfterConfirmation}
          />
        </td>
      )}
      <td className="py-2 px-4 text-right">
        <a
          className="inline-block w-[30px] hover:text-deleteHover"
          data-confirm={`\u0022${user.name}\u0022を削除しますか？`}
          rel="nofollow"
          data-method="delete"
          href={`/users/${user.id}`}
        >
          <DeleteOutlined fontSize="small" />
        </a>
      </td>
    </tr>
  )
}
