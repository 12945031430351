import {FC} from "react";
import * as React from "react";
import { DrawingOnTimeShadowLines} from "@/components/Page/VolumeCheckRequests/DrawingViewer/Drawing";
export type ShadowLinesProps = {
  shadowLines: DrawingOnTimeShadowLines;
  selectedShadowLine: number;
};
const ShadowLines: FC<ShadowLinesProps> = ({
  shadowLines,
  selectedShadowLine
}) => {

  let displayShadowLines: DrawingOnTimeShadowLines = {polygons: [], chars: []}

  switch (selectedShadowLine) {
    case 1:
      displayShadowLines.polygons = shadowLines.polygons.filter(polygon => !polygon.PenLayer.startsWith('時刻日影線'))
      displayShadowLines.chars = shadowLines.chars.filter(char => !char.FontLayer.startsWith('時刻日影線'))
      break;
    case 2:
      displayShadowLines.polygons = shadowLines.polygons.filter(polygon => polygon.PenLayer.startsWith('時刻日影線') || polygon.PenLayer.startsWith('日影規制線'))
      displayShadowLines.chars = shadowLines.chars.filter(char => char.FontLayer.startsWith('時刻日影線') || char.FontLayer.startsWith('日影規制線'))
      break;
    default:
      displayShadowLines = shadowLines
      break;
  }

  function dashArrayType(penLayer: string, type: string) {
    let dashArray: string | undefined;

    switch (type) {
      case '0':
        dashArray = undefined;
        break;
      case '1':
        dashArray = '300, 90';
        break;
      case '2':
        dashArray = '30, 30';
        break;
      default:
        dashArray = undefined;
        break;
    }

    if (penLayer.endsWith('時半')) {
      dashArray = '300, 90';
    }

    return dashArray;
  }

  function checkColor(penLayer: string, penColor: string) {
    if (penLayer.startsWith('等時間日影線')) {
      return `#${Number(16711680).toString(16)}`
    } else if (penLayer.startsWith('日影規制線')) {
      return `#${Number(16749363).toString(16)}`
    } else {
      return `#${Number(penColor).toString(16)}`
    }
  }

  function checkWidth(penLayer: string, penWidth: string) {
    if (penLayer.startsWith('等時間日影線')) {
      return '90'
    } else if (penLayer.startsWith('日影規制線')) {
      return '30'
    } else {
      return penWidth
    }
  }

  function checkText(fontLayer: string, text: string) {
    if (fontLayer.startsWith('等時間日影線')) {
      return fontLayer
    } else {
      return text
    }
  }

  return (
    <g>
      {displayShadowLines.polygons?.map((polygon, index) => {
        return (
          <polyline
            key={`shadowLine-${index}`}
            stroke={checkColor(polygon.PenLayer, polygon.PenColor)}
            strokeWidth={checkWidth(polygon.PenLayer, polygon.PenWidth)}
            strokeDasharray={dashArrayType(polygon.PenLayer, polygon.PenStyle)}
            fill="none"
            points={polygon.points.map((xy)=> `${xy.x},${xy.y}`).join(' ')}
          />
        )
      })}
      {displayShadowLines.chars?.map((char) => {
        return char.chars.map((item,index) => {
          return (
            <text
              key={`shadowText-${char.FontNumber}-${index}`}
              fontSize={160}
              textAnchor="middle"
              color={`#${Number(char.FontColor).toString(16)}`}
              alignmentBaseline="baseline"
              dominantBaseline="central"
              x={item.x}
              y={item.y}
              transform={`rotate(${char.FontRote}, ${item.x}, ${item.y})`}
            >
              {checkText(char.FontLayer, item.text)}
            </text>
          )
        })
      })}
    </g>
  )
}

export {ShadowLines};