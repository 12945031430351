import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined'
import ClassOutlinedIcon from '@material-ui/icons/ClassOutlined'
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined'
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined'

import type { PricingType } from '@/types/pricingType'
import type { Company } from '@/types/company'
import * as React from 'react'

// enterpriseとownerが使用可能な機能は同じ
const menusEnterprise = [
  { title: '物件登録', path: '/properties', icon: <HomeWorkOutlinedIcon /> },
  { title: '入手情報一覧', path: '/proposals', icon: <BusinessCenterOutlinedIcon /> },
  { title: '案件一覧', path: '/projects_business_types', icon: <ClassOutlinedIcon /> },
  { title: '分析情報', path: '/analyses?type=monthly', icon: <AssessmentOutlinedIcon /> },
  { title: 'CSVインポート', path: '/imports', icon: <CloudUploadOutlinedIcon /> },
  { title: '周辺事例一覧', path: '/market_data', icon: <AddLocationAltOutlinedIcon /> },
  { title: '設定', path: '/companies', icon: <SettingsOutlinedIcon /> },
]

const menusStandardIncludes = [
  '物件登録',
  '入手情報一覧',
  '案件一覧',
  '分析情報',
  'CSVインポート',
  '設定',
]
const menusBasicPlusIncludes = ['物件登録', '入手情報一覧', '分析情報', 'CSVインポート', '設定']
const menusBasicIncludes = ['物件登録', '入手情報一覧', 'CSVインポート', '設定']

export const menusPricingType = {
  owner: menusEnterprise,
  enterprise: menusEnterprise,
  standard: menusEnterprise.filter((menu) => menusStandardIncludes.includes(menu.title)),
  basic: menusEnterprise.filter((menu) => menusBasicIncludes.includes(menu.title)),
}

const subMenuOptionMenusOwner = {
  settings: [
    { title: '利用企業設定', path: '/companies' },
    { title: '部署設定', path: '/departments' },
    { title: 'ユーザー設定', path: '/users' },
    { title: 'タグ設定', path: '/tags' },
    { title: '事業形態設定', path: '/business_types' },
    { title: '必須項目設定', path: '/required_items' },
    { title: '表示項目設定', path: '/display_items' },
    { title: '帳票フォーム設定', path: '/market_datum_report_layouts' },
    { title: '即時登録設定', path: '/instant_registration' },
    { title: '登記情報利用明細', path: '/touki_account_statement' },
    { title: 'OCR利用明細', path: '/ocr_usage_detail' },
    { title: 'kintone連携設定', path: '/kintone_setting' },
    { title: 'ジョブ結果', path: '/job_result' },
    { title: 'T&P運用設定', path: '/time_frame' },
  ],
  properties: [
    { title: '入手情報', path: '?tab=proposals' },
    { title: '物件詳細', path: '?tab=property_details' },
    { title: '関連資料', path: '?tab=attachments' },
    { title: '周辺情報', path: '?tab=surroundings' },
    { title: '帳票作成', path: '?tab=market_datum_report' },
    { title: '公開ページ', path: '?tab=public_files' },
    { title: '登記情報', path: '?tab=touki' },
    { title: 'Volume Check', path: '?tab=volume_check' },
  ],
}

const subMenuOptionMenusEnterprise = {
  settings: [
    { title: '利用企業設定', path: '/companies' },
    { title: '部署設定', path: '/departments' },
    { title: 'ユーザー設定', path: '/users' },
    { title: 'タグ設定', path: '/tags' },
    { title: '事業形態設定', path: '/business_types' },
    { title: '必須項目設定', path: '/required_items' },
    { title: '表示項目設定', path: '/display_items' },
    { title: '帳票フォーム設定', path: '/market_datum_report_layouts' },
    { title: '即時登録設定', path: '/instant_registration' },
    { title: 'ファイル容量確認', path: '/file_capacities' },
    { title: '登記情報利用明細', path: '/touki_account_statement' },
    { title: 'OCR利用明細', path: '/ocr_usage_detail' },
    { title: 'kintone連携設定', path: '/kintone_setting' },
    { title: 'T&P運用設定', path: '/time_frame' },
  ],
  properties: [
    { title: '入手情報', path: '?tab=proposals' },
    { title: '物件詳細', path: '?tab=property_details' },
    { title: '関連資料', path: '?tab=attachments' },
    { title: '周辺情報', path: '?tab=surroundings' },
    { title: '帳票作成', path: '?tab=market_datum_report' },
    { title: '公開ページ', path: '?tab=public_files' },
    { title: '登記情報', path: '?tab=touki' },
    { title: 'Volume Check', path: '?tab=volume_check' },
  ],
}

const subMenuOptionMenusStandard = {
  settings: [
    { title: '利用企業設定', path: '/companies' },
    { title: '部署設定', path: '/departments' },
    { title: 'ユーザー設定', path: '/users' },
    { title: 'タグ設定', path: '/tags' },
    { title: '事業形態設定', path: '/business_types' },
    { title: '必須項目設定', path: '/required_items' },
    { title: '表示項目設定', path: '/display_items' },
    { title: '即時登録設定', path: '/instant_registration' },
    { title: 'ファイル容量確認', path: '/file_capacities' },
  ],
  properties: [
    { title: '入手情報', path: '?tab=proposals' },
    { title: '物件詳細', path: '?tab=property_details' },
    { title: '関連資料', path: '?tab=attachments' },
    { title: '周辺情報', path: '?tab=surroundings' },
  ],
}

const subMenuOptionMenusBasic = {
  settings: [
    { title: '利用企業設定', path: '/companies' },
    { title: '部署設定', path: '/departments' },
    { title: 'ユーザー設定', path: '/users' },
    { title: 'タグ設定', path: '/tags' },
    { title: '事業形態設定', path: '/business_types' },
    { title: '必須項目設定', path: '/required_items' },
    { title: '表示項目設定', path: '/display_items' },
    { title: 'ファイル容量確認', path: '/file_capacities' },
  ],
  properties: [
    { title: '入手情報', path: '?tab=proposals' },
    { title: '物件詳細', path: '?tab=property_details' },
  ],
}

const subMenuOptionsMerge = (pricingType: PricingType) => {
  const subMenuOptions = {
    settings: {
      name: '設定',
      icon: SettingsOutlinedIcon,
      menus: [],
    },
    properties: {
      name: '物件管理',
      icon: HomeWorkOutlinedIcon,
      menus: [],
    },
    projects: {
      name: '案件一覧',
      icon: ClassOutlinedIcon,
      menus: [
        { title: '事業形態別', path: '/projects_business_types' },
        { title: 'タグ別', path: '/projects_tags' },
        { title: '案件担当部署別', path: '/projects_departments' },
        { title: '案件担当者別', path: '/projects_users' },
        { title: '一括表示', path: '/projects_totals' },
      ],
    },
    analyses: {
      name: '分析情報',
      icon: AssessmentOutlinedIcon,
      menus: [
        { title: '月次集計', path: '/analyses?type=monthly' },
        { title: '部門別前月比', path: '/analyses?type=departments_comparing_monthly' },
        { title: '部門別集計', path: '/analyses?type=departments_monthly' },
        { title: '個人別集計', path: '/analyses?type=users_totalling' },
        { title: '入手先トップ30', path: '/analyses?type=source_ranking' },
        { title: '買付・仕入件数', path: '/analyses?type=purchace_applied_contract' },
      ],
    },
  }

  if (pricingType === 'owner') {
    subMenuOptions.settings.menus = subMenuOptionMenusOwner.settings
    subMenuOptions.properties.menus = subMenuOptionMenusOwner.properties
  } else if (pricingType === 'enterprise') {
    subMenuOptions.settings.menus = subMenuOptionMenusEnterprise.settings
    subMenuOptions.properties.menus = subMenuOptionMenusEnterprise.properties
  } else if (pricingType === 'standard') {
    subMenuOptions.settings.menus = subMenuOptionMenusStandard.settings
    subMenuOptions.properties.menus = subMenuOptionMenusStandard.properties
  } else if (pricingType === 'basic') {
    subMenuOptions.settings.menus = subMenuOptionMenusBasic.settings
    subMenuOptions.properties.menus = subMenuOptionMenusBasic.properties
  }

  return subMenuOptions
}

export const subMenuOptionsPricingType = (pricingType: string) => {
  if (pricingType === 'owner') {
    return subMenuOptionsMerge('owner')
  } else if (pricingType === 'enterprise') {
    return subMenuOptionsMerge('enterprise')
  } else if (pricingType === 'standard') {
    return subMenuOptionsMerge('standard')
  } else if (pricingType === 'basic') {
    return subMenuOptionsMerge('basic')
  }
}

const moblieMenusMerge = (pricingType: PricingType) => {
  const { settings, projects, analyses } = subMenuOptionsPricingType(pricingType)

  let moblieMenus = [
    { title: '物件登録', path: '/properties', icon: <HomeWorkOutlinedIcon fontSize="small" /> },
    {
      title: '入手情報一覧',
      path: '/proposals',
      icon: <BusinessCenterOutlinedIcon fontSize="small" />,
    },
    {
      title: '案件一覧',
      icon: <ClassOutlinedIcon fontSize="small" />,
      submenu: projects.menus,
    },
    {
      title: '分析情報',
      icon: <AssessmentOutlinedIcon fontSize="small" />,
      submenu: analyses.menus,
    },
    {
      title: 'CSVインポート',
      path: '/imports',
      icon: <CloudUploadOutlinedIcon fontSize="small" />,
    },
    {
      title: '周辺事例一覧',
      path: '/market_data',
      icon: <AddLocationAltOutlinedIcon fontSize="small" />,
    },
    {
      title: '設定',
      icon: <SettingsOutlinedIcon fontSize="small" />,
      submenu: settings.menus.filter((menu) => menu.title !== 'ファイル容量確認'),
    },
  ]

  if (pricingType === 'standard') {
    moblieMenus = moblieMenus.filter((menu) => menusStandardIncludes.includes(menu.title))
  } else if (pricingType === 'basic') {
    moblieMenus = moblieMenus.filter((menu) => menusBasicIncludes.includes(menu.title))
  }

  return moblieMenus
}

export const mobileMenusPricingType = (pricingType: PricingType) => {
  return moblieMenusMerge(pricingType)
}
