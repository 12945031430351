import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import { Breadcrumb } from '@/components/Breadcrumb'
import type { VolumeCheckDetail } from '@/types/volumeCheckDetail'
import { useJsApiLoader } from '@react-google-maps/api'
import { GoogleMapVolume } from '@/components/GoogleMapVolume'
import { Button, AnchorButton } from '@/components/Button'
import { wards } from '@/models/wards'
import BordersForm3 from './BordersForm3'
import ExtendsForm3 from './ExtendsForm3'
import LatLngForm3 from './LatLngForm3'
import FundamentalsForm3 from './FundamentalsForm3'
import { quickVolumeResult } from '../Properties/quickVolumeResult'
import quickVolumeCheck from '../Properties/quickVolumeCheck'
import { unit_m2 } from './volumeUnitFormat'
import { Table } from '@/components/Table'
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { useFullScreen } from '@/components/Page/VolumeCheckRequests/viewFullscreen'
import UsageForm3 from './UsageForm3'
import '@/components/GoogleMapVolume/style.css'
import AreaInfo from '@/components/Page/Properties/AreaInfo'
import { usageOptions } from '@/components/Page/VolumeCheckRequests/usage'

type Props = {
  current_user: User
  volume_check_detail: VolumeCheckDetail
  ref_volume_check_parameters?: any
  ref_volume_check_area?: any
  google_maps_api_key: string
  polygons_api_base_url: string
  authenticity_token: string
  plan_number: string
  is_processing: boolean
  env_name: string
}

type shadeType = {
  id: number
  types: {
    shade_5: number
    shade_10: number
    shade_height: number
    layer: string
    id: number
  }
  lines: [
    {
      lat: number
      lng: number
    }
  ]
  innerlines: any[]
}
type antifireType = {
  id: number
  types: {
    bouka_chiiki: number
    layer: string
    id: number
  }
  lines: [
    {
      lat: number
      lng: number
    }
  ]
  innerlines: []
}
type heightType = {
  id: number
  types: {
    koudo_chiku: number
    min_height: number
    max_height: number
    layer: string
    id: number
  }
  lines: [
    {
      lat: number
      lng: number
    }
  ]
  innerlines: []
}
type usageType = {
  id: number
  types: {
    youto_chiiki: number
    floor_area_ratio: number
    building_coverage_ratio: number
    wall_setback: number
    minimum_area: number
    special_area: number
    max_height_limit: number
    layer: 'usage'
    id: number
  }
  lines: [
    {
      lat: number
      lng: number
    }
  ]
  innerlines: []
}

type PolygonType = {
  id: number
  usage?: string
  buildingRatio?: string
  volumeRatio?: string
  firePreventionArea?: string
  altitudeArea?: string
  maxHeight?: string
  minHeight?: string
  beyond5?: string
  beyond10?: string
  waterPlan?: string
}

type LineType = {
  id: number
  color: string
  lines: { lat: number; lng: number }[]
}

interface PropertyData {
  area_info: string
  shape: any[]
}

const VolumeCheckRequestEditV3Page: React.FC<Props> = ({
  current_user,
  volume_check_detail,
  ref_volume_check_parameters,
  ref_volume_check_area,
  google_maps_api_key,
  polygons_api_base_url,
  authenticity_token,
  plan_number,
  is_processing,
  env_name
}) => {
  const GoogleMapVolumeRef = React.useRef()
  const [areaParams, setAreaParams] = React.useState([])
  const [addRefresh, setAddRefresh] = React.useState(0)
  const containerRef = React.useRef()
  const [containerHeight, setContainerHeight] = React.useState(0)
  const title = 'Volume Check登録'
  const property_id = Number(volume_check_detail.property.id)
  const [usageMapFlg, setUsageMapFlg] = React.useState(false)
  const shadeValue = React.useRef({ beyond5: 0, beyond10: 0, waterPlan: 0 })

  const [activePanel, setActivePanel] = React.useState<
    'fundametals' | 'borders' | 'extends' | null
  >('fundametals')

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: google_maps_api_key,
    libraries: ['geometry', 'drawing', 'places'],
  })
  const [prefectureName, setPrefectureName] = React.useState('')
  const [usageList, setUsageList] = React.useState([
    {
      id: 0,
      usage: '0',
      buildingRatio: '0',
      volumeRatio: '0',
      firePreventionArea: '0',
      altitudeArea: '4',
      maxHeight: '0',
      minHeight: '0',
      beyond5: '0',
      beyond10: '0',
      waterPlan: '0',
    },
  ])
  const drawnPolygons = React.useRef([])
  const isClearFlg = React.useRef(false)
  const [usageListTmp, setUsageListTmp] = React.useState([])
  const [drawnPolygonsTmp, setDrawnPolygonsTmp] = React.useState([])
  const areaOfUser = React.useRef([])
  const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]')
  const isFirstRender = React.useRef(true)
  const [property, setProperty] = React.useState(volume_check_detail.property)
  const [propertyShape, setPropertyShape] = React.useState<string>(null)
  const [widthLength, setWidthLength] = React.useState<string[]>([])
  const [responseArea, setResponseArea] = React.useState('　')
  const [isPolygonRemoved, setIsPolygonRemoved] = React.useState(false)
  const formRef = React.useRef(null)
  const usageFlg = React.useRef(false)
  const [usageButtonDisabled, setUsageButtonDisabled] = React.useState(false)
  const usageSaveFlg = React.useRef(false)
  const [checkboxValues, setCheckboxValues] = React.useState(() => {
    if (ref_volume_check_parameters) {
      if (JSON.parse(ref_volume_check_parameters).regulation) {
        return JSON.parse(ref_volume_check_parameters).regulation
      } else {
        let tmp = []
        if (JSON.parse(ref_volume_check_parameters).compact_residence === 'true') {
          tmp.push('0')
        }
        if (JSON.parse(ref_volume_check_parameters).shade_regulation === 'true') {
          tmp.push('1')
        }
        return tmp
      }
    } else {
      return []
    }
  })
  const [disabledGroup, setDisabledGroup] = React.useState([])

  const {
    elementRef: mapRef,
    triggerFullScreen: triggerMapFullScreen,
    exitFullScreen: exitMapFullScreen,
    isFullScreen: isMapFullScreen,
  } = useFullScreen()

  const handleCheckboxChange = (newValue) => {
    setCheckboxValues(newValue)
  }

  const [inverseShadow, setInverseShadow] = React.useState('2')

  const handleInverseShadowChange = (newValue: string) => {
    setInverseShadow(newValue)
  }

  const highPointBox = [
    { name: '北', value: 'north' },
    { name: '南', value: 'south' },
    { name: '東', value: 'east' },
    { name: '西', value: 'west' },
  ]

  const [highPointValues, setHighPointValues] = React.useState({ south: true })

  const handleHighPointValuesChange = (key: string, value: boolean) => {
    const newHighPointValues = { ...highPointValues }
    newHighPointValues[key] = value
    setHighPointValues(newHighPointValues)
  }

  const [assumedVolumeDigestionFloor, setAssumedVolumeDigestionFloor] = React.useState('0')

  const handleAssumedVolumeDigestionFloorChange = (newValue: string) => {
    setAssumedVolumeDigestionFloor(newValue)
  }

  const [calculationOrder, setCalculationOrder] = React.useState('0')

  const handleCalculationOrder = (newValue: string) => {
    setCalculationOrder(newValue)
  }

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      setResponseArea('')
    }
  }, [ref_volume_check_area, property])

  const [propertyData, setPropertyData] = React.useState<PropertyData>({
    area_info: '',
    instant_city_plannings: [],
    shape: [],
  })
  const responseAreaData = propertyData?.area_info ? JSON.parse(propertyData.area_info) : null

  const [propertyLatLng, setPropertyLatLng] = React.useState<{
    lat: string | number
    lng: string | number
  }>({
    lat: property?.lat || '',
    lng: property?.lng || '',
  })
  const [existdAreaValue, setExistdAreaValue] = React.useState('')

  React.useEffect(() => {
    if (ref_volume_check_area) {
      setExistdAreaValue(ref_volume_check_area || '')
    }
  }, [ref_volume_check_area, csrfToken.content])

  const currentUrl = window.location.href
  const isAdjusted = currentUrl.includes('adjusted')

  React.useEffect(() => {
    if (!ref_volume_check_area && !isPolygonRemoved) {
      let existdArea = property?.area_info
        ? unit_m2(JSON.parse(property?.area_info)?.['site']?.['area'])
        : ' '

      if (isAdjusted) {
        existdArea = ''
      }
      setExistdAreaValue(existdArea)
    }
  }, [ref_volume_check_area, property, isAdjusted])

  const [requestUUID, setRequestUUID] = React.useState('')
  const [polygonChanged, setPolygonChanged] = React.useState(isAdjusted ? true : false)
  const [areaChecked, setAreaChecked] = React.useState(false)

  React.useEffect(() => {
    if (isAdjusted) {
      setActivePanel('borders')
    } else {
      if (!polygonChanged) {
        setActivePanel('fundametals')
      } else {
        setActivePanel('borders')
      }
    }
  }, [propertyShape, isAdjusted, polygonChanged])

  const isclickDisabled = polygonChanged && !areaChecked

  const [mapCenterLatLng, setMapCenterLatLng] = React.useState<{
    lat: string
    lng: string
  }>({
    lat: '',
    lng: '',
  })

  // パラメーターが変更されたときのイベントハンドラ
  const parametersChanged = () => {
    const params = {
      center: {
        lat: Number((document.getElementById('center_lat') as HTMLInputElement).value),
        lng: Number((document.getElementById('center_lng') as HTMLInputElement).value),
      },
      borders: [],
      plan_setting: {
        chome: '',
        prefecture_name: '',
        city_name: '',
        plan_name: '',
        administrative_name: '',
        plan_number: 0,
        building_use: '',
        building_structure: '',
        manager_name: '',
        project_name: '',
        customization: {
          shade_regulation: '',
          compact_residence: '',
        },
        map_center: {
          lat: 0,
          lng: 0,
        },
      },
      city_planning_setting: {
        guideline: 0,
        district_planning: 0,
        city_planning_road: 0,
      },
      surroundings: [],
      building_setting: {
        floors: [],
        floors_above_ground: 0,
        floors_under_ground: 0,
        max_height: 0,
        floor_height: 0,
        gf_slab_level: 0,
        design_ground_level: 0,
        min_offset_road: 0,
        min_offset_neighbor: 0,
        sky_factor: 0,
        inverse_shadow: 0,
        high_floor_point: [],
        number_of_units: 0,
        setting_building_area_ratio: '0',
        setting_building_area_ratio_high: '0',
        setting_building_area_ratio_low: '0',
        assumed_volume_digestion_floor: 0,
        calculation_order: 0,
      },
      unit_setting: {
        unit_area: 0,
        dwelling_unit_frontage: 0,
        balcony_width: 0,
        hallway_width: 0,
        entrance_area: 0,
        admin_room: 0,
        waste_storage: 0,
        elevator_number: 0,
      },
      area_of_use: [],
    }
    if (property && (property?.shape?.latlngs || !!propertyShape)) {
      params.plan_setting = {
        chome: (document.getElementById('chome') as HTMLInputElement).value,
        prefecture_name: (document.getElementById('prefecture_name') as HTMLInputElement).value,
        city_name: (document.getElementById('city_name') as HTMLInputElement).value,
        plan_name: (document.getElementById('plan_name') as HTMLInputElement).value,
        administrative_name: (document.getElementById('administrative_name') as HTMLInputElement)
          .value,
        plan_number: Number((document.getElementById('plan_number') as HTMLInputElement).value),
        building_use: (document.getElementById('building_use') as HTMLInputElement).value,
        building_structure: (document.getElementById('building_structure') as HTMLInputElement)
          .value,
        manager_name: (document.getElementById('manager_name') as HTMLInputElement).value,
        project_name: (document.getElementById('project_name') as HTMLInputElement).value,
        customization: {
          compact_residence: checkboxValues.indexOf('0') !== -1 ? 'true' : 'false',
          shade_regulation: checkboxValues.indexOf('1') !== -1 ? 'true' : 'false',
        },
        map_center: {
          lat: Number(mapCenterLatLng.lat),
          lng: Number(mapCenterLatLng.lng),
        },
      }
      params.city_planning_setting = {
        guideline:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('3') !== -1
            ? 1
            : 0,
        district_planning:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('5') !== -1
            ? 1
            : 0,
        city_planning_road:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('6') !== -1
            ? 1
            : 0,
      }
      params.unit_setting = {
        unit_area: Number((document.getElementById('unit_area') as HTMLInputElement).value),
        dwelling_unit_frontage: Number(
          (document.getElementById('dwelling_unit_frontage') as HTMLInputElement).value
        ),
        balcony_width: Number((document.getElementById('balcony_width') as HTMLInputElement).value),
        hallway_width: Number((document.getElementById('hallway_width') as HTMLInputElement).value),
        entrance_area: Number((document.getElementById('entrance_area') as HTMLInputElement).value),
        admin_room:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('1') !== -1
            ? 1
            : 0,
        waste_storage:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('2') !== -1
            ? 1
            : 0,
        elevator_number:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('0') !== -1
            ? 1
            : 0,
      }
      let next_i = 0
      const count_borders = parseInt(
        (document.getElementById('count_borders') as HTMLInputElement).value,
        10
      )
      let disabledGroupValue: { width: boolean; setback: boolean; surroundings: boolean }[] = []
      for (let i = 0; i < count_borders; i++) {
        let disabledGroupMap: { width: boolean; setback: boolean; surroundings: boolean } = {
          width: true,
          setback: true,
          surroundings: true,
        }
        if (i == count_borders - 1) {
          next_i = 0
        } else {
          next_i = i + 1
        }
        const from = (document.getElementById(`from_${i}`) as HTMLInputElement).value.split(',')
        const to = (document.getElementById(`to_${next_i}`) as HTMLInputElement).value.split(',')
        const border_type_code = (document.getElementById(`border_${i}`) as HTMLInputElement).value
        let set_back_method = (document.getElementById(`setback_${i}`) as HTMLInputElement).value
        let width = (document.getElementById(`width_${i}`) as HTMLInputElement).value
        const height = '0'
        const surroundings_type = (document.getElementById(`surroundings_${i}`) as HTMLInputElement)
          .value
        let surroundings_width = (
          document.getElementById(`surroundings_${i}_width`) as HTMLInputElement
        ).value
        if (['1', '2', '3', '5', '6', '7'].indexOf(border_type_code) !== -1) {
          if (Number(width) === 0) {
            width = '6'
          }
        } else {
          width = '0'
          set_back_method = '0'
        }

        disabledGroupMap.width = true
        disabledGroupMap.setback = true
        if (['1', '2', '3'].indexOf(border_type_code) !== -1) {
          disabledGroupMap.setback = false
          disabledGroupMap.width = false
        } else if (['5', '6', '7'].indexOf(border_type_code) !== -1) {
          disabledGroupMap.width = false
          set_back_method = '0'
        }
        disabledGroupMap.surroundings = false
        params.borders.push({
          id: i + 1,
          from: { lat: Number(from[0]), lng: Number(from[1]), height: Number(height) },
          to: { lat: Number(to[0]), lng: Number(to[1]) },
          border_type_code: Number(border_type_code),
          set_back_method: Number(set_back_method),
          width: Number(width),
        })
        params.surroundings.push({
          border_id: i + 1,
          border_type_code: Number(surroundings_type),
          width: Number(surroundings_width),
        })

        disabledGroupValue.push(disabledGroupMap)
        setDisabledGroup(disabledGroupValue)
      }
      let floors_above_ground = Number(
        (document.getElementById(`floors_above_ground`) as HTMLInputElement).value
      )
      if (floors_above_ground === 0) {
        floors_above_ground = 15
      }
      const floors_under_ground = Number(
        (document.getElementById(`floors_under_ground`) as HTMLInputElement).value
      )
      const highFloorPoint = Object.entries(highPointValues)
        .filter(([key, value]) => value)
        .map(([key]) => key)
      if (highFloorPoint.length === 0) {
        highFloorPoint.push('south')
      }
      params.building_setting = {
        floors: [],
        floors_above_ground: floors_above_ground,
        floors_under_ground: floors_under_ground,
        max_height:
          Number((document.getElementById(`max_height`) as HTMLInputElement).value) === 0
            ? 50
            : Number((document.getElementById(`max_height`) as HTMLInputElement).value),
        floor_height: Number(
          (document.getElementById(`max_floor_height`) as HTMLInputElement).value
        ),
        gf_slab_level: Number((document.getElementById(`gf_slab_level`) as HTMLInputElement).value),
        design_ground_level: Number(
          (document.getElementById(`design_ground_level`) as HTMLInputElement).value
        ),
        min_offset_road: Number(
          (document.getElementById('min_offset_road') as HTMLInputElement).value
        ),
        min_offset_neighbor: Number(
          (document.getElementById('min_offset_neighbor') as HTMLInputElement).value
        ),
        sky_factor:
          Array.from((document.getElementById('verify') as HTMLSelectElement).selectedOptions)
            .map((option) => option.value)
            .indexOf('4') !== -1
            ? 1
            : 0,
        inverse_shadow: Number(inverseShadow),
        high_floor_point: highFloorPoint,
        number_of_units: Number(
          (document.getElementById('number_of_units') as HTMLInputElement).value
        ),
        setting_building_area_ratio: '0',
        setting_building_area_ratio_high: (
          document.getElementById('setting_building_area_ratio_high') as HTMLInputElement
        ).value,
        setting_building_area_ratio_low: (
          document.getElementById('setting_building_area_ratio_low') as HTMLInputElement
        ).value,
        assumed_volume_digestion_floor: Number(assumedVolumeDigestionFloor),
        calculation_order: Number(calculationOrder),
      }
      let max_floor_height = Number(
        (document.getElementById(`max_floor_height`) as HTMLInputElement).value
      )
      if (max_floor_height === 0) {
        max_floor_height = 3
      }
      for (let i = 0; i < floors_above_ground; i++) {
        params.building_setting.floors.push({
          floor_number: i + 1,
          max_floor_height: max_floor_height,
        })
      }
      for (let i = 0; i < floors_under_ground; i++) {
        params.building_setting.floors.push({
          floor_number: i - 1,
          max_floor_height: max_floor_height,
        })
      }
    }

    if (drawnPolygons.current.length > 0) {
      const shade = { polygons: [], layer: 'shade' }
      const usage = { polygons: [], layer: 'usage' }
      const height = { polygons: [], layer: 'height' }
      const antifire = { polygons: [], layer: 'antifire' }
      const shadePolygons = []
      const usagePolygons = []
      const heightPolygons = []
      const antifirePolygons = []
      usageList.forEach((item) => {
        let shadeItem: shadeType = {
          id: 0,
          types: {
            shade_5: 0,
            shade_10: 0,
            shade_height: 0,
            layer: 0,
            id: 0,
          },
          lines: [
            {
              lat: 0,
              lng: 0,
            },
          ],
          innerlines: [],
        }
        let usageItem: usageType = {
          id: 0,
          types: {
            youto_chiiki: 0,
            floor_area_ratio: 0,
            building_coverage_ratio: 0,
            wall_setback: 0,
            minimum_area: 0,
            special_area: 0,
            max_height_limit: 0,
            layer: 'usage',
            id: 0,
          },
          lines: [
            {
              lat: 0,
              lng: 0,
            },
          ],
          innerlines: [],
        }
        let heightItem: heightType = {
          id: 0,
          types: {
            koudo_chiku: 4,
            min_height: 0,
            max_height: 0,
            layer: 0,
            id: 0,
          },
          lines: [
            {
              lat: 0,
              lng: 0,
            },
          ],
          innerlines: [],
        }
        let antifireItem: antifireType = {
          id: 0,
          types: {
            bouka_chiiki: 0,
            layer: 0,
            id: 0,
          },
          lines: [
            {
              lat: 0,
              lng: 0,
            },
          ],
          innerlines: [],
        }
        const polygons = drawnPolygons.current.filter((drawnPolygon) => item.id === drawnPolygon.id)
        getShadeItem(item, shadeItem, polygons[0].polygon)
        getUsageItem(item, usageItem, polygons[0].polygon)
        getHeightItem(item, heightItem, polygons[0].polygon)
        getAntifireItem(item, antifireItem, polygons[0].polygon)
        shadePolygons.push(shadeItem)
        usagePolygons.push(usageItem)
        heightPolygons.push(heightItem)
        antifirePolygons.push(antifireItem)
      })
      shade.polygons = shadePolygons
      usage.polygons = usagePolygons
      height.polygons = heightPolygons
      antifire.polygons = antifirePolygons
      params.area_of_use.push(usage)
      params.area_of_use.push(height)
      params.area_of_use.push(antifire)
      params.area_of_use.push(shade)
    } else if (!isClearFlg.current) {
      params.area_of_use = areaOfUser.current ? areaOfUser.current : []
    } else {
      params.area_of_use = []
    }
    setAreaParams(params.area_of_use)
    ;(document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
      JSON.stringify(params)
  }
  const getShadeItem = (item, shadeItem, polygon) => {
    shadeItem.id = item.id
    shadeItem.types.shade_5 = Number(shadeValue.current.beyond5)
    shadeItem.types.shade_10 = Number(shadeValue.current.beyond10)
    shadeItem.types.shade_height = Number(shadeValue.current.waterPlan)
    shadeItem.types.layer = 'shade'
    shadeItem.types.id = item.id
    shadeItem.lines = []
    polygon.getPath().forEach((item) => {
      shadeItem.lines.push({ lat: item.lat(), lng: item.lng() })
    })
    shadeItem.innerlines = []
  }
  const getUsageItem = (item, usageItem, polygon) => {
    usageItem.id = item.id
    usageItem.types.youto_chiiki = Number(item.usage)
    usageItem.types.floor_area_ratio = Number(item.volumeRatio)
    usageItem.types.building_coverage_ratio = Number(item.buildingRatio)
    usageItem.types.layer = 'usage'
    usageItem.types.id = item.id
    usageItem.types.wall_setback = 0
    usageItem.types.minimum_area = 0
    usageItem.types.special_area = 0
    usageItem.types.max_height_limit = 0
    usageItem.lines = []
    polygon.getPath().forEach((item) => {
      usageItem.lines.push({ lat: item.lat(), lng: item.lng() })
    })
    usageItem.innerlines = []
  }
  const getAntifireItem = (item, antifireItem, polygon) => {
    antifireItem.id = item.id
    antifireItem.types.bouka_chiiki = Number(item.firePreventionArea)
    antifireItem.types.layer = 'antifire'
    antifireItem.types.id = item.id
    antifireItem.lines = []
    polygon.getPath().forEach((item) => {
      antifireItem.lines.push({ lat: item.lat(), lng: item.lng() })
    })
    antifireItem.innerlines = []
  }
  const getHeightItem = (item, heightItem, polygon) => {
    heightItem.id = item.id
    heightItem.types.koudo_chiku = Number(item.altitudeArea)
    heightItem.types.min_height = Number(item.minHeight)
    heightItem.types.max_height = Number(item.maxHeight)
    heightItem.types.layer = 'height'
    heightItem.types.id = item.id
    heightItem.lines = []
    polygon.getPath().forEach((item) => {
      heightItem.lines.push({ lat: item.lat(), lng: item.lng() })
    })
    heightItem.innerlines = []
  }
  const [checkRetryTime, setCheckRetryTime] = React.useState(0)

  const { loading, loadingIcon } = quickVolumeResult(
    requestUUID,
    csrfToken.content,
    setResponseArea,
    checkRetryTime,
    setCheckRetryTime,
    propertyData,
    setPropertyData,
    null,
    displayNoAreaInfoMessage
  )

  const handlequickVolumeCheck = () => {
    setResponseArea('')
    setExistdAreaValue('')
    setAreaChecked(true)
    setPolygonChanged(false)
    quickVolumeCheck({
      isLoaded,
      propertyLatLng,
      propertyShape,
      setRequestUUID,
      csrfToken: csrfToken.content,
      setCheckRetryTime,
      setPrefectureName,
      params: areaParams,
    })
  }

  const [showNoAreaInfoMessage, setShowNoAreaInfoMessage] = React.useState(false)

  function displayNoAreaInfoMessage() {
    setShowNoAreaInfoMessage(true)
  }

  const usageMapFlgTrue = React.useCallback(() => {
    setUsageMapFlg(true)
  }, [])

  const usageMapFlgFalse = React.useCallback(() => {
    setUsageMapFlg(false)
  }, [])
  React.useEffect(() => {
    if (containerRef.current) {
      if (usageFlg.current) {
        setContainerHeight(containerRef.current.scrollHeight - 92)
      } else {
        setContainerHeight(containerRef.current.offsetHeight + 6)
      }
    }
  }, [containerRef.current?.scrollHeight])

  const transformAreaOfUse = (
    areaOfUse: any[]
  ): { listDatas: PolygonType[]; polygonDatas: LineType[] } => {
    const listDatas: PolygonType[] = []
    const polygonDatas: LineType[] = []

    areaOfUse.forEach((layer) => {
      if (layer.layer === 'usage') {
        layer.polygons.forEach((polygon: any) => {
          const id = polygon.id

          const types = polygon.types || {}
          const filteredTypes: Partial<PolygonType> = {
            id,
            ...(types.youto_chiiki !== undefined && { usage: String(types.youto_chiiki) }),
            ...(types.building_coverage_ratio !== undefined && {
              buildingRatio: String(types.building_coverage_ratio),
            }),
            ...(types.floor_area_ratio !== undefined && {
              volumeRatio: String(types.floor_area_ratio),
            }),
            ...(types.bouka_chiiki !== undefined && {
              firePreventionArea: String(types.bouka_chiiki),
            }),
            ...(types.koudo_chiku !== undefined && { altitudeArea: String(types.koudo_chiku) }),
            ...(types.max_height !== undefined && { maxHeight: String(types.max_height) }),
            ...(types.min_height !== undefined && { minHeight: String(types.min_height) }),
            ...(types.shade_5 !== undefined && { beyond5: String(types.shade_5) }),
            ...(types.shade_10 !== undefined && { beyond10: String(types.shade_10) }),
            ...(types.shade_height !== undefined && { waterPlan: String(types.shade_height) }),
          }

          const existingA = listDatas.find((entry) => entry.id === id)
          if (existingA) {
            Object.assign(existingA, {
              ...filteredTypes,
              usage: filteredTypes.usage || existingA.usage,
              buildingRatio: filteredTypes.buildingRatio || existingA.buildingRatio,
              volumeRatio: filteredTypes.volumeRatio || existingA.volumeRatio,
              firePreventionArea: filteredTypes.firePreventionArea || existingA.firePreventionArea,
              altitudeArea: filteredTypes.altitudeArea || existingA.altitudeArea,
              maxHeight: filteredTypes.maxHeight || existingA.maxHeight,
              minHeight: filteredTypes.minHeight || existingA.minHeight,
              beyond5: filteredTypes.beyond5 || existingA.beyond5,
              beyond10: filteredTypes.beyond10 || existingA.beyond10,
              waterPlan: filteredTypes.waterPlan || existingA.waterPlan,
            })
          } else {
            listDatas.push(filteredTypes as PolygonType)
          }

          polygonDatas.push({
            id,
            color: usageOptions.find((item) => item.value === String(types.youto_chiiki || '0'))
              ?.color,
            lines: polygon.lines,
          })
        })
      } else {
        layer.polygons.forEach((polygon: any) => {
          const id = polygon.id
          const types = polygon.types || {}
          const filteredTypes: Partial<PolygonType> = {
            id,
            ...(types.youto_chiiki !== undefined && { usage: String(types.youto_chiiki) }),
            ...(types.building_coverage_ratio !== undefined && {
              buildingRatio: String(types.building_coverage_ratio),
            }),
            ...(types.floor_area_ratio !== undefined && {
              volumeRatio: String(types.floor_area_ratio),
            }),
            ...(types.bouka_chiiki !== undefined && {
              firePreventionArea: String(types.bouka_chiiki),
            }),
            ...(types.koudo_chiku !== undefined && { altitudeArea: String(types.koudo_chiku) }),
            ...(types.max_height !== undefined && { maxHeight: String(types.max_height) }),
            ...(types.min_height !== undefined && { minHeight: String(types.min_height) }),
            ...(types.shade_5 !== undefined && { beyond5: String(types.shade_5) }),
            ...(types.shade_10 !== undefined && { beyond10: String(types.shade_10) }),
            ...(types.shade_height !== undefined && { waterPlan: String(types.shade_height) }),
          }

          const existingA = listDatas.find((entry) => entry.id === id)
          if (existingA) {
            Object.assign(existingA, {
              ...filteredTypes,
              usage: filteredTypes.usage || existingA.usage,
              buildingRatio: filteredTypes.buildingRatio || existingA.buildingRatio,
              volumeRatio: filteredTypes.volumeRatio || existingA.volumeRatio,
              firePreventionArea: filteredTypes.firePreventionArea || existingA.firePreventionArea,
              altitudeArea: filteredTypes.altitudeArea || existingA.altitudeArea,
              maxHeight: filteredTypes.maxHeight || existingA.maxHeight,
              minHeight: filteredTypes.minHeight || existingA.minHeight,
              beyond5: filteredTypes.beyond5 || existingA.beyond5,
              beyond10: filteredTypes.beyond10 || existingA.beyond10,
              waterPlan: filteredTypes.waterPlan || existingA.waterPlan,
            })
          } else {
            listDatas.push(filteredTypes as PolygonType)
          }
        })
      }
    })

    return { listDatas, polygonDatas }
  }

  React.useEffect(() => {
    const result = JSON.parse(ref_volume_check_parameters)?.area_of_use
    if (result && result.length > 0) {
      const { listDatas, polygonDatas } = transformAreaOfUse(result)
      shadeValue.current.beyond5 = listDatas[0].beyond5
      shadeValue.current.beyond10 = listDatas[0].beyond10
      shadeValue.current.waterPlan = listDatas[0].waterPlan
      setDrawnPolygonsTmp(polygonDatas)
      setUsageListTmp(listDatas)
      areaOfUser.current = result
    }
  }, [])
  React.useEffect(() => {
    if (drawnPolygons.current.length > 0 && drawnPolygons.current.length === usageList.length) {
      parametersChanged()
    }
  }, [usageList.length])

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={
        <Breadcrumb
          link_texts={['物件管理', 'Volume Check', '登録']}
          property_id={volume_check_detail.property.hashid}
          tab="volume_check"
        />
      }
      submenuCategory="properties"
      city={volume_check_detail.property.prefecture + volume_check_detail.property.city}
    >
      <div className="overflow-y-auto h-[calc(100vh_-_170px_-_2rem)]" ref={containerRef}>
        <form
          ref={formRef}
          action={`/properties/${volume_check_detail.property.hashid}/volume_check_requests`}
          acceptCharset="UTF-8"
          method="post"
        >
          <input type="hidden" name="authenticity_token" value={authenticity_token} />
          <div className="mt-8 lg:flex gap-4">
            <div className="overflow-y-auto left-fixed" style={{ height: containerHeight + 'px' }}>
              <Table
                className="border-t border-b block mb-16 w-full"
                header={
                  <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                    基本設定
                  </div>
                }
              >
                <tbody className="block md:table-row-group">
                  {property && (property?.shape?.latlngs || !!propertyShape) && (
                    <FundamentalsForm3
                      property={property}
                      plan_number={plan_number}
                      onChangeParameters={parametersChanged}
                      ref_volume_check_parameters={ref_volume_check_parameters}
                    />
                  )}
                </tbody>
              </Table>
              <div className={usageButtonDisabled ? '' : 'row_hidden'}>
                <Table
                  className="border-t border-b block mb-16 w-full"
                  header={
                    <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                      用途地域情報設定
                    </div>
                  }
                >
                  <tbody className="block md:table-row-group">
                    <UsageForm3
                      usageList={usageList}
                      setUsageList={setUsageList}
                      GoogleMapVolumeRef={GoogleMapVolumeRef}
                      parametersChanged={parametersChanged}
                      drawnPolygons={drawnPolygons}
                      addRefresh={addRefresh}
                      shadeValue={shadeValue}
                    />
                  </tbody>
                </Table>
              </div>
              <div className={usageButtonDisabled ? 'row_hidden' : ''}>
                <Table
                  className="border-t border-b block mb-16"
                  header={
                    <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                      敷地境界条件
                    </div>
                  }
                >
                  <tbody className="block md:table-row-group">
                    {property && (property?.shape?.latlngs || !!propertyShape) && (
                      <BordersForm3
                        property={property}
                        propertyShape={propertyShape}
                        onChangeParameters={parametersChanged}
                        ref_volume_check_parameters={ref_volume_check_parameters}
                        disabledGroup={disabledGroup}
                        authenticity_token={authenticity_token}
                        current_user={current_user}
                        widthLength={widthLength}
                      />
                    )}
                  </tbody>
                </Table>
              </div>
              <div
                className={
                  propertyShape === null || propertyShape === '' || loading || isclickDisabled
                    ? 'row_hidden'
                    : ''
                }
              >
                <AreaInfo
                  responseAreaData={responseAreaData}
                  propertyData={propertyData}
                  prefectureName={prefectureName}
                />
              </div>
              <div className={usageButtonDisabled ? 'row_hidden' : ''}>
                <Table
                  className="border-t border-b block mb-16"
                  header={
                    <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                      拡張設定
                    </div>
                  }
                >
                  <tbody className="block md:table-row-group">
                    {property && (property?.shape?.latlngs || !!propertyShape) && (
                      <ExtendsForm3
                        current_user={current_user}
                        property={property}
                        propertyShape={propertyShape}
                        onChangeParameters={parametersChanged}
                        ref_volume_check_parameters={ref_volume_check_parameters}
                        checkboxValues={checkboxValues}
                        onCheckboxChange={handleCheckboxChange}
                        inverseShadow={inverseShadow}
                        onInverseShadowChange={handleInverseShadowChange}
                        highPointBox={highPointBox}
                        highPointValues={highPointValues}
                        onHighFloorPointChange={handleHighPointValuesChange}
                        assumedVolumeDigestionFloor={assumedVolumeDigestionFloor}
                        onAssumedVolumeDigestionFloorChange={
                          handleAssumedVolumeDigestionFloorChange
                        }
                        calculationOrder={calculationOrder}
                        onCalculationOrderChange={handleCalculationOrder}
                        env_name={env_name}
                      />
                    )}
                  </tbody>
                </Table>
              </div>
              <div
                className={
                  current_user.role === 'system_admin' && !usageButtonDisabled ? '' : 'row_hidden'
                }
              >
                <Table
                  className="border-t border-b block mb-8"
                  header={
                    <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
                      道路、隣地の高さ
                    </div>
                  }
                >
                  <tbody className="block md:table-row-group">
                    {property && (property?.shape?.latlngs || !!propertyShape) && (
                      <LatLngForm3
                        property={property}
                        propertyShape={propertyShape}
                        onChangeParameters={parametersChanged}
                        ref_volume_check_parameters={ref_volume_check_parameters}
                      />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className="right-variable lg:block">
              {(!property || (!property?.shape?.latlngs && !propertyShape)) && (
                <p className="mb-4 text-sm text-red">敷地形状を作成して下さい</p>
              )}
              <div className="relative" ref={mapRef}>
                {isLoaded && (
                  <>
                    <GoogleMapVolume
                      usageList={usageList}
                      setUsageList={setUsageList}
                      ref={GoogleMapVolumeRef}
                      polygons_api_base_url={polygons_api_base_url}
                      csrfToken={csrfToken.content}
                      mode="volume"
                      property_id={property_id}
                      lat={volume_check_detail.property.address_geocoding[0]}
                      lng={volume_check_detail.property.address_geocoding[1]}
                      ref_volume_check_parameters={ref_volume_check_parameters}
                      existdAreaValue={existdAreaValue}
                      areaTextFromResponse={responseArea}
                      setResponseArea={setResponseArea}
                      areaShape={propertyShape}
                      requestUUID={requestUUID}
                      current_user={current_user}
                      onChangePolygon={setPolygonChanged}
                      onChangeAreaCheck={setAreaChecked}
                      onRemovePolygon={setIsPolygonRemoved}
                      zoom={20}
                      onChangeParameters={(property) => {
                        setProperty(property)
                      }}
                      onChangeShape={(shape) => {
                        setPropertyShape(shape)
                      }}
                      setWidthLength={setWidthLength}
                      style={{
                        width: '100%',
                        height: isMapFullScreen ? '100%' : '450px',
                      }}
                      checkAreaButton={
                        <Button
                          className="w-[102px] self-center py-[3px] md:py-[4px]"
                          size="small"
                          type="button"
                          disabled={
                            propertyShape === null ||
                            propertyShape === '' ||
                            loading ||
                            usageButtonDisabled
                          }
                          title={
                            propertyShape === null || propertyShape === ''
                              ? '敷地形状を作成してください'
                              : ''
                          }
                          onClick={handlequickVolumeCheck}
                        >
                          {loading ? loadingIcon : 'area check'}
                        </Button>
                      }
                      setDisabledGroup={setDisabledGroup}
                      mapDivRef={mapRef}
                      onChangeMapCenter={setMapCenterLatLng}
                      volume_check_detail={volume_check_detail}
                      plan_number={plan_number}
                      authenticity_token={authenticity_token}
                      usageFlg={usageFlg}
                      usageSaveFlg={usageSaveFlg}
                      usageMapFlgTrue={usageMapFlgTrue}
                      usageMapFlgFalse={usageMapFlgFalse}
                      usageMapFlg={usageMapFlg}
                      propertyShape={propertyShape}
                      drawnPolygons={drawnPolygons}
                      containerRef={containerRef}
                      usageButtonDisabled={usageButtonDisabled}
                      setUsageButtonDisabled={setUsageButtonDisabled}
                      setAddRefresh={setAddRefresh}
                      addRefresh={addRefresh}
                      isClearFlg={isClearFlg}
                      usageListTmp={usageListTmp}
                      drawnPolygonsTmp={drawnPolygonsTmp}
                      areaOfUser={areaOfUser}
                      setAreaParams={setAreaParams}
                      shadeValue={shadeValue}
                      parameterChanged={parametersChanged}
                    />
                    <AnchorButton
                      className="absolute right-2 top-2 w-8 h-8 z-10"
                      onClick={isMapFullScreen ? exitMapFullScreen : triggerMapFullScreen}
                    >
                      {isMapFullScreen ? (
                        <FullscreenExitIcon fontSize="small" />
                      ) : (
                        <FullscreenIcon fontSize="small" />
                      )}
                    </AnchorButton>
                  </>
                )}
              </div>
              {showNoAreaInfoMessage && (
                <>
                  <span className="text-primary-font">
                    <br />
                    この地域の情報は提供しておりません
                  </span>
                </>
              )}
            </div>
          </div>
          {property && (property?.shape?.latlngs || !!propertyShape) && !is_processing ? (
            <div className="bg-white flex flex-wrap justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
              <AnchorButton
                className="w-full md:w-40 md:h-auto text-gray-300 mx-2"
                variant="text"
                href={`/properties/${volume_check_detail.property.hashid}/?tab=volume_check`}
              >
                キャンセル
              </AnchorButton>
              <div className="flex items-center mx-2 gap-4 my-2">
                <input type="checkbox" id="update_required" name="update_required" value="true" />
                <label htmlFor="update_required" className="text-sm text-primary-font select-none">
                  物件の形状を更新する
                </label>
              </div>
              <Button
                className="w-full md:w-40 md:h-auto mx-2"
                disabled={
                  propertyShape === null || propertyShape === '' || loading || isclickDisabled
                }
                onClick={(e) => {
                  e.preventDefault()
                  parametersChanged()
                  formRef.current.submit()
                }}
              >
                ボリュームチェック
              </Button>
              {isclickDisabled ||
                (loading && (
                  <>
                    <span className="text-sm text-gray-300">area checkが未実施</span>
                  </>
                ))}
            </div>
          ) : (
            <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-150 md:py-2 fixed bottom-0 md:left-12 z-20">
              <AnchorButton
                className="w-full md:w-40 md:h-auto text-gray-300"
                variant="text"
                href={`/properties/${volume_check_detail.property.hashid}/?tab=volume_check`}
              >
                キャンセル
              </AnchorButton>
              <Button className="w-full md:w-40 md:h-auto opacity-50" disabled>
                ボリュームチェック
              </Button>
              {is_processing ? (
                <span className="text-sm text-gray-300">現在、前のプラン計算中</span>
              ) : (
                <span className="text-sm text-gray-300">area checkが未実施</span>
              )}
            </div>
          )}
          <input
            type="hidden"
            name="volume_check_request[parameters]"
            id="volume_check_request_parameters"
            value={volume_check_detail.parameters}
          />
          <input
            type="hidden"
            name="prefecture_name"
            id="prefecture_name"
            value={volume_check_detail.property.prefecture_before_type_cast}
          />
          <input
            type="hidden"
            name="city_name"
            id="city_name"
            value={wards[volume_check_detail.property.city]}
          />
          <input
            type="hidden"
            name="chome"
            id="chome"
            value={`${volume_check_detail.property.town}${volume_check_detail.property.chome}`}
          />
          <input type="hidden" name="administrative_name" id="administrative_name" value="atr:" />
          <input
            type="hidden"
            name="center_lat"
            id="center_lat"
            value={volume_check_detail.property.lat}
          />
          <input
            type="hidden"
            name="center_lng"
            id="center_lng"
            value={volume_check_detail.property.lng}
          />
          <input type="hidden" name="area_info" id="area_info" value={propertyData.area_info} />
          <input type="hidden" name="shape" id="shape" value={propertyData.shape} />
        </form>
      </div>
    </Base>
  )
}

export default VolumeCheckRequestEditV3Page
