export const usageOptions = [
    {
      text: "指定なし",
      value: '0',
      color: '#000000'
    },
    {
      text: "第一種低層住居専用地域",
      value: '1',
      color: '#00C58E'
    },
    {
      text: "第二種低層住居専用地域",
      value: '2',
      color: '#51E7C7'
    },
    {
      text: "第一種中高層住居専用地域",
      value: '3',
      color: '#7ED043'
    },
    {
      text: "第二種中高層住居専用地域",
      value: '4',
      color: '#BCE69A'
    },
    {
      text: "第一種住居地域",
      value: '5',
      color: '#FFE395'
    },
    {
      text: "第二種住居地域",
      value: '6',
      color: '#FFA4A7'
    },
    {
      text: "準住居地域",
      value: '7',
      color: '#FFB92F'
    },
    {
      text: "近隣商業地域",
      value: '8',
      color: '#FF8EC3'
    },
    {
      text: "商業地域",
      value: '9',
      color: '#FF5C5F'
    },
    {
      text: "準工業地域",
      value: '10',
      color: '#9359F5'
    },
    {
      text: "工業地域",
      value: '11',
      color: '#8CBBE1'
    },
    {
      text: "工業専用地域",
      value: '12',
      color: '#7FA1D5'
    },
]

export const firePreventionAreaOptions = [
  {
    text: "指定なし",
    value: '0',
  },
  {
    text: "防火地域",
    value: '10',
  },
  {
    text: "準防火地域",
    value: '20',
  },
]

export const altitudeAreaOptions = [
  {
    text: "指定なし",
    value: '4',
  },
  {
    text: "第一種高度地区",
    value: '1',
  },
  {
    text: "第ニ種高度地区",
    value: '2',
  },
  {
    text: "第三種高度地区",
    value: '3',
  },
]
