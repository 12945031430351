import * as React from 'react'
import { Modal } from '@/components/Modal'
import ClearIcon from '@material-ui/icons/Clear'
import { Button, AnchorButton } from '@/components/Button'
import { ocrExecution } from '@/components/Ocr'
import { FileUploader } from '@/components/Ocr/FileUploader'
import { createSession, sessionCount } from '@/components/Ocr/api/session'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'
import { Status as OcrJobStatus } from '@/api/ocr/aws/src/API'

type MultipleOcrModalProps = {
  open: boolean
  onClose: () => void
  inputFileType: { accept?: string }
  csrfToken: string
}

type FileResult = {
  file: File
  url?: string
}

export const MultipleOcrModal: React.FC<MultipleOcrModalProps> = ({
  open,
  onClose,
  inputFileType,
  csrfToken,
}) => {
  // ファイルとそのOCR結果URLをまとめて管理する state
  const [fileResults, setFileResults] = React.useState<FileResult[]>([])
  const [ocr, setOcr] = React.useState<ocrExecution>({ localFileValidity: true } as unknown as ocrExecution)
  const [isLoading, setIsLoading] = React.useState(false)

  // OCR実行済みかどうかの判定（全てのファイルに URL が設定されていれば実行済みとみなす）
  const isOcrExecuted = fileResults.length > 0 && fileResults.every(fr => fr.url)

  // 指定された accept 属性に合致するかチェック
  const isAllowedFile = (file: File): boolean => {
    if ('accept' in inputFileType) {
      const allowed = inputFileType.accept
      if (allowed) {
        const allowedTypes = allowed.split(',').map((type: string) => type.trim())
        return allowedTypes.some((type: string) => {
          if (type.startsWith('.')) {
            return file.name.toLowerCase().endsWith(type.toLowerCase())
          }
          return file.type === type
        })
      }
    }
    return true
  }

  // ファイル追加（最大5件＆ファイルタイプチェック）
  const addFiles = (newFiles: File[]) => {
    const allowedCount = 5 - fileResults.length
    if (allowedCount <= 0) {
      alert('これ以上ファイルを追加できません')
      return
    }
    // 指定されたファイルタイプ以外のファイルは除外する
    const filteredFiles = newFiles.filter((file) => isAllowedFile(file))
    if (filteredFiles.length !== newFiles.length) {
      alert('指定されたファイルタイプ以外のファイルは無視されました')
    }

    let filesToAdd = filteredFiles
    if (filteredFiles.length > allowedCount) {
      filesToAdd = filteredFiles.slice(0, allowedCount)
      alert(`最大5ファイルまで選択可能です。`)
    }
    const newFileResults = filesToAdd.map((file) => ({ file }))
    setFileResults([...fileResults, ...newFileResults])
  }

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const selectedFiles = Array.from(event.target.files)
    addFiles(selectedFiles)
  }

  const handleReload = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set('openModal', 'true');
    window.location.href = currentUrl.toString();
  }
  // モーダル全体にドラッグ＆ドロップのイベントを適用
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (!event.dataTransfer.files) return
    const droppedFiles = Array.from(event.dataTransfer.files)
    addFiles(droppedFiles)
  }

  const handleRemoveFile = (index: number) => {
    const updated = [...fileResults]
    updated.splice(index, 1)
    setFileResults(updated)
  }

  // OCR実行中かどうかのフラグ
  const isProcessing = React.useRef(false)

  const handleOcrExecution = async () => {
    if (isProcessing.current) return
    isProcessing.current = true
    setIsLoading(true)

    try {
      if (fileResults.length === 0) {
        alert('ファイルが選択されていません')
        return
      }

      try {
        const sessionData = await sessionCount(csrfToken)
        if (sessionData.count + fileResults.length > 5) {
          alert('複数OCR読み取りは同時に5件までしか実行できません。処理の完了をお待ちください。')
          return
        }
      } catch (error) {
        console.error('セッション確認エラー:', error);
        alert('セッションの確認に失敗しました。')
        return
      }

      try {
        const uploader = new FileUploader(setOcr);
        // 各ファイルに対してアップロードを実行し、全て完了するまで待つ
        const uploadPromises = fileResults.map((fr) => uploader.upload(fr.file, csrfToken));
        const ocrStates = await Promise.all(uploadPromises);

        // 各 OCR 状態ごとに UUID を生成し、対応するファイル名を含む ocrData オブジェクトを作成
        const ocrData = ocrStates.map((ocrState, index) => {
          const uuid = crypto.randomUUID();
          return {
            ocr_session_id: uuid,
            is_processing: false,
            ocr_state: {
              blobSignedId: ocrState.signedId,
              remoteFileKey: ocrState.blobKey,
              remoteFileURL: ocrState.imageUrl,
              fileName: fileResults[index].file.name, // ファイル名を追加
            },
          };
        });
        // セッション作成
        try {
          await createSession(csrfToken, ocrData);
        } catch (error) {
          console.error('セッション作成エラー:', error);
          alert(error)
          return
        }
        console.log('Session data created for OCR.')
        // URLオブジェクトを生成
        const parsedUrl = new URL(window.location.href);
        // searchParamsを使い、"data_type"パラメータの値を取得
        const dataType = parsedUrl.searchParams.get("data_type");

        // 各ファイルに対応するOCR結果のURLを生成し、state を更新
        const updatedFileResults = ocrData.map((item, index) => ({
          file: fileResults[index].file,
          url: `${window.location.origin}/market_data/new?ocrKey=${item.ocr_session_id}&data_type=${dataType}&redirect_to=/market_data`,
        }))
        setFileResults(updatedFileResults)
        alert('全てのファイルのアップロードとOCR実行用のリンクの生成が完了しました。')
      } catch (error) {
        console.error('アップロードエラー:', error)
        alert('ファイルのアップロードに失敗しました')
      }
    } catch (error) {
      console.error('エラー:', error)
    } finally {
      isProcessing.current = false
      setIsLoading(false)
    }
  }

  const handleClearFiles = () => {
    setFileResults([])
  }

  // 生成されたリンクを数秒ずつ順次新規タブで開く関数（ここでは2秒間隔を設定）
  const handleOpenAllLinks = () => {
    // ポップアップブロックが発生する可能性を通知
    alert("注意: ブラウザのポップアップブロックが有効な場合、リンクが開かれないことがあります。ポップアップの許可設定をご確認ください。")
    const delay = 2000 // 2秒ごとにリンクを開く
    fileResults.forEach((fr, index) => {
      if (fr.url) {
        setTimeout(() => {
          window.open(fr.url, '_blank')
        }, index * delay)
      }
    })
  }

  return (
    <Modal title="複数枚OCR読み取り" open={open} onClose={onClose}>
      <LoadingOverlay isOpen={isLoading} text="処理中..." />
      <div className="p-4 sm:p-6 bg-white rounded-md w-full max-w-[600px] mx-auto">
        {/* OCR未実行の場合のみ、ファイル選択用のドラッグ＆ドロップエリア、入力エリア、説明文、ボタン類を表示 */}
        {!isOcrExecuted && (
          <>
            <div onDragOver={handleDragOver} onDrop={handleDrop}>
              <div
                className="md:flex p-6 border-2 border-dashed border-gray-200 cursor-pointer w-full text-sm mb-4"
                onClick={() => document.getElementById('fileInput')?.click()}
              >
                <div className="text-center w-full">
                  <p className="text-sm text-gray-600 mb-2">ファイルをドラッグドロップ</p>
                  <p className="text-sm text-gray-600 mb-4">または</p>
                  <label className="text-sm text-primary font-semibold cursor-pointer">
                    ファイル選択
                  </label>
                </div>
                <input
                  id="fileInput"
                  type="file"
                  className="hidden"
                  {...inputFileType}
                  multiple
                  onChange={handleFileSelect}
                />
              </div>
              <p className="p-2 pl-2 text-xs text-left">
                OCR、または、ファイル添付したい物件概要書を{' '}
                <span className="font-bold">PDF形式</span> でアップロードしてください。
                <br />
                5枚まで選択可能です。
              </p>
            </div>
          </>
        )}
        
        {/* ファイルリスト */}
        {fileResults.length > 0 && (
          <div className="border border-gray rounded p-4 mb-4">
            {/* OCR未実行の場合のみ、ファイル数表示と「全てクリア」ボタンを表示 */}
            {!isOcrExecuted && (
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm">{fileResults.length}/5 ファイル選択中</span>
                <button className="text-sm text-blue-600 hover:underline" onClick={handleClearFiles}>
                  全てクリア
                </button>
              </div>
            )}
            <ul>
              {fileResults.map((fr, index) => (
                <li
                  key={index}
                  className="flex flex-col sm:flex-row justify-between items-center text-sm border-gray-200 py-2 first:border-t-0"
                >
                  {fr.url ? (
                    <a
                      href={fr.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="truncate text-blue-600 underline text-left w-full sm:w-4/5"
                    >
                      {fr.file.name}
                    </a>
                  ) : (
                    <span className="truncate text-left w-full sm:w-4/5">
                      {fr.file.name}
                    </span>
                  )}
                  {!isOcrExecuted && (
                    <div className="flex items-center space-x-2 mt-2 sm:mt-0">
                      <button
                        onClick={() => handleRemoveFile(index)}
                        className="text-red-500 hover:text-red-700"
                      >
                        <ClearIcon style={{ fontSize: 16 }} />
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* OCR実行後は、下部にリンク押下時の説明と全リンクを順次開くボタンを表示 */}
        {isOcrExecuted && (
          <>
            <p className="p-2 text-left text-sm text-gray-700">
              ※OCR読み込みは最大5件まで同時に実行できます。5件以上読み込みを実行すると、エラーが発生します。<br />
              ※各ファイル名のリンクをクリックすると、OCR実行ページが新しいタブで開きます。<br />
              ※リンクは一度だけ有効です。再実施する場合は、再度OCR読み取りを実行してください。<br />
              ※処理を完了せずにtabを閉じると、制限数にカウントされ、制限に達することがあります。その場合は5分後に再度実行してください。<br />
            </p>
            <div className="flex justify-end mt-4 space-x-4">
              <AnchorButton outline variant="text" onClick={handleReload}>
                ファイルリセット
              </AnchorButton>
              <Button variant="primary" onClick={handleOpenAllLinks}>
                全リンクを順次開く
              </Button>
            </div>
          </>
        )}

        {/* OCR未実行の場合のみ、下部のキャンセル・OCR実行ボタンを表示 */}
        {!isOcrExecuted && (
          <div className="flex justify-end space-x-3">
            <AnchorButton outline variant="text" onClick={onClose}>
              キャンセル
            </AnchorButton>
            <Button variant="primary" onClick={handleOcrExecution}>
              OCR実行
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}
