export const buildingStructureType = {
  rc: 'RC造：鉄筋コンクリート造',
  wrc: 'WRC造：壁式鉄筋コンクリート造',
  src: 'SRC造：鉄骨鉄筋コンクリート造',
  s: 'S造：鉄骨造',
  s_light: 'S造（軽量）：軽量鉄骨造',
  s_heavy: 'S造（重量）：重量鉄骨造',
  w: 'W造：木造',
  al: 'AL造：アルミ造',
  cft: 'CFT造：コンクリート充填鋼管構造',
  cb: 'CB造：コンクリートブロック造',
  others: 'その他',
}
