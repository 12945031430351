export const propertyStatusOptions = [
  { value: 'ongoing', text: '検討中' },
  { value: 'yet', text: '検討終了' },
]

export const propertyTypeOptions = [
  { value: '土地', text: '土地' },
  { value: '土地＋建物（一棟）', text: '土地＋建物（一棟）' },
  { value: '土地＋建物（区分）', text: '土地＋建物（区分）' },
]

export const buildingStructureOptions = [
  { value: 'rc', text: 'RC造：鉄筋コンクリート造' },
  { value: 'wrc', text: 'WRC造：壁式鉄筋コンクリート造' },
  { value: 'src', text: 'SRC造：鉄骨鉄筋コンクリート造' },
  { value: 's', text: 'S造：鉄骨造' },
  { value: 's_light', text: 'S造：軽量鉄骨造' },
  { value: 's_heavy', text: 'S造：重量鉄骨造' },
  { value: 'w', text: 'W造：木造' },
  { value: 'al', text: 'AL造：アルミ造' },
  { value: 'cft', text: 'CFT造：コンクリート充填鋼管構造' },
  { value: 'cb', text: 'CB造：コンクリートブロック造' },
  { value: 'others', text: 'その他' },
]

export const localSituationOptions = [
  { value: '更地', text: '更地' },
  { value: '建物有（全空）', text: '建物有（全空）' },
  { value: '建物有（稼働中）', text: '建物有（稼働中）' },
  { value: '解体中', text: '解体中' },
  { value: '建築中', text: '建築中' },
]

export const usageAreaCode = {
  '1': '第1種低層住居専用地域',
  '2': '第2種低層住居専用地域',
  '3': '第1種中高層住居専用地域',
  '4': '第2種中高層住居専用地域',
  '5': '第1種住居地域',
  '6': '第2種住居地域',
  '7': '準住居地域',
  '8': '近隣商業地域',
  '9': '商業地域',
  '10': '準工業地域',
  '11': '工業地域',
  '12': '工業専用地域',
}

export const propertyCertificateOfInspectionOptions = [
  { value: '有', text: '有' },
  { value: '無', text: '無' },
]

export const LatDefault = 35.6808404

export const LngDefault = 139.7614134
