import * as React from 'react'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import type { Tag } from '@/types/tag'
import { MultiSelectCheckboxModal } from '@/components/Select/MultiSelectCheckboxModal'
import { User } from '@/types/user'
import { MarketDatumReport } from '@/types/marketDatumReport'
import { ErrorMessage } from '@/components/ErrorMessage'
import { CustomizeTable } from '@/components/Table/CustomizeTable'
import { LoadSpinner } from '@/components/Loading/LoadSpinner'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'
import { Introduction } from '@/components/Layout/Introduction'
import {
  COLUMN_LABELS,
  FIXED_COLUMNS,
  SAMPLE_VALUES,
  INITIAL_NOT_DISPLAY_ITEMS,
  HIDDEN_SEND_COLUMNS,
  HEAD_STICKY_STYLES,
  BODY_STICKY_STYLES,
} from '@/models/tableConfig'

type Props = {
  current_user: User
  business_type: Tag
  columns_selected_values: { number: string }
  not_display_columns: boolean[]
  hidden_send_columns: string[]
  dummy_values: string[]
  market_datum_report: MarketDatumReport
  column_list: { value: string; text: string }[]
  authenticity_token: string
  error_messages: string[]
}

const MarketDatumReportLayoutNewEditPage: React.FC<Props> = ({
  current_user,
  not_display_columns = [],
  dummy_values = [],
  hidden_send_columns = [],
  market_datum_report,
  column_list = [],
  authenticity_token,
  error_messages,
}) => {
  const title = '帳票フォーム設定'
  const action = market_datum_report.id ? 'patch' : 'post'
  const introduction_message = [
    `◾️ 周辺事例 : ${market_datum_report.data_type_text}の項目の順番を変更できます。`,
    '◾️ 右矢印をクリックすると右にカラムが移動し、左矢印をクリックすると左に移動します。',
    '◾️ 横長の画面で表示する場合、No.と物件名の位置は固定です。',
    '◾️ 表示項目内のゴミ箱のマークをクリックすると選択が解除されます。',
    '▪️ 項目選択ボタンのポップアップについて',
    '　　- 表示項目の種類を選択することができます。',
    '　　- 「閉じる」またはポップアップ画面以外の場所をクリックすると閉じることができます。',
  ]
  const display_keys = FIXED_COLUMNS.concat(
    column_list.filter((item) => item.text !== '(未設定)').map((item) => item.text)
  )
  const display_dummy_values = SAMPLE_VALUES.concat(dummy_values)
  const use_not_display_items = INITIAL_NOT_DISPLAY_ITEMS.concat(not_display_columns)
  const use_hidden_send_columns = HIDDEN_SEND_COLUMNS.concat(hidden_send_columns)

  const [isLoading, setIsLoading] = React.useState(false)
  const [useColumn, setUseColumn] = React.useState(display_keys)
  const [isDummyValue, setDummyValue] = React.useState(display_dummy_values)
  const [isNotDisplayColumns, setNotDisplayColumns] = React.useState(use_not_display_items)
  const [isHiddenSendColumns, setHiddenSendColumns] = React.useState(use_hidden_send_columns)

  const [isMultiSelectCheckboxModalFlg, setMultiSelectCheckboxModalFlg] = React.useState(false)

  const swapArrayItems = (arr: any[], index: number, direction: number) => {
    const newArr = [...arr]
    ;[newArr[index], newArr[index + direction]] = [newArr[index + direction], newArr[index]]
    return newArr
  }

  const swapItems = (index: number, direction: number) => {
    setUseColumn((prev) => swapArrayItems(prev, index, direction))
    setDummyValue((prev) => swapArrayItems(prev, index, direction))
    setNotDisplayColumns((prev) => swapArrayItems(prev, index, direction))
    setHiddenSendColumns((prev) => swapArrayItems(prev, index, direction))
  }

  const moveLeft = (index: number) => {
    if (index > Object.keys(HEAD_STICKY_STYLES).length) {
      swapItems(index, -1)
      // 左側が表示項目でなかった場合にさらに進む
      if (isNotDisplayColumns[index - 1]) {
        moveLeft(index - 1)
      }
    }
  }

  const moveRight = (index: number) => {
    // 右端に到達したら処理を終了(表示項目が見つからなかった場合の保険)
    if (index >= useColumn.length - 1) {
      return
    }
    const nextIndex = index + 1
    swapItems(index, 1)
    // 次のカラムが非表示の場合、更に右に進む
    if (isNotDisplayColumns[nextIndex]) {
      moveRight(nextIndex)
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const payload = isHiddenSendColumns
      .slice(FIXED_COLUMNS.length)
      .map((column, index) =>
        isNotDisplayColumns.slice(FIXED_COLUMNS.length)[index] ? null : column
      )
      .reduce((acc, column, index) => {
        if (column) acc[`column_${index}`] = column
        return acc
      }, {})
    console.log('送信データ:', payload)
    fetch(actionPath(market_datum_report), {
      method: action.toUpperCase(),
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': authenticity_token,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('通信に失敗しました')
        }
        return response.json()
      })
      .then((data) => {
        console.log('サーバーの応答:', data)
      })
      .catch((error) => {
        console.error('送信エラー:', error)
      })
      .finally(() => {
        window.location.reload()
        setTimeout(() => setIsLoading(false), 2 * 60 * 1000)
      })
  }

  const toggleMultiSelectCheckboxModalFlg = () => {
    setMultiSelectCheckboxModalFlg((prev) => !prev)
  }
  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={[title]} />}
      submenuCategory="settings"
    >
      <form className="mt-8" onSubmit={handleSubmit} acceptCharset="UTF-8">
        <input type="hidden" name="_method" value={action} />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />
        <MultiSelectCheckboxModal
          isMultiSelectCheckboxModalFlg={isMultiSelectCheckboxModalFlg}
          toggleMultiSelectCheckboxModalFlg={toggleMultiSelectCheckboxModalFlg}
          purpose="manual"
          item_name={useColumn}
          item_value={isNotDisplayColumns}
          setItemValue={setNotDisplayColumns}
          inversion_value={true}
          first_elm={true}
          second_elm={true}
        />
        <ErrorMessage error_messages={error_messages} />

        <div className="h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <p className={`my-4`}>周辺事例：{market_datum_report.data_type_text}</p>
          <Introduction introduction_message={introduction_message} details_style={'my-4'} />

          <div
            className={`border border-[#0b689d] w-[30%] md:w-[10%] flex items-center justify-center rounded-[10px] p-[5px] shadow-[0_4px_6px_rgba(0,0,0,0.1)] my-5 cursor-pointer ${
              isMultiSelectCheckboxModalFlg ? 'bg-[#0b689d] text-white' : 'text-[#5d92b8]'
            }`}
            onClick={() => setMultiSelectCheckboxModalFlg(true)}
          >
            項目選択
          </div>

          <LoadingOverlay isOpen={isLoading} text="変更中" />
          <p>表示項目数 : {isNotDisplayColumns.filter((item) => item === false).length}</p>
          <CustomizeTable
            use_view_name={'market_datum_report_layout'}
            purpose={'show_hide_toggle'}
            display_keys={useColumn}
            display_values={isDummyValue}
            column_labels={COLUMN_LABELS}
            display_visibility_num={FIXED_COLUMNS.length - 1}
            head_sticky_styles={HEAD_STICKY_STYLES}
            body_sticky_styles={BODY_STICKY_STYLES}
            custom_visibility_flg={true}
            display_move_left_num={FIXED_COLUMNS.length}
            display_move_right_num={FIXED_COLUMNS.length - 1}
            not_display_columns={isNotDisplayColumns}
            setNotDisplayColumns={setNotDisplayColumns}
            moveLeft={moveLeft}
            moveRight={moveRight}
          />
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href={`/market_datum_report_layouts/new?data_type=${market_datum_report.data_type}`}
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto" type="submit">
            編集を保存
          </Button>
        </div>
      </form>
    </Base>
  )
}

const actionPath = (marketDatumReport: MarketDatumReport): string => {
  return marketDatumReport.id
    ? `/market_datum_report_layouts/${marketDatumReport.id}`
    : `/market_datum_report_layouts?data_type=${marketDatumReport.data_type}`
}

export default MarketDatumReportLayoutNewEditPage
