import * as React from 'react'
import { Button, AnchorButton } from '@/components/Button'
import { Checkbox } from '@/components/Checkbox'
import { User } from '@/types/user'
import { DisplayItem } from '@/types/displayItem'
import { RequiredItem } from '@/types/requiredItem'
import { Table } from '@/components/Table'
import type { Property } from '@/types/property'
import { Balloon } from '@/components/GoogleMapViewer/setupButtons/Layer/utils/balloonDom'
import { MarketDatum } from '@/types/marketDatum'
import { twoColumns } from '@/models/baloonSetting'

type Props = {
  current_user: User
  property?: Property
  display_item_data: DisplayItem[]
  balloon_dummy: MarketDatum
  required_item_data: RequiredItem[]
  authenticity_token: string
  error_messages: string[]
}

const BaloonSetting: React.FC<Props> = ({
  authenticity_token,
  display_item_data,
  balloon_dummy,
  error_messages,
}) => {


  const [activeInfomationTab, setInfomationTab] = React.useState(3)
  const [selectedSaleOrder, setSelectedSaleOrder] = React.useState([]);
  const [selectedRentOrder, setSelectedRentOrder] = React.useState([]);

  const item_type = (() => {
    if (window.location.href.includes('for_property&land')) {
      return 0
    } else if (window.location.href.includes('for_property&building')) {
      return 1
    } else if (window.location.href.includes('for_property&classification')) {
      return 2
    }
    return 0
  })()
  const display_name = "周辺事例"
  const getItemsByCategory = (category) =>
    display_item_data.filter(
    (item) => item.display_infomation_category === category && item.item_type == item_type
    )

  const is_get_sale_items = getItemsByCategory('3')
  const is_get_rent_items = getItemsByCategory('4')
  const mapItemsToObject = (items) =>
    items.reduce((acc, item) => ({ ...acc, [item.display_item_id]: item.value }), {});

    const initialDisplayItems = {
    3: mapItemsToObject(is_get_sale_items),
    4: mapItemsToObject(is_get_rent_items),
    };
    const [tabDisplayItems, setTabDisplayItems] = React.useState(initialDisplayItems)

  const updateValue = (  tabIndex: number, name: string, value: boolean, item_type: number) => {
    // 2カラム分を使用するカラム
    console.log('tabindex', tabIndex)
    console.log('name', name)
    console.log('value', value)

    const trueCount = Object.values(tabDisplayItems[tabIndex]).filter(value => value === true).length;
    const matchedItem = display_item_data.find(
      (item) => item.name === name && item.item_type === item_type && item.display_infomation_category === tabIndex.toString()
    )
    const columnName = matchedItem ? matchedItem.column_name : null
    const columnId = matchedItem ? matchedItem.display_item_id : null
    // 順番
    let updatedOrder = tabIndex === 3 ? [...selectedSaleOrder] : [...selectedRentOrder]

    const maxItems = 5;
    const warningMessage = "バルーンには5つ以上の項目を配置できません。";
    // 特定の項目かどうかを判定する関数を作成
    const isSpecialItem = updatedOrder.includes("rent_maintenance_fee_and_unit_price") || columnName === "rent_maintenance_fee_and_unit_price";
    // 配置可能なアイテム数を決定
    const maxAllowedItems = isSpecialItem ? 4 : maxItems;
    if (trueCount >= maxAllowedItems && value === true) {
      alert(warningMessage);
      return;
    }

    if (["売出NOI利回り・成約NOI利回り" , "売出価格・成約価格（万円）" , "売出表面利回り・成約表面利回り"].includes(name) && value === true){
      const [openColumnRaw, closeColumnRaw] = name.split("・");
      // 余分な単位は削除
      const openColumn = openColumnRaw.replace(/（万円）/g, "");
      const closeColumn = closeColumnRaw.replace(/（万円）/g, "");
      alert(`状態が募集中の時に「${openColumn}」、成約の時に「${closeColumn}」が表示されます。`)
    }

    if (["賃料（管理費込み）@ 賃料（管理費込み）㎡単価"].includes(name) && value === true){
      alert(`「${name}」は2カラムとしてカウントされます。`)
    }



    setTabDisplayItems((prevState) => {
      // 複数カラムは二要素目には配置させない
      let falseColumnName = null;
      if (updatedOrder.length === 1 && twoColumns.includes(columnName) && value){
        falseColumnName = updatedOrder[0]
        updatedOrder[0] = columnName
      }else{
        // 条件に基づいて順番を更新
        updatedOrder = value
        ? [...updatedOrder, columnName]
        : updatedOrder.filter((column) => column !== columnName);
      }

      // isSaleがtrueならselectedSaleOrderを更新、それ以外ならselectedRentOrderを更新
      if (tabIndex === 3 ) {
        setSelectedSaleOrder(updatedOrder)
      } else {
        setSelectedRentOrder(updatedOrder)
      }
      const newState = {
        ...prevState,
        [tabIndex]: {
          ...prevState[tabIndex],
          [columnId]: value,
        },
      }
      return newState
    })
    console.log('Updated State:', tabDisplayItems)
  }

  React.useEffect(() => {

    // 順番の配列を設定する
    const sortedSaleItems = is_get_sale_items
    .filter(item => item.value)
    .sort((a, b) => a.order - b.order)
    .map(item => item.column_name);

    const sortedRentItems = is_get_rent_items
    .filter(item => item.value)
    .sort((a, b) => a.order - b.order)
    .map(item => item.column_name);

    setSelectedSaleOrder(sortedSaleItems)
    setSelectedRentOrder(sortedRentItems)

    const baloonColumns = {
      type: "for_sale",
      columns: sortedSaleItems
    }
    const getDomSale = document.querySelector('#balloon-dom-sale')
    const getDomRent = document.querySelector('#balloon-dom-rent')
    const balloonDom = Balloon(balloon_dummy, baloonColumns, true).toString()
    getDomSale.innerHTML = balloonDom
    getDomRent.innerHTML = balloonDom
  }, [])

  React.useEffect(() => {
    const isSale = activeInfomationTab === 3
    const changeId = isSale ? "#balloon-dom-sale" : "#balloon-dom-rent"
    const getDom = document.querySelector(changeId)

    const baloonColumns = {
      type: isSale ? "for_sale" : "for_rent",
      columns: isSale ? selectedSaleOrder : selectedRentOrder
    }
    const balloonDom = Balloon(balloon_dummy , baloonColumns, true).toString()
    getDom.innerHTML = balloonDom
  }, [ activeInfomationTab, selectedSaleOrder, selectedRentOrder])

  return (
    <>
      <div className="flex mt-6">
        <button
          className={`inline-flex outline-none select-none text-sm py-2 px-8 border border-gray-300 ${
            activeInfomationTab === 3
              ? 'bg-primary text-white border-primary'
              : 'bg-white text-primary'
          }`}
          onClick={() => setInfomationTab(3)}
        >売買事例
        </button>
        <button
          className={`inline-flex outline-none select-none text-sm py-2 px-8 border border-gray-300 ${
            activeInfomationTab === 4
              ? 'bg-primary text-white border-primary'
              : 'bg-white text-primary'
          }`}
          onClick={() => setInfomationTab(4)}
        >
          賃貸事例
        </button>
      </div>
      <div className="xl:flex">
        <form className="mt-8 xl:w-1/2" action="/display_items" acceptCharset="UTF-8" method="post">
          <input type="hidden" name="_method" value="patch" />
          <input type="hidden" name="authenticity_token" value={authenticity_token} />
          <input type="hidden" name="item_type" value={item_type} />
          <input type="hidden" name="display_name" value={display_name} />
          <Table
            className={
              window.innerHeight > 920
                ? 'h-[500px] md:h-[500px]'
                : 'h-[calc(85vh_-_182px)] md:h-[calc(85vh_-_195px)]'
            }
            borderLine={false}
            topScrollbar={true}
          >
            <thead className="bg-gray-150 text-sm sticky top-0" style={{ zIndex: 100 }}>
              <tr className="whitespace-nowrap py-2">
                <th scope="col" className="py-2 px-4 font-medium">
                  No.
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  画面名
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  カラム名
                </th>
                <th scope="col" className="py-2 px-4 w-1/12 font-medium">
                  ON/OFF
                </th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              {is_get_sale_items.map((item, i) => (
                <tr
                  key={i}
                  className={`${
                    i % 2 ? 'bg-gray-light' : 'bg-white'
                  } hover:bg-targetLine cursor-pointer ${
                    activeInfomationTab !== 3 ? 'hidden' : ''
                  }`}
                >
                  <td className="table-cell px-8 w-1/12">{i + 1}</td>
                  <td className="table-cell px-4 w-1/12">{item.display_name}</td>
                  <td className="table-cell px-4 w-1/2">{item.name}</td>
                  <td className="table-cell px-4 w-1/12">
                    <Checkbox
                      key={item.display_item_id}
                      id={`display_item_id${item.display_item_id}`}
                      name="display_item[display_item_id][]"
                      value={item.display_item_id}
                      checked={tabDisplayItems[3][item.display_item_id] || false}
                      className="py-2 px-4"
                      onChange={(e) => updateValue(3, item.name, e.target.checked, item.item_type)}
                    />
                  </td>
                </tr>
              ))}
              {is_get_rent_items.map((item, i) => (
                <tr
                  key={i}
                  className={`${
                    i % 2 ? 'bg-gray-light' : 'bg-white'
                  } hover:bg-targetLine cursor-pointer ${
                    activeInfomationTab !== 4 ? 'hidden' : ''
                  }`}
                >
                  <td className="table-cell px-8 w-1/12">{i + 1}</td>
                  <td className="table-cell px-4 w-1/12">{item.display_name}</td>
                  <td className="table-cell px-4 w-1/2">{item.name}</td>
                  <td className="table-cell px-4 w-1/12">
                    <Checkbox
                      key={item.display_item_id}
                      id={`display_item_id${item.display_item_id}`}
                      name="display_item[display_item_id][]"
                      value={item.display_item_id}
                      checked={tabDisplayItems[4][item.display_item_id] || false}
                      className="py-2 px-4"
                      onChange={(e) => updateValue(4, item.name, e.target.checked, item.item_type)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 pt-2">
            <AnchorButton
              className="w-full md:w-40 md:h-auto text-primary-font border-white"
              variant="text"
              outline
              href="/display_items"
            >
              キャンセル
            </AnchorButton>
            <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
          </div>
        </form>
        <div
          className={`mt-8 xl:w-1/2 ${activeInfomationTab !== 3 ? 'hidden' : ''} relative`}
          style={{ marginLeft: '1%' }}
        >
          <>
            <p className="text-xs">※ 5つまでカラムを設定できます。</p>
            <p className="text-xs">※ 1要素で2カラムとしてカウントされるカラムが存在します。</p>
            <p className="text-xs">※ 以下バルーンはプレビューのイメージです。</p>
            <div className="w-1/2 mx-auto mt-10" id="balloon-dom-sale" />
          </>
        </div>
        <div
          className={` mt-8 xl:w-1/2 ${activeInfomationTab !== 4 ? 'hidden' : ''}`}
          style={{ marginLeft: '1%' }}
        >
            <p className="text-xs">※ 5つまでカラムを設定できます。</p>
            <p className="text-xs">※ 1要素で2カラムとしてカウントされるカラムが存在します。</p>
            <p className="text-xs">※ 以下バルーンはプレビューのイメージです。</p>
          <div className="w-1/2 mx-auto mt-10" id="balloon-dom-rent"></div>
        </div>
      </div>
    </>
  )
}

export default BaloonSetting
