import * as React from 'react'
import { format, parseISO } from 'date-fns'

import { Table } from '@/components/Table'
import { Input } from '@/components/Input'
import { Button } from '@/components/Button'

import DeleteOutlined from '@material-ui/icons/DeleteOutlined'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import CircularProgress from '@mui/material/CircularProgress'

import type { Property } from '@/types/property'
import type { User } from '@/types/user'
import type { File } from '@/types/file'

import { cover_enterprise_pricing_type } from '@/utils/policy'

import { useDropzone } from 'react-dropzone'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'

import { PasswordModal } from '@/components/Layout/Password'

const PropetyAttachments: React.FC<{
  property: Property
  user: User
  authenticity_token: string
  attached_files: File[]
  error_messages: []
}> = ({ property, user, authenticity_token, attached_files = [], error_messages }) => {
  type sortTypeEnum = 'filename-asc' | 'filename-desc' | 'date-asc' | 'date-desc'
  const [attachedFiles, setAttachedFiles] = React.useState(attached_files)
  const [sortType, setSortType] = React.useState<sortTypeEnum>('date-desc')
  const [fileNames, setFileNames] = React.useState<string[]>([])
  const [files, setFiles] = React.useState<any[]>([])
  const [processing, setProcessing] = React.useState<boolean>(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const onDrop = React.useCallback(
    (acceptedFiles) => {
      if (cover_enterprise_pricing_type(user)) {
        setFileNames(fileNames.concat(acceptedFiles.map((file) => file.name)))
        setFiles(files.concat(acceptedFiles))
      } else {
        const file = acceptedFiles[0]
        setFileNames([file.name])
        setFiles([file])
      }
    },
    [fileNames]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: cover_enterprise_pricing_type(user) ? acceptEnterprise : acceptStandard,
  })

  React.useEffect(() => {
    sortAtattchedFiles(sortType)
  }, [sortType])

  const sortAtattchedFiles = (sortType: sortTypeEnum) => {
    setAttachedFiles(
      Array.from(attachedFiles).sort((n1, n2) => {
        if (sortType === 'filename-asc') {
          const [n1name, n2name] = [parseInt(n1.name), parseInt(n2.name)]
          if (n1name === NaN || n2name === NaN) {
            return n1.name > n2.name ? 1 : -1
          } else {
            return n1name > n2name ? 1 : -1
          }
        } else if (sortType === 'filename-desc') {
          const [n1name, n2name] = [parseInt(n1.name), parseInt(n2.name)]
          if (n1name === NaN || n2name === NaN) {
            return n1.name < n2.name ? 1 : -1
          } else {
            return n1name < n2name ? 1 : -1
          }
        } else if (sortType === 'date-asc') {
          return n1.created_at > n2.created_at ? 1 : -1
        } else if (sortType === 'date-desc') {
          return n1.created_at < n2.created_at ? 1 : -1
        } else {
          return 0
        }
      })
    )
  }

  const [zipPassword, setZipPassword] = React.useState("")
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const allDownload = async () => {
    const csrfToken: HTMLMetaElement = document.head.querySelector('meta[name="csrf-token"]');
    // ダウンロードするファイルIDを収集
    const selectedFilesData = attachedFiles.filter(file => selectedFiles.includes(file.id));
    const fileIds = selectedFilesData.map(file => file.id);
    const keys = selectedFilesData.map(file => file.key);

    setIsLoading(true)

    // fileIds が 1 つだけの場合、直接ダウンロード
    if (fileIds.length === 1) {
      const file = selectedFilesData[0]
      const a = document.createElement("a")
      a.href = file.download_url
      a.download = file.filename
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setTimeout(() => setIsLoading(false), 500)
      return; // ここで処理を終了
    }

    try {
      // ZIPダウンロードのリクエスト
      const response = await fetch('/properties/download_zip', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken.content,
        },
        body: JSON.stringify({ file_keys: keys }),
      });

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.error || 'ファイルのダウンロードに失敗しました');
      }

      // const password = response.headers.get('X-Download-Password'); // ヘッダーからパスワードを取得
      // setZipPassword(password)
      // setIsModalOpen(true)

      // 現在の日時を取得
      const now = new Date();
      const formattedDate = now.toISOString().replace(/[-T:]/g, "").split(".")[0]; // YYYYMMDDHHMMSS
      // ファイル名を指定
      const fileName = `物件資料_${formattedDate}.zip`;
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // 解放
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      alert(error.message); // エラーメッセージを表示
    }
  };

  const [selectedFiles, setSelectedFiles] = React.useState<string[]>([])
  const toggleFileSelection = (fileId: string) => {
    setSelectedFiles(prev =>
      prev.includes(fileId) ? prev.filter(id => id !== fileId) : [...prev, fileId]
    )
  }
  const toggleSelectAll = () => {
    if (selectedFiles.length === attachedFiles.length) {
      setSelectedFiles([])
    } else {
      setSelectedFiles(attachedFiles.map(file => file.id));
    }
  }

  return (
    <div className="mt-10 mb-10">
      <div className="border-b-[1px] border-primary mb-2">
        <span className="inline-flex bg-primary text-white text-sm py-2 px-8">関連資料</span>
      </div>
      <div className="text-gray-300 text-[10px]">
        ※ダウンロード時に文字化けする場合は、ブラウザの拡張機能のPDF Viewer（例：Adobe
        Acrobat）の無効化をお試しください。
      </div>
      <LoadingOverlay isOpen={isLoading} text="Download" />
      <div className="flex justify-end w-full">
        {/* パスワード表示モーダル */}
        {/* {isModalOpen && (
          <PasswordModal
            PASS={zipPassword}
          />
        )} */}
        <div className="ml-2">
          <Button
            className="text-primary-font m-0 bg-white mb-2.5"
            outline
            size="small"
            variant="primary"
            prefix={<CloudDownloadIcon fontSize="small" />}
            onClick={allDownload}
            disabled={selectedFiles.length === 0}
          >
            <span className="hidden md:inline">資料をダウンロード</span>
          </Button>
        </div>
      </div>
      <div className="mb-2">
        <Table borderLine={false}>
          <thead className="bg-gray-150 text-sm sticky top-0">
            <tr className="whitespace-nowrap">
              <th scope="col" className="py-2 px-4 font-medium">
                <input
                  type="checkbox"
                  onChange={toggleSelectAll}
                  checked={selectedFiles.length === attachedFiles.length}
                />
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  if (sortType === 'filename-asc') {
                    setSortType('filename-desc')
                  } else {
                    setSortType('filename-asc')
                  }
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>ファイル名</p>
                  <div className={'w-6'}>
                    {sortType === 'filename-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'filename-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  if (sortType === 'date-asc') {
                    setSortType('date-desc')
                  } else {
                    setSortType('date-asc')
                  }
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>登録日時</p>
                  <div className={'w-6'}>
                    {sortType === 'date-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'date-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th
                scope="col"
                className="py-2 px-4 font-medium cursor-pointer"
                onClick={() => {
                  if (sortType === 'user_name-asc') {
                    setSortType('user_name-desc')
                  } else {
                    setSortType('user_name-asc')
                  }
                }}
              >
                <div className={'flex'}>
                  <p className={'my-auto'}>担当者</p>
                  <div className={'w-6'}>
                    {sortType === 'user_name-asc' && <KeyboardArrowUpIcon />}
                    {sortType === 'user_name-desc' && <KeyboardArrowDownIcon />}
                  </div>
                </div>
              </th>
              <th scope="col" className="py-2 px-4 font-medium"></th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {attachedFiles.map((file, index) => (
              <tr key={index} className={`${index % 2 ? 'bg-gray-light' : 'bg-white'}`}>
                {!file.processing ? (
                  <>
                    <td className="py-2 px-4">
                      <input
                        type="checkbox"
                        checked={selectedFiles.includes(file.id)}
                        onChange={() => toggleFileSelection(file.id)}
                      />
                    </td>
                    <td className="py-2 px-4">
                      {user.proxy_company_id ? (
                        <>**********************</>
                      ) : (
                        <a
                          href={file.url}
                          target="_blank"
                          rel="noreferrer"
                          className="text-primary"
                        >
                          {file.name}
                        </a>
                      )}
                    </td>
                    <td className="py-2 px-4">
                      {format(parseISO(file.created_at), 'yyyy/MM/dd HH:mm:ss')}
                    </td>
                    <td className="py-2 px-4">
                      {file.user_name} / {file.department_name}
                    </td>
                    <td className="py-2 px-4 text-right">
                      <a
                        className="inline-block w-[30px] hover:text-deleteHover"
                        data-confirm={`\u0022${file.name}\u0022を削除しますか？`}
                        rel="nofollow"
                        data-method="delete"
                        href={`/properties/${property.hashid}/detach?attached_file_id=${file.id}`}
                      >
                        <DeleteOutlined fontSize="small" />
                      </a>
                    </td>
                  </>
                ) : (
                  <>
                    <td className="py-2 px-4">{file.name}</td>
                    <td className="py-2 px-4 flex items-center">
                      <CircularProgress size={18} />
                      <span className="pl-2">ただいま処理中です。完了までお待ち下さい。</span>
                    </td>
                    <td className="py-2 px-4 text-right"></td>
                    <td className="py-2 px-4 text-right"></td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
        <form
          className="form"
          onSubmit={(e) => {
            e.preventDefault()
            if (files.length === 0) {
              return
            }

            const fileSize = files.map((file) => file.size).reduce((size, value) => size + value, 0)

            // Nginxの設定で500MBより大きいファイルは処理できない
            if (fileSize > 500 * 1000 * 1000) {
              alert('ファイルサイズは500MB以下にしてください')
              setFileNames([])
              setFiles([])
              return
            }

            setProcessing(true)

            const formData = new FormData()

            files.forEach((file) => {
              formData.append('property[attached_files][]', file)
            })
            formData.append('_method', 'patch')
            formData.append('authenticity_token', authenticity_token)

            fetch(`/properties/${property.hashid}/attach`, {
              method: 'POST',
              body: formData,
            })
              .catch((error) => {
                console.error(err)
                alert('エラーが発生しました。')
              })
              .finally(() => {
                window.location.reload()
              })

            // 処理待ちとして表示
            setAttachedFiles(
              attachedFiles.concat(
                files.map((file) => {
                  return {
                    name: file.name,
                    processing: true,
                  }
                })
              )
            )
          }}
        >
          <p className="text-sm mb-4 mt-6">
            枠内にファイルをドラッグ＆ドロップしてからアップロードボタンを押してください
            {cover_enterprise_pricing_type(user) ? (
              <>&nbsp;(添付のファイルサイズは500.0MB以内でお願いします)</>
            ) : (
              <>&nbsp;(添付のファイルサイズは10.0MB以内でお願いします)</>
            )}
          </p>

          {error_messages.length > 0 && (
            <ul className="mb-6 text-sm text-red">
              {error_messages.map((message, i) => (
                <li key={i} className="mb-2 break-all">
                  {message}
                </li>
              ))}
            </ul>
          )}

          <div
            className={[
              'md:flex bg-gray-light p-6 border-2 border-dashed cursor-pointer',
              isDragActive && 'border-primary',
            ].join(' ')}
            {...getRootProps()}
          >
            <Input
              className="mb-3 md:mb-0 md:w-[246px] md:mr-3 pointer-events-none"
              placeholder="選択されていません"
              readOnly
              value={fileNames.join(',')}
            />
            <label htmlFor="property_attached_files" className={styles.fileButton}>
              <input className="absolute w-0 h-0 opacity-0 hidden" {...getInputProps()} />
              ファイル選択
            </label>
            {fileNames.length > 1 && (
              <span className="text-sm pt-[7px] pb-[7px]">{`${fileNames.length}つのファイルが選択されています。`}</span>
            )}
          </div>
          <Button
            className="w-full md:w-40 md:h-auto mt-3"
            disabled={fileNames.length === 0 || processing}
          >
            アップロード
          </Button>
        </form>
      </div>
    </div>
  )
}

export default PropetyAttachments

const styles = {
  fileButton: `
    mb-3
    md:mb-0
    md:mr-6
    flex
    md:inline-flex
    align
    items-center
    justify-center
    gap-1.5
    rounded-sm
    leading-none
    duration-200
    transition-opacity
    hover:opacity-80
    rounded-sm
    border
    box-border
    border-primary-font
    text-primary-font
    py-[5px]
    md:py-[7px]
    px-4
    text-sm
    cursor-pointer
  `,
}

const acceptEnterprise = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': [],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'application/vnd.ms-powerpoint': [],
  'application/zip': [],
  'text/csv': [],
  'text/plain': [],
  'video/*': [],
  'audio/*': [],
}

const acceptStandard = {
  'image/jpeg': [],
  'image/png': [],
  'application/pdf': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.ms-excel': [],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'application/vnd.ms-powerpoint': [],
  'application/zip': [],
  'text/csv': [],
  'text/plain': [],
}
