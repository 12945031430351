import { DropDown } from '@/components/DropDown/Index'
import { MapHandles } from '@/components/GoogleMapViewer'
import { Input } from '@/components/Input'
import { ocrExecution } from '@/components/Ocr'
import { AddressMember } from '@/components/Ocr/formats'
import type { User } from '@/types/user'
import { Row, Td, Th } from '@/components/Table'
import * as React from 'react'

import { prefectureOptions } from '@/models/prefecture'

type Props = {
  mapRef: MapHandles
  prefectureInitial: string
  current_user: User
  cityInitial: string
  townInitial: string
  is_edit?: boolean
  name: string
  data: any
  ocrExtractedItems: ocrExecution['extractedItems']
  onChangeData: (data: any) => void
  projects_layouts: string[]
  market_datum_report_layouts: string[]
  requiredItems?: string[]
  error_messages?: string[]
}

export const Address: React.FC<Props> = ({
  mapRef,
  prefectureInitial,
  current_user,
  cityInitial,
  townInitial,
  name,
  data,
  ocrExtractedItems,
  onChangeData,
  projects_layouts,
  market_datum_report_layouts,
  requiredItems,
  is_edit,
  error_messages
}) => {
  const [prefecture, setPrefecture] = React.useState('')
  const [city, setCity] = React.useState('')
  const [town, setTown] = React.useState('')
  const [cityOptions, setCityOptions] = React.useState([])
  const [townOptions, setTownOptions] = React.useState([])
  const isRenderMapMoveFlgRef = React.useRef(true);
  const checkRequiredItem = (column_name) => {
    const required = requiredItems?.some((item) => item.column_name === column_name)

    return required ?? true
  }

  React.useEffect(() => {
    setPrefecture(prefectureInitial ?? '')

    fetchCityOptions(prefectureInitial, true, cityInitial, townInitial)

    const ocrAddress = ocrExtractedItems?.result?.住所
    const keys: AddressMember[] = ['都道府県', '市区町村', '町名', '丁目・その他']
    if (ocrAddress && keys.every((k) => k in ocrAddress)) {
      const location = keys.map((k) => ocrAddress[k]).join('')
      mapRef.current.setLocation(location)
      mapRef.current.setPing(location)
    }
  }, [prefectureInitial, cityInitial, townInitial])

  const fetchCityOptions = React.useCallback(
    (prefecture, isInit = false, cityInitial?: string, townInitial?: string) => {
      if (prefecture === '') {
        setCity('')
        setTown('')
        setCityOptions([])
        setTownOptions([])
        return
      }

      fetch('/japanese-addresses/ja.json')
        .then((response) => response.json())
        .then((json) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          const options = json[prefecture].map((cityName) => {
            return { value: cityName, label: cityName }
          })

          setCityOptions(options)
        })
        .catch(() => {
          setCityOptions([])
          setTownOptions([])
        })
        .finally(() => {
          if (prefecture !== '') {
            if (isInit && cityInitial) {
              setCity(cityInitial)
              fetchTownOptions(prefecture, cityInitial, townInitial)
            }
          }
          setTown('')
        })
    },
    [setCity, setTown, setCityOptions, setTownOptions]
  )

  const fetchTownOptions = React.useCallback(
    (prefecture: string, city: string, townInitial?: string) => {
      if (city === '') {
        setTown('')
        setTownOptions([])
        return
      }

      const uri = `/japanese-addresses/ja/${prefecture}/${city}.json`
      fetch(uri)
        .then((response) => response.json())
        .then((json) => {
          if (!json) {
            setTown('')
            setTownOptions([])
            return
          }
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return
          const options = json.map((data) => ({
            value: data.town,
            label: data.town,
          }))
          setTownOptions(options)
        })
        .catch(() => {
          setTownOptions([])
        })
        .finally(() => {
          if (prefecture !== '' && city !== '') {
            if (townInitial) {
              setTown(townInitial)
            }
          }
        })
    },
    [setTown, setTownOptions]
  )

  const onChangePrefecture = React.useCallback(
    (prefecture) => {
      setCity('')
      setTown('')
      setCityOptions([])
      setTownOptions([])
      setPrefecture(prefecture)
      fetchCityOptions(prefecture)
    },
    [setPrefecture, fetchCityOptions, setCity, setTown]
  )

  const onChangeCity = React.useCallback(
    (city) => {
      setCity(city)
      fetchTownOptions(prefecture, city)
    },
    [prefecture, setCity, fetchTownOptions]
  )

  const handlePing = () => {
    if (current_user.company.is_auto_ping && current_user.is_auto_ping) {
      if (prefecture && city && town && data?.chome) {
        const fullAddress = `${prefecture}${city}${town}${data.chome}`;
        if (mapRef?.current?.setPing) {
          mapRef.current.setPing(fullAddress);
        }
        if (mapRef?.current?.setLocation) {
          mapRef.current.setLocation(fullAddress);
        }
      }
    }
  };
  React.useEffect(() => {
    if (!is_edit) {
      if (error_messages?.length === 0 ) {
        if (prefecture && city && town) {
          handlePing();
        }
      }
    }
  }, [prefecture, city, town]);
  return (
    <>
      <Row
        label={
          <Th projects_layouts={projects_layouts} market_datum_report_layouts={market_datum_report_layouts} required>
            都道府県
          </Th>
        }
      >
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <DropDown
              className="md:w-[300px] inline-block"
              name={`${name}[prefecture]`}
              id={`${name}_prefecture`}
              placeholder="---"
              options={prefectureOptions}
              value={prefecture && { value: prefecture, label: prefecture }}
              onChange={(e) => {
                if (mapRef?.current) {
                  mapRef.current.setLocation(e ? e.label : '')
                }
                onChangePrefecture(e ? e.value : '')
              }}
            />
          </div>
        </Td>
      </Row>
      {/* 市区町村取得 */}
      <Row
        label={
          <Th projects_layouts={projects_layouts} market_datum_report_layouts={market_datum_report_layouts} required={checkRequiredItem('city')}>
            市区町村
          </Th>
        }
      >
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <DropDown
              className="md:w-[300px] inline-block"
              name={`${name}[city]`}
              id={`${name}_city`}
              placeholder="---"
              options={cityOptions}
              value={city && { value: city, label: city }}
              onChange={(e) => {
                if (mapRef?.current) {
                  mapRef.current.setLocation(prefecture + (e ? e.label : ''))
                }
                onChangeCity(e ? e.value : '')
              }}
            />
          </div>
        </Td>
      </Row>
      {/* 町名取得 */}
      <Row
        label={
          <Th projects_layouts={projects_layouts} market_datum_report_layouts={market_datum_report_layouts} required={checkRequiredItem('town')}>
            町名
          </Th>
        }
      >
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <DropDown
              className="md:w-[300px] inline-block"
              name={`${name}[town]`}
              id={`${name}_town`}
              placeholder="---"
              options={townOptions}
              value={
                townOptions.map((e) => e.label).includes(town) && {
                  label: town,
                  value: town,
                }
              }
              onChange={(e) => {
                if (mapRef?.current) {
                  mapRef.current.setLocation(prefecture + city + (e ? e.label : ''))
                }
                setTown(e ? e.label : '')
              }}
            />
          </div>
        </Td>
      </Row>
      <Row
        label={
          <Th projects_layouts={projects_layouts} market_datum_report_layouts={market_datum_report_layouts} required={checkRequiredItem('chome')}>
            丁目以降（住居表示）
          </Th>
        }
      >
        <Td>
          <div className="flex gap-4 flex-col md:flex-row">
            <Input
              className="md:w-[300px] inline-block"
              value={data?.chome || ''}
              name={`${name}[chome]`}
              id={`${name}_chome`}
              onChange={(e) =>
                onChangeData({
                  ...data,
                  chome: e.target.value,
                })
              }
              onBlur={(e) => {
                const value = formatChome(e.target.value);
                if (mapRef?.current) {
                  mapRef.current.setLocation(prefecture + city + town + value);
                }
                onChangeData({
                  ...data,
                  chome: value,
                });
                handlePing();
              }}
            />
          </div>
        </Td>
      </Row>
    </>
  )
}

const formatChome = (value: string): string => {
  const match = value.match(/[0-9\-０-９ーー―‐ー−－]/g)
  value = match === null ? '' : match.join('')
  value = value.replace(/[０-９ーー―‐ー−－]/g, function (c) {
    return c.match(/[ーー―‐ー−－]/) ? '-' : String.fromCharCode(c.charCodeAt(0) - 0xfee0)
  })
  return value
}
