import * as React from "react"
import { GoogleMap } from "@react-google-maps/api";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { AnchorButton } from "@/components/Button";
import { useFullScreen } from "@/components/Page/VolumeCheckRequests/viewFullscreen";
import { usageOptions } from "./usage"

interface Props {
  lat
  lng
  mode
  zoom
  property_id
  csrfToken
  onChangeShape
  onChangePolygon
  onChangeAreaCheck
  polygons_api_base_url
  onChangeMapCenter
  areaText
  drawingComplete
  setDrawingComplete
  propertyShape
  usageList
  setUsageList
  drawnPolygons
  usageSaveFlg
  setAddRefresh
  addRefresh
}
var markerRefresh = false
const UsageGoogleMap = React.forwardRef(({
    lat,
    lng,
    mode,
    zoom,
    property_id,
    polygons_api_base_url = '',
    onChangeMapCenter,
    areaText,
    drawingComplete,
    setDrawingComplete,
    propertyShape,
    usageList,
    setUsageList,
    drawnPolygons,
    usageSaveFlg,
    setAddRefresh,
    addRefresh,
}: Props, ref) => {
  const snapThreshold = 10
  const [maxId, setMaxId] = React.useState(0)
  const isDraggingPolygon = React.useRef(false)
  const mapRef = React.useRef(null)
  const usageInfoMarkers = React.useRef([])
  const markerList = []
  const intersection = React.useRef([])
  const nonIntersect = React.useRef([])

  const drawFlg = React.useRef(false)

  const {
    elementRef: mapDivRef,
    triggerFullScreen: triggerMapFullScreen,
    exitFullScreen: exitMapFullScreen,
    isFullScreen: isMapFullScreen,
  } = useFullScreen()

  const mapOptions = React.useMemo(() => {
    return {
      center: { lat: Number(lat), lng: Number(lng) },
      zoom: zoom,
      minZoom: 1,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: false,
      fullscreenControl: false,
      tilt: 0,
      mapTypeId: 'roadmap',
    }
  }, [lat, lng, mode])
  const drawingManager = React.useRef()
  const currentPolygon = React.useRef(null)
  React.useImperativeHandle(ref, () => ({
    removeDrawButton(index: number, id) {
      setUsageList((prev) => prev.filter((_, i) => i !== index));
      const polygonToRemove = drawnPolygons.current.find((item) => item.id === id)?.polygon;
      if (polygonToRemove) {
        polygonToRemove.setMap(null);
        drawnPolygons.current = drawnPolygons.current.filter((item) => item.id !== id);
      } else {
        drawingManager.current.setDrawingMode(null)
        if (usageList.length !== drawingManager.current.length) {
          setMaxId(maxId - 1)
        }
      }
    },
    handleAddNewPolygon() {
      setMaxId(maxId + 1)
      setUsageList(prev => [...prev, {
        id: maxId + 1,
        usage: '0',
        buildingRatio: '0',
        volumeRatio: '0',
        firePreventionArea: '0',
        altitudeArea: '4',
        maxHeight: '0',
        minHeight: '0',
        beyond5: '0',
        beyond10: '0',
        waterPlan: '0',
      }])
      handleDraw(maxId + 1, '0')
    },
    mapAreaChange(id, changeKey, changeValue) {
      const polygonToRemove = drawnPolygons.current.find((item) => item.id === id)?.polygon;
      if (polygonToRemove) {
        let drawnPolygon = []
        if (changeKey === 'usage') {
          const tmp = usageOptions.find(item => item.value === changeValue);
           drawnPolygon = drawnPolygons.current.map((item) => {
            if (item.id === id) {
              item.polygon.setOptions({fillColor: tmp.color})
            }
            return item
          })
        }
        drawnPolygons.current = drawnPolygon
      } else {
        handleDraw(id, changeValue)
      }
    },
    stopDrawing() {
      setMaxId(0)
      handleDraw(0, '0')
    },
    handleCenterChange() {
      mapRef.current.setCenter({ lat, lng })
      mapRef.current.setZoom(zoom)
      usageInfoMarkers.current.forEach((infoMarker) => {
        infoMarker.setMap(null)
      })
    },
    updateDarwnPolygonsDelete() {
      setMaxId(0)
      drawnPolygons.current.map((item) => {
        item.polygon.setMap(null)
      })
      drawnPolygons.current = []
      setUsageList([{
        id: 0,
        usage: '0',
        buildingRatio: '0',
        volumeRatio: '0',
        firePreventionArea: '0',
        altitudeArea: '4',
        maxHeight: '0',
        minHeight: '0',
        beyond5: '0',
        beyond10: '0',
        waterPlan: '0',
      }])
      handleDraw(0, '0')
      usageInfoMarkers.current.forEach((infoMarker) => {
        infoMarker.setMap(null)
      })
    },
    isAdjacent(groups) {
      function arePointsEqual(point1, point2) {
        return point1.lat === point2.lat && point1.lng === point2.lng;
      }

      function countSharedPoints(group1, group2) {
        return group1.filter(point1 =>
            group2.some(point2 => arePointsEqual(point1, point2))
        ).length;
      }

      if (groups.length === 1) {
        return true
      }
      for (let i = 0; i < groups.length; i++) {
        let sharedCount = 0;
        for (let j = 0; j < groups.length; j++) {
          if (i !== j) {
            const sharedPoints = countSharedPoints(groups[i], groups[j]);
            if (sharedPoints >= 2) {
              sharedCount++;
            }
          }
        }
        if (sharedCount === 0) {
          return false;
        }
      }

      return true;
    },
    drawInit(listDatas, polygonDatas) {
      setUsageList(listDatas);
      polygonDatas.forEach((polygonData) => {
        drawingManager.current.setDrawingMode(null);
        const paths = polygonData.lines.map((latlng) => new google.maps.LatLng(latlng.lat, latlng.lng));
        const polygon = new google.maps.Polygon({
          paths,
          fillColor: polygonData.color,
          draggable: true,
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        })
        polygon.setMap(mapRef.current)
        polygon.id = polygonData.id
        drawnPolygons.current.push({
          id: polygonData.id,
          polygon
        });
        setMaxId(polygonData.id)
        setAddRefresh(pre => pre + 1)
        drawingManager.current.setDrawingMode(null);
        google.maps.event.addListener(polygon, 'click', () => {
          drawnPolygons.current = moveItemToEnd(drawnPolygons.current, polygon.id)
          setAddRefresh(pre => pre + 1)
        })
        const path = polygon.getPath();
        let changeId = polygon.id;

        let backupPolygons = drawnPolygons.current.map((item) => {
          return {
            id: item.id,
            path: item.polygon.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })),
          };
        });
        backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
        google.maps.event.addListener(path, 'insert_at', (index) => {
          const updatedVertex = path.getAt(index);
          const otherPolygons = drawnPolygons.current.filter((item) => item.id !== changeId);

          const closestVertex = findClosestVertex(updatedVertex, otherPolygons);

          if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
            backupPolygons = drawnPolygons.current.map((item) => ({
              id: item.id,
              path: item.polygon.getPath().getArray().map((latLng) => ({
                lat: latLng.lat(),
                lng: latLng.lng(),
              })),
            }));
            backupPolygons.forEach((item) => {
              if (item.id === changeId) {
                item.path.push({
                  lat: closestVertex.vertex.lat(),
                  lng: closestVertex.vertex.lng(),
                })
              }
            })
            backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
            setTimeout(() => {
              try {
                path.setAt(index, closestVertex.vertex);
                setAddRefresh((pre) => pre + 1);
              } catch (error) {
                console.error("Error updating path in insert_at:", error);
              }
            }, 0);
          }
        });
        google.maps.event.addListener(polygon, "drag", () => {
          const currentPath = polygon.getPath();
          const currentBackupPolygons = backupPolygons.filter((item) => item.id === polygon.id)

          const otherDrawnPolygons = drawnPolygons.current.filter(item =>{
            if (item.id !== polygon.id) {
              if (currentBackupPolygons.length === 1) {
                let findFlg = false
                item.polygon.getPath().forEach((item1) => {
                  currentBackupPolygons[0].path.forEach((item2) => {
                    if (item1.lat() === item2.lat && item1.lng() === item2.lng) {
                      findFlg = true
                      return
                    }
                  })
                })
                return !findFlg
              } else {
                return true
              }
            } else {
              return false
            }
          })
          const snapCandidates = []
          let snapCandidatesTmp = []
          currentPath.forEach((value, index) => {
            const closestVertex = findClosestVertex(value, otherDrawnPolygons)
            if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
              snapCandidatesTmp.push({index, vertex: value})
              snapCandidates.push({ index, vertex: closestVertex.vertex, distance: closestVertex.distance });
            }
          })
          const lastVertex = []
          let tempVertex = []
          do {
            tempVertex = []
            getLastVertex(lastVertex, tempVertex, snapCandidates)
            if (tempVertex.length > 0) {
              const newSnapCandidates = [];
              snapCandidatesTmp = snapCandidatesTmp.filter(itemA => tempVertex.some(itemB => itemA.index === itemB.index))
              snapCandidatesTmp.forEach((item) => {
                const closestVertex = findClosestVertex(item.vertex, otherDrawnPolygons, lastVertex);
                if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
                  newSnapCandidates.push({
                    index: item.index,
                    vertex: closestVertex.vertex,
                    distance: closestVertex.distance,
                  });
                }
              });
              snapCandidates.push(...newSnapCandidates);
              if (newSnapCandidates.length === 0) {
                tempVertex = []
              }
            }
          } while (tempVertex.length > 0)
          if (lastVertex.length > 0 && lastVertex.length === intersection.current.length && lastVertex.every((item, index) => item.index === intersection.current[index].index && item.vertex.lat() === intersection.current[index].vertex.lat() && item.vertex.lng() === intersection.current[index].vertex.lng()))
          {
            path.forEach((item, index) => {
              const intersectTmp = intersection.current.find(itemB => itemB.index === index)
              const nonIntersectTmp = nonIntersect.current.find(itemC => itemC.index === index)
              if (intersectTmp) {
                path.setAt(index, intersectTmp.vertex);
              }
              if (nonIntersectTmp) {
                path.setAt(index, nonIntersectTmp.vertex)
              }
            })
          } else if (lastVertex.length > 0) {
            const intersectTmp = []
            const nonIntersectTmp = []
            path.forEach((item, index) => {
              const tmp = lastVertex.find(itemB => itemB.index === index)
              if (tmp) {
                path.setAt(index, tmp.vertex);
                intersectTmp.push({index, vertex: tmp.vertex})
              } else {
                nonIntersectTmp.push({index, vertex: item})
              }
            })
            intersection.current = intersectTmp
            nonIntersect.current = nonIntersectTmp
          }
        });
        google.maps.event.addListener(polygon, "dragend", () => {
          changeId = polygon.id
          const currentPolygons = backupPolygons.find(item => item.id === changeId)
          const intersectionIndex = []
          currentPolygons.path.forEach((item, index) => {
            intersection.current.forEach((item1) => {
              if (index === item1.index) {
                intersectionIndex.push({originalInfo: item, latestInfo: item1.vertex, index: item1.index})
              }
            })
          })
          intersection.current = []
          nonIntersect.current = []
          const otherPolygons = drawnPolygons.current.filter((item) => item.id !== changeId);
          let IntersectPolygons = []
          let indexs = -1
          if (currentPolygons) {
            findIntersectPolygons(currentPolygons,otherPolygons)
          }

          function findIntersectPolygons(polygon,otherPolygons) {
            polygon.path.forEach((polygon, index) => {
              otherPolygons.forEach((other) => {
                other.polygon.getPath().forEach((item) => {
                  if (item.lat() === polygon.lat && item.lng() === polygon.lng) {
                    if (indexs === -1) {
                      indexs = index
                    }
                    IntersectPolygons.push({id: other.id, index: indexs})
                    const newOtherPolygons = otherPolygons.filter((item) => item.id !== other.id);
                    const newPolygon = {
                      id: other.id,
                      path: other.polygon.getPath().getArray().map((latLng) => ({
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                      })),
                    }
                    findIntersectPolygons(newPolygon, newOtherPolygons)
                  }
                })
              })
            })
          }
          if (IntersectPolygons.length > 0) {
            let deltaLat = 0
            let deltaLng = 0
            IntersectPolygons = Array.from(
                new Set(IntersectPolygons.map(item => JSON.stringify(item)))
            ).map(item => JSON.parse(item));
            IntersectPolygons.forEach((item) => {
              deltaLat = polygon.getPath().getAt(item.index).lat() - currentPolygons.path[item.index].lat
              deltaLng = polygon.getPath().getAt(item.index).lng() - currentPolygons.path[item.index].lng

              const targetPolygon = otherPolygons.filter((item1) => item1.id === item.id)
              targetPolygon[0].polygon.getPath().forEach((item1, idx) =>{
                const newLatLng = new google.maps.LatLng(
                    item1.lat() + deltaLat,
                    item1.lng() + deltaLng)

                try {
                  intersectionIndex.forEach((itemA) => {
                    if (itemA.originalInfo.lat === item1.lat() && itemA.originalInfo.lng === item1.lng()) {
                      targetPolygon[0].polygon.getPath().setAt(idx, itemA.latestInfo)
                    } else {
                      targetPolygon[0].polygon.getPath().setAt(idx, newLatLng)
                    }
                  })
                  if (intersectionIndex.length === 0) {
                    targetPolygon[0].polygon.getPath().setAt(idx, newLatLng)
                  }
                } catch (error) {
                  console.error("Error updating path in insert_at:", error);
                }
              })
            })
            const tmp = drawnPolygons.current.filter(item => item.id === changeId)
            tmp[0].polygon.getPath().forEach((item, idx) => {
              const newLatLng = new google.maps.LatLng(
                  currentPolygons.path[idx].lat + deltaLat,
                  currentPolygons.path[idx].lng + deltaLng)
              intersectionIndex.forEach((itemA) => {
                if (itemA.index === idx) {
                  tmp[0].polygon.getPath().setAt(idx, itemA.latestInfo)
                } else {
                  tmp[0].polygon.getPath().setAt(idx, newLatLng)
                }
              })
              if (intersectionIndex.length === 0) {
                tmp[0].polygon.getPath().setAt(idx, newLatLng)
              }
            })
          }
          backupPolygons = drawnPolygons.current.map((item) => {
            return {
              id: item.id,
              path: item.polygon.getPath().getArray().map((latLng) => ({
                lat: latLng.lat(),
                lng: latLng.lng(),
              })),
            };
          });
          backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
          setAddRefresh(pre => pre + 1)
          isDraggingPolygon.current = false
        })
        google.maps.event.addListener(polygon, "dragstart", () => {
          isDraggingPolygon.current = true
          backupPolygons = drawnPolygons.current.map((item) => {
            return {
              id: item.id,
              path: item.polygon.getPath().getArray().map((latLng) => ({
                lat: latLng.lat(),
                lng: latLng.lng(),
              })),
            };
          });
          backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
        });
        let isUpdating = false;
        google.maps.event.addListener(path, 'set_at', (index) => {
          if (isDraggingPolygon.current || isUpdating) return
          isUpdating = true
          const updatedVertex = path.getAt(index);
          const otherPolygons = drawnPolygons.current.filter((item) => item.id !== changeId);

          const closestVertex = findClosestVertex(updatedVertex, otherPolygons);

          const currentPolygons = backupPolygons.filter(item => item.id === changeId)
          let currentPath = []
          const movePolygons = []
          if (currentPolygons.length > 0) {
            currentPath = currentPolygons[0].path[index]
            otherPolygons.forEach((item) => {
              item.polygon.getPath().forEach((item1, i) => {
                if (currentPath.lat === item1.lat() && currentPath.lng === item1.lng()) {
                  movePolygons.push({id: item.id, index: i})
                }
              })
            })
          }
          if (movePolygons.length > 0) {
            movePolygons.forEach((item) => {
              drawnPolygons.current.map((item1) => {
                if (item1.id === item.id) {
                  item1.polygon.getPath().setAt(item.index, updatedVertex)
                }
              })
            })
            backupPolygons = drawnPolygons.current.map((item) => ({
              id: item.id,
              path: item.polygon.getPath().getArray().map((latLng) => ({
                lat: latLng.lat(),
                lng: latLng.lng(),
              })),
            }));
            backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
            isUpdating = false
            setAddRefresh((pre) => pre + 1);
            return
          }

          if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
            path.setAt(index, closestVertex.vertex);
          } else {
            drawnPolygons.current = drawnPolygons.current.map((item) => {
              if (item.id === changeId) {
                item.polygon.getPath().setAt(index, updatedVertex)
              }
              return item;
            });
          }
          backupPolygons = drawnPolygons.current.map((item) => ({
            id: item.id,
            path: item.polygon.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })),
          }));
          backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
          isUpdating = false
          setAddRefresh((pre) => pre + 1);
        });
      })
    }
  }))
  function handleDraw(id, usage) {
    drawFlg.current = true
    drawingManager.current.setDrawingMode(null)
    const color = usageOptions.find(item => item.value === usage)?.color
    drawingManager.current.setOptions({
      polygonOptions: {
        fillColor: color? color: '#000000',
        draggable: true,
        fillOpacity: 0.5,
        strokeWeight: 2,
        clickable: true,
        editable: true,
        zIndex: 1,
      }
    })
    drawingManager.current.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    google.maps.event.addListenerOnce(drawingManager.current, "polygoncomplete", (polygon) => {

      if (!usageList.find(item => item.id === 0) && drawnPolygons.current.find(item => item.id === 0)) {
        drawnPolygons.current = drawnPolygons.current.filter((item) => item.id !== 0)
      }
      const path = polygon.getPath();
      const snapCandidates = []
      let snapCandidatesTmp = []
      path.forEach((value, index) => {
        const closestVertex = findClosestVertex(value, drawnPolygons.current)
        if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
          snapCandidatesTmp.push({index, vertex: value})
          snapCandidates.push({ index, vertex: closestVertex.vertex, distance: closestVertex.distance });
        }
      })
      const lastVertex = []
      let tempVertex = []
      do {
        tempVertex = []
        getLastVertex(lastVertex, tempVertex, snapCandidates)
        if (tempVertex.length > 0) {
          const newSnapCandidates = [];
          snapCandidatesTmp = snapCandidatesTmp.filter(itemA => tempVertex.some(itemB => itemA.index === itemB.index))
          snapCandidatesTmp.forEach((item) => {
            const closestVertex = findClosestVertex(item.vertex, drawnPolygons.current, lastVertex);
            if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
              newSnapCandidates.push({
                index: item.index,
                vertex: closestVertex.vertex,
                distance: closestVertex.distance,
              });
            }
          });
          snapCandidates.push(...newSnapCandidates);
          if (newSnapCandidates.length === 0) {
            tempVertex = []
          }
        }
      } while (tempVertex.length > 0)
      if (lastVertex.length > 0) {
        lastVertex.forEach((item) => {
          path.setAt(item.index, item.vertex);
        })
      }

      const tmp = [...drawnPolygons.current]
      if (!drawnPolygons.current.find(item => item.id === id)) {
        polygon.id = id
        tmp.push({polygon, id: id})
      }
      const addFlg = tmp.length === 1 && tmp.find(item => item.id !== 0) && usageList.find(item => item.id === 0)
      if (!addFlg) {
        drawnPolygons.current = tmp
        setAddRefresh(pre => pre + 1)
      }
      drawingManager.current.setDrawingMode(null);
      drawFlg.current = true
      google.maps.event.addListener(polygon, 'click', () => {
        drawnPolygons.current = moveItemToEnd(drawnPolygons.current, polygon.id)
        setAddRefresh(pre => pre + 1)
      })
      let changeId = polygon.id;

      let backupPolygons = drawnPolygons.current.map((item) => {
        return {
          id: item.id,
          path: item.polygon.getPath().getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          })),
        };
      });
      backupPolygons = JSON.parse(JSON.stringify(backupPolygons))

      google.maps.event.addListener(path, 'insert_at', (index) => {
        const updatedVertex = path.getAt(index);
        const otherPolygons = drawnPolygons.current.filter((item) => item.id !== changeId);

        const closestVertex = findClosestVertex(updatedVertex, otherPolygons);

        if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
          backupPolygons = drawnPolygons.current.map((item) => ({
            id: item.id,
            path: item.polygon.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })),
          }));
          backupPolygons.forEach((item) => {
            if (item.id === changeId) {
              item.path.push({
                lat: closestVertex.vertex.lat(),
                lng: closestVertex.vertex.lng(),
              })
            }
          })
          backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
          setTimeout(() => {
            try {
              path.setAt(index, closestVertex.vertex);
              setAddRefresh((pre) => pre + 1);
            } catch (error) {
              console.error("Error updating path in insert_at:", error);
            }
          }, 0);
        }
      });
      google.maps.event.addListener(polygon, "drag", () => {
        const currentPath = polygon.getPath();
        const currentBackupPolygons = backupPolygons.filter((item) => item.id === polygon.id)

        const otherDrawnPolygons = drawnPolygons.current.filter(item =>{
          if (item.id !== polygon.id) {
            if (currentBackupPolygons.length === 1) {
              let findFlg = false
              item.polygon.getPath().forEach((item1) => {
                currentBackupPolygons[0].path.forEach((item2) => {
                  if (item1.lat() === item2.lat && item1.lng() === item2.lng) {
                    findFlg = true
                    return
                  }
                })
              })
              return !findFlg
            } else {
              return true
            }
          } else {
            return false
          }
        })
        const snapCandidates = []
        let snapCandidatesTmp = []
        currentPath.forEach((value, index) => {
          const closestVertex = findClosestVertex(value, otherDrawnPolygons)
          if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
            snapCandidatesTmp.push({index, vertex: value})
            snapCandidates.push({ index, vertex: closestVertex.vertex, distance: closestVertex.distance });
          }
        })
        const lastVertex = []
        let tempVertex = []
        do {
          tempVertex = []
          getLastVertex(lastVertex, tempVertex, snapCandidates)
          if (tempVertex.length > 0) {
            const newSnapCandidates = [];
            snapCandidatesTmp = snapCandidatesTmp.filter(itemA => tempVertex.some(itemB => itemA.index === itemB.index))
            snapCandidatesTmp.forEach((item) => {
              const closestVertex = findClosestVertex(item.vertex, otherDrawnPolygons, lastVertex);
              if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
                newSnapCandidates.push({
                  index: item.index,
                  vertex: closestVertex.vertex,
                  distance: closestVertex.distance,
                });
              }
            });
            snapCandidates.push(...newSnapCandidates);
            if (newSnapCandidates.length === 0) {
              tempVertex = []
            }
          }
        } while (tempVertex.length > 0)
        if (lastVertex.length > 0 && lastVertex.length === intersection.current.length && lastVertex.every((item, index) => item.index === intersection.current[index].index && item.vertex.lat() === intersection.current[index].vertex.lat() && item.vertex.lng() === intersection.current[index].vertex.lng()))
        {
          path.forEach((item, index) => {
            const intersectTmp = intersection.current.find(itemB => itemB.index === index)
            const nonIntersectTmp = nonIntersect.current.find(itemC => itemC.index === index)
            if (intersectTmp) {
              path.setAt(index, intersectTmp.vertex);
            }
            if (nonIntersectTmp) {
              path.setAt(index, nonIntersectTmp.vertex)
            }
          })
        } else if (lastVertex.length > 0) {
          const intersectTmp = []
          const nonIntersectTmp = []
          path.forEach((item, index) => {
            const tmp = lastVertex.find(itemB => itemB.index === index)
            if (tmp) {
              path.setAt(index, tmp.vertex);
              intersectTmp.push({index, vertex: tmp.vertex})
            } else {
              nonIntersectTmp.push({index, vertex: item})
            }
          })
          intersection.current = intersectTmp
          nonIntersect.current = nonIntersectTmp
        }
      });

      google.maps.event.addListener(polygon, "dragend", () => {
        changeId = polygon.id
        const currentPolygons = backupPolygons.find(item => item.id === changeId)
        const intersectionIndex = []
        currentPolygons.path.forEach((item, index) => {
          intersection.current.forEach((item1) => {
            if (index === item1.index) {
              intersectionIndex.push({originalInfo: item, latestInfo: item1.vertex, index: item1.index})
            }
          })
        })
        intersection.current = []
        nonIntersect.current = []
        const otherPolygons = drawnPolygons.current.filter((item) => item.id !== changeId);
        let IntersectPolygons = []
        let indexs = -1
        if (currentPolygons) {
          findIntersectPolygons(currentPolygons,otherPolygons)
        }

        function findIntersectPolygons(polygon,otherPolygons) {
          polygon.path.forEach((polygon, index) => {
            otherPolygons.forEach((other) => {
              other.polygon.getPath().forEach((item) => {
                if (item.lat() === polygon.lat && item.lng() === polygon.lng) {
                  if (indexs === -1) {
                    indexs = index
                  }
                  IntersectPolygons.push({id: other.id, index: indexs})
                  const newOtherPolygons = otherPolygons.filter((item) => item.id !== other.id);
                  const newPolygon = {
                    id: other.id,
                    path: other.polygon.getPath().getArray().map((latLng) => ({
                      lat: latLng.lat(),
                      lng: latLng.lng(),
                    })),
                  }
                  findIntersectPolygons(newPolygon, newOtherPolygons)
                }
              })
            })
          })
        }
        if (IntersectPolygons.length > 0) {
          let deltaLat = 0
          let deltaLng = 0
          IntersectPolygons = Array.from(
              new Set(IntersectPolygons.map(item => JSON.stringify(item)))
          ).map(item => JSON.parse(item));
          IntersectPolygons.forEach((item) => {
            deltaLat = polygon.getPath().getAt(item.index).lat() - currentPolygons.path[item.index].lat
            deltaLng = polygon.getPath().getAt(item.index).lng() - currentPolygons.path[item.index].lng

            const targetPolygon = otherPolygons.filter((item1) => item1.id === item.id)
            targetPolygon[0].polygon.getPath().forEach((item1, idx) =>{
              const newLatLng = new google.maps.LatLng(
                  item1.lat() + deltaLat,
                  item1.lng() + deltaLng)

              try {
                intersectionIndex.forEach((itemA) => {
                  if (itemA.originalInfo.lat === item1.lat() && itemA.originalInfo.lng === item1.lng()) {
                    targetPolygon[0].polygon.getPath().setAt(idx, itemA.latestInfo)
                  } else {
                    targetPolygon[0].polygon.getPath().setAt(idx, newLatLng)
                  }
                })
                if (intersectionIndex.length === 0) {
                  targetPolygon[0].polygon.getPath().setAt(idx, newLatLng)
                }
              } catch (error) {
                console.error("Error updating path in insert_at:", error);
              }
            })
          })
          const tmp = drawnPolygons.current.filter(item => item.id === changeId)
          tmp[0].polygon.getPath().forEach((item, idx) => {
            const newLatLng = new google.maps.LatLng(
                currentPolygons.path[idx].lat + deltaLat,
                currentPolygons.path[idx].lng + deltaLng)
            intersectionIndex.forEach((itemA) => {
              if (itemA.index === idx) {
                tmp[0].polygon.getPath().setAt(idx, itemA.latestInfo)
              } else {
                tmp[0].polygon.getPath().setAt(idx, newLatLng)
              }
            })
            if (intersectionIndex.length === 0) {
              tmp[0].polygon.getPath().setAt(idx, newLatLng)
            }
          })
        }
        backupPolygons = drawnPolygons.current.map((item) => {
          return {
            id: item.id,
            path: item.polygon.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })),
          };
        });
        backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
        setAddRefresh(pre => pre + 1)
        isDraggingPolygon.current = false
      })
      google.maps.event.addListener(polygon, "dragstart", () => {
        isDraggingPolygon.current = true
        backupPolygons = drawnPolygons.current.map((item) => {
          return {
            id: item.id,
            path: item.polygon.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })),
          };
        });
        backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
      });
      let isUpdating = false;
      google.maps.event.addListener(path, 'set_at', (index) => {
        if (isDraggingPolygon.current || isUpdating) return
        isUpdating = true
        const updatedVertex = path.getAt(index);
        const otherPolygons = drawnPolygons.current.filter((item) => item.id !== changeId);

        const closestVertex = findClosestVertex(updatedVertex, otherPolygons);

        const currentPolygons = backupPolygons.filter(item => item.id === changeId)
        let currentPath = []
        const movePolygons = []
        if (currentPolygons.length > 0) {
          currentPath = currentPolygons[0].path[index]
          otherPolygons.forEach((item) => {
            item.polygon.getPath().forEach((item1, i) => {
              if (currentPath.lat === item1.lat() && currentPath.lng === item1.lng()) {
                movePolygons.push({id: item.id, index: i})
              }
            })
          })
        }
        if (movePolygons.length > 0) {
          movePolygons.forEach((item) => {
            drawnPolygons.current.map((item1) => {
              if (item1.id === item.id) {
                item1.polygon.getPath().setAt(item.index, updatedVertex)
              }
            })
          })
          backupPolygons = drawnPolygons.current.map((item) => ({
            id: item.id,
            path: item.polygon.getPath().getArray().map((latLng) => ({
              lat: latLng.lat(),
              lng: latLng.lng(),
            })),
          }));
          backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
          isUpdating = false
          setAddRefresh((pre) => pre + 1);
          return
        }

        if (closestVertex && closestVertex.distance !== 0 && closestVertex.distance < snapThreshold) {
          path.setAt(index, closestVertex.vertex);
        } else {
          drawnPolygons.current = drawnPolygons.current.map((item) => {
            if (item.id === changeId) {
              item.polygon.getPath().setAt(index, updatedVertex)
            }
            return item;
          });
        }
        backupPolygons = drawnPolygons.current.map((item) => ({
          id: item.id,
          path: item.polygon.getPath().getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
          })),
        }));
        backupPolygons = JSON.parse(JSON.stringify(backupPolygons))
        isUpdating = false
        setAddRefresh((pre) => pre + 1);
      });
    });
  }

  function findClosestVertex(targetVertex, polygons, lastVertex = []) {
    let closestVertex = null;
    let minDistance = Infinity;

    polygons.forEach((item) => {
      const path = item.polygon.getPath();
      path.forEach((vertex) => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(targetVertex, vertex);
        if (distance < minDistance) {
          if (lastVertex.length > 0) {
            if (lastVertex.find(item => item.vertex.lat() === vertex.lat() && item.vertex.lng() === vertex.lng())) {
              return
            }
            minDistance = distance;
            closestVertex = vertex;
          } else {
            minDistance = distance;
            closestVertex = vertex;
          }
        }
      });
    });

    return closestVertex ? { vertex: closestVertex, distance: minDistance } : null;
  }

  const onLoad = (map) => {
    mapRef.current = map

    const styledMapType = new google.maps.StyledMapType(
        [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit.line', // 交通機関の路線
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'transit.station.rail', // 電車の駅
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
        ],
        { name: 'Styled Map' }
    )
    map.mapTypes.set('styled_map', styledMapType)
    // let drawingManager

    map.setMapTypeId('styled_map')

    function temporaryBoundsListener() {
      const listener = google.maps.event.addListener(map, 'bounds_changed', () => {
        // makeUsageInfoMarks();
        google.maps.event.removeListener(listener);
      });
    }
    google.maps.event.addListenerOnce(map, 'tilesloaded', () => {
      temporaryBoundsListener()
    });

    function addMarker(
        property: {
          id: number
          lat: number
          lng: number
          property_type?: string
        },
        map,
        tag_id: number = undefined,
        tag_color: number = undefined
    ) {
      const marker_params = {
        position: new google.maps.LatLng(Number(property.lat), Number(property.lng)),
        map: map,
        optimized: false,
      }
      if (mode == 'volume') {
        marker_params['draggable'] = true
      }
      const marker = new google.maps.Marker(marker_params)

      if (mode === 'volume') {
        let icon
        if (property_id == property.id) {
          icon = {
            url: '/target_ping.png',
            scaledSize: new google.maps.Size(37, 30),
          }
          marker.setOptions({ zIndex: 99999 })
        } else {
          marker.setVisible(false)
        }
        marker.setIcon(icon)
      }
      marker.setDraggable(false)
      return marker
    }

    if (mode === 'volume') {
      drawingManager.current = new google.maps.drawing.DrawingManager({
        drawingMode: null, // PAN
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [google.maps.drawing.OverlayType.POLYGON],
        },
        //ポリゴンのオプション
        polygonOptions: {
          draggable: true,
          fillColor: '#55ee55',
          fillOpacity: 0.5,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
      })
      drawingManager.current.setMap(map)

      google.maps.event.addListener(drawingManager.current, 'polygoncomplete', function (polygon) {
        if (mode == 'volume' && !drawFlg.current) {
          const shape = []
          polygon.getPath().forEach((latlng) => {
            shape.push({ lat: latlng.lat(), lng: latlng.lng() })
          })
          google.maps.event.addListener(polygon, 'mouseup', () => {
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
          })
          if (!(currentPolygon.current === null)) {
            currentPolygon.current.setMap()
          }
          currentPolygon.current = polygon
          drawingManager.current.setDrawingMode(null)
        }
      })
    }

    areaInit()
    let params: any = {}

    google.maps.event.addListener(map, 'idle', () => {
      map.setCenter(map.getCenter())
      const latlngBounds = map.getBounds()
      const swLatlng = latlngBounds.getSouthWest()
      const neLatlng = latlngBounds.getNorthEast()
      if (
          params &&
          params.lat1 == swLatlng.lat() &&
          params.lng1 == swLatlng.lng() &&
          params.lat2 == neLatlng.lat() &&
          params.lng2 == neLatlng.lng()
      ) {
        if (!markerRefresh) return
      }
      params = {
        lat1: swLatlng.lat(),
        lng1: swLatlng.lng(),
        lat2: neLatlng.lat(),
        lng2: neLatlng.lng(),
        with_shape: map.getZoom() >= 17,
      }
      if (mode === 'volume') {
        if (markerList.length > 0) {
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ;[...markerList].forEach((marker) => {
            marker.setMap()
          })
          markerList.length = 0
        }
        const query = new URLSearchParams(params)
        fetch(`/api/properties?${query}`)
            .then((res) => res.json())
            .then((json) => {
              json.res.forEach((marker) => {
                if (marker.lat < 0 || marker.lng < 0) return
                // マーカーを追加
                markerList.push(addMarker(marker, map, marker.tag_id, marker.tag_color))
              })
            })
            .catch((err) => {
              console.log(err)
            })
        markerRefresh = false
      }
      const center = map.getCenter()
      onChangeMapCenter({lat: center.lat(), lng: center.lng()})
      if (drawnPolygons.current.length > 0) {
        makeUsageInfoMarks()
      }
    })
    fullscreenHandler()
  }

  function moveItemToEnd(array, id) {
    const index = array.findIndex(item => item.id === id);
    if (index > -1) {
      const [item] = array.splice(index, 1);
      array.push(item);
    }
    return array;
  }

  React.useEffect(() => {
    if (drawingComplete) {
      if (currentPolygon.current !== null) {
        currentPolygon.current.setMap()
      }
      changeArea()
      setDrawingComplete(false)
    }
  }, [drawingComplete])


  function areaInit() {
    if (mode === 'volume') {
      let fetchURL = `/properties/${property_id}/shape`
      if (mode == 'volume') {
        let currentSearchParams = new URLSearchParams(window.location.search)
        if (currentSearchParams.has('ref')) {
          let fetchParams = new URLSearchParams({ ref: currentSearchParams.get('ref') })
          fetchURL += `?${fetchParams.toString()}`
        }
      }
      fetch(fetchURL)
          .then((res) => res.json())
          .then((json) => {
            if (json.status == 'NG') {
              handleDraw(0, '0')
              return
            }

            const polygon = new google.maps.Polygon({
              paths: json.shape.map((latlng) => {
                return new google.maps.LatLng(latlng.lat, latlng.lng)
              }),
              draggable: false,
              fillColor: '#55ee55',
              fillOpacity: 0.5,
              strokeWeight: 2,
              clickable: mode === 'volume',
              editable: false,
              zIndex: 1,
            })
            polygon.setMap(mapRef.current)
            handleDraw(0, '0')
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            if (mode == 'volume') {
              google.maps.event.addListener(polygon, 'mouseup', () => {
                const shape = []
                polygon.getPath().forEach((latlng) => {
                  shape.push({ lat: latlng.lat(), lng: latlng.lng() })
                })
              })
            }
            currentPolygon.current = polygon
          })
          .catch((err) => {
            console.log(err)
          })
    }
  }

  function changeArea() {
    drawingManager.current.setDrawingMode(null);
    const polygon = new google.maps.Polygon({
      paths: JSON.parse(propertyShape).map((latlng) => {
        return new google.maps.LatLng(latlng.lat, latlng.lng)
      }),
      draggable: false,
      fillColor: '#55ee55',
      fillOpacity: 0.5,
      strokeWeight: 2,
      clickable: mode === 'volume',
      editable: false,
      zIndex: 1,
    })
    polygon.setMap(mapRef.current)
    currentPolygon.current = polygon
    const tmp = usageOptions.find(item => item.value === usageList[usageList.length - 1].usage)
    const usage = tmp ? tmp.value: '0'
    handleDraw(usageList[usageList.length - 1].id, usage)
  }

  function displayLayerMarker(
      zoomLevel,
      markerSize,
      fontSize,
      contentFunc,
      markerArray,
      data,
      center,
      iconURL,
      zIndex,
  ) {
    if (mapRef.current.getZoom() >= zoomLevel) {
      const contentString = contentFunc(data)
      if (typeof contentString === 'undefined') {
        return
      }
      let marker = new google.maps.Marker({
        position: center,
        map: mapRef.current,
        icon: {
          url: iconURL,
          scaledSize: new google.maps.Size(markerSize[0], markerSize[1]),
        },
        label: {
          text: contentString,
          color: '#125690',
          fontSize: fontSize,
          fontWeight: 'bold',
        },
        clickable: false,
        zIndex: zIndex,
      })
      markerArray.push(marker)
    }
  }

  function fullscreenHandler() {
    const elementToSendFullscreen = mapDivRef.current
    document.onwebkitfullscreenchange =
        document.onmsfullscreenchange =
            document.onmozfullscreenchange =
                document.onfullscreenchange =
                    function () {
                      if (isFullscreen(elementToSendFullscreen)) {
                        document.getElementById('layerButton').style.display = 'block'
                      } else {
                        document.getElementById('layerButton').style.display = 'none'
                        document.querySelectorAll('.polygons-button2').forEach((elem) => {
                          ;(elem as HTMLElement).style.display = 'none'
                        })
                      }
                    }
  }
  function isFullscreen(element: HTMLElement) {
    return (
        (document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement) == element
    )
  }

  const containerStyle = React.useMemo(() => {
    return (
        {
          width: '100%',
          height: isMapFullScreen ? '100%' : '450px',
        }
    )
  }, [isMapFullScreen])

  function usageInfoMarker(data, center, zIndex = 15) {
    displayLayerMarker(
        17,
        [130 / 17 * mapRef.current.getZoom(), 60 / 17 * mapRef.current.getZoom()],
        12 / 17 * mapRef.current.getZoom() + 'px',
        usageLabelContent,
        usageInfoMarkers.current,
        data,
        center,
        '/info_label.png',
        zIndex
    )
  }

  function usageLabelContent(data: any) {
    const acronym = {
      '1': '1低',
      '2': '2低',
      '3': '1中',
      '4': '2中',
      '5': '1住',
      '6': '2住',
      '7': '準住',
      '8': '近商',
      '9': '商業',
      '10': '準工',
      '11': '工業',
      '12': '工専',
      '0': '-',
    }
    const buildingRatio = data.buildingRatio === '0' ? '-' : data.buildingRatio
    const volumeRatio = data.volumeRatio === '0' ? '-' : data.volumeRatio
    let content = `${acronym[data.usage]}:${buildingRatio}:${volumeRatio}`
    return content
  }

  function makeUsageInfoMarks() {
    usageInfoMarkers.current.forEach((infoMarker) => {
      infoMarker.setMap(null)
    })
    usageInfoMarkers.current = []
    let zIndex = 15
    drawnPolygons.current.map((item, i) => {
      zIndex++
      let minLatLng = mapRef.current.getBounds().getSouthWest()
      let maxLatLng = mapRef.current.getBounds().getNorthEast()
      let pad_lat_range = (Number(maxLatLng.lat()) - Number(minLatLng.lat())) * 0.1
      let pad_lng_range = (Number(maxLatLng.lng()) - Number(minLatLng.lng())) * 0.1
      const targetPoints = []
      item.polygon.getPath().forEach((point) => {
        if (
            Number(point.lat()) > Number(minLatLng.lat()) &&
            Number(point.lat()) < Number(maxLatLng.lat()) &&
            Number(point.lng()) > Number(minLatLng.lng()) &&
            Number(point.lng()) < Number(maxLatLng.lng())
        ) {
          targetPoints.push(point)
        }
      })
      if (targetPoints.length > 0) {
        const bounds = new google.maps.LatLngBounds()
        item.polygon.getPath().forEach((path) => {
          bounds.extend(path)
        })
        let center = bounds.getCenter()
        if (center.lat() < minLatLng.lat()) {
          center = new google.maps.LatLng(minLatLng.lat() + pad_lat_range, center.lng())
        }
        if (center.lat() > maxLatLng.lat()) {
          center = new google.maps.LatLng(
              maxLatLng.lat() - pad_lat_range * 2,
              center.lng()
          )
        }
        if (center.lng() < minLatLng.lng()) {
          center = new google.maps.LatLng(center.lat(), minLatLng.lng() + pad_lng_range)
        }
        if (center.lng() > maxLatLng.lng()) {
          center = new google.maps.LatLng(
              center.lat(),
              maxLatLng.lng() - pad_lng_range * 2
          )
        }
        const usageCurrent = usageList.filter(data => data.id === item.id)
        if (google.maps.geometry.poly.containsLocation(center, item.polygon) && usageCurrent.length > 0) {
          usageInfoMarker(usageCurrent[0], center, zIndex)
        } else {
          pad_lat_range = pad_lat_range / 2
          pad_lng_range = pad_lng_range / 2
          let minLat = minLatLng.lat() + pad_lat_range
          let minLng = minLatLng.lng() + pad_lng_range
          lng = Number(minLng)
          lat = Number(minLat)
          while (!google.maps.geometry.poly.containsLocation(center, item.polygon)) {
            if (center.lng() > mapRef.current.getBounds().getNorthEast().lng()) {
              lat = lat + Number(pad_lat_range)
              lng =
                  mapRef.current.getBounds().getSouthWest().lng() + Number(pad_lng_range)
            }
            center = new google.maps.LatLng(Number(lat), Number(lng))
            if (
                center.lat() > mapRef.current.getBounds().getNorthEast().lat() &&
                center.lng() > mapRef.current.getBounds().getNorthEast().lng()
            ) {
              break
            }
            lng = Number(lng) + Number(pad_lng_range)
          }
          if (usageCurrent.length > 0) {
            usageInfoMarker(usageCurrent[0], center, zIndex)
          }
        }
      }
    })
  }

  function getLastVertex(lastVertex, tempVertex, snapCandidates) {
    const vertexMap = new Map();

    snapCandidates.forEach(({ index, vertex, distance }) => {
      const vertexKey = `${vertex.lat()},${vertex.lng()}`;
      if (!vertexMap.has(vertexKey) || vertexMap.get(vertexKey).distance > distance) {
        if (vertexMap.has(vertexKey)) {
          tempVertex.push(vertexMap.get(vertexKey));
        }
        vertexMap.set(vertexKey, { index, vertex, distance });
      } else {
        tempVertex.push({ index, vertex, distance });
      }
    });

    lastVertex.push(...vertexMap.values());
  }

  const usageListDependencies = usageList.map(item => `${item.id}-${item.usage}-${item.volumeRatio}-${item.buildingRatio}`).join(',');
  React.useEffect(() => {
    makeUsageInfoMarks()
  },[
      usageListDependencies,
      addRefresh,
      mapRef.current?.getZoom(),
      mapRef.current?.getCenter()
  ])


  return (
      <>
        <div style={{position: 'relative', height: '100%'}} ref={mapDivRef}>
          <GoogleMap mapContainerStyle={containerStyle} options={mapOptions} onLoad={onLoad}/>
          <AnchorButton
              className="absolute right-2 top-2 w-8 h-8 z-10"
              onClick={isMapFullScreen ? exitMapFullScreen : triggerMapFullScreen}
          >
            {isMapFullScreen ? (
                <FullscreenExitIcon fontSize="small" />
            ) : (
                <FullscreenIcon fontSize="small" />
            )}
          </AnchorButton>
          {usageSaveFlg.current && (
              <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    zIndex: 20,
                  }}
              >
              </div>
          )}
        </div>
      </>
  );
});

export default UsageGoogleMap;