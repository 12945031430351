import { MarketDatum } from '@/types/marketDatum'
import { formatColumn } from '@/utils/marketDatumFormatColumn'
import OpenInNew from '@mui/icons-material/OpenInNew';
import { twoColumns ,dummyMarketNum} from '@/models/baloonSetting'
import * as React from 'react'
import * as ReactDOMServer from 'react-dom/server'

export function Balloon(marketData: MarketDatum, settingColumns: any, isDisplaySetting?: boolean) {
  const forSale = (settingColumns?.type || 'for_sale') == 'for_sale'
  const columnsArray = settingColumns?.columns
  const marketNumber = marketData?.selectNo || marketData?.no || dummyMarketNum
  const tabIndex = forSale ? 0 : 1
  const getPropertyParameter = window.location.href.match(/\/properties\/([^\/?,]+)/)
  const propertyHashId = getPropertyParameter ? getPropertyParameter[1] : null
  const detailPath = (marketDataHashId, propertyHashId, tabIndex) => {
    return `/market_data/${marketDataHashId}/?property_id=${propertyHashId}&tab_index=${tabIndex}`
  }

  // formatColumnを順番に取得し、それを表示
  const formattedColumns = columnsArray.map((col) => formatColumn(marketData, col , isDisplaySetting));
  const jsx = (
    <div className="flex justify-center">
      <div
        className="inline-block bg-white border-2 rounded min-w-42 gap-0"
        style={{ borderColor: marketData?.status === 'open' ? '#3f51b5' : '#ff9800' }}
        id={`balloon-item-${marketData?.no}`}
      >
        <p className="m-1 p-0 text-sm inline font-bold">
        {`${marketNumber} ${marketData?.status_text}`}
          {!isDisplaySetting && (
          <>
            <a
              id="market_datum_report_detail_transition"
              className="float-right"
              href={`${detailPath(marketData?.hashid, propertyHashId, tabIndex)}`}
              target="_blank"
            >
              <OpenInNew style={{ width: '17px', height: '18px' }} />
            </a>
            </>
          )}
        </p>
        <p style={{ background: 'gray', padding: 0, margin: 0, width: '', height: '0.1px' }} />
      <>
      {twoColumns.includes(columnsArray[0]) ? (
        <div className="flex flex-col">
          <div className="mx-1 flex flex-nowrap items-center gap-2">
            <span className="font-bold text-sm whitespace-nowrap">
              {formattedColumns[0]}
            </span>
          </div>
          <div className="mx-1 flex flex-nowrap items-center gap-2">
            <span className="font-bold text-sm whitespace-nowrap">
            {formattedColumns[1]} {formattedColumns[2]} {formattedColumns[3]}
            </span>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="mx-1 flex flex-nowrap items-center gap-2">
            <span className="font-bold text-sm whitespace-nowrap">
              {formattedColumns[0]} {formattedColumns[1]}
            </span>
          </div>
          <div className="mx-1 flex flex-nowrap items-center gap-2">
            <span className="font-bold text-sm whitespace-nowrap">
            {formattedColumns[2]} {formattedColumns[3]} {formattedColumns[4]}
            </span>
          </div>
        </div>
      )}
    </>

      </div>
    </div>
  )
  return jsxToHtml(jsx)
}

function jsxToHtml(jsx: React.ReactElement): string {
  return ReactDOMServer.renderToStaticMarkup(jsx)
}
