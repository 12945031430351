// chartSizeConstants.ts
export const CHART_SIZE = {
  MIN_HEIGHT_PER_LABEL: 30,
  LABEL_THRESHOLD: 20,
  MIN_TOTAL_HEIGHT: 400,
  PARENT_HEIGHT_RATIO: 0.95,
  CHAR_WIDTH_MULTIPLIER: 15,

  BAR_MIN_WIDTH_PER_LABEL: 30,
  BAR_MIN_TOTAL_WIDTH: 600,
  BAR_MIN_HEIGHT: 400,
  BAR_LABEL_THRESHOLD: 20,

  DEFAULT_HEADER_HEIGHT: 220,
  MIN_WIDTH: 500,
}

export const CHART_CONTAINER_SELECTOR = '.h-\\[calc\\(100vh_-_220px\\)\\]'

export const CHART_ID = {
  USERS_TOTALLING: 'usersTotallingChart',
  DEPARTMENTS_MONTHLY: 'departmentsMonthlyChart',
  MONTHLY: 'monthlyChart',
  PURCHACE_APPLIED_CONTRACT: 'purchaceAppliedContractChart',
}

export enum ChartType {
  HORIZONTAL_BAR,
  BAR,
}
