import * as React from 'react'
import { Row, Th, Td } from '@/components/Table'
import { Input } from '@/components/Input'
import { Select } from '@/components/Select'
import { ParcelUseOptions, ParcelRightsOptions } from '@/models/parcel_record'
import { ParcelRecord } from '@/types/property'

type ParcelRecordRowProps = {
  index: number
  parcel_record: object
  data: any
  setData: (data: object) => void
  defaultParcelRecordData: object
  requestParamName: string
  projectsLayouts?: string[]
}

export const ParcelRecordRow: React.FC<ParcelRecordRowProps> = (props) => {
  const {
    index,
    parcel_record,
    data,
    setData,
    defaultParcelRecordData,
    requestParamName,
    projectsLayouts,
  } = props

  React.useEffect(() => {
    if (!data.chiban || data.chiban.trim() === '') {
      const resetParcelRecords = data.parcel_records.map((record) => ({
        ...record,
        parcel_number: '',
      }))

      setData({
        ...data,
        parcel_records: resetParcelRecords,
      })
      return
    }

    const chibanData: string[] = data.chiban.split(',').map((item: string) => item.trim()) || []

    const updatedParcelRecords = chibanData.map((chiban, i) => ({
      ...(data.parcel_records[i] ?? defaultParcelRecordData),
      parcel_number: chiban,
    }))

    setData({
      ...data,
      parcel_records: updatedParcelRecords.slice(0, 4),
    })
  }, [data.chiban])

  const onChangeData = (value, index, column) => {
    const parcel_records = [...data.parcel_records]
    parcel_records.splice(index, 1, {
      ...parcel_records[index],
      [column]: value,
    })
    setData({ ...data, parcel_records })
  }

  return (
    <Row
      label={
        projectsLayouts ? (
          <Th projects_layouts={projectsLayouts}>地目・権利{index > 0 && ` ${index + 1}`}</Th>
        ) : (
          <Th>地目・権利{index > 0 && ` ${index + 1}`}</Th>
        )
      }
    >
      <Td>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Input
            multiple
            className="relative md:w-[300px] flex-shrink-0"
            value={parcel_record.parcel_number || ''}
            name={`${requestParamName}[parcel_number][]`}
            id={`${requestParamName}_parcel_number`}
            prefix="地番"
            readOnly
            onChange={(e) => onChangeData(e.target.value, index, 'parcel_number')}
          />
        </div>
        <div className="py-1 flex gap-4 flex-col md:flex-row">
          <Select
            className="md:w-[300px]"
            value={parcel_record.parcel_use || ''}
            name={`${requestParamName}[parcel_use][]`}
            id={`${requestParamName}_parcel_use`}
            options={ParcelUseOptions}
            hasBlank
            prefix="地目"
            blankLabel="(未設定)"
            onChange={(e) => onChangeData(e.target.value, index, 'parcel_use')}
          />
          <Select
            className="md:w-[300px]"
            value={parcel_record.parcel_rights || ''}
            name={`${requestParamName}[parcel_rights][]`}
            id={`${requestParamName}_parcel_rights`}
            options={ParcelRightsOptions}
            hasBlank
            prefix="権利"
            blankLabel="(未設定)"
            onChange={(e) => onChangeData(e.target.value, index, 'parcel_rights')}
          />
        </div>
      </Td>
    </Row>
  )
}
