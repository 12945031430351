import { BackSide, Vector2 } from 'three';

type Vector = [number, number];
type XY = {
  x: number;
  y: number;
};

type Segment = {
  a: Vector;
  b: Vector;
};

type Polyline = {
  points: Vector[];
};

type ConstructableArea = {
  area: number;
  center: Vector;
  shapes: {
    polyline: Polyline;
    inner: Polyline;
    outer: Polyline;
  }[];
};

type BoundingBox = {
  min: Vector;
  max: Vector;
  size: Vector;
  x_axis: Vector;
  y_axis: Vector;
};

export type DrawingFloor = {
  constructable_area: ConstructableArea;
  bounding_box: BoundingBox;
  height: number;
};

export type DrawingBorderLine =
  | {
      Neighbor: Segment;
    }
  | {
      Road: {
        inner_segment: Segment;
        center_segment: Segment;
        outer_segment: Segment;
        set_back_inner_segment: Segment | null;
        set_back_outer_segment: Segment | null;
        set_back_normal: Vector;
        road_width: number;
        set_back_road_width: number | null;
        set_back_inner_distance: number | null;
        set_back_outer_distance: number | null;
      };
    };
export type Surrounding = {
  inner_segment: Segment;
  outer_segment: Segment;
  distance: number;
}
export type DrawingSurroundings =
  | {
      River: Surrounding;
    }
  | {
      Park: Surrounding;
    }
  | {
      RailWay: Surrounding;
    };
export type ShadowLinePolygon = {
  rainum: string,
  PenNumber: string,
  PenStyle: string,
  PenWidth: string,
  PenWeight: string,
  PenColor: string,
  PenLayer: string,
  points: XY[]
}
type Chars = {
  x: number;
  y: number;
  text: string;
}
export type ShadowLineChar = {
  rainum: string,
  FontNumber: string,
  FontHight: number,
  FontRote: number,
  FontColor: string,
  FontLayer: string,
  chars: Chars[]
}
export type DrawingOnTimeShadowLines = {
  polygons: ShadowLinePolygon[],
  chars: ShadowLineChar[]
}
export type DrawingRegion = {
  polygon: {
    exterior: XY[];
    interiors: XY[][];
  };
  id_youto?: {
    id: number;
    youto_chiiki: number;
    floor_area_ratio: number;
    building_coverage_ratio: number;
    max_height_limit: number;
  } | null;
  id_bouka?: {
    id: number;
    bouka_chiiki: number;
  } | null;
  id_koudo?: {
    id: number;
    koudo_chiku: number;
    min_height: number;
    max_height: number;
  } | null;
  id_hikage?: {
    id: number;
    shade_5: number;
    shade_10: number;
    shade_height: number;
  } | null;
};

export type DrawingSkyFactor = {
  factors: {
    area: {
      points: Vector[];
    };
    points: {
      point: Vector;
      plan_building_percent: number;
      compatible_building_percent: number;
      percent_diff: number;
      is_satisfied: boolean;
    }[];
  }[];
};

export type Drawing = {
  title: string;
  floors: DrawingFloor[];
  border_lines: DrawingBorderLine[];
  regions?: DrawingRegion[];
  sky_factor?: DrawingSkyFactor;
  balcony?: Polyline[];
  surroundings?: DrawingSurroundings[];
  ontime_shadow_lines?: DrawingOnTimeShadowLines;
};

function flipYSegment(segment: Segment | null): Segment | null {
  if (segment === null) {
    return null;
  }
  return {
    a: [segment.a[0], -segment.a[1]],
    b: [segment.b[0], -segment.b[1]],
  };
}

function flipYPolyline(polyline: Polyline): Polyline {
  return {
    points: polyline.points.map((p) => {
      return [p[0], -p[1]];
    }),
  };
}

function flipYBoundingBox(bounding_box: BoundingBox): BoundingBox {
  return {
    ...bounding_box,
    size: [bounding_box.size[0], bounding_box.size[1]],
    x_axis: [bounding_box.x_axis[0], -bounding_box.x_axis[1]],
    y_axis: [bounding_box.y_axis[0], -bounding_box.y_axis[1]],
  };
}

function flipPolygon(polygon: XY[]): XY[] {
  return polygon.map((p) => {
    return {
      x: p.x,
      y: -p.y,
    };
  });
}

function reverseSegment(segment: Segment | null): Segment | null {
  if (segment === null) {
    return null;
  }
  return {
    a: segment.b,
    b: segment.a,
  };
}

export function reverseBorderLine(
  border: DrawingBorderLine,
): DrawingBorderLine {
  if ('Road' in border) {
    return {
      ...border,
      Road: {
        ...border.Road,
        inner_segment: reverseSegment(border.Road.inner_segment)!,
        center_segment: reverseSegment(border.Road.center_segment)!,
        outer_segment: reverseSegment(border.Road.outer_segment)!,
        set_back_inner_segment: reverseSegment(
          border.Road.set_back_inner_segment,
        ),
        set_back_outer_segment: reverseSegment(
          border.Road.set_back_outer_segment,
        ),
        set_back_normal: [
          -border.Road.set_back_normal[0],
          -border.Road.set_back_normal[1],
        ],
      },
    };
  } else {
    return {
      ...border,
      Neighbor: {
        a: border.Neighbor.b,
        b: border.Neighbor.a,
      },
    };
  }
}

export function reverseSurroundings(
  surround: DrawingSurroundings,
): DrawingSurroundings {
  if ('River' in surround) {
    surround = {
      ...surround,
      River: {
        ...surround.River,
        inner_segment: reverseSegment(surround.River.inner_segment)!,
        outer_segment: reverseSegment(surround.River.outer_segment)!,
      },
    };
  }
  if ('Park' in surround) {
    surround = {
      ...surround,
      Park: {
        ...surround.Park,
        inner_segment: reverseSegment(surround.Park.inner_segment)!,
        outer_segment: reverseSegment(surround.Park.outer_segment)!,
      },
    };
  }
  if ('RailWay' in surround) {
    surround = {
      ...surround,
      RailWay: {
        ...surround.RailWay,
        inner_segment: reverseSegment(surround.RailWay.inner_segment)!,
        outer_segment: reverseSegment(surround.RailWay.outer_segment)!,
      },
    };
  }
  return surround;
}

export function flipY(dwg: Drawing): Drawing {
  return {
    ...dwg,
    balcony:
      dwg.balcony?.map((b) => {
        return flipYPolyline(b);
      }) ?? undefined,
    floors: dwg.floors.map((f) => {
      const { bounding_box, constructable_area } = f;

      return {
        ...f,
        bounding_box: flipYBoundingBox(bounding_box),
        constructable_area: {
          ...constructable_area,
          center: [constructable_area.center[0], -constructable_area.center[1]],
          shapes: constructable_area.shapes.map((s) => {
            return {
              ...s,
              polyline: flipYPolyline(s.polyline),
              inner: flipYPolyline(s.inner),
              outer: flipYPolyline(s.outer),
            };
          }),
        },
      };
    }),
    border_lines: dwg.border_lines.map((b) => {
      if ('Road' in b) {
        return {
          ...b,
          Road: {
            ...b.Road,
            inner_segment: flipYSegment(b.Road.inner_segment)!,
            center_segment: flipYSegment(b.Road.center_segment)!,
            outer_segment: flipYSegment(b.Road.outer_segment)!,
            set_back_inner_segment: flipYSegment(b.Road.set_back_inner_segment),
            set_back_outer_segment: flipYSegment(b.Road.set_back_outer_segment),
            set_back_normal: [
              b.Road.set_back_normal[0],
              -b.Road.set_back_normal[1],
            ],
          },
        };
      } else {
        return {
          ...b,
          Neighbor: flipYSegment(b.Neighbor)!,
        };
      }
    }),
    regions: dwg.regions?.map((r) => {
      const { polygon } = r;
      return {
        ...r,
        polygon: {
          exterior: flipPolygon(polygon.exterior),
          interiors: polygon.interiors.map((interior) => {
            return flipPolygon(interior);
          }),
        },
      };
    }),
    sky_factor: {
      factors: dwg.sky_factor.factors?.map((f) => {
        const points = f.points.map((p) => {
          const point: Vector = [p.point[0], -p.point[1]]
          return {
            ...p,
            point: point,
          }
        })
        return {
          ...f,
          points: points,
        }
      })
    },
    surroundings:dwg.surroundings?.map((s) => {
      if ('River' in s) {
        s = {
          ...s,
          River: {
            ...s.River,
            inner_segment: flipYSegment(s.River.inner_segment)!,
            outer_segment: flipYSegment(s.River.outer_segment)!,
          },
        };
      }
      if ('Park' in s) {
        s = {
          ...s,
          Park: {
            ...s.Park,
            inner_segment: flipYSegment(s.Park.inner_segment)!,
            outer_segment: flipYSegment(s.Park.outer_segment)!,
          },
        };
      }
      if ('RailWay' in s) {
        s = {
          ...s,
          RailWay: {
            ...s.RailWay,
            inner_segment: flipYSegment(s.RailWay.inner_segment)!,
            outer_segment: flipYSegment(s.RailWay.outer_segment)!,
          },
        };
      }
      return s;
    }),
    ontime_shadow_lines:{
      polygons: dwg.ontime_shadow_lines?.polygons.map((polygon) => {
        return {
          ...polygon,
          points: polygon.points.map((p) => {
            return {
              x: p.x,
              y: -p.y
            }
          })
        }
      }),
      chars: dwg.ontime_shadow_lines?.chars.map((char) => {
        return {
          ...char,
          chars: char.chars.map((c) => {
            return {
              x: c.x,
              y: -c.y,
              text: c.text
            }
          })
        }
      })
    }
  };
}

export function computeBoundingBox(borderLines: DrawingBorderLine[]): {
  min: Vector2;
  max: Vector2;
} {
  const pts = borderLines.flatMap((b) => {
    if ('Road' in b) {
      const segment = [
        b.Road.set_back_outer_segment,
        b.Road.outer_segment,
      ].find((s): s is Segment => s !== null)!;
      return [segment.a, segment.b];
    } else {
      return [b.Neighbor.a, b.Neighbor.b];
    }
  });
  const xs = pts.map((p) => {
    return p[0];
  });
  const ys = pts.map((p) => {
    return p[1];
  });
  return {
    min: new Vector2(Math.min(...xs.slice()), Math.min(...ys.slice())),
    max: new Vector2(Math.max(...xs.slice()), Math.max(...ys.slice())),
  };
}
