import * as React from 'react'
import { Button } from '@/components/Button'
import { Modal } from '@/components/Modal'
import { RadioButton } from '@/components/RadioButton'

type CalculationSettings = {
  isConstructionUnitPrice: boolean
}

type Props = {
  open: boolean
  onClose: () => void
  isConstructionUnitPrice: boolean
  onSettingChange: (isConstructionUnitPrice: boolean) => void
}

export const CalculationColumnSettingsModal: React.FC<Props> = ({
  open,
  onClose,
  isConstructionUnitPrice,
  onSettingChange,
}) => {
  const [localIsConstructionUnitPrice, setLocalIsConstructionUnitPrice] =
    React.useState(isConstructionUnitPrice)

  React.useEffect(() => {
    if (open) {
      setLocalIsConstructionUnitPrice(isConstructionUnitPrice)
    }
  }, [isConstructionUnitPrice, open])

  const handleClose = () => {
    onSettingChange(localIsConstructionUnitPrice)
    onClose()
  }

  return (
    <Modal open={open} onClose={handleClose} title="計算項目設定">
      <div className="p-6">
        <div className="mb-6 flex items-center gap-6">
          <div className="whitespace-nowrap font-medium w-32">工事費坪単価:</div>
          <div className="flex gap-6">
            <RadioButton
              name="modal_construction_unit_price"
              id="modal_construction_unit_price_false"
              text="本体工事費÷延床面積（坪）"
              value="false"
              checked={localIsConstructionUnitPrice === false}
              onChange={() => setLocalIsConstructionUnitPrice(false)}
            />
            <RadioButton
              name="modal_construction_unit_price"
              id="modal_construction_unit_price_true"
              text="本体工事費÷専有面積（坪）"
              value="true"
              checked={localIsConstructionUnitPrice === true}
              onChange={() => setLocalIsConstructionUnitPrice(true)}
            />
          </div>
        </div>

        <div className="flex justify-end mt-8">
          <Button outline onClick={handleClose} className="text-primary-font" type="button">
            閉じる
          </Button>
        </div>
      </div>
    </Modal>
  )
}
