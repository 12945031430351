// chartSizeUtils.ts
import { CHART_SIZE, CHART_CONTAINER_SELECTOR, ChartType } from './chartSizeConstants'
import Chart from 'chart.js'

/**
 * コンテナサイズを計算して適用する
 *
 * @param containerRef コンテナ要素への参照
 * @param labels チャートのラベル配列
 * @param chartType チャートのタイプ
 * @param parentSelector 親コンテナのセレクタ
 */
export function setupChartSize(
  containerRef: HTMLDivElement,
  labels: string[],
  chartType: ChartType = ChartType.HORIZONTAL_BAR,
  parentSelector: string = CHART_CONTAINER_SELECTOR
): void {
  if (chartType === ChartType.HORIZONTAL_BAR) {
    setupHorizontalBarSize(containerRef, labels, parentSelector)
  } else if (chartType === ChartType.BAR) {
    setupBarSize(containerRef, labels, parentSelector)
  }
}

/**
 * 横棒グラフ（HorizontalBar）のサイズを設定
 */
function setupHorizontalBarSize(
  containerRef: HTMLDivElement,
  labels: string[],
  parentSelector: string = CHART_CONTAINER_SELECTOR
): void {
  const parentContainer = document.querySelector(parentSelector)
  const parentWidth = parentContainer ? parentContainer.clientWidth : window.innerWidth

  const avgLabelLength =
    labels.reduce((sum, label) => sum + (label ? label.length : 0), 0) / (labels.length || 1)
  const minRequiredWidth = Math.max(
    CHART_SIZE.MIN_WIDTH,
    avgLabelLength * CHART_SIZE.CHAR_WIDTH_MULTIPLIER
  )

  containerRef.style.height = '100%'
  containerRef.style.position = 'relative'
  containerRef.style.overflow = 'visible'

  if (labels.length > CHART_SIZE.LABEL_THRESHOLD) {
    const minHeightForLabels = labels.length * CHART_SIZE.MIN_HEIGHT_PER_LABEL
    containerRef.style.minHeight = `${minHeightForLabels}px`

    if (parentContainer && minHeightForLabels > parentContainer.clientHeight) {
      // 親コンテナでスクロールしたいので、ここでは特に何もしない
    }
  } else {
    containerRef.style.minHeight = `${CHART_SIZE.MIN_TOTAL_HEIGHT}px`
  }

  // 幅の設定
  if (minRequiredWidth > parentWidth) {
    containerRef.style.width = `${parentWidth}px`
    containerRef.style.minWidth = `${parentWidth}px`
  } else {
    containerRef.style.width = '100%'
    containerRef.style.minWidth = ''
  }
}

/**
 * 縦棒グラフ（Bar）のサイズを設定
 */
function setupBarSize(
  containerRef: HTMLDivElement,
  labels: string[],
  parentSelector: string = CHART_CONTAINER_SELECTOR
): void {
  const parentContainer = document.querySelector(parentSelector)
  const parentWidth = parentContainer ? parentContainer.clientWidth : window.innerWidth

  containerRef.style.height = '100%'
  containerRef.style.position = 'relative'
  containerRef.style.overflow = 'visible'

  containerRef.style.minHeight = `${CHART_SIZE.BAR_MIN_HEIGHT}px`

  const idealWidth = Math.max(
    CHART_SIZE.BAR_MIN_TOTAL_WIDTH,
    labels.length * CHART_SIZE.BAR_MIN_WIDTH_PER_LABEL
  )

  // 幅の設定
  if (labels.length > CHART_SIZE.BAR_LABEL_THRESHOLD && idealWidth > parentWidth * 0.95) {
    containerRef.style.width = '100%'
    containerRef.style.minWidth = `${idealWidth}px`
    containerRef.style.overflowX = 'auto'
  } else {
    containerRef.style.width = '100%'
    containerRef.style.minWidth = ''
    containerRef.style.overflowX = 'visible'
  }
}

/**
 * チャートのリサイズハンドラ
 *
 * @param chartRef Chart.jsチャートへの参照
 * @param containerRef コンテナ要素への参照
 * @param chartType チャートのタイプ
 * @param parentSelector 親コンテナのセレクタ
 */
export function handleChartResize(
  chartRef: React.MutableRefObject<Chart | null>,
  containerRef: React.RefObject<HTMLDivElement>,
  chartType: ChartType = ChartType.HORIZONTAL_BAR,
  parentSelector: string = CHART_CONTAINER_SELECTOR
): void {
  if (!chartRef.current || !containerRef.current) return

  const parentContainer = document.querySelector(parentSelector)
  if (!parentContainer) return

  const labels = chartRef.current.data.labels || []

  setupChartSize(containerRef.current, labels as string[], chartType, parentSelector)

  chartRef.current.resize()
  chartRef.current.update({ duration: 0 })
}
