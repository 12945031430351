import { MarketDatum } from '@/types/marketDatum'
import { cammedFormat, formatDecimalToPercentage } from './cammedFormat'
import { percentColumns, priceColumns, areaColumns, areaTsuboColumns , defaultLegendNames} from '@/models/baloonSetting'


const formatter = new Intl.NumberFormat('ja-JP')

export const formatColumn = (marketDatum: MarketDatum, column: string, is_settings?: boolean): string => {
  let value = marketDatum?.[column]
  if (is_settings) return value ?? "ー"

  const columnMapping: Record<string, { open: string; close: string }> = {
    sale_and_prospective_price: {
      open: "sale_price_4",
      close: "building_price_4",
    },
    sale_and_prospective_yield: {
      open: "sale_prospective_yield",
      close: "prospective_yield",
    },
    sale_and_prospective_noi_yield: {
      open: "sale_prospective_noi_yield",
      close: "contract_prospective_noi_yield",
    },
  }

  if (column in columnMapping) {
    const statusKey = marketDatum?.status
    value = marketDatum?.[columnMapping[column][statusKey]]
  }

  const formatPrice = (val: number) => `${formatter.format(val)}円`
  const formatPercentage = (val: number) => `${formatDecimalToPercentage(val).toFixed(2)}%`
  const formatArea = (val: number) => `${cammedFormat(val, 2)}㎡`
  const formatTsubo = (val: number) => `${cammedFormat(val, 2)}坪`

  if (column === "rent_maintenance_fee_and_unit_price") {
    return `${formatPrice(marketDatum?.rent_maintenance_fee)} @ ${formatPrice(marketDatum?.rent_maintenance_fee_unit_price_tsubo)}`
  }
  if (value === undefined || value === null || value === "") return "ー"

  if (column === "build_age") {
    if (value === 0 || value === "新築") return "新築"
    const match = value.match(/(\d+)年(?:(\d+)ヶ月)?/)
    if (match) {
      const years = parseInt(match[1])
      const months = match[2] ? parseInt(match[2]) : 0
      return months === 0 ? `築${years}年` : `築${years}年${months}ヶ月`
    }
    return `築${value}`
  }

  if (column === "floor")  return `${marketDatum?.["floor_type_text"]}${value}階`
  if (column === "number_of_units") return `${value}戸`
  if (priceColumns.includes(column)) return formatPrice(value)
  if (percentColumns.includes(column)) return formatPercentage(value)
  if (areaColumns.includes(column)) return formatArea(value)
  if (areaTsuboColumns.includes(column)) return formatTsubo(value)

  return value;
};


export const formatLegendNames = (columns: any[] ,dataType: number): string[][] => {
  // columns が空ならデフォルト値を返す
  if (columns.length === 0 && defaultLegendNames[dataType]) {
    return defaultLegendNames[dataType]
  }
  const removeCurrencySuffix = (name: string): string =>
    name.replace(/（(円|千円|万円|百万円)）$/, '')

  const replaceMarketStatus = (name: string): string => {
    if (name === "売出表面利回り・成約表面利回り" ) {
        return "NOI利回り";
    }
    if (name === "売出価格・成約価格（万円)" ) {
        return "価格"
    }
    if (name === "売出表面利回り・成約表面利回り") {
        return "利回り";
    }
    return name;
};

  const splitRentalPrice = (name: string): string[] =>
      name === '賃料（管理費込み）@ 賃料（管理費込み）㎡単価'
          ? ['賃料（管理費込み）', '賃料（管理費込み）㎡単価']
          : [name];

  columns.sort((a, b) => a.order - b.order)

  const formatted: string[] = columns.map(col => replaceMarketStatus(removeCurrencySuffix(col.name)))
                                     .flatMap(splitRentalPrice);

  return [
      ["物件番号", "状態"],
      formatted.slice(0, 2),
      formatted.slice(2)
  ]
}

