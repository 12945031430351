import '@/components/GoogleMapViewer/style.css'
import { UserContext } from '@/components/Layout/Base'
import { Loading } from '@/components/Loading'
import { Row, Td, Th } from '@/components/Page/Properties/Detail/Form'
import { Table } from '@/components/Table'
import { usageAreaCode } from '@/models/property'
import { tagIcons } from '@/models/tagIcons'
import type { MarketDatum } from '@/types/marketDatum'
import type { LatLng } from '@/types/property'
import type { User } from '@/types/user'
import { cover_enterprise_pricing_type } from '@/utils/policy'
import { GoogleMap } from '@react-google-maps/api'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { marketDataReportSetup } from './marketDataReportSetup'
import { marketDataSetup } from './marketDataSetup'
import MapModal from './modal'
import { dosyaLayers, hazardButtonLayers } from './utils/hazardData'

export { stationSearch } from './stationSearch'

type ModeType =
  | 'property_edit'
  | 'property'
  | 'list'
  | 'volume'
  | 'volume_detail'
  | 'market_datum'
  | 'market_datum_report'
  | ''

interface MapProps {
  mode?: ModeType
  polygons_api_base_url?: string
  zoom?: number
  current_user: User
  csrfToken: string
  property_id?: number
  volume_check_request_id?: number
  lat?: number | string
  lng?: number | string
  propertyLat?: number
  propertyLng?: number
  propertyShape?: LatLng[]
  existdAreaValue?: string
  areaTextFromResponse?: string
  areaShape?: string
  requestUUID?: string
  onChangePolygon?: (changed: boolean) => void
  onChangeAreaCheck?: (changed: boolean) => void
  onRemovePolygon?: (removed: boolean) => void
  ref_volume_check_parameters?: any
  setChibanInfo?: (info: any) => void
  onChangeLatLng?: (latLng: LatLng) => void
  onChangeShape?: (shape: any) => void
  onChangeParameters?: (property: any) => void
  setWideAreaZoom?: (zomm: number) => void
  setNarrowAreaZoom?: (zomm: number) => void
  setShowYoutoInfo?: (show: boolean) => void
  setYoutoInfo?: (key: string, info: any) => void
  setOnLoadComplete?: (complete: boolean) => void
  style?: React.CSSProperties
}

export interface MapHandles {
  setLocation(address: string, zoom?: number): void
  setPing(address: string): void
  setMarketData(marketData: MarketDatum[]): void
  setMarketDataReport(marketData: MarketDatum[]): void
  clearMarketData(): void
}

// マーカー関連の再読み込み。trueにするとidle内でリフレッシュ処理が走って終わったらfalseになる
// コンポーネント内に置くと再描画が走ってしまうのでここに置く
var markerRefresh = false

export const OldGoogleMapViewer = React.forwardRef<MapHandles, MapProps>(
  (
    {
      mode = '',
      polygons_api_base_url = '',
      zoom = 19,
      current_user,
      csrfToken,
      property_id = 0,
      volume_check_request_id = 0,
      lat,
      lng,
      propertyLat,
      propertyLng,
      propertyShape,
      existdAreaValue = '',
      areaTextFromResponse,
      areaShape,
      requestUUID,
      onChangePolygon,
      onChangeAreaCheck,
      onRemovePolygon,
      setChibanInfo,
      onChangeLatLng,
      onChangeShape,
      ref_volume_check_parameters,
      onChangeParameters,
      setWideAreaZoom,
      setNarrowAreaZoom,
      setShowYoutoInfo,
      setYoutoInfo,
      setOnLoadComplete,
      style,
    }: MapProps,
    ref
  ) => {
    const mapOptions = React.useMemo(() => {
      return {
        center: { lat: Number(lat), lng: Number(lng) },
        zoom: zoom,
        minZoom: 1,
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: false,
        fullscreenControl:
          mode === 'property_edit' ||
          mode === 'property' ||
          mode === 'market_datum' ||
          mode === 'volume' ||
          mode === 'market_datum_report',
        tilt: 0,
        mapTypeId: 'roadmap',
      }
    }, [lat, lng, mode])

    const { user } = React.useContext(UserContext)

    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [modalProperty, setModalProperty] = React.useState({})
    const [antifireInfo, setAntifireInfo] = React.useState('-')
    const [showInfoTable, setShowInfoTable] = React.useState(false)
    const [usageInfos, setUsageInfos] = React.useState({
      usageArea: '-',
      buildingCoverageRatio: '-',
      floorAreaRatio: '-',
    })
    const [heightInfos, setHeightInfos] = React.useState({
      heightInfo: '-',
      heightMax: '-',
      heightMin: '-',
    })
    const [marketDataMarkers, setMarketDataMarkers] = React.useState([])

    const mapRef = React.useRef(null)
    let newProperty = null

    React.useImperativeHandle(ref, () => ({
      async setLocation(address, zoom) {
        const geocoder = new google.maps.Geocoder()
        const results = await geocoder.geocode({ address: address, language: 'ja' })
        mapRef?.current.setCenter(results.results[0].geometry.location)
        if (zoom !== undefined) {
          mapRef?.current.setZoom(zoom)
        }
      },
      async setPing(address) {
        const geocoder = new google.maps.Geocoder()
        const results = await geocoder.geocode({ address: address, language: 'ja' })
        const location = results.results[0].geometry.location
        const lat = location.lat()
        const lng = location.lng()
        if (location !== undefined) {
          // 物件のピンを追加
          const icon = {
            url: '/target_ping.png',
            scaledSize: new google.maps.Size(37, 30),
          }
          newProperty && newProperty.setMap(null)
          newProperty = new google.maps.Marker({
            position: location,
            map: mapRef.current,
            optimized: false,
            draggable: true,
          })

          newProperty.setOptions({ zIndex: 99999 })
          newProperty.setIcon(icon)
          onChangeLatLng({ lat, lng })
          google.maps.event.addListener(newProperty, 'dragend', () => {
            const lat = newProperty.getPosition().lat()
            const lng = newProperty.getPosition().lng()
            onChangeLatLng({ lat: lat.toString(), lng: lng.toString() })
            onChangePolygon(true)
          })
        }
      },
      setMarketData(
        marketData: MarketDatum[],
        clickMarker: (marketDatum: MarketDatum) => void,
        filteredMarketDatum: MarketDatum
      ) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataSetup(
          mapRef?.current,
          marketData,
          marketDataMarkers,
          clickMarker,
          filteredMarketDatum
        )

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      setMarketDataReport(marketData: MarketDatum[]) {
        // Mapに周辺事例のピンを表示する
        const markers = marketDataReportSetup(mapRef?.current, marketData)

        // タブ切替時に表示していたピンのクリアを行うため保持しておく
        setMarketDataMarkers(markers)
      },
      clearMarketData() {
        // タブ切り替え前に表示していた周辺事例のピンをクリアする
        marketDataMarkers.forEach((marker) => {
          marker.setMap(null)
        })

        setMarketDataMarkers([])
      },
    }))

    const [shadeInfos, setShadeInfos] = React.useState({
      shade5m: '-',
      shade10m: '-',
      shadeHeight: '-',
    })

    const processParamValue = (paramValue: string[] | null) => {
      if (!paramValue || paramValue.length === 0) {
        return []
      } else {
        return paramValue
      }
    }

    const [currentLocationMarker, setCurrentLocationMarker] = React.useState(null)
    const showAreaTextRef = React.useRef(null)
    const [reportDragListener, setReportDragListener] = React.useState(null)
    const [reportZoomListener, setReportZoomListener] = React.useState(null)
    const markerList = []

    const loading = <Loading height={20} width={20} />
    const onLoad = (map) => {
      mapRef.current = map

      if (setOnLoadComplete) {
        // mapRef.currentが取得できることを通知
        setOnLoadComplete(true)
      }

      let currentPolygon = null // 図形のポリゴン
      let areaText = null
      let infoMarkers = []
      let chibanMarkers = []
      let selectedInfoWindows = []
      let centerCursors = []
      let overlays = {
        kouzuiSaidai: null,
        kouzuiKeikaku: null,
        tsunami: null,
        takashio: null,
        dosyaDosekiryu: null,
        dosyaKyukeisha: null,
        dosyaJisuberi: null,
        dosekiryukikenkeiryu: null,
        kyukeisyachihoukai: null,
        jisuberikikenkasyo: null,
        nadarekikenkasyo: null,
      }
      let layerButtonStates = {
        showPolygonsClicked: false,
        showKouzuMapClicked: false,
        showOtherPropertiesClicked: mode === 'property_edit', // 自社物件登録はデフォルトで有効
        kouzuiSaidaiButtonClicked: false,
        kouzuiKeikakuButtonClicked: false,
        tsunamiButtonClicked: false,
        takashioButtonClicked: false,
        dosyaButtonClicked: false,
      }
      const polygonsHash = {
        youto_chiiki: {},
        bouka_chiiki: {},
        koudo_chiku: {},
        shikichi: {},
        hikage_kisei: {},
        kouzu_map: {},
      }
      const cityPlanItems = ['youto_chiiki', 'bouka_chiiki', 'koudo_chiku', 'hikage_kisei']
      const cadastralItems = ['kouzu_map']
      const areaMarkers = []
      const styledMapType = new google.maps.StyledMapType(
        [
          {
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit.line', // 交通機関の路線
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
          {
            featureType: 'transit.station.rail', // 電車の駅
            elementType: 'all', // ラベルを含めてすべて
            stylers: [{ visibility: 'on' }],
          },
        ],
        { name: 'Styled Map' }
      )

      let youtoInfo = {}

      map.mapTypes.set('styled_map', styledMapType)
      let drawingManager

      map.setMapTypeId('styled_map')
      if (mode == 'property_edit' || mode == 'volume' || mode === 'market_datum_report') {
        // ドローイングマネージャーを追加
        // DrawingManagerを生成
        drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: null, // PAN
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: [google.maps.drawing.OverlayType.POLYGON],
          },
          //ポリゴンのオプション
          polygonOptions: {
            draggable: true,
            fillColor: '#55ee55',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            zIndex: 1,
          },
        })

        // Mapに割り当て
        drawingManager.setMap(map)

        // 敷地の描画
        google.maps.event.addListener(drawingManager, 'polygoncomplete', function (polygon) {
          showAreaText(polygon)
          showAreaMarkers(polygon)
          if (mode == 'volume') {
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            onChangeShape(JSON.stringify(shape))
            onChangePolygon(true)
            if (onChangePolygon) {
              onChangePolygon(true)
            }
            if (onChangeAreaCheck) {
              onChangeAreaCheck(false)
            }

            google.maps.event.addListener(polygon, 'mouseup', () => {
              showAreaText(polygon)
              showAreaMarkers(polygon)
              const shape = []
              polygon.getPath().forEach((latlng) => {
                shape.push({ lat: latlng.lat(), lng: latlng.lng() })
              })
              onChangeShape(JSON.stringify(shape))
              if (onChangePolygon) {
                onChangePolygon(true)
              }
              if (onChangeAreaCheck) {
                onChangeAreaCheck(false)
              }
            })
            if (!(currentPolygon === null)) {
              currentPolygon.setMap()
            }
            currentPolygon = polygon
            drawingManager.setDrawingMode(null)
            void createParameters(polygon)
          } else {
            if (!(currentPolygon === null)) {
              currentPolygon.setMap()
            }
            const shape = []

            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            if (newProperty === null) {
              const bounds = new google.maps.LatLngBounds()

              polygon.getPath().forEach((latlng) => {
                bounds.extend(latlng)
              })
              putNewProperty(bounds.getCenter(), map)
              showAreaText(polygon)

              const lat = newProperty.position.lat()
              const lng = newProperty.position.lng()
              onChangeLatLng({ lat, lng })
            }
            onChangeShape(JSON.stringify(shape))
            if (onChangePolygon) {
              onChangePolygon(true)
            }
            if (onChangeAreaCheck) {
              onChangeAreaCheck(false)
            }
            showAreaMarkers(polygon)
            google.maps.event.addListener(polygon, 'mouseup', () => {
              shape.splice(0)

              polygon.getPath().forEach((latlng) => {
                shape.push({ lat: latlng.lat(), lng: latlng.lng() })
              })
              onChangeShape(JSON.stringify(shape))
              if (onChangePolygon) {
                onChangePolygon(true)
              }
              if (onChangeAreaCheck) {
                onChangeAreaCheck(false)
              }
              showAreaText(polygon)
              showAreaMarkers(polygon)
            })
            drawingManager.setDrawingMode(null)
            currentPolygon = polygon
          }
        })

        let currentSearchParams = new URLSearchParams(window.location.search)
        // 敷地の削除ボタンを追加（対象モード： mode == 'property_edit' || mode == 'volume'）
        const deleteButton = document.createElement('button')

        deleteButton.textContent = '描画削除'
        deleteButton.classList.add('custom-map-control-button')
        deleteButton.setAttribute('type', 'button')
        deleteButton.style.fontSize = '14px'
        deleteButton.style.margin = '4px 8px'

        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(deleteButton)

        deleteButton.addEventListener('click', () => {
          if (onChangePolygon) {
            onChangePolygon(false)
          }
          if (onChangeAreaCheck) {
            onChangeAreaCheck(false)
          }
          if (currentPolygon === null) return
          currentPolygon.setMap()
          currentPolygon = null
          areaText.setMap()
          areaText = null
          showAreaMarkers()
          onChangeShape('')
          if (mode === 'volume') {
            void createParameters()
            onRemovePolygon(true)
          }
        })
      }

      if (mode !== 'volume_detail' && mode !== 'volume') {
        // リセットボタンを追加
        const resetButton = document.createElement('button')
        resetButton.textContent = 'リセット'
        resetButton.classList.add('custom-map-control-button')
        resetButton.setAttribute('type', 'button')
        resetButton.style.fontSize = '14px'
        resetButton.style.margin = '4px 8px'

        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(resetButton)

        resetButton.addEventListener('click', () => {
          // ピンや敷地形状をクリア
          clearProperty()

          // ピンや敷地形状を初期表示に戻す
          renderProperty()

          // 呼び出し側の値も初期値に戻す
          if (propertyLat && propertyLng) {
            onChangeLatLng({ lat: propertyLat, lng: propertyLng })
          }
          if (propertyShape) {
            onChangeShape(JSON.stringify(propertyShape))
          }
        })
      }

      if (
        mode === 'property_edit' ||
        mode === 'property' ||
        mode === 'list' ||
        mode === 'market_datum' ||
        mode === 'market_datum_report'
      ) {
        // 3D表示ボタンを追加
        let is3D = false
        const map3DButton = document.createElement('button')
        map3DButton.textContent = '3D'

        map3DButton.classList.add('custom-map-control-button')
        map3DButton.setAttribute('type', 'button')
        map3DButton.style.fontSize = '14px'
        map3DButton.style.display = map.getMapTypeId() === 'styled_map' ? 'none' : 'block'

        map3DButton.addEventListener('click', () => {
          is3D = !is3D
          map.setTilt(is3D ? 45 : 0)
          map3DButton.textContent = is3D ? '2D' : '3D'
        })

        // 地図、航空写真ボタンを追加
        const mapTypeButton = document.createElement('button')
        mapTypeButton.textContent = map.getMapTypeId() === 'styled_map' ? '航空写真' : '地図'

        mapTypeButton.classList.add('custom-map-control-button')
        mapTypeButton.setAttribute('type', 'button')
        mapTypeButton.style.fontSize = '14px'

        const mapBox = document.createElement('div')
        mapBox.style.display = 'flex'
        mapBox.appendChild(mapTypeButton)
        mapBox.appendChild(map3DButton)

        map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(mapBox)

        mapTypeButton.addEventListener('click', () => {
          map.setMapTypeId(map.getMapTypeId() === 'styled_map' ? 'satellite' : 'styled_map')
          const styledMap = map.getMapTypeId() === 'styled_map'
          mapTypeButton.textContent = styledMap ? '航空写真' : '地図'
          map3DButton.style.display = styledMap ? 'none' : 'block'
        })
      }

      if (mode === 'property') {
        // 広域として保存ボタンを追加
        const wideAreaButton = document.createElement('button')

        wideAreaButton.textContent = '広域として保存'
        wideAreaButton.classList.add('custom-map-control-button')
        wideAreaButton.setAttribute('type', 'button')
        wideAreaButton.style.fontSize = '14px'
        wideAreaButton.style.margin = '4px 8px'

        map.controls[google.maps.ControlPosition.TOP].push(wideAreaButton)

        wideAreaButton.addEventListener('click', () => {
          const zoom = map.getZoom()
          setWideAreaZoom(zoom)
        })

        // 狭域として保存ボタンを追加
        const narrowAreaButton = document.createElement('button')

        narrowAreaButton.textContent = '狭域として保存'
        narrowAreaButton.classList.add('custom-map-control-button')
        narrowAreaButton.setAttribute('type', 'button')
        narrowAreaButton.style.fontSize = '14px'
        narrowAreaButton.style.margin = '4px 8px'

        map.controls[google.maps.ControlPosition.TOP].push(narrowAreaButton)

        narrowAreaButton.addEventListener('click', () => {
          const zoom = map.getZoom()
          setNarrowAreaZoom(zoom)
        })
      }

      if (mode == 'property_edit' || mode === 'market_datum') {
        renderProperty()

        // 地図をクリック
        google.maps.event.addListener(map, 'click', (event) => {
          // 物件のピンを追加
          putNewProperty(event.latLng, map)
          const lat = newProperty.position.lat()
          const lng = newProperty.position.lng()
          onChangeLatLng({ lat, lng })
          google.maps.event.addListener(newProperty, 'dragend', () => {
            const lat = newProperty.position.lat()
            const lng = newProperty.position.lng()
            onChangeLatLng({ lat, lng })
            onChangePolygon(true)
          })
        })
      }

      if (
        mode === 'property' ||
        mode === 'volume' ||
        mode === 'volume_detail' ||
        mode === 'market_datum_report'
      ) {
        let fetchURL = `/properties/${property_id}/shape`

        // 過去分ボリュームチャック敷地履歴の描画
        if (mode == 'volume_detail') {
          const currentUrl = new URL(window.location.href)
          const pathParams = currentUrl.pathname.split('/')
          const volume_check_request_id = pathParams[pathParams.length - 1]
          if (volume_check_request_id) {
            let fetchParams = new URLSearchParams({ ref: volume_check_request_id.toString() })
            fetchURL += `?${fetchParams.toString()}`
          }
        } else if (mode == 'volume') {
          let currentSearchParams = new URLSearchParams(window.location.search)
          if (currentSearchParams.has('ref')) {
            let fetchParams = new URLSearchParams({ ref: currentSearchParams.get('ref') })
            fetchURL += `?${fetchParams.toString()}`
          }
        }

        fetch(fetchURL)
          .then((res) => res.json())
          .then((json) => {
            if (json.status == 'NG') return

            const polygon = new google.maps.Polygon({
              paths: json.shape.map((latlng) => {
                return new google.maps.LatLng(latlng.lat, latlng.lng)
              }),
              draggable: mode != 'property',
              fillColor: '#55ee55',
              fillOpacity: 0.5,
              strokeWeight: 2,
              clickable: mode != 'property' && mode != 'volume_detail',
              editable: mode != 'property' && mode != 'volume_detail',
              zIndex: 1,
            })
            polygon.setMap(map)
            showAreaMarkers(polygon)
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            if (onChangeShape) {
              onChangeShape(JSON.stringify(shape))
            }
            if (mode == 'volume') {
              google.maps.event.addListener(polygon, 'mouseup', () => {
                showAreaText(polygon)
                showAreaMarkers(polygon)
                void createParameters(polygon)
                const shape = []
                polygon.getPath().forEach((latlng) => {
                  shape.push({ lat: latlng.lat(), lng: latlng.lng() })
                })
                onChangeShape(JSON.stringify(shape))
                if (onChangePolygon) {
                  onChangePolygon(true)
                }
                if (onChangeAreaCheck) {
                  onChangeAreaCheck(false)
                }
              })
              drawingManager.setDrawingMode(null)
            }
            currentPolygon = polygon
            showAreaText(currentPolygon)
            void createParameters(polygon)
          })
          .catch((err) => {
            console.log(err)
          })
      }

      if (mode == 'list') {
        // mapクリック時にマーカーを追加
        google.maps.event.addListener(map, 'click', (event) => {
          addMarker({ id: 0, lat: event.latLng.lat(), lng: event.latLng.lng() }, map)
        })
      }

      if (mode === 'list' || mode === 'property_edit' || mode === 'market_datum') {
        const locationButton = document.createElement('button')

        locationButton.textContent = '現在地'
        locationButton.classList.add('custom-map-control-button')
        locationButton.setAttribute('type', 'button')
        locationButton.style.fontSize = '14px'

        map.controls[google.maps.ControlPosition.LEFT_TOP].push(locationButton)

        locationButton.addEventListener('click', () => {
          navigator.geolocation.getCurrentPosition((position) => {
            const gpslat = position.coords.latitude
            const gpslng = position.coords.longitude
            const latlng = new google.maps.LatLng(gpslat, gpslng)
            map.setCenter(latlng)

            if (cover_enterprise_pricing_type(current_user)) {
              if (currentLocationMarker) {
                currentLocationMarker.setMap(null)
              }

              const marker = new google.maps.Marker({
                map: map,
                position: new google.maps.LatLng(latlng),
                icon: {
                  url: '/flag_icon/current_location.svg',
                  scaledSize: new google.maps.Size(30, 60),
                },
                optimized: false,
              })

              setCurrentLocationMarker(marker)
            }
          })
        })

        // Create the search box and link it to the UI element.
        if (mode == 'list') {
          const input = document.getElementById('pac-input') as HTMLInputElement
          const searchBox = new google.maps.places.SearchBox(input)

          map.controls[google.maps.ControlPosition.TOP_LEFT].push(input)

          // Bias the SearchBox results towards current map's viewport.
          map.addListener('bounds_changed', () => {
            searchBox.setBounds(map.getBounds())
          })

          // Listen for the event fired when the user selects a prediction and retrieve
          // more details for that place.
          searchBox.addListener('places_changed', () => {
            const places = searchBox.getPlaces()

            if (places.length == 0) {
              return
            }

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds()

            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                console.log('Returned place contains no geometry')
                return
              }

              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport)
              } else {
                bounds.extend(place.geometry.location)
              }
            })
            map.fitBounds(bounds)
          })
        }
      }

      let params: any = {}

      google.maps.event.addListener(map, 'idle', () => {
        map.setCenter(map.getCenter())
        const latlngBounds = map.getBounds()
        const swLatlng = latlngBounds.getSouthWest()
        const neLatlng = latlngBounds.getNorthEast()
        const currentParams = new URLSearchParams(window.location.search)

        if (
          params &&
          params.lat1 == swLatlng.lat() &&
          params.lng1 == swLatlng.lng() &&
          params.lat2 == neLatlng.lat() &&
          params.lng2 == neLatlng.lng()
        ) {
          if (!markerRefresh) return
        }
        params = {
          lat1: swLatlng.lat(),
          lng1: swLatlng.lng(),
          lat2: neLatlng.lat(),
          lng2: neLatlng.lng(),
          with_shape: map.getZoom() >= 17,
          'q[map_prefecture_eq]': currentParams.get('q[map_prefecture_eq]') || '',
          'q[map_city_eq]': currentParams.get('q[map_city_eq]') || '',
          'q[map_town_eq]': currentParams.get('q[map_town_eq]') || '',
          'q[map_chome_cont]': currentParams.get('q[map_chome_cont]') || '',
          'q[map_map_information_cont]': currentParams.get('q[map_map_information_cont]') || '',
          'q[user_id_eq]': currentParams.get('q[property_user_id_eq]') || '', // 情報入手者
          'q[proposals_source_user_id_eq]': currentParams.get('q[source_user_id_eq]') || '', // 案件担当者
          'q[proposals_source_user_department_name_eq]':
            currentParams.get('q[user_department_name_eq]') || '', // 情報入手部署
          'q[user_department_name_eq]': currentParams.get('q[user_department_name_eq]') || '', // 案件入手部署
          'q[proposals_sourced_at_gteq]': currentParams.get('q[sourced_at_gteq]') || '', // 入手日from
          'q[proposals_sourced_at_lteq]': currentParams.get('q[sourced_at_lteq]') || '', // 入手日to
          'q[purchace_applied_at_gteq]': currentParams.get('q[property_purchace_applied_at_gteq]'), // 買付提出日from
          'q[purchace_applied_at_lteq]': currentParams.get('q[property_purchace_applied_at_lteq]'), // 買付提出日to
          'q[purchace_contract_at_gteq]':
            currentParams.get('q[property_purchace_contract_at_gteq]') || '', // 仕入契約日from
          'q[purchace_contract_at_lteq]':
            currentParams.get('q[property_purchace_contract_at_lteq]') || '', // 仕入れ契約日to
          'q[purchace_settlement_at_gteq]':
            currentParams.get('q[property_purchace_settlement_at_gteq]') || '', // 仕入決済日from
          'q[purchace_settlement_at_lteq]':
            currentParams.get('q[property_purchace_settlement_at_lteq]') || '', // 仕入決済日to
          'q[proposals_order_gteq]': currentParams.get('q[proposal_order_gteq]') || '', // 順番from
          'q[proposals_order_lteq]': currentParams.get('q[proposal_order_lteq]') || '', // 順番to
          'q[proposals_ongoing_eq]': currentParams.get('q[ongoing_eq]') || '', // 進捗
          'q[tag_name_eq]': currentParams.get('q[property_tag_name_eq]') || '', // タグ
          'q[management_code_cont]': currentParams.get('q[management_code_cont]') || '', // タグ
          'q[property_type_eq]': currentParams.get('q[property_property_type_eq]') || '', // 物件種目
          'q[area_m3_gteq]': currentParams.get('q[property_area_m3_gteq]') || '', // 敷地面積from
          'q[area_m3_lteq]': currentParams.get('q[property_area_m3_lteq]') || '', // 敷地面積to
          'q[occupied_area_gteq]': currentParams.get('q[property_occupied_area_gteq]') || '', // 建物面積from
          'q[occupied_area_lteq]': currentParams.get('q[property_occupied_area_lteq]') || '', // 建物面積to
          'q[business_type_name_eq]': currentParams.get('q[property_business_type_eq]') || '', // 事業形態
          'q[source_cont_eq]': currentParams.get('q[source_cont]') || '', // 入手先
          'q[proposals_by_freeword]': currentParams.get('q[by_freeword]') || '', // フリーワード
          searchAddressList: currentParams.get('searchAddressList') || '',
          'q[source_user_id_in]': JSON.stringify(
            currentParams.getAll('q[source_user_id_in][]').filter((v) => v !== '')
          ),
          'q[property_user_id_in]': JSON.stringify(
            currentParams.getAll('q[property_user_id_in][]').filter((v) => v !== '')
          ),
          'q[source_user_department_name_in]': JSON.stringify(
            currentParams.getAll('q[source_user_department_name_in][]').filter((v) => v !== '')
          ),
          'q[property_user_department_name_in]': JSON.stringify(
            currentParams.getAll('q[property_user_department_name_in][]').filter((v) => v !== '')
          ),
          'q[ongoing_in]': JSON.stringify(currentParams.getAll('q[ongoing_in][]') || []),
          'q[property_tag_name_in]': JSON.stringify(
            processParamValue(currentParams.getAll('q[property_tag_name_in][]'))
          ),
          'q[property_property_type_in]': JSON.stringify(
            currentParams.getAll('q[property_property_type_in][]') || []
          ),
          'q[property_business_type_name_in]': JSON.stringify(
            processParamValue(currentParams.getAll('q[property_business_type_name_in][]'))
          ),
        }

        if (
          mode === 'list' ||
          mode === 'property' ||
          mode === 'property_edit' ||
          mode === 'volume' ||
          mode == 'market_datum' ||
          mode === 'market_datum_report'
        ) {
          // マーカーのリフレッシュ
          if (markerList.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;[...markerList].forEach((marker) => {
              marker.setMap()
            })
            markerList.length = 0
          }
          // 物件リストのmarkerを追加
          const query = new URLSearchParams(params)
          fetch(`/api/properties?${query}`)
            .then((res) => res.json())
            .then((json) => {
              json.res.forEach((marker) => {
                if (marker.lat < 0 || marker.lng < 0) return
                // マーカーを追加
                markerList.push(addMarker(marker, map, marker.tag_id, marker.tag_color))
                // 敷地を表示
                if (
                  (mode === 'list' || layerButtonStates.showOtherPropertiesClicked) &&
                  marker.shape &&
                  !polygonsHash.shikichi[marker.id]
                ) {
                  drawArea(marker, map)
                }
                if (mode != 'list' && !layerButtonStates.showOtherPropertiesClicked) {
                  deleteArea()
                  markerList.length = 0
                }
              })
            })
            .catch((err) => {
              console.log(err)
            })
          markerRefresh = false
        }

        if (map.getZoom() >= 17) {
          if (layerButtonStates.showPolygonsClicked || layerButtonStates.showKouzuMapClicked) {
            renderPolygons()
          }

          if (layerButtonStates.showPolygonsClicked) {
            setShowInfoTable(true)
          } else {
            clearCityPlanItems()
            setShowInfoTable(false)
          }

          if (!layerButtonStates.showKouzuMapClicked) {
            clearCadastralItems()
          }
        } else {
          setShowInfoTable(false)
          clearCityPlanItems()
          clearCadastralItems()
        }
      })

      google.maps.event.addListener(map, 'bounds_changed', () => {
        if (map.getZoom() < 17) {
          clearCityPlanItems()
          clearCadastralItems()
        } else {
          if (!layerButtonStates.showPolygonsClicked) {
            clearCityPlanItems()
          }

          if (!layerButtonStates.showKouzuMapClicked) {
            clearCadastralItems()
          }
        }
      })

      function clearItems(areas) {
        areas.forEach((area) => {
          Object.keys(polygonsHash[area]).forEach((key) => {
            polygonsHash[area][key].setMap()
            delete polygonsHash[area][key]
          })
        })
      }

      function clearCityPlanItems() {
        clearItems(cityPlanItems)
        infoMarkers.forEach((infoMarker) => {
          infoMarker.setMap(null)
        })
        centerCursors.forEach((centerCursor) => {
          centerCursor.setMap(null)
        })
        setUsageInfos({
          usageArea: '-',
          buildingCoverageRatio: '-',
          floorAreaRatio: '-',
        })
        setAntifireInfo('-')
        setHeightInfos({
          heightInfo: '-',
          heightMax: '-',
          heightMin: '-',
        })
        setShadeInfos({
          shade5m: '-',
          shade10m: '-',
          shadeHeight: '-',
        })
      }

      function clearCadastralItems() {
        clearItems(cadastralItems)
        chibanMarkers.forEach((chibanMarker) => {
          chibanMarker.setMap(null)
        })
      }

      // 敷地の面積を表示する
      function showAreaText(polygon, areaValue = '') {
        const bounds = new google.maps.LatLngBounds()
        let area
        let labelColor = '#691a11'
        if (areaValue) {
          if (!polygon) {
            return
          }
          if (areaValue.endsWith('gmap')) {
            areaValue = areaValue.replace('gmap', '㎡')
            labelColor = '#0000ff'
          }
          const polygonShape = JSON.parse(polygon)
          const parsedShape = polygonShape.map((coord) => {
            return {
              lat: Number(coord.lat),
              lng: Number(coord.lng),
            }
          })
          parsedShape.forEach((latlng) => {
            bounds.extend(latlng)
          })
        } else {
          const roundedPath = []
          polygon.getPath().forEach((latlng) => {
            bounds.extend(latlng)

            roundedPath.push({
              lat: Math.round(latlng.lat() * 1000000) / 1000000,
              lng: Math.round(latlng.lng() * 1000000) / 1000000,
            })
          })
          area = google.maps.geometry.spherical.computeArea(roundedPath)
        }
        if (areaText) {
          areaText.setMap()
        }
        areaText = new google.maps.Marker({
          position: bounds.getCenter(),
          map,
          icon: {
            url: '',
            size: new google.maps.Size(1, 1),
          },
          label: {
            text: areaValue || ' ',
            color: labelColor,
            fontFamily: 'sans-serif',
            fontWeight: 'bold',
            fontSize: '14px',
          },
        })
      }
      showAreaTextRef.current = showAreaText

      function renderProperty() {
        if (propertyLat && propertyLng) {
          putNewProperty(new google.maps.LatLng(propertyLat, propertyLng), map)
          google.maps.event.addListener(newProperty, 'dragend', () => {
            const lat = newProperty.position.lat()
            const lng = newProperty.position.lng()
            onChangeLatLng({ lat, lng })
          })
        }
        const shape = propertyShape
        if (shape) {
          const polygon = new google.maps.Polygon({
            paths: shape.map((latlng) => {
              return new google.maps.LatLng(latlng.lat, latlng.lng)
            }),
            draggable: true,
            fillColor: '#55ee55',
            fillOpacity: 0.5,
            strokeWeight: 2,
            clickable: true,
            editable: true,
            zIndex: 1,
          })
          polygon.setMap(map)
          showAreaMarkers(polygon)
          google.maps.event.addListener(polygon, 'mouseup', () => {
            const shape = []
            polygon.getPath().forEach((latlng) => {
              shape.push({ lat: latlng.lat(), lng: latlng.lng() })
            })
            onChangeShape(JSON.stringify(shape))
            if (onChangePolygon) {
              onChangePolygon(true)
            }
            if (onChangeAreaCheck) {
              onChangeAreaCheck(false)
            }
            showAreaText(polygon)
            showAreaMarkers(polygon)
          })
          currentPolygon = polygon
          showAreaText(currentPolygon)
          drawingManager.setDrawingMode(null)
        }
      }

      // 敷地を表示
      function drawArea(property, map) {
        const polygon = new google.maps.Polygon({
          paths: property.shape.map((latlng) => {
            return new google.maps.LatLng(latlng.lat, latlng.lng)
          }),
          draggable: false,
          fillColor: '#55ee55',
          fillOpacity: 0.5,
          strokeWeight: 1,
          clickable: false,
          editable: false,
          zIndex: 1,
        })
        polygon.setMap(map)
        polygonsHash['shikichi'][property.id] = polygon
        void createParameters(polygon)
      }

      function deleteArea() {
        const area = 'shikichi'
        const keysToDelete = []

        Object.keys(polygonsHash[area]).forEach((key) => {
          polygonsHash[area][key].setMap(null)
          keysToDelete.push(key)
        })

        keysToDelete.forEach((key) => {
          delete polygonsHash[area][key]
        })
      }

      // polygonの各頂点にピン番号を表示する(nullを与えるとピン番号を隠す)
      function showAreaMarkers(polygon = null) {
        areaMarkers.forEach((marker) => {
          marker.setMap()
        })
        areaMarkers.length = 0
        if (polygon) {
          let i = 1
          polygon.getPath().forEach((latlng) => {
            areaMarkers.push(
              new google.maps.Marker({
                position: latlng,
                map: map,
                label: {
                  text: `${i++}`,
                  color: '#ffffff',
                  fontWeight: 'bold',
                  fontSize: '14',
                },
                icon: {
                  url: '/map_flag.png',
                  anchor: new google.maps.Point(2, 30),
                  labelOrigin: new google.maps.Point(8, 9),
                  scaledSize: new google.maps.Size(15, 30),
                },
                optimized: false,
              })
            )
          })
        }
      }

      function createLayerButtons(
        type,
        imageSource,
        buttonText,
        buttonFunction,
        svgAttributes = {}
      ) {
        const button = document.createElement('button')
        button.classList.add('custom-map-control-button')
        button.setAttribute('type', 'button')
        button.style.fontSize = '14px'
        button.style.margin = '4px 4px 0 0'
        button.style.color = 'black'
        if (type === 'svg') {
          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          Object.entries(svgAttributes).forEach(([key, value]) => {
            svg.setAttribute(key, String(value))
          })
          const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
          path.setAttribute('d', imageSource)
          svg.appendChild(path)
          button.appendChild(svg)
        } else if (type === 'img') {
          const img = document.createElement('img')
          img.src = imageSource
          img.height = 15
          img.width = 15
          img.style.display = 'inline-block'
          img.style.verticalAlign = 'middle'
          img.style.marginRight = '5px'
          button.appendChild(img)
        }
        const span = document.createElement('span')
        span.textContent = buttonText
        button.appendChild(span)
        button.addEventListener('click', buttonFunction)
        return button
      }

      function handleLayerButtonStatesChange(newLayerButtonStates) {
        const oldLayerButtonStates = { ...layerButtonStates }
        layerButtonStates = newLayerButtonStates

        if (newLayerButtonStates.showPolygonsClicked !== oldLayerButtonStates.showPolygonsClicked) {
          if (newLayerButtonStates.showPolygonsClicked) {
            setShowInfoTable(true)
            renderPolygons()
          } else {
            clearCityPlanItems()
          }
        }

        if (newLayerButtonStates.showKouzuMapClicked !== oldLayerButtonStates.showKouzuMapClicked) {
          if (newLayerButtonStates.showKouzuMapClicked) {
            renderPolygons()
          } else {
            clearCadastralItems()
          }
        }
        userGuideButtonHidden(
          !hazardButtonLayers.some((layer) => layerButtonStates[layer.buttonState])
        )
      }

      function userGuideButtonHidden(isHidden: boolean, button?: HTMLButtonElement) {
        const guideBtn = button || document.querySelector<HTMLButtonElement>('.usage-guide-button')
        if (guideBtn) {
          guideBtn.style.display = isHidden ? 'none' : 'block'
        }
      }

      function updateUserGuideImgs(isHidden: boolean) {
        // 少しちらつくが、実装がシンプルなので一旦消して再生成する
        document.querySelector('#guide-container')?.remove()
        if (isHidden) {
          return
        }

        const guideContainer = document.createElement('div')
        guideContainer.style.position = 'absolute'
        guideContainer.id = 'guide-container'
        guideContainer.style.width = '100%'

        const guideDiv = document.createElement('div')
        guideDiv.className =
          'absolute h-[196px] bottom-[-75px] left-[100px] flex bg-white opacity-60 rounded overflow-x-scroll max-w-[calc(100%-160px)]'

        const addGuideItem = (label: string, image: string) => {
          const guideItem = document.createElement('div')
          guideItem.className = 'flex flex-col space-between m-[10px]'

          const guideTitle = document.createElement('div')
          guideTitle.innerHTML = label
          guideTitle.className = 'text-center mb-[5px] text-sm w-[140px] h-full'

          const img = document.createElement('img')
          img.src = image
          img.style.width = '140px'
          img.style.objectFit = 'contain'

          guideItem.appendChild(guideTitle)
          guideItem.appendChild(img)
          guideDiv.appendChild(guideItem)
        }

        hazardButtonLayers.forEach((layer) => {
          if (!layerButtonStates[layer.buttonState]) return
          if (layer.tileName === 'dosya') {
            Object.entries(overlays).forEach(([key, value]) => {
              if (!value || hazardButtonLayers.some((layer) => layer.tileName === key)) return

              const item = dosyaLayers.find((layer) => layer.name === key)
              if (!item) return
              addGuideItem(item.guideLabel, item.guideImg)
            })
          } else {
            addGuideItem(layer.label, layer.guideImg)
          }
        })

        guideContainer.appendChild(guideDiv)

        map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(guideContainer)
      }

      let showUsageGuide = false
      function setupUserGuideButton() {
        const button = document.createElement('button')
        button.classList.add('custom-map-control-button')
        button.classList.add('usage-guide-button')
        button.setAttribute('type', 'button')
        button.style.fontSize = '14px'
        button.style.color = 'black'
        button.style.display = 'hidden'
        button.textContent = '凡例'
        button.style.zIndex = 'unset'
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(button)

        userGuideButtonHidden(
          !hazardButtonLayers.some((layer) => layerButtonStates[layer.buttonState]),
          button
        )

        button.addEventListener('click', () => {
          const isVisible = hazardButtonLayers.some((layer) => layerButtonStates[layer.buttonState])
          if (!showUsageGuide && isVisible) {
            updateUserGuideImgs(false)
            showUsageGuide = true
            button.style.color = 'red'
          } else {
            updateUserGuideImgs(true)
            showUsageGuide = false
            button.style.color = 'black'
          }
        })
      }

      // レイヤーボタン群の描画
      if (user.pricing_type !== 'basic' && mode != 'volume_detail') {
        // レイヤーボタン
        const layerButtonSvgPath =
          'm11.99 18.54-7.37-5.73L3 14.07l9 7 9-7-1.63-1.27-7.38 5.74zM12 16l7.36-5.73L21 9l-9-7-9 7 1.63 1.27L12 16z'
        const svgAttributes = {
          class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
          focusable: 'false',
          'aria-hidden': 'true',
          viewBox: '0 0 24 24',
        }
        const layerButton = createLayerButtons(
          'svg',
          layerButtonSvgPath,
          'レイヤー',
          () => {
            document.querySelectorAll('.polygons-button').forEach((elem) => {
              ;(elem as HTMLElement).style.display =
                (elem as HTMLElement).style.display === 'none' ? 'block' : 'none'
            })
          },
          svgAttributes
        )
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(layerButton)
        const mapTypeControl = map.controls[google.maps.ControlPosition.RIGHT_TOP].getAt(0)
        if (mode == 'list') mapTypeControl.style.marginTop = '64px'

        setupUserGuideButton()

        // 用途地域ボタン
        const usageSvgAttributes = {
          class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
          focusable: 'false',
          'aria-hidden': 'true',
          viewBox: '0 0 25 28',
        }
        const usageSvgPath =
          'M17 11V3H7v4H3v14h8v-4h2v4h8V11h-4zM7 19H5v-2h2v2zm0-4H5v-2h2v2zm0-4H5V9h2v2zm4 4H9v-2h2v2zm0-4H9V9h2v2zm0-4H9V5h2v2zm4 8h-2v-2h2v2zm0-4h-2V9h2v2zm0-4h-2V5h2v2zm4 12h-2v-2h2v2zm0-4h-2v-2h2v2z'
        const usageButtonFunction = () => {
          const newLayerButtonStates = {
            ...layerButtonStates,
            showPolygonsClicked: !layerButtonStates.showPolygonsClicked,
          }
          handleLayerButtonStatesChange(newLayerButtonStates)

          setShowYoutoInfo && setShowYoutoInfo(newLayerButtonStates.showPolygonsClicked)
          if (!newLayerButtonStates.showPolygonsClicked) {
            usageButton.style.color = 'black'
            setShowInfoTable(false)
            map.setOptions({
              draggable: true,
              scrollwheel: true,
              disableDoubleClickZoom: false,
              zoomControl: true,
              scaleControl: true,
              panControl: true,
              minZoom: 0,
              maxZoom: 20,
            })
            if (setYoutoInfo) {
              youtoInfo = {}
              setYoutoInfo(youtoInfo)
            }
          } else {
            usageButton.style.color = 'red'
          }
        }
        const usageButton = createLayerButtons(
          'svg',
          usageSvgPath,
          '用途地域',
          usageButtonFunction,
          usageSvgAttributes
        )
        usageButton.classList.add('polygons-button')
        usageButton.style.display = 'none'
        usageButton.style.color = layerButtonStates.showPolygonsClicked ? 'red' : 'black'
        map.controls[google.maps.ControlPosition.RIGHT_TOP].push(usageButton)

        // 公図ボタン
        if (user.pricing_type === 'owner' && user.role === 'system_admin') {
          const kouzuMapSvgAttributes = {
            class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
            focusable: 'false',
            'aria-hidden': 'true',
            viewBox: '0 0 25 28',
          }
          const kouzuMapSvgPath =
            'M3 21h2v-2H3v2zm4 0h2v-2H7v2zM5 7H3v2h2V7zM3 17h2v-2H3v2zM9 3H7v2h2V3zM5 3H3v2h2V3zm12 0h-2v2h2V3zm2 6h2V7h-2v2zm0-6v2h2V3h-2zm-4 18h2v-2h-2v2zM13 3h-2v8H3v2h8v8h2v-8h8v-2h-8V3zm6 18h2v-2h-2v2zm0-4h2v-2h-2v2z'
          const kouzuMapButtonFunction = () => {
            let newLayerButtonStates = {
              ...layerButtonStates,
              showKouzuMapClicked: !layerButtonStates.showKouzuMapClicked,
            }
            handleLayerButtonStatesChange(newLayerButtonStates)

            kouzuMapButton.style.display = 'block'
            if (newLayerButtonStates.showKouzuMapClicked) {
              kouzuMapButton.style.color = 'red'
            } else {
              kouzuMapButton.style.color = 'black'
            }
          }
          const kouzuMapButton = createLayerButtons(
            'svg',
            kouzuMapSvgPath,
            '公図情報',
            kouzuMapButtonFunction,
            kouzuMapSvgAttributes
          )
          kouzuMapButton.id = 'kouzuMapButton'
          kouzuMapButton.classList.add('polygons-button')
          kouzuMapButton.style.display = 'none'
          map.controls[google.maps.ControlPosition.RIGHT_TOP].push(kouzuMapButton)
        }

        // 自社登録物件ボタン（現状システム管理者のみ閲覧可能）
        if (mode != 'list') {
          const otherPropertiesSvgAttributes = {
            class: 'MuiSvgIcon-root MuiSvgIcon-fontSizeSmall',
            focusable: 'false',
            'aria-hidden': 'true',
            viewBox: '0 0 25 28',
          }
          const otherPropertiesSvgPath =
            'M19.3 16.9c.4-.7.7-1.5.7-2.4 0-2.5-2-4.5-4.5-4.5S11 12 11 14.5s2 4.5 4.5 4.5c.9 0 1.7-.3 2.4-.7l3.2 3.2 1.4-1.4-3.2-3.2zm-3.8.1c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5 2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5zM12 20v2C6.48 22 2 17.52 2 12S6.48 2 12 2c4.84 0 8.87 3.44 9.8 8h-2.07c-.64-2.46-2.4-4.47-4.73-5.41V5c0 1.1-.9 2-2 2h-2v2c0 .55-.45 1-1 1H8v2h2v3H9l-4.79-4.79C4.08 10.79 4 11.38 4 12c0 4.41 3.59 8 8 8z'
          const otherPropertiesButtonFunction = () => {
            layerButtonStates.showOtherPropertiesClicked =
              !layerButtonStates.showOtherPropertiesClicked
            otherPropertiesButton.style.color = layerButtonStates.showOtherPropertiesClicked
              ? 'red'
              : 'black'
            if (layerButtonStates.showOtherPropertiesClicked) {
              otherPropertiesButton.style.color = 'red'
            } else {
              otherPropertiesButton.style.color = 'black'
            }
            let currentCenter = map.getCenter()
            map.panTo(
              new google.maps.LatLng(currentCenter.lat() + 0.000001, currentCenter.lng() + 0.000001)
            )
          }
          const otherPropertiesButton = createLayerButtons(
            'svg',
            otherPropertiesSvgPath,
            '自社登録物件',
            otherPropertiesButtonFunction,
            otherPropertiesSvgAttributes
          )
          if (mode != 'property_edit') {
            otherPropertiesButton.classList.add('polygons-button')
            otherPropertiesButton.style.display = 'none'
          }

          otherPropertiesButton.style.color = layerButtonStates.showOtherPropertiesClicked
            ? 'red'
            : 'black'
          map.controls[google.maps.ControlPosition.RIGHT_TOP].push(otherPropertiesButton)
        }

        // ハザードボタン系
        if (mode != 'volume') {
          let dosyaPanelDiv = document.createElement('div')
          dosyaPanelDiv.classList.add('dosya-control-panel')
          dosyaPanelDiv.title = '土砂災害パーネル'
          dosyaLayers.forEach((layer) => {
            let p = document.createElement('p')
            p.classList.add('panel-line')
            p.innerText = layer.label
            p.id = layer.name
            p.addEventListener('click', function () {
              if (overlays[layer.name] === null) {
                p.style.backgroundColor = '#eee'
                setTile(layer.name, layer.url)
              } else {
                p.style.backgroundColor = 'initial'
                removeTile(layer.name)
              }
              updateUserGuideImgs(!showUsageGuide)
            })
            p.addEventListener('mouseover', function () {
              p.style.backgroundColor = '#eee'
            })
            p.addEventListener('mouseout', function () {
              if (overlays[layer.name] === null) {
                p.style.backgroundColor = 'initial'
              }
            })
            dosyaPanelDiv.appendChild(p)
          })
          map.controls[google.maps.ControlPosition.LEFT_CENTER].push(dosyaPanelDiv)

          hazardButtonLayers.forEach((data) => {
            const button = createLayerButtons('img', data.icon, data.label, () => {
              layerButtonStates[data.buttonState] = !layerButtonStates[data.buttonState]
              if (layerButtonStates[data.buttonState]) {
                button.style.color = 'red'
                if (data.tileUrl) {
                  setTile(data.tileName, data.tileUrl)
                }
              } else {
                button.style.color = 'black'
                if (data.tileUrl) {
                  removeTile(data.tileName)
                }
              }
              if (data.buttonName === 'dosyaButton') {
                if (layerButtonStates[data.buttonState]) {
                  dosyaPanelDiv.style.display = 'block'
                } else {
                  dosyaPanelDiv.style.display = 'none'
                }
              }

              const userGuideIsHidden = !hazardButtonLayers.some(
                (layer) => layerButtonStates[layer.buttonState]
              )
              userGuideButtonHidden(userGuideIsHidden)
              updateUserGuideImgs(!showUsageGuide || userGuideIsHidden)
            })
            button.classList.add('polygons-button')
            button.style.display = 'none'
            map.controls[google.maps.ControlPosition.RIGHT_TOP].push(button)
          })
        }
      }

      function setTile(name, url) {
        if (overlays[name] === null) {
          let option = {
            tileSize: new google.maps.Size(256, 256),
            isPng: true,
            getTile: function (coord, zoom, ownerDocument) {
              let fullUrl = `${url}${zoom}/${coord.x}/${coord.y}.png`
              let img = ownerDocument.createElement('img')
              img.style.opacity = 0.8
              let tempImg = new Image()
              tempImg.onload = function () {
                img.src = tempImg.src
              }
              tempImg.src = fullUrl
              return img
            },
          }
          let currentLength = map.overlayMapTypes.getLength()
          map.overlayMapTypes.insertAt(currentLength, option)
          overlays[name] = currentLength
        }
      }

      function removeTile(name) {
        let overlayIndex = overlays[name]
        if (overlayIndex !== null) {
          map.overlayMapTypes.removeAt(overlayIndex)
          for (let key in overlays) {
            if (overlays[key] !== null && overlays[key] > overlayIndex) {
              overlays[key]--
            }
          }
          overlays[name] = null
        }
      }

      function createLoadingIcon(buttonId, iconId) {
        const button = document.getElementById(buttonId)
        if (!button) return

        let loadingIconContainer = document.createElement('span')
        loadingIconContainer.id = iconId
        loadingIconContainer.style.display = 'inline-block'
        loadingIconContainer.style.marginLeft = '5px'
        button.appendChild(loadingIconContainer)

        let loadingRoot = createRoot(loadingIconContainer)
        loadingRoot.render(<Loading height={12} width={12} />)

        return loadingRoot
      }

      function removeLoadingIcon(loadingRoot, iconId) {
        let loadingIconContainer = document.getElementById(iconId)
        if (loadingIconContainer) {
          loadingRoot.unmount()
          loadingIconContainer.parentNode.removeChild(loadingIconContainer)
        }
      }

      function convertFullwidthToHalfwidth(str) {
        return str.replace(/[\uFF10-\uFF19]/g, function (m) {
          return String.fromCharCode(m.charCodeAt(0) - 0xfee0)
        })
      }

      // 用途地域の描画
      async function renderPolygons() {
        let loadingRoot

        clearCityPlanItems()
        if (polygons_api_base_url == '' || map.getZoom() < 17) {
          clearCityPlanItems()
          setShowInfoTable(false)
          return
        }

        !layerButtonStates.showPolygonsClicked && clearCityPlanItems()

        infoMarkers.forEach((infoMarker) => {
          infoMarker.setMap(null)
        })
        chibanMarkers.forEach((chibanMarker) => {
          chibanMarker.setMap(null)
        })
        selectedInfoWindows.forEach((infoWindow) => {
          infoWindow.close()
        })
        selectedInfoWindows = []
        centerCursors.forEach((centerCursor) => {
          centerCursor.setMap(null)
        })
        centerCursor()
        setUsageInfos({
          usageArea: '取得中',
          buildingCoverageRatio: '取得中',
          floorAreaRatio: '取得中',
        }),
          setAntifireInfo('取得中'),
          setHeightInfos({
            heightInfo: '取得中',
            heightMax: '取得中',
            heightMin: '取得中',
          }),
          setShadeInfos({
            shade5m: '取得中',
            shade10m: '取得中',
            shadeHeight: '取得中',
          }),
          map.setOptions({
            draggable: false,
            scrollwheel: false,
            disableDoubleClickZoom: true,
            zoomControl: false,
            scaleControl: false,
            panControl: false,
            minZoom: map.getZoom(),
            maxZoom: map.getZoom(),
          })

        const center = map.getCenter()
        let hasUsageInfo = false
        let hasAntifireInfo = false
        let hasHeightInfo = false
        let hasHikageInfo = false

        let polygonTypes = []
        if (layerButtonStates.showPolygonsClicked) {
          polygonTypes = polygonTypes.concat(cityPlanItems)
        }

        if (layerButtonStates.showKouzuMapClicked) {
          polygonTypes = polygonTypes.concat(cadastralItems)
          loadingRoot = createLoadingIcon('kouzuMapButton', 'kouzuMapButtonLoadingIcon')
        }
        let selectedPolygons = []
        let originalColors = []
        let selectedChibanInfos = []
        try {
          await Promise.all(
            polygonTypes.map(async (area) => {
              const query2 = new URLSearchParams({
                lat: center.lat(),
                lng: center.lng(),
                polygon_type: area,
              })

              try {
                const res = await fetch(`${polygons_api_base_url}?${query2}`)

                if (!res.ok) {
                  throw new Error(`HTTP error! ${res.status} ${res.statusText}`)
                }

                const json = await res.json()

                if (Object.keys(json).indexOf('polygons') === -1) {
                  return
                }

                if (json.layer === 'cadastral' && json.polygons.length === 0) {
                  removeLoadingIcon(loadingRoot, 'kouzuMapButtonLoadingIcon')
                }

                Object.keys(polygonsHash[area]).forEach((key) => {
                  polygonsHash[area][key].setMap()
                  delete polygonsHash[area][key]
                })

                const area_colors = {
                  youto_chiiki: {
                    '1': '#00C58E', // '第一種低層住居専用地域'
                    '2': '#51E7C7', // '第二種低層住居専用地域'
                    '3': '#7ED043', // '第一種中高層住居専用地域'
                    '4': '#BCE69A', // '第二種中高層住居専用地域'
                    '5': '#FFE395', // '第一種住居地域'
                    '6': '#FFA4A7', // '第二種住居地域'
                    '7': '#FFB92F', // '準住居地域'
                    '8': '#FF8EC3', // '近隣商業地域'
                    '9': '#FF5C5F', // '商業地域'
                    '10': '#9359F5', // '準工業地域'
                    '11': '#8CBBE1', // '工業地域'
                    '12': '#7FA1D5', // '工業専用地域'
                    '0': '#000000', // '指定なし
                  },
                  bouka_chiiki: {
                    '10': '#000000',
                    '20': '#888888',
                    '0': '#000000',
                  },
                }
                json.polygons.forEach((data) => {
                  let points = data.lines.map((point) => {
                    return new google.maps.LatLng(Number(point.lat), Number(point.lng))
                  })
                  let framePoints = points
                  const innerPoints = data.innerlines.map((path) => {
                    return path.map((point) => {
                      return new google.maps.LatLng(Number(point.lat), Number(point.lng))
                    })
                  })
                  if (innerPoints.length > 0) {
                    innerPoints.unshift(points)
                    points = innerPoints
                  }

                  let option = {
                    paths: points,
                    strokeColor: '#000000',
                    strokeOpacity: 0,
                    strokeWeight: 0,
                    fillColor: '#000000',
                    fillOpacity: 0,
                  }

                  if (area == 'youto_chiiki' || area == 'bouka_chiiki') {
                    option.strokeColor = area_colors[area][`${data.types[area]}`]
                    option.fillColor = area_colors[area][`${data.types[area]}`]

                    if (data.types[area] === 0) {
                      option.fillOpacity = 0
                      option.strokeOpacity = 0
                    } else {
                      option.strokeOpacity = area == 'youto_chiiki' ? 0.1 : 0
                      option.strokeWeight = area == 'youto_chiiki' ? 0.5 : 0
                      option.fillOpacity = area == 'youto_chiiki' ? 0.3 : 0.1
                    }
                  } else if (area == 'koudo_chiku') {
                    option.strokeColor = '#0066F9'
                    option.strokeOpacity = data.types.koudo_chiku == 0 ? 0 : 0.5
                    option.strokeWeight = 1
                  } else if (area == 'hikage_kisei') {
                    option.strokeOpacity = 0.15
                    option.strokeWeight = 2
                  } else if (area == 'kouzu_map') {
                    option.strokeColor = '#125690'
                    option.strokeOpacity = 0.3
                    option.strokeWeight = 1
                    option.fillColor = '#e1f0fc'
                    option.fillOpacity = 0.1
                  }
                  const polygon = new google.maps.Polygon(option)

                  let minLatLng = map.getBounds().getSouthWest()
                  let maxLatLng = map.getBounds().getNorthEast()
                  let pad_lat_range = (Number(maxLatLng.lat()) - Number(minLatLng.lat())) * 0.1
                  let pad_lng_range = (Number(maxLatLng.lng()) - Number(minLatLng.lng())) * 0.1

                  const processPolygons = (displayMarkerFunc) => {
                    const targetPoints = []
                    framePoints.forEach((point) => {
                      if (
                        Number(point.lat()) > Number(minLatLng.lat()) &&
                        Number(point.lat()) < Number(maxLatLng.lat()) &&
                        Number(point.lng()) > Number(minLatLng.lng()) &&
                        Number(point.lng()) < Number(maxLatLng.lng())
                      ) {
                        targetPoints.push(point)
                      }
                    })
                    // 地図枠内にパスがあるポリゴンのみ表示
                    if (targetPoints.length > 0) {
                      let bounds = new google.maps.LatLngBounds()
                      targetPoints.forEach((point) => {
                        bounds.extend(point)
                      })
                      let center = bounds.getCenter()
                      // ポリゴンの中心が地図の外側に出ている場合、地図の内側に収まるように調整
                      if (center.lat() < minLatLng.lat()) {
                        center = new google.maps.LatLng(
                          minLatLng.lat() + pad_lat_range,
                          center.lng()
                        )
                      }
                      if (center.lat() > maxLatLng.lat()) {
                        center = new google.maps.LatLng(
                          maxLatLng.lat() - pad_lat_range * 2,
                          center.lng()
                        )
                      }
                      if (center.lng() < minLatLng.lng()) {
                        center = new google.maps.LatLng(
                          center.lat(),
                          minLatLng.lng() + pad_lng_range
                        )
                      }
                      if (center.lng() > maxLatLng.lng()) {
                        center = new google.maps.LatLng(
                          center.lat(),
                          maxLatLng.lng() - pad_lng_range * 2
                        )
                      }
                      // ポリゴンの中心点がポリゴンの外側にある場合（L状のようなポリゴンとか）、ポリゴンの内側に収まるように調整
                      if (google.maps.geometry.poly.containsLocation(center, polygon)) {
                        displayMarkerFunc(data, center)
                      } else {
                        pad_lat_range = pad_lat_range / 2
                        pad_lng_range = pad_lng_range / 2
                        let minLat = minLatLng.lat() + pad_lat_range
                        let minLng = minLatLng.lng() + pad_lng_range
                        lng = Number(minLng)
                        lat = Number(minLat)
                        while (!google.maps.geometry.poly.containsLocation(center, polygon)) {
                          if (center.lng() > map.getBounds().getNorthEast().lng()) {
                            lat = lat + Number(pad_lat_range)
                            lng = map.getBounds().getSouthWest().lng() + Number(pad_lng_range)
                          }
                          center = new google.maps.LatLng(Number(lat), Number(lng))
                          if (
                            center.lat() > map.getBounds().getNorthEast().lat() &&
                            center.lng() > map.getBounds().getNorthEast().lng()
                          ) {
                            break
                          }
                          lng = Number(lng) + Number(pad_lng_range)
                        }
                        displayMarkerFunc(data, center)
                      }
                    }
                  }

                  switch (area) {
                    case 'youto_chiiki':
                      // 0番用途地域でない場合、ラベルを貼る
                      if (data.types.youto_chiiki != 0) {
                        processPolygons(displayInfoMarker)
                      }
                      if (google.maps.geometry.poly.containsLocation(map.getCenter(), polygon)) {
                        getUsageInfo(data, setYoutoInfo)
                        hasUsageInfo = true
                      }
                      break

                    case 'bouka_chiiki':
                      if (google.maps.geometry.poly.containsLocation(map.getCenter(), polygon)) {
                        getAntifireInfo(data, setYoutoInfo)
                        hasAntifireInfo = true
                      }
                      break

                    case 'koudo_chiku':
                      if (google.maps.geometry.poly.containsLocation(map.getCenter(), polygon)) {
                        getHeightInfo(data, setYoutoInfo)
                        hasHeightInfo = true
                      }
                      break

                    case 'hikage_kisei':
                      if (google.maps.geometry.poly.containsLocation(map.getCenter(), polygon)) {
                        getHikageInfo(data, setYoutoInfo)
                        hasHikageInfo = true
                      }
                      break

                    case 'kouzu_map':
                      if (map.getZoom() == 20) {
                        processPolygons(displayChibanMarker)
                        google.maps.event.addListener(polygon, 'click', function (mouseEvent) {
                          let index = selectedPolygons.indexOf(this)
                          if (index !== -1) {
                            this.setOptions({ fillColor: originalColors[index] })
                            selectedPolygons.splice(index, 1)
                            originalColors.splice(index, 1)
                            selectedChibanInfos.splice(index, 1)
                            if (setChibanInfo) {
                              setChibanInfo(selectedChibanInfos.join(', '))
                            }
                            if (selectedInfoWindows[index]) {
                              selectedInfoWindows[index].close()
                              selectedInfoWindows.splice(index, 1)
                            }
                          } else {
                            originalColors.push(this.get('fillColor'))
                            this.setOptions({ fillColor: '#FF0000' })
                            selectedPolygons.push(this)
                            const chomeMatch = data.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
                            const chomeNumber = chomeMatch
                              ? `${convertFullwidthToHalfwidth(chomeMatch[0])}-`
                              : ''
                            const chibanInfo = `${chomeNumber}${data.types['地番']}`
                            selectedChibanInfos.push(chibanInfo)
                            if (setChibanInfo) {
                              setChibanInfo(selectedChibanInfos.join(', '))
                            }
                            const infoWindow = new google.maps.InfoWindow({
                              content: `地番：${chibanInfo}`,
                              position: mouseEvent.latLng,
                            })
                            infoWindow.open(map)
                            selectedInfoWindows.push(infoWindow)
                          }
                        })
                      } else {
                        chibanMarkers.forEach((chibanMarker) => {
                          chibanMarker.setMap(null)
                        })
                      }
                      break

                    default:
                      console.log('Unknown area: ', area)
                  }

                  polygon.setMap(map)
                  polygonsHash[area][data.id] = polygon
                })
              } catch (err) {
                console.error(`Failed to fetch polygon for area ${area}: ${err}`)
              } finally {
                removeLoadingIcon(loadingRoot, 'kouzuMapButtonLoadingIcon')
                if (area == 'youto_chiiki' && !hasUsageInfo) {
                  setUsageInfos({
                    usageArea: 'N/A',
                    buildingCoverageRatio: 'N/A',
                    floorAreaRatio: 'N/A',
                  })
                } else if (area == 'bouka_chiiki' && !hasAntifireInfo) {
                  isTokyoArea().then((result) => {
                    if (result === '東京都内') {
                      setAntifireInfo('-')
                    } else {
                      setAntifireInfo('N/A')
                    }
                  })
                } else if (area == 'koudo_chiku' && !hasHeightInfo) {
                  isTokyoArea().then((result) => {
                    if (result === '東京都内') {
                      setHeightInfos({
                        heightInfo: '-',
                        heightMax: '-',
                        heightMin: '-',
                      })
                    } else {
                      setHeightInfos({
                        heightInfo: 'N/A',
                        heightMax: 'N/A',
                        heightMin: 'N/A',
                      })
                    }
                  })
                } else if (area == 'hikage_kisei' && !hasHikageInfo) {
                  setShadeInfos({
                    shade5m: 'N/A',
                    shade10m: 'N/A',
                    shadeHeight: 'N/A',
                  })
                }
                map.setOptions({
                  draggable: true,
                  scrollwheel: true,
                  disableDoubleClickZoom: false,
                  zoomControl: true,
                  scaleControl: true,
                  panControl: true,
                  minZoom: 0,
                  maxZoom: 20,
                })
              }
            })
          )
        } catch (error) {
          console.error(`Failed to complete all polygon fetch requests: ${error}`)
        }
      }

      async function isTokyoArea() {
        const center = map.getCenter()
        const latlng = new google.maps.LatLng(center.lat(), center.lng())
        const geocoder = new google.maps.Geocoder()

        try {
          const results = await new Promise((resolve, reject) => {
            geocoder.geocode({ location: latlng, language: 'ja' }, (results, status) => {
              if (status === 'OK') {
                resolve(results)
              } else {
                reject(new Error('Geocoder failed: ' + status))
              }
            })
          })

          const addressComponents = results[0]?.address_components
          const prefecture = addressComponents?.find((c) =>
            c.types.includes('administrative_area_level_1')
          )?.long_name

          if (prefecture === '東京都') {
            return '東京都内'
          } else {
            return '都外'
          }
        } catch (error) {
          console.error(error.message)
          return 'エラーが発生しました'
        }
      }

      function displayLayerMarker(
        zoomLevel,
        markerSize,
        fontSize,
        contentFunc,
        markerArray,
        data,
        center,
        iconURL
      ) {
        if (map.getZoom() >= zoomLevel) {
          const contentString = contentFunc(data)
          if (typeof contentString === 'undefined') {
            return
          }
          let marker = new google.maps.Marker({
            position: center,
            map: map,
            icon: {
              url: iconURL,
              scaledSize: new google.maps.Size(markerSize[0], markerSize[1]),
            },
            label: {
              text: contentString,
              color: '#125690',
              fontSize: fontSize,
              fontWeight: 'bold',
            },
            clickable: false,
          })
          markerArray.push(marker)
        }
      }

      function displayInfoMarker(data, center) {
        displayLayerMarker(
          17,
          [130, 60],
          '12px',
          labelContent,
          infoMarkers,
          data,
          center,
          '/info_label.png'
        )
      }

      function displayChibanMarker(data, center) {
        displayLayerMarker(
          20,
          [40, 40],
          '11px',
          (d) => {
            const chomeMatch = d.types['丁目名']?.match(/[\uFF10-\uFF19]+/)
            const chomeNumber = chomeMatch ? `${convertFullwidthToHalfwidth(chomeMatch[0])}-` : ''
            return `${chomeNumber}${d.types['地番']}`
          },
          chibanMarkers,
          data,
          center,
          '/info_label2.png'
        )
      }

      function labelContent(data: any) {
        const acronym = {
          '1': '1低',
          '2': '2低',
          '3': '1中',
          '4': '2中',
          '5': '1住',
          '6': '2住',
          '7': '準住',
          '8': '近商',
          '9': '商業',
          '10': '準工',
          '11': '工業',
          '12': '工専',
          '0': '-',
        }
        let content = `${acronym[data.types.youto_chiiki]}:${data.types.building_coverage_ratio}:${
          data.types.floor_area_ratio
        }`
        return content
      }

      function centerCursor() {
        let centerCursor = new google.maps.Marker({
          position: map.getCenter(),
          map: map,
          icon: {
            url: '/center_cursor.png',
            scaledSize: new google.maps.Size(35, 35),
          },
        })
        centerCursors.push(centerCursor)
      }

      function getUsageInfo(data, setYoutoInfo) {
        if (data.types.youto_chiiki == 0) {
          const content = {
            usageArea: 'N/A',
            buildingCoverageRatio: 'N/A',
            floorAreaRatio: 'N/A',
          }
          setUsageInfos(content)

          if (setYoutoInfo) {
            youtoInfo['usage'] = content
            setYoutoInfo(youtoInfo)
          }
        } else {
          const content = {
            usageArea: usageAreaCode[data.types.youto_chiiki],
            buildingCoverageRatio: `${data.types.building_coverage_ratio}%`,
            floorAreaRatio: `${data.types.floor_area_ratio}%`,
          }
          setUsageInfos(content)

          if (setYoutoInfo) {
            youtoInfo['usage'] = content
            setYoutoInfo(youtoInfo)
          }
        }
      }

      function getAntifireInfo(data, setYoutoInfo) {
        const acronym = { '10': '防火地域', '20': '準防火地域', '0': '指定なし' }
        let content = acronym[data.types.bouka_chiiki]
        setAntifireInfo(content)

        if (setYoutoInfo) {
          youtoInfo['antifire'] = content
          setYoutoInfo(youtoInfo)
        }
      }

      function getHeightInfo(data, setYoutoInfo) {
        if (data.types.koudo_chiku == 0) {
          const content = {
            heightInfo: '指定なし',
            heightMax: '指定なし',
            heightMin: '指定なし',
          }
          setHeightInfos(content)

          if (setYoutoInfo) {
            youtoInfo['height'] = content
            setYoutoInfo(youtoInfo)
          }
        } else if (data.types.koudo_chiku == 4) {
          const content = {
            heightInfo: '指定なし',
            heightMax: data.types.max_height == 0 ? '指定なし' : `${data.types.max_height}m`,
            heightMin: data.types.min_height == 0 ? '指定なし' : `${data.types.min_height}m`,
          }
          setHeightInfos(content)

          if (setYoutoInfo) {
            youtoInfo['height'] = content
            setYoutoInfo(youtoInfo)
          }
        } else {
          const content = {
            heightInfo: `第${data.types.koudo_chiku}種高度地区`,
            heightMax: data.types.max_height == 0 ? '指定なし' : `${data.types.max_height}m`,
            heightMin: data.types.min_height == 0 ? '指定なし' : `${data.types.min_height}m`,
          }
          setHeightInfos(content)

          if (setYoutoInfo) {
            youtoInfo['height'] = content
            setYoutoInfo(youtoInfo)
          }
        }
      }

      function getHikageInfo(data, setYoutoInfo) {
        if (data.types.hikage == 'I') {
          const content = {
            shade5m: '指定なし',
            shade10m: '指定なし',
            shadeHeight: '指定なし',
          }
          setShadeInfos(content)

          if (setYoutoInfo) {
            youtoInfo['shade'] = content
            setYoutoInfo(youtoInfo)
          }
        } else {
          const content = {
            shade5m: data.types.shade_5 == 0 ? '指定なし' : `${data.types.shade_5}時間`,
            shade10m: data.types.shade_10 == 0 ? '指定なし' : `${data.types.shade_10}時間`,
            shadeHeight: data.types.shade_height == 0 ? '指定なし' : `${data.types.shade_height}m`,
          }
          setShadeInfos(content)

          if (setYoutoInfo) {
            youtoInfo['shade'] = content
            setYoutoInfo(youtoInfo)
          }
        }
      }

      // ボリュームチェックパラメーター入力用DOMの生成
      let initialLoad = true
      async function createParameters(polygon = null) {
        if (mode != 'volume') return
        const refVolume = new URLSearchParams(window.location.search).has('ref')

        if (polygon) {
          const res = await fetch(`/properties/${property_id}/volume_check_requests/property_json`)
          const property = await res.json()
          if (onChangeParameters) {
            onChangeParameters(property)
          }

          // DOMの描画完了を待つ
          await new Promise((resolve) => setTimeout(resolve))

          let params = JSON.parse(
            (document.getElementById('volume_check_request_parameters') as HTMLInputElement)
              .value || '{ "borders": [], "surroundings": [], "building_setting": {} }'
          )

          if (refVolume && initialLoad) {
            params = JSON.parse(ref_volume_check_parameters)
            initialLoad = false
          }

          const path = []
          polygon.getPath().forEach((latlng) => {
            path.push(latlng)
          })
          let next_i = 0
          for (let i = 0; i < path.length; i++) {
            if (i == path.length - 1) {
              next_i = 0
            } else {
              next_i = i + 1
            }
            const distance = google.maps.geometry.spherical.computeDistanceBetween(
              path[i],
              path[next_i]
            )
              ; (document.getElementById(`length_${i}`) as HTMLInputElement).value = `${Math.round(distance * 100) / 100
                }m`
              ; (document.getElementById(`border_${i}`) as HTMLInputElement).value =
                (i in params.borders && params.borders[i].border_type_code) || '4'
              ; (document.getElementById(`width_${i}`) as HTMLInputElement).value =
                (i in params.borders && params.borders[i].width) || ''
              ; (document.getElementById(`setback_${i}`) as HTMLInputElement).value =
                (i in params.borders && params.borders[i].set_back_method) || '0'
              ; (document.getElementById(`height_${i}`) as HTMLInputElement).value =
                (i in params.borders && params.borders[i].from.height) || '0'
              ; (document.getElementById(`surroundings_${i}`) as HTMLInputElement).value =
                (i in params.surroundings && params.surroundings[i].border_type_code) || '4'
              ; (document.getElementById(`surroundings_${i}_width`) as HTMLInputElement).value =
                (i in params.surroundings && params.surroundings[i].width) || ''
          }
        } else {
          if (onChangeParameters) {
            onChangeParameters(null)
          }
          ;(document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
            ''
        }
        parametersChanged()
      }

      // パラメーターが変更されたときのイベントハンドラ
      function parametersChanged() {
        if (mode != 'volume') {
          return
        }

        const params = {
          prefecture_name: (document.getElementById('prefecture_name') as HTMLInputElement).value,
          city_name: (document.getElementById('city_name') as HTMLInputElement).value,
          chome: (document.getElementById('chome') as HTMLInputElement).value,
          administrative_name: (document.getElementById('administrative_name') as HTMLInputElement)
            .value,
          center: {
            lat: Number((document.getElementById('center_lat') as HTMLInputElement).value),
            lng: Number((document.getElementById('center_lng') as HTMLInputElement).value),
          },
          borders: [],
          surroundings: [],
          building_setting: {
            max_height: 0,
            floors: [],
            floor_height: 0,
            gf_slab_level: 0,
            design_ground_level: 0,
          },
        }

        let next_i = 0
        const count_borders = parseInt(
          (document.getElementById('count_borders') as HTMLInputElement).value,
          10
        )
        for (let i = 0; i < count_borders; i++) {
          if (i == count_borders - 1) {
            next_i = 0
          } else {
            next_i = i + 1
          }
          const from = (document.getElementById(`from_${i}`) as HTMLInputElement).value.split(',')
          const to = (document.getElementById(`to_${next_i}`) as HTMLInputElement).value.split(',')
          const border_type_code = (document.getElementById(`border_${i}`) as HTMLInputElement)
            .value
          let set_back_method = (document.getElementById(`setback_${i}`) as HTMLInputElement).value
          let width = (document.getElementById(`width_${i}`) as HTMLInputElement).value
          const height = (document.getElementById(`height_${i}`) as HTMLInputElement).value
          const surroundings_type = (
            document.getElementById(`surroundings_${i}`) as HTMLInputElement
          ).value
          const surroundings_width = (
            document.getElementById(`surroundings_${i}_width`) as HTMLInputElement
          ).value
          if (['1', '2', '3', '5', '6', '7'].indexOf(border_type_code) !== -1) {
            if (Number(width) === 0) {
              width = '6'
            }
          } else {
            width = '0'
            set_back_method = '0'
          }
          params.borders.push({
            id: i + 1,
            from: { lat: Number(from[0]), lng: Number(from[1]), height: Number(height) },
            to: { lat: Number(to[0]), lng: Number(to[1]) },
            border_type_code: Number(border_type_code),
            set_back_method: Number(set_back_method),
            width: Number(width),
          })
          params.surroundings.push({
            border_id: i + 1,
            border_type_code: Number(surroundings_type),
            width: Number(surroundings_width),
          })
          document.getElementById(`width_${i}_collapse`).classList.remove('show')
          if (['1', '2', '3', '5', '6', '7'].indexOf(border_type_code) !== -1) {
            document.getElementById(`width_${i}_collapse`).classList.add('show')
          }
          if (['1', '2', '3', '5', '6', '7'].indexOf(surroundings_type) !== -1) {
            document.getElementById(`surroundings_width_${i}_collapse`).classList.add('show')
          }
          document.getElementById(`setback_${i}_collapse`).classList.remove('show')
          if (['1', '2', '3'].indexOf(border_type_code) !== -1) {
            document.getElementById(`setback_${i}_collapse`).classList.add('show')
          }
          document.getElementById(`surroundings_${i}_collapse`).classList.remove('show')
          document.getElementById(`surroundings_${i}_border`).classList.remove('border')
          if ((document.getElementById(`surroundings_${i}_check`) as HTMLInputElement).checked) {
            document.getElementById(`surroundings_${i}_collapse`).classList.add('show')
            document.getElementById(`surroundings_${i}_border`).classList.add('border')
          }
        }
        params.building_setting = {
          max_height: Number((document.getElementById(`max_height`) as HTMLInputElement).value),
          floors: [],
          floor_height: Number(
            (document.getElementById(`max_floor_height`) as HTMLInputElement).value
          ),
          gf_slab_level: Number(
            (document.getElementById(`gf_slab_level`) as HTMLInputElement).value
          ),
          design_ground_level: Number(
            (document.getElementById(`design_ground_level`) as HTMLInputElement).value
          ),
        }
        let floors_above_ground = Number(
          (document.getElementById(`floors_above_ground`) as HTMLInputElement).value
        )
        if (floors_above_ground === 0) {
          floors_above_ground = 5
        }
        const floors_under_ground = Number(
          (document.getElementById(`floors_under_ground`) as HTMLInputElement).value
        )
        let max_floor_height = Number(
          (document.getElementById(`max_floor_height`) as HTMLInputElement).value
        )
        if (max_floor_height === 0) {
          max_floor_height = 3
        }
        for (let i = 0; i < floors_above_ground; i++) {
          params.building_setting.floors.push({
            floor_number: i + 1,
            max_floor_height: max_floor_height,
          })
        }
        for (let i = 0; i < floors_under_ground; i++) {
          params.building_setting.floors.push({
            floor_number: i - 1,
            max_floor_height: max_floor_height,
          })
        }
        ;(document.getElementById('volume_check_request_parameters') as HTMLInputElement).value =
          JSON.stringify(params)
      }

      // polygonからshapeを取り出す
      function getShape(polygon) {
        const path = []

        polygon.getPath().forEach((latlng) => {
          path.push({ lat: latlng.lat(), lng: latlng.lng() })
        })
        return { shape: path }
      }

      // markerの追加
      function addMarker(
        property: {
          id: number
          lat: number
          lng: number
          property_type?: string
        },
        map,
        tag_id: number = undefined,
        tag_color: number = undefined
      ) {
        const marker_params = {
          position: new google.maps.LatLng(Number(property.lat), Number(property.lng)),
          map: map,
          optimized: false,
        }

        if (mode == 'property_edit') {
          marker_params['draggable'] = property_id === property.id
        } else if (mode == 'volume') {
          marker_params['draggable'] = true
        }
        const marker = new google.maps.Marker(marker_params)

        if (mode == 'list') {
          const icon = {
            url: tag_id ? tagIcons[tag_color] : '/flag_icon/no_tag.png',
            scaledSize: new google.maps.Size(tag_id ? 20 : 10, 20),
          }
          marker.setIcon(icon)
        } else {
          let icon
          if (property_id == property.id) {
            icon = {
              url: '/target_ping.png',
              scaledSize: new google.maps.Size(37, 30),
            }
            marker.setOptions({ zIndex: 99999 })
          } else if (layerButtonStates.showOtherPropertiesClicked) {
            icon = {
              url: tag_id ? tagIcons[tag_color] : '/flag_icon/no_tag.png',
              scaledSize: new google.maps.Size(tag_id ? 20 : 10, 20),
            }
          } else {
            marker.setVisible(false)
          }
          marker.setIcon(icon)
        }

        if (mode == 'property_edit' || mode == 'volume') {
          marker.addListener('dragend', () => {
            const lat = marker.getPosition().lat()
            const lng = marker.getPosition().lng()
            fetch('/api/property_latlng_update', {
              method: 'PATCH',
              body: JSON.stringify({
                id: property.id,
                lat: lat,
                lng: lng,
              }),
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken,
              },
            }).catch((err) => {
              console.log(err)
            })
          })
        }
        // markerにクリックイベント処理追加
        // リスト表示の情報をコピーしてモーダルに表示
        if (
          mode === 'list' ||
          ((mode === 'property' || mode === 'market_datum_report') && property_id != property.id)
        ) {
          marker.addListener('click', async () => {
            const columns = [
              'ongoing_proposal_id',
              'tag_name',
              'tag_color',
              'tag_name_color',
              'name',
              'prefecture',
              'city',
              'town',
              'chome',
              'chiban',
              'map_information',
              'property_type',
              'area_m3',
              'effective_area',
              'occupied_area',
              'location_of_division',
              'total_floor_area',
              'purchace_applied_at',
              'purchace_contract_at',
              'suggested_unit_price',
              'suggested_primary_unit_price',
              'purchase_unit_price',
              'purchase_primary_unit_price',
              'business_type_name',
              'coverage_ratio',
              'volume_rate',
              'selling_unit_price',
              'selling_primary_unit_price',
            ]
            const markerProperty = { ...property, marker }
            let params: any = {}
            if (markerProperty.id === 0) {
              const geocoder = new google.maps.Geocoder()
              const results = await geocoder.geocode({
                location: new google.maps.LatLng(
                  Number(markerProperty.lat),
                  Number(markerProperty.lng)
                ),
                language: 'ja',
              })
              // a. 東京都港区北青山2-5-8の場合
              // b. 愛知県名古屋市南区宝生町1-43-14の場合
              // c. 丹後公園（愛知県名古屋市）の場合
              const address_components = results.results[0].address_components
              const address = address_components
                .map((c) => {
                  return c.long_name
                })
                .reverse()
              const prefecture = address_components.find((c) =>
                c.types.includes('administrative_area_level_1')
              )?.long_name
              // a. 港区 (locality)
              // b. 名古屋市南区 (locality + sublocality_level_1)
              // c. 名古屋市 (locality)
              const city = address_components
                .filter(
                  (c) => c.types.includes('locality') || c.types.includes('sublocality_level_1')
                )
                .map((c) => c.long_name)
                .reverse()
                .join('')
              // a. 北青山（sublocality_level_2）
              // b. 宝生町（sublocality_level_2）
              // c. なし
              const town = address_components.find((c) =>
                c.types.includes('sublocality_level_2')
              )?.long_name
              // a. 2-5-8 (sublocality_level_3 + sublocality_level_4 + premise)
              // b. 1-43-14 (sublocality_level_3 + premiseの1つ目)
              // c. なし
              let premise = true
              let chome = address_components
                .reverse()
                .filter((c) => {
                  if (
                    c.types.includes('sublocality_level_3') ||
                    c.types.includes('sublocality_level_4') ||
                    (premise && c.types.includes('premise'))
                  ) {
                    // premiseは最初の1つだけ
                    if (c.types.includes('premise')) {
                      premise = false
                    }
                    return true
                  } else {
                    return false
                  }
                })
                .map((c) => c.long_name)
                .join('-')
                .replace(/[^0-9０-９]+/g, '-')
                .replace(/-$/, '')
              chome = chome.replace(/[０-９]/g, function (c) {
                return String.fromCharCode(c.charCodeAt(0) - 0xfee0)
              })
              params = {
                prefecture,
                city,
                town,
                chome,
                map_information: address[8] || '',
              }
              Object.assign(markerProperty, params)
            } else {
              columns.forEach((column) => {
                let text = markerProperty[column]

                if (text && column.includes('_unit_price')) {
                  text = `${Number(text).toLocaleString()}円`
                  markerProperty[column] = text
                }
              })
            }
            const address_params = Object.keys(params)
              .map((p) => {
                return `${p}=${params[p]}`
              })
              .join('&')
            const url =
              markerProperty.id === 0
                ? `/properties/new?${address_params}&lat=${markerProperty.lat}&lng=${markerProperty.lng}&drop_pin=true`
                : `/properties/${markerProperty.hashid}`
            const label = markerProperty.id === 0 ? '新規作成' : '詳細'
            markerProperty['url'] = url
            markerProperty['text'] = label
            setModalProperty(markerProperty)
            setIsModalOpen(true)
          })
        }

        return marker
      }

      function clearProperty() {
        if (currentPolygon) {
          currentPolygon.setMap(null)
          currentPolygon = null
        }
        if (areaText) {
          areaText.setMap(null)
          areaText = null
        }
        showAreaMarkers()
        if (newProperty) {
          newProperty.setMap(null)
          newProperty = null
        }
        if (onChangeShape) {
          onChangeShape('')
        }
        if (onChangeLatLng) {
          onChangeLatLng({ lat: '', lng: '' })
        }
      }
    }

    function putNewProperty(position, map) {
      newProperty && newProperty.setMap(null)
      const icon = {
        url: '/target_ping.png',
        scaledSize: new google.maps.Size(37, 30),
      }
      newProperty = new google.maps.Marker({
        position: position,
        map: map,
        optimized: false,
        draggable: true,
        icon,
        zIndex: 99999,
      })
      newProperty.setOptions({ zIndex: 99999 })
      newProperty.setIcon(icon)
    }

    const containerStyle = React.useMemo(() => {
      return (
        style ?? {
          width: '100%',
          height: '600px',
        }
      )
    }, [style])

    React.useEffect(() => {
      const element = document.getElementById('showInfoTable')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, [showInfoTable])

    React.useEffect(() => {
      const interval = setInterval(() => {
        const pinElement = document.querySelector<HTMLButtonElement>("button[title='描画をやめる']")
        if (pinElement) {
          pinElement.ariaLabel = 'ピンで示す'
          pinElement.title = 'ピンで示す'
        }

        const polygonElement =
          document.querySelector<HTMLButtonElement>("button[title='図形を描画']")
        if (polygonElement) {
          polygonElement.ariaLabel = '敷地を描画'
          polygonElement.title = '敷地を描画'
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }, [])

    const [siteArea, setSiteArea] = React.useState('')

    React.useEffect(() => {
      setSiteArea(existdAreaValue)
    }, [existdAreaValue])

    React.useEffect(() => {
      const isTargetMode =
        mode === 'property_edit' ||
        mode === 'property' ||
        mode === 'volume' ||
        mode === 'volume_detail' ||
        mode === 'market_datum_report'

      if (!isTargetMode) return
      const hasAreaText = areaTextFromResponse !== '' || siteArea !== ''
      const hasAreaShape = areaShape !== null

      if (hasAreaText && hasAreaShape) {
        const areaValue = areaTextFromResponse || siteArea
        if (showAreaTextRef.current) {
          showAreaTextRef.current(areaShape, areaValue)
        }
      }
    }, [
      areaTextFromResponse,
      requestUUID,
      siteArea,
      mode === 'volume_detail' ? areaShape : undefined,
    ])

    const redrawMarkers = () => {
      const marketData = marketDataMarkers.map((marker) => marker.marketDatum)
      marketDataMarkers.forEach((marker) => {
        marker.setMap(null)
      })
      // Mapに周辺事例のピンを表示する
      const markers = marketDataReportSetup(mapRef?.current, marketData)
      // タブ切替時に表示していたピンのクリアを行うため保持しておく
      setMarketDataMarkers(markers)
    }

    if (mode === 'market_datum_report') {
      React.useEffect(() => {
        if (!mapRef.current) {
          return
        }

        if (reportDragListener) {
          google.maps.event.removeListener(reportDragListener)
        }

        if (reportZoomListener) {
          google.maps.event.removeListener(reportZoomListener)
        }

        // マップがドラッグされたときに再描画する
        const listenerDrag = google.maps.event.addListener(mapRef.current, 'dragend', redrawMarkers)

        // マップがズームされたときに再描画する
        const listenerZoom = google.maps.event.addListener(
          mapRef.current,
          'zoom_changed',
          redrawMarkers
        )

        setReportDragListener(listenerDrag)
        setReportZoomListener(listenerZoom)
      }, [mapRef.current, marketDataMarkers])
    }

    function reloadMarker() {
      markerRefresh = true
      google.maps.event.trigger(mapRef.current, 'idle')
    }

    return (
      <>
        <GoogleMap mapContainerStyle={containerStyle} options={mapOptions} onLoad={onLoad} />
        {(mode === 'property' || mode === 'list' || mode === 'market_datum_report') && (
          <MapModal
            open={isModalOpen}
            property={modalProperty}
            current_user={current_user}
            closeModal={(removeMarker: boolean = false, reload: boolean = false) => {
              // マーカーを消去
              if (removeMarker) {
                modalProperty.marker.setMap(null)
              }

              if (reload) {
                reloadMarker()
              }
              setIsModalOpen(false)
            }}
          />
        )}
        {showInfoTable && (
          <>
            <Table className="border-t border-b border-[#3885B0]" tag="div">
              <div id="showInfoTable">
                <Row label={<Th left={true}>用途地域</Th>}>
                  <Td>{usageInfos.usageArea == '取得中' ? loading : usageInfos.usageArea}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        建ぺい率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.buildingCoverageRatio == '取得中'
                        ? loading
                        : usageInfos.buildingCoverageRatio}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        容積率
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {usageInfos.floorAreaRatio == '取得中' ? loading : usageInfos.floorAreaRatio}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>防火地域</Th>}>
                  <Td>{antifireInfo == '取得中' ? loading : antifireInfo}</Td>
                </Row>
                <Row label={<Th left={true}>高度地区</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : heightInfos.heightInfo}</Td>
                </Row>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最高高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : heightInfos.heightMax}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        最低高度
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : heightInfos.heightMin}
                    </Td>
                  </Row>
                </div>
                <div className="flex flex-wrap">
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲5M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMax == '取得中' ? loading : shadeInfos.shade5m}
                    </Td>
                  </Row>
                  <Row
                    className="w-full md:w-1/2"
                    label={
                      <Th left={true} column={2}>
                        日影範囲10M超
                      </Th>
                    }
                  >
                    <Td column={2}>
                      {heightInfos.heightMin == '取得中' ? loading : shadeInfos.shade10m}
                    </Td>
                  </Row>
                </div>
                <Row label={<Th left={true}>日影測定水平面</Th>}>
                  <Td>{heightInfos.heightInfo == '取得中' ? loading : shadeInfos.shadeHeight}</Td>
                </Row>
              </div>
            </Table>
            <div className="block text-xs px-4 pt-1 pb-2 whitespace-normal text-primary font-small">
              ※ 情報データが提供されていない地域の場合は、表内に"N/A"と表示されます。
            </div>
          </>
        )}
      </>
    )
  }
)
