// 検索用と登録用のオプションが2つずつ存在するため、合わせるべき
export const statusOptions = [
  { value: 'open', text: '募集中' },
  { value: 'close', text: '成約' },
]

export const orientationOptions = [
  { value: 'unknown', text: '不明' },
  { value: 'north', text: '北' },
  { value: 'northeast', text: '北東' },
  { value: 'east', text: '東' },
  { value: 'southeast', text: '南東' },
  { value: 'south', text: '南' },
  { value: 'southwest', text: '南西' },
  { value: 'west', text: '西' },
  { value: 'northwest', text: '北西' },
]

export const orientationOptionsForSearch = [
  { value: 0, text: '不明' },
  { value: 1, text: '北' },
  { value: 2, text: '北東' },
  { value: 3, text: '東' },
  { value: 4, text: '南東' },
  { value: 5, text: '南' },
  { value: 6, text: '南西' },
  { value: 7, text: '西' },
  { value: 8, text: '北西' },
]

export const radioOptions = [
  { value: '有り', text: '有り' },
  { value: '無し', text: '無し' },
  { value: '不明', text: '不明' },
]
export const extraOptionsForSearch = [
  { value: 1, text: '有り' },
  { value: 2, text: '無し' },
  { value: 3, text: '不明' },
]

export const buildingTypeRentOptions = [
  { value: 'mansion', text: 'マンション' },
  { value: 'apartment', text: 'アパート' },
  { value: 'shop', text: '店舗' },
  { value: 'workspace', text: '事務所' },
  { value: 'warehouse', text: '倉庫' },
  { value: 'parking', text: '駐車場' },
  { value: 'others_type', text: 'その他' },
]
export const buildingTypeRentOptionsForSearch = [
  {value:1, text: 'マンション' },
  {value:2, text: 'アパート' },
  {value:3, text: '店舗' },
  {value:4, text: '事務所' },
  {value:5, text: '倉庫' },
  {value:8, text: '駐車場' },
  {value:99, text: 'その他' },
]
export const buildingTypeSaleOptions = [
  { value: 'land', text: '土地' },
  { value: 'detached_house', text: '戸建' },
  { value: 'mansion', text: 'マンション' },
  { value: 'apartment', text: 'アパート' },
  { value: 'building', text: 'ビル' },
  { value: 'hotel', text: 'ホテル' },
  { value: 'others_type', text: 'その他' },
]
export const buildingTypeSaleOptionsForSearch = [
  { value:10, text: '土地' },
  { value:9, text: '戸建' },
  { value:1, text: 'マンション' },
  { value:2, text: 'アパート' },
  { value:6, text: 'ビル' },
  { value:11, text: 'ホテル' },
  { value:99, text: 'その他' },
]
export const floorTypeOptions = [
  { value: 'above_ground', text: '地上' },
  { value: 'under_ground', text: '地下' },
]
export const statusOptionsForSearch  = [
  { value:0 , text: '募集中' },
  { value:1 , text: '成約' },
]

export const buildingStructureOptionsForSearch = [
  { value:1 , text: 'RC造：鉄筋コンクリート造' },
  { value:2 , text: 'WRC造：壁式鉄筋コンクリート造' },
  { value:3 , text: 'SRC造：鉄骨鉄筋コンクリート造' },
  { value:4 , text: 'S造（軽量）：軽量鉄骨造' },
  { value:5 , text: 'S造（重量）：重量鉄骨造' },
  { value:6 , text: 'W造：木造' },
  { value:7 , text: 'AL造：アルミ造' },
  { value:8 , text: 'CFT造：コンクリート充填鋼管構造' },
  { value:9 , text: 'CB造：コンクリートブロック造' },
  {value:99 , text: 'その他' },
]

export const saleSortOptions = [
  { value: '', text: '（なし）' },
  { value: 'sale_prospective_yield', text: '売出表面利回り（昇順）' },
  { value: 'sale_prospective_yield desc', text: '売出表面利回り（降順）' },
  { value: 'prospective_yield', text: '成約表面利回り（昇順）' },
  { value: 'prospective_yield desc', text: '成約表面利回り（降順）' },
  { value: 'build_age', text: '築年数（昇順）' },
  { value: 'build_age desc', text: '築年数（降順）' },
  { value: 'created_at', text: '登録日（昇順）' },
  { value: 'created_at desc', text: '登録日 （降順）' },
]

export const rentSortOptions = [
  { value: '', text: '（なし）' },
  { value: 'occupied_area', text: '専有面積（昇順）' },
  { value: 'occupied_area desc', text: '専有面積（降順）' },
  { value: 'build_age', text: '築年数（昇順）' },
  { value: 'build_age desc', text: '築年数（降順）' },
  { value: 'rent_maintenance_fee_unit_price_tsubo', text: '  賃料（管理費込み）坪単価（昇順）' },
  { value: 'rent_maintenance_fee_unit_price_tsubo desc', text: '  賃料（管理費込み）坪単価（降順）' },
  { value: 'created_at', text: '登録日（昇順）' },
  { value: 'created_at desc', text: '登録日 （降順）' },
]
