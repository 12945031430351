import * as React from "react";
import type {User} from "@/types/user";
import type {Property} from "@/types/property";
import DatePicker, {registerLocale} from "react-datepicker";
import {AnchorButton} from "@/components/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import ja from 'date-fns/locale/ja'
import 'react-datepicker/dist/react-datepicker.css'
import CalendarToday from "@material-ui/icons/CalendarToday";
import {colors} from "@/utils/colors";
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'

const TimeFrame: React.FC<{
  user: User
}> = ( user ) => {
  registerLocale('ja', ja)

  const Today = new Date()
  const [from, setFrom] = React.useState<Date>(Today)
  const [to, setTo] = React.useState<Date>(Today)
  const [isLoading, setIsLoading] = React.useState(false)
  const loadStart = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const response = await fetch(
        `/time_frame/export?year_from=${from.getFullYear()}&month_from=${from.getMonth()}&year_to=${to.getFullYear()}&month_to=${to.getMonth()}`
      )
      if (!response.ok) throw new Error("ダウンロードに失敗しました")
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement("a")
      a.href = url
      a.download = "time_frame.csv"
      document.body.appendChild(a)
      a.click()
      a.remove()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="mt-10">
      <div className="flex justify-between items-center bg-primary text-white py-2 px-4 text-sm">
        ボリューム履歴確認
      </div>
      <div className="py-8">
        <div className="flex items-center gap-4">
            <DatePicker
              selected={from}
              locale="ja"
              maxDate={to}
              dateFormat="yyyy年MM月"
              showMonthYearPicker
              onChange={(selectedDate) => {
                setFrom(selectedDate)
              }}
              customInput={<CustomInput/>}
            />
          <div className="text-sm">〜</div>
            <DatePicker
              selected={to}
              locale="ja"
              minDate={from}
              maxDate={Today}
              dateFormat="yyyy年MM月"
              showMonthYearPicker
              onChange={(selectedDate) => {
                setTo(selectedDate)
              }}
              customInput={<CustomInput/>}
            />
          <AnchorButton
            className="text-primary-font whitespace-nowrap"
            prefix={<CloudDownloadIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={`/time_frame/export?year_from=${from.getFullYear()}&month_from=${from.getMonth()}&year_to=${to.getFullYear()}&month_to=${to.getMonth()}`}
          >
            <span className="hidden md:inline">期間内の履歴集計</span>
          </AnchorButton>
          <AnchorButton
            className="text-primary-font whitespace-nowrap"
            prefix={<CloudDownloadIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={`/time_frame/export?year_from=${from.getFullYear()}&month_from=${from.getMonth()}&year_to=${to.getFullYear()}&month_to=${to.getMonth()}&pending=true`}
          >
            <span className="hidden md:inline">未完了のみ</span>
          </AnchorButton>
          <AnchorButton
            className="text-primary-font whitespace-nowrap"
            prefix={<CloudDownloadIcon fontSize="small" />}
            outline
            size="small"
            variant="primary"
            href={`/time_frame/export?year_from=${from.getFullYear()}&month_from=${from.getMonth()}&year_to=${to.getFullYear()}&month_to=${to.getMonth()}&timeout=true`}
          >
            <span className="hidden md:inline">タイムアウトのみ</span>
          </AnchorButton>
        </div>
      </div>
    </div>
  )
}

const customInputStyle = `
  className="appearance-none
  bg-no-repeat
  bg-center
  border
  rounded-sm
  bg-white
  outline-none
  leading-normal
  text-black-base
  border-gray-border
  placeholder-gray-300
  w-full
  py-2
  pl-4
  pr-3
  text-sm
  flex
  items-center
`
// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef(
  (
    {
      value,
      onClick,
    }: {
      value?: string
      onClick?: React.MouseEventHandler<HTMLButtonElement>
    },
    ref
  ) => {
    return (
      <button
        className={customInputStyle}
        onClick={(e) => {
          e.preventDefault()
          onClick(e)
        }}
      >
        {value}
        <CalendarToday className="ml-auto" fontSize="small" htmlColor={colors.gray} />
      </button>
    )
  }
)

export default TimeFrame
