// ２カラムとしてカウントされるカラム
export const twoColumns = ["rent_maintenance_fee_and_unit_price"]

// フォーマットを行う対象のカラム
export const percentColumns = [
  'prospective_yield',
  'sale_prospective_yield',
  'sale_prospective_noi_yield',
  'contract_prospective_noi_yield',
  'sale_and_prospective_yield',
  'sale_and_prospective_noi_yield',

]

export const priceColumns = [
  'rent_maintenance_fee_unit_price_tsubo'
]

export const areaColumns = [
  'area_m3',
  'occupied_area',
  'total_floor_area',
  'effective_area'
]

export const areaTsuboColumns = [
  'area_m3_tsubo',
  'occupied_area_tsubo',
  'total_floor_area_tsubo',
  'effective_area_tsubo'
]

export const dummyMarketNum = 1

export const defaultColumnsMap = {
  "3": ["sale_and_prospective_price", "building_structure_short", "sale_and_prospective_yield", "build_age"],
  "4": ["rent_maintenance_fee_and_unit_price", "occupied_area", "build_age", "building_structure_short"]
}

export const defaultLegendNames: { [key: number]: string[][] } = {
  3: [
    ["物件番号", "状態"],
    ["価格", "構造"],
    ["NOI利回り", "築年数"]
  ],
  4: [
    ["物件番号", "状態"],
    ["賃料（管理費込み）", "賃料（管理費込み）㎡単価"],
    ["専有面積", "築年数", "構造"]
  ]
};