import { UserContext } from '@/components/Layout/Base'
import { ArrowLeftIcon, ArrowRightIcon, ListIcon } from '@/components/SvgIcon'
import * as React from 'react'

import { menusPricingType } from '@/models/menu'

type Props = {
  sidebarClicked: string
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
}

const Sidebar: React.FC<Props> = ({ sidebarClicked, setOpen, open }) => {
  const { user } = React.useContext(UserContext)
  const param = window.location.search
  const tab = new URLSearchParams(param).get('tab')

  let Menus = menusPricingType[user.pricing_type]

  if (user.role === 'general') {
    Menus = Menus.filter((menu) => menu.title !== '分析情報')
  }

  if (user.role === 'leader' || user.role === 'general') {
    Menus = Menus.filter((menu) => menu.title != 'CSVインポート')
    Menus = Menus.map((menu) => {
      if (menu.title === '設定') {
        return { title: menu.title, path: `/users/${user.id}/edit`, icon: menu.icon }
      } else {
        return menu
      }
    })
  }

  function menuSet(menu: any, index: string | number) {
    if (menu.title === '物件登録') {
      return (
        <li
          key={index}
          className={`items-center cursor-pointer group hover:bg-white
          ${(window.location.pathname.match(/^\/\w*/)[0] === menu.path ||
              tab === 'proposals' ||
              sidebarClicked === menu.title) &&
            'bg-white'
            }`}
          onClick={() => {
            window.location.href = menu.path
          }}
        >
          <div
            className={`flex items-center group-hover:text-primary-font ${(window.location.pathname.match(/^\/\w*/)[0] === menu.path ||
              tab === 'proposals' ||
              sidebarClicked === menu.title) ?
              'text-primary-font' : 'text-white'
              }`}
          >
            <div className="p-3">{menu.icon}</div>
            <div
              className={`${open ? 'opacity-100' : 'opacity-0'
                } z-0 ml-12 select-none text-[15px] font-medium absolute duration-150`}
            >
              {menu.title}
            </div>
          </div>
        </li>
      )
    } else if (menu.title === '入手情報一覧') {
      return (
        <li
          key={index}
          className={`items-center cursor-pointer group hover:bg-white
          ${((window.location.pathname.match(/^\/\w*/)[0] === menu.path && tab !== 'proposals') ||
              sidebarClicked === menu.title) &&
            'bg-white'
            }`}
          onClick={() => {
            window.location.href = menu.path
          }}
        >
          <div
            className={`flex items-center group-hover:text-primary-font ${((window.location.pathname.match(/^\/\w*/)[0] === menu.path && tab !== 'proposals') ||
              sidebarClicked === menu.title) ?
              'text-primary-font' : 'text-white'
              }`}
          >
            <div className="p-3">{menu.icon}</div>
            <div
              className={`${open ? 'opacity-100' : 'opacity-0'
                } z-0 ml-12 select-none text-[15px] font-medium absolute duration-150`}
            >
              {menu.title}
            </div>
          </div>
        </li>
      )
    } else {
      return (
        <li
          key={index}
          className={`items-center cursor-pointer group hover:bg-white
          ${(window.location.pathname.match(/^\/\w*/)[0] === menu.path ||
              sidebarClicked === menu.title) &&
            'bg-white'
            }`}
          onClick={() => {
            window.location.href = menu.path
          }}
        >
          <div
            className={`flex items-center group-hover:text-primary-font ${(window.location.pathname.match(/^\/\w*/)[0] === menu.path ||
              sidebarClicked === menu.title) ?
              'text-primary-font' : 'text-white'
              }`}
          >
            <div className="p-3">{menu.icon}</div>
            <div
              className={`${open ? 'opacity-100' : 'opacity-0'
                } z-0 ml-12 select-none text-[15px] font-medium absolute duration-150`}
            >
              {menu.title}
            </div>
          </div>
        </li>
      )
    }
  }

  return (
    <div
      className={`pt-1 hidden md:block bg-primary transition-[width] z-0 ${open ? 'w-[184px]' : 'w-12'}`}   
    >
      <div className={`flex items-center`} onClick={() => setOpen(!open)}>
        <div className={`${open ? 'opacity-0' : 'opacity-100'} p-2 flex duration-200`}>
          <div className="absolute bg-primary opacity-100 hover:opacity-0 duration-300">
            <ListIcon size={32} fill="#fff" />
          </div>
          <ArrowRightIcon size={32} fill="#fff" />
        </div>
        <div className={`${open ? 'opacity-100' : 'opacity-0'} ml-[100px] duration-100 pr-1`}>
          <ArrowLeftIcon size={32} fill="#fff" />
        </div>
      </div>

      <ul className="" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>{Menus.map((menu, index) => menuSet(menu, index))}</ul>
    </div>
  )
}

const path = '/market_data/new'

export default Sidebar
