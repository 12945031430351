import type { LatLng } from '@/types/property'
import { wards } from '@/models/wards'
import { preCodes } from '@/models/prefectureCodes'

type quickVolumeCheckProps = {
  isLoaded: boolean
  propertyLatLng: LatLng
  propertyShape: string
  setRequestUUID: (value: string) => void
  csrfToken: string
  setCheckRetryTime: (value: number) => void
  setPrefectureName?: (value: string) => void
  params
}

const quickVolumeCheck = async ({
  isLoaded,
  propertyLatLng,
  propertyShape,
  setRequestUUID,
  csrfToken,
  setCheckRetryTime,
  setPrefectureName,
  params,
}: quickVolumeCheckProps) => {
  const geocode = (geocoder, latlng) =>
    new Promise((resolve, reject) => {
      geocoder.geocode({ location: latlng, language: 'ja' }, (results, status) => {
        if (status === 'OK') {
          resolve(results)
        } else {
          reject(new Error('Geocoder failed: ' + status))
        }
      })
    })

  let addressComponents
  if (isLoaded) {
    const geocoder = new google.maps.Geocoder()
    const latlng = new google.maps.LatLng(Number(propertyLatLng.lat), Number(propertyLatLng.lng))
    try {
      const results = await geocode(geocoder, latlng)
      if (results[0]) {
        addressComponents = results[0].address_components
      } else {
        console.error('No results found')
      }
    } catch (error) {
      console.error(error.message)
    }
  }

  let prefecture
  let city
  let town
  let chome
  if (addressComponents) {
    prefecture = addressComponents.find((c) =>
      c.types.includes('administrative_area_level_1')
    )?.long_name

    city = addressComponents
      .filter((c) => c.types.includes('locality') || c.types.includes('sublocality_level_1'))
      .map((c) => c.long_name)
      .reverse()
      .join('')

    town = addressComponents.find((c) => c.types.includes('sublocality_level_2'))?.long_name

    let premise = true
    chome = addressComponents
      .slice()
      .reverse()
      .filter((c) => {
        if (c.types.includes('sublocality_level_3') || c.types.includes('sublocality_level_4')) {
          return true
        } else if (premise && c.types.includes('premise')) {
          premise = false
          return true
        } else {
          return false
        }
      })
      .map((c) => c.long_name)
      .join('-')
      .replace(/[^0-9０-９]+/g, '-')
      .replace(/-$/, '')

    chome = chome.replace(/[０-９]/g, function (c) {
      return String.fromCharCode(c.charCodeAt(0) - 0xfee0)
    })
  }

  setPrefectureName && setPrefectureName(prefecture)

  let propertyShapeArray = JSON.parse(propertyShape)

  const borders = []
  const surroundings = []

  propertyShapeArray.forEach((coord, index) => {
    let previousCoord

    if (index !== 0) {
      previousCoord = propertyShapeArray[index - 1]
    } else {
      previousCoord = propertyShapeArray[propertyShapeArray.length - 1]
    }

    const border = {
      id: index + 1,
      to: { lat: Number(coord.lat), lng: Number(coord.lng) },
      from: { lat: Number(previousCoord.lat), lng: Number(previousCoord.lng), height: 0 },
      width: 15,
      border_type_code: 1,
      set_back_method: 0,
    }

    const surrounding = {
      width: 0,
      border_id: index + 1,
      border_type_code: 4,
    }

    borders.push(border)
    surroundings.push(surrounding)
  })
  let parameters = {}
  if (params && params.length > 0) {
    parameters = {
      response_type: 'area',
      chome: `${town}${chome}`,
      center: {
        lat: Number(propertyLatLng.lat),
        lng: Number(propertyLatLng.lng),
      },
      borders: borders,
      surroundings: surroundings,
      prefecture_name: preCodes[prefecture].toString(),
      city_name: wards[city]?.toString() || '',
      building_setting: {
        floors: [
          { floor_number: 1, max_floor_height: 3 },
          { floor_number: 2, max_floor_height: 3 },
          { floor_number: 3, max_floor_height: 3 },
          { floor_number: 4, max_floor_height: 3 },
          { floor_number: 5, max_floor_height: 3 },
        ],
        max_height: 50,
        floor_height: 3,
        gf_slab_level: 2,
        design_ground_level: 2,
      },
      administrative_name: 'atr:',
      area_of_use: params
    }
  } else {
    parameters = {
      response_type: 'area',
      chome: `${town}${chome}`,
      center: {
        lat: Number(propertyLatLng.lat),
        lng: Number(propertyLatLng.lng),
      },
      borders: borders,
      surroundings: surroundings,
      prefecture_name: preCodes[prefecture].toString(),
      city_name: wards[city]?.toString() || '',
      building_setting: {
        floors: [
          { floor_number: 1, max_floor_height: 3 },
          { floor_number: 2, max_floor_height: 3 },
          { floor_number: 3, max_floor_height: 3 },
          { floor_number: 4, max_floor_height: 3 },
          { floor_number: 5, max_floor_height: 3 },
        ],
        max_height: 50,
        floor_height: 3,
        gf_slab_level: 2,
        design_ground_level: 2,
      },
      administrative_name: 'atr:',
    }
  }

  fetch('/quick_check_request', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ parameters: parameters }),
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === 'OK') {
        console.log(data.uuid)
        setRequestUUID(data.uuid)
        // areacheck結果取得時のリトライタイマーを0にリセットしておく
        setCheckRetryTime(0)
      } else {
        console.error(data.message)
      }
    })
    .catch((error) => {
      console.error('Error:', error)
    })
}

export default quickVolumeCheck
