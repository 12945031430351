
// セッション作成
export const createSession = async (csrfToken: string, ocrData: object): Promise<any> => {
  const response = await fetch('/api/ocr/create_session', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ ocr_data: ocrData }),
  });

  // ステータスコードが 400 の場合（Bad Request）にエラーとして扱う
  if (response.status === 400) {
    const { error } = await response.json();
    throw new Error('すでに実行されているOCR処理です。OCRの処理完了後か、5分経過後に再度実行してください。');
  } else if (!response.ok) {
    throw new Error(`セッション作成に失敗しました。ステータスコード: ${response.status}`);
  }
  return response.json();
};

// セッション削除
export const deleteSession = async (token, ocrData) => {
  try {
    const response = await fetch('/api/ocr/delete_session', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': token,
      },
      body: JSON.stringify({ ocr_session_id: ocrData.ocrSessionId }),
    });
    const data = await response.json();
    if (data.success) {
      if (data.message) {
        console.log('session_delete_message:', data.message);
      }
    }else{
      console.error('セッション削除エラー:', data.error);
      alert('セッションの削除に失敗しました。');
    }
  } catch (error) {
    console.error('セッション削除中のエラー:', error);
    alert('セッションの削除に失敗しました。');
  }
};

// セッション件数取得
export const sessionCount = async (csrfToken: string): Promise<any> => {
  const response = await fetch('/api/ocr/count_processing_session', {
    method: 'GET',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  });

  if (!response.ok) {
    throw new Error('セッション件数の取得に失敗しました');
  }

  return response;
};

export const editSessionStatus = async (csrfToken: string, ocrSessionId: string): Promise<any> => {
  const response = await fetch('/api/ocr/edit_session_status', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ ocr_session_id: ocrSessionId}),
  });

  // エラー処理：ステータスコードが 400 の場合はエラーとして扱う
  if (response.status === 400) {
    const { error } = await response.json();
    throw new Error('セッション更新中にエラーが発生しました。既にセッションが削除されている可能性があります。');
  } else if (!response.ok) {
    throw new Error(`セッション更新に失敗しました。ステータスコード: ${response.status}`);
  }

  return response.json();
};


