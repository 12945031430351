import * as React from 'react'
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';

export const Row: React.FC<{
  label?: React.ReactNode
  children?: React.ReactNode
  className?: string
}> = ({ label, children, className }) => (
  <div className={['flex md:flex-nowap border-b border-[#C3DEEE]', className].join(' ')}>
    {label}
    {children}
  </div>
)

export const Th: React.FC<{
  className?: string
  children?: React.ReactNode
  column?: number
  left?: boolean
  market_datum_report_layouts?: string[]
}> = ({ className, children, column = 1, left = false, market_datum_report_layouts = [] }) => {
  const width = styles.th[`column${column}`]

  return (
    <div
      className={[
        'flex items-center bg-[#eff9ff] py-1 px-2.5 md:py-1 md:px-4 text-black-base text-sm place-content-between',
        left ? 'md:text-left' : 'md:text-right',
        width,
        className,
      ].join(' ')}
    >
      {children}
      {(market_datum_report_layouts?.includes(children) ||
        (Array.isArray(children) && market_datum_report_layouts?.includes(children[0]))) && (
        <span className="ml-2 inline-block py-0.1 px-0.1 bg-primary text-white scale-[0.8]">
          <AddLocationAltOutlinedIcon />
        </span>
      )}
    </div>
  )
}

export const Td: React.FC<{
  children?: React.ReactNode
  column?: number
  multiLine?: boolean
}> = ({ children, column = 1, multiLine = false }) => {
  const width = styles.td[`column${column}`]

  return (
    <div
      className={[
        'border-l border-[#C3DEEE] py-1 px-2.5 md:py-1 md:px-4',
        width,
        multiLine ? '' : 'flex flex-wrap items-center',
      ].join(' ')}
    >
      {children}
    </div>
  )
}

const styles = {
  th: {
    column1: `w-1/3 md:w-1/6`,
    column2: `w-1/3`,
    column3: `w-1/3 md:w-1/2`,
    column4: `w-1/2 md:w-1/4`,
    column5: `w-1/2`,
  },
  td: {
    column1: `w-2/3 md:w-5/6`,
    column2: `w-2/3`,
    column3: `w-2/3 md:w-1/2`,
    column4: `w-1/6 md:w-5/24`,
    column5: `w-1/3 md:w-5/12`,
    column6: `w-2/15 md:w-1/6`,
    column7: `w-1/2 md:w-3/4`,
    column8: `w-1/2`,
  },
}
