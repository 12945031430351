import * as React from 'react'
import { UserContext } from '@/components/Layout/Base'
import { useAuth } from '@/components/Ocr/auth/auth'
import { CreateJobMutation, CreateJobInput, Status as OcrJobStatus } from '@/api/ocr/aws/src/API'
import { OCRSubscription, ocrExecution } from '@/components/Ocr'
import { API, graphqlOperation, GraphQLQuery } from '@aws-amplify/api'
import { OcrFormat, chooseFormat } from '@/components/Ocr/formats'
import { createJob } from '@/api/ocr/aws/src/graphql/mutations'
import { Property } from '@/types/property'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { DirectUpload } from '@rails/activestorage'
import { OCRResultConverter } from '@/components/Ocr/factory'
import { OCRJobLogger } from '@/components/Ocr/api/job'
import { FileUpload, FileUploadHandle } from '@/components/FileUpload'
import Configure from '@/config/aws/configure'
import { AcceptableMimeTypes } from '@/components/Ocr/file'
import { PDFDocument } from 'pdf-lib'
import { Input } from '@/components/Input'
import { useInputValidator } from '@/utils/hooks/validator'
import { intFormat, dataPrecisionSetup } from '@/utils/cammedFormat'
import { createSession , sessionCount , deleteSession, editSessionStatus} from '@/components/Ocr/api/session'

type OCRPreviewZoneProps = {
  ocr: ocrExecution
  csrfToken: string
  fileRef?: React.MutableRefObject<FileUploadHandle>
  attachedFileRef?: React.MutableRefObject<FileUploadHandle>
  formatType: OcrFormat
  isEdit: boolean
  setOcr: React.Dispatch<React.SetStateAction<ocrExecution>>
  setProperty: React.Dispatch<React.SetStateAction<Property>>
  setPdfPreview: any
  mode?: string
}

export const OCRPreviewZone: React.FC<OCRPreviewZoneProps> = (props) => {
  const { csrfToken, ocr, fileRef, attachedFileRef, formatType, setOcr, setProperty, isEdit ,mode} =
    props
  Configure()
  const { user } = React.useContext(UserContext)
  const { token, error } = useAuth({ csrfToken })
  if (error) {
    console.error(error)
  }

  const [splittedFile, setSplittedFile] = React.useState<File>(ocr.localFile)

React.useEffect(() => {
  if (!token ) {
    // token がまだ取得できていない場合は何もしない
    return;
  }
  const uploader = new FileUploader(setOcr);
  const processOcr = async () => {
    // ファイルアップロード状態
    if (ocr.jobStatus === 'fileUpload') {

      // isAutoOcrがtrue(一括OCR)の場合は、ファイルはS3にアップロード済
      // そのため、アップロードはスキップする
      // セッションの作成、セッション数確認もモーダルの処理ですでに行っているためスキップ
      if (ocr.isAutoOcr) {
        try {
          // sessionCount 関数を利用してセッション数を取得
          const response = await sessionCount(csrfToken);
          const sessionData = await response.json();

          // セッション件数が一定数以上の場合の処理
          if (sessionData.count + 1 > 5) {
            alert('複数OCR読み取りは同時に5件までしか実行できません。');
            setOcr((prevState) => ({
              ...prevState,
              jobStatus: OcrJobStatus.failed,
            }));
            return;
          }else{
            const editResponse = await editSessionStatus(csrfToken, ocr.ocrSessionId );
            setOcr((prevState) => ({
              ...prevState,
              jobStatus: OcrJobStatus.waiting,
            }));
          }
        } catch (error) {
          console.error('セッション確認エラー:', error);
          alert('セッションの確認に失敗しました。');
        }
      } else {
        // セッション数確認
        // セッション数が2件以上の場合は、アラートを表示して処理を中断
        try {
          // sessionCount 関数を利用してセッション数を取得
          const response = await sessionCount(csrfToken);
          const sessionData = await response.json();

          // セッション件数が一定数以上の場合の処理
          if (sessionData.count + 1 > 5) {
            alert('複数OCR読み取りは同時に5件までしか実行できません。');
            setOcr((prevState) => ({
              ...prevState,
              jobStatus: 'preview',
            }));
            return;
          }
          // セッション作成
          try {
            const uuid = crypto.randomUUID();
            const ocrData = [{
              ocr_session_id: uuid,
              is_processing: true,
              ocr_state: {
                blobSignedId: null,
                remoteFileKey: null,
                remoteFileURL: null,
              },
            }]
            await createSession(csrfToken, ocrData);
            setOcr((prevState) => ({
              ...prevState,
              ocrSessionId: uuid,
            }));
            // アップロード処理
            uploader.handleUpload(ocr.localFile, splittedFile || undefined, csrfToken);

          } catch (error) {
            console.error('セッション作成エラー:', error);
            setOcr((prevState) => ({
              ...prevState,
              jobStatus: OcrJobStatus.failed,
            }));
            return
          }
        } catch (error) {
          console.error('セッション確認エラー:', error);
          alert('セッションの確認に失敗しました。');
        }
      }
    }
    // OCRジョブ待機状態
    else if (ocr.jobStatus === OcrJobStatus.waiting) {
      if (!token) {
        return;
      }
      const file = `s3://${process.env.REACT_APP_S3_BUCKET}/${ocr.remoteOcrFileKey}`;
      const input: CreateJobInput = {
        groupId: user.company_id,
        file,
        status: OcrJobStatus.waiting,
        format: JSON.stringify(chooseFormat(formatType)),
        createdAt: new Date().toISOString(),
      };
      API.graphql<GraphQLQuery<CreateJobMutation>>(
        graphqlOperation(createJob, { input, auth: { operation: 'mutate' } }, token)
      )
        .then((res) => {
          const { id: jobId, status: jobStatus } = res.data.createJob;
          setOcr((prevState) => ({
            ...prevState,
            jobId,
            jobStatus,
            extractedItems: undefined,
          }));
        })
        .catch((err) => {
          console.error(err);
        });
    // OCRジョブ完了または失敗状態
    }else if (ocr.jobStatus === OcrJobStatus.successed || ocr.jobStatus === OcrJobStatus.failed) {
      try {
        // fileRef の初期化（通常はコンポーネント内で useRef を呼び出している前提）
        // 既存の処理：セッション削除とログ保存
        if (ocr.ocrSessionId) {
          await deleteSession(csrfToken, { ocrSessionId: ocr.ocrSessionId });
        }
        new OCRJobLogger(ocr.jobId, formatType, ocr.jobStatus).save(csrfToken);
      } catch (error) {
        console.error('セッション削除エラー:', error);
      }

     // バリデーションエラーの場合（署名付き URL を取得）
    } else if (ocr.jobStatus === 'validationError' && ocr.blobSignedId) {
      try {
        // deleteSession 関数を利用してセッションを削除
        if (ocr.ocrSessionId){
          await deleteSession(csrfToken, { ocrSessionId: ocr.ocrSessionId });
        }
        new OCRJobLogger(ocr.jobId, formatType, ocr.jobStatus).save(csrfToken);
      } catch (error) {
        console.error('セッション削除エラー:', error);
      }
      uploader.getUrl(ocr.blobSignedId, csrfToken).then((url) => {
        setOcr((prevState) => ({
          ...prevState,
          remoteFileURL: url,
        }));
      });
    };
  }
  processOcr().catch((error) => {
    console.error('OCR処理中にエラー:', error);
    setOcr((prevState) => ({
      ...prevState,
      jobStatus: OcrJobStatus.failed,
    }));
  });
}, [ocr.jobStatus, token]);

  const previewUrl = React.useMemo(
    () => (ocr.localFile ? URL.createObjectURL(ocr.localFile) + '#view=Fit' : ''),
    [ocr.localFile]
  )

  const [ref, validate] = useInputValidator(1)

  const unitPriceChange = (propertyData) => {
    const m3_tsubo_ratio = 0.3025
    let base_area = 0
    if (propertyData['property_type'] === '土地') {
      base_area = intFormat(String(propertyData['effective_area']))
    } else {
      base_area = intFormat(String(propertyData['occupied_area']))
    }
    if (base_area > 0) {
      const price = dataPrecisionSetup(String(propertyData['suggested_total_price']), 12, 0)
      return price ? price / (Math.floor(base_area * m3_tsubo_ratio * 100) / 100) : 0
    } else {
      return null
    }
  }

  const calculateCurrentProspectiveYield = (propertyData) => {
    const current_rent = propertyData?.current_rent
      ? dataPrecisionSetup(String(propertyData?.current_rent), 12, 0)
      : 0
    const suggested_total_price = propertyData?.suggested_total_price
      ? dataPrecisionSetup(String(propertyData?.suggested_total_price), 12, 0)
      : 0
    const purchase_price = propertyData?.purchase_price
      ? dataPrecisionSetup(String(propertyData?.purchase_price), 12, 0)
      : 0
    let current_prospective_yield = 0

    if (current_rent !== 0 && purchase_price !== 0) {
      current_prospective_yield = Math.floor(((current_rent * 12) / purchase_price) * 10000) / 100
    } else if (current_rent === 0 || suggested_total_price === 0) {
      current_prospective_yield = 0
    } else {
      current_prospective_yield =
        Math.floor(((current_rent * 12) / suggested_total_price) * 10000) / 100
    }

    return current_prospective_yield || ''
  }

  const render = ({ status }) => {
    switch (status) {
      case 'preview':
        return (
          <div className="flex gap-8 h-[80vh] w-full">
            <div className="flex-col w-4/5 justify-start items-center">
              <embed
                className="w-full h-full mb-4 border-b border-b-[3px] border-gray-700"
                src={URL.createObjectURL(splittedFile || ocr.localFile) + '#view=Fit'}
                type={ocr.localFile.type}
              />
            </div>
            <div className="flex-col w-1/5">
              <span>PDFページ指定</span>
              <Input
                type="text"
                placeholder="ex. 1"
                pattern="^[1-9][0-9]*$"
                ref={ref.current[0]}
                onChange={(e) => {
                  !e.target.value && setSplittedFile(null) // 何か入力した後に再度空欄にした場合は、元ファイルを表示したいので、nullで消す
                  const validity = validate(ref.current[0], '正の数値を入力してください')
                  setOcr((prevState) => {
                    return {
                      ...prevState,
                      localFileValidity: validity,
                    }
                  })

                  ocr.localFile.arrayBuffer().then(async (data) => {
                    const copyToFile: PDFDocument = await PDFDocument.create()
                    const copyFromFile = await PDFDocument.load(data)
                    const allPages = copyFromFile.getPages()
                    const extractedPage = e.target.value
                      ? await copyToFile.copyPages(copyFromFile, [Number(e.target.value) - 1])
                      : allPages
                    extractedPage.forEach((page) => copyToFile.addPage(page))
                    const pdfBytes = await copyToFile.save()
                    const newFile = new File([pdfBytes], ocr.localFile.name, {
                      type: ocr.localFile.type,
                    })
                    setSplittedFile(newFile)
                  })
                }}
              />
            </div>
          </div>
        )
      case 'fileUpload':
      case OcrJobStatus.waiting:
        return (
          <div className="mt-10 flex justify-center items-center w-full h-full">
            <CircularProgress className="" size={80} />
            <div className="ml-10 text-left">
              <p>{ocr.jobStatus == 'fileUpload' ? 'ファイルアップロード中' : 'OCR実行中'}</p>
              <p>完了までの数分間、画面遷移をお控えください</p>
              <p>※別タブでの操作は可能です</p>
            </div>
            <OCRSubscription ocr={ocr} setOcr={setOcr} token={token} />
          </div>
        )
      case OcrJobStatus.processing:
        return null
      case OcrJobStatus.successed:
        try {
          ;(async () => {
            const converter = new OCRResultConverter(formatType, ocr.extractedItems)
            const scannedProperty = await converter.createData(isEdit)
            const ocrAddress = ocr?.extractedItems?.result?.住所
            const keys: AddressMember[] = ['都道府県', '市区町村', '町名', '丁目・その他']
            // 住所が全て読み取れていない時は、交通機関の自動取得をスキップしない
            // 物件編集画面では住所を読み取らないため、自動取得をスキップする
            if (isEdit || (ocrAddress && keys.every((k) => k in ocrAddress && ocrAddress[k]))) {
              // OCRで取得した交通機関を適応するために、自動ピン刺し時に交通機関の自動取得をスキップするフラグを立てる
              setOcr((prevState) => {
                return { ...prevState, isSkipSearchTra: true }
              })
            }

            setProperty((prevState) => {
              const mergedPropeties = {...prevState, ...scannedProperty,}
              // 売主希望坪単価と現況表面利回りの計算はOCRで取ってきた値のみだと計算できないため、ここで実施する
              const unitPrice = prevState['suggested_total_price']
                ? unitPriceChange(mergedPropeties)
                : null
              const prospectiveYield = calculateCurrentProspectiveYield(mergedPropeties)
              return {
                ...prevState,
                ...scannedProperty,
                suggested_unit_price: unitPrice ? unitPrice : prevState['suggested_unit_price'],
                current_prospective_yield: prospectiveYield ?  prospectiveYield : prevState['current_prospective_yield'],
              }
            })
          })()
        } catch (e) {
          setOcr((prevState) => {
            return { ...prevState, jobStatus: OcrJobStatus.failed }
          })
        } finally {
          setOcr({ ...ocr, jobStatus: 'finish' })
          return null
        }
      case OcrJobStatus.failed:
        return (
          <div className="mt-10 flex justify-center items-center w-full h-full">
            <Alert severity="error">
              <AlertTitle>ERROR</AlertTitle>
              OCRスキャンが失敗しました。再実行してください。
            </Alert>
          </div>
        )
      case 'validationError':
      case 'finish':
        ocr.localFile && fileRef?.current?.setFile(ocr.localFile)
        ocr.localFile && attachedFileRef?.current?.setFile(ocr.localFile)
        return (
          <>
            {fileRef && ( // 周辺事例画面の場合はファイルをフォームで送信
              <FileUpload
                className={'mt-4 w-full'}
                hidden={true}
                name="market_datum[attached_file]"
                accept={AcceptableMimeTypes}
                filenameInitial={ocr.localFile?.name}
                csrfToken={token}
                ref={fileRef}
              />
            )}
            {attachedFileRef && (
              <FileUpload
                className={'mt-4 w-full'}
                hidden={true}
                name="property[attached_files][]"
                accept={AcceptableMimeTypes}
                filenameInitial={ocr.localFile?.name}
                csrfToken={token}
                ref={attachedFileRef}
              />
            )}

            <embed
              className="w-full h-full aspect-[9/16] lg:h-screen mb-4 border-b border-b-[3px] border-gray-700"
              src={ocr.remoteFileURL ? `${ocr.remoteFileURL}#toolbar=1&navpanes=0` : previewUrl}
              type={ocr.localFile ? ocr.localFile?.type : ''}
            />
          </>
        )
      case 'uploadOnly':
        const filename = ocr.localFile?.name
        ocr.localFile && fileRef?.current?.setFile(ocr.localFile)
        ocr.localFile && attachedFileRef?.current?.setFile(ocr.localFile)

        return (
          <div className="flex flex-col justify-start w-full">
            <FileUpload
              className="p-4 w-full bg-gray-light border-2 border-dashed text-sm"
              accept={AcceptableMimeTypes}
              id="ocr_file_uploader"
              filenameInitial={filename}
              csrfToken={token}
              onDrop={(file: File) => {
                setOcr((prevState) => {
                  return {
                    ...prevState,
                    jobStatus: 'preview',
                    localFile: file,
                  }
                })

              }}
            />
            {fileRef && (
              <FileUpload
                hidden={true}
                name="market_datum[attached_file]"
                accept={AcceptableMimeTypes}
                filenameInitial={ocr.localFile?.name}
                csrfToken={csrfToken}
                ref={fileRef}
              />
            )}
            {attachedFileRef && (
              <FileUpload
                hidden={true}
                name="property[attached_files][]"
                accept={AcceptableMimeTypes}
                filenameInitial={ocr.localFile?.name}
                ref={attachedFileRef}
                csrfToken={csrfToken}
              />
            )}
            <p className="pt-2 pl-2 text-xs">
              OCR、または、ファイル添付したい物件概要書を <span className="font-bold">PDF形式</span>{' '}
              でアップロードしてください
            </p>
          </div>
        )
      default:
        return (
          <div className="flex flex-col justify-start w-full">
            <FileUpload
              className="p-4 w-full bg-gray-light border-2 border-dashed text-sm"
              accept={AcceptableMimeTypes}
              id="ocr_file_uploader"
              csrfToken={csrfToken}
              onDrop={(file: File) => {
                setOcr((prevState) => {
                  return {
                    ...prevState,
                    jobStatus: 'preview',
                    localFile: file,
                  }
                })
              }}
              isAutoOcr={mode === "market_datum" ? true : false}
            />
            <p className="pt-2 pl-2 text-xs">
              OCR、または、ファイル添付したい物件概要書を <span className="font-bold">PDF形式</span>{' '}
              でアップロードしてください
            </p>
          </div>
        )
    }
  }
  return <>{render({ status: ocr.jobStatus })}</>
}

export class FileUploader {
  directUpload: DirectUpload
  setOcr: React.Dispatch<React.SetStateAction<ocrExecution>>
  constructor(setOcr: React.Dispatch<React.SetStateAction<ocrExecution>>) {
    this.setOcr = setOcr
  }

  async getUrl(blobSignedId: string, csrfToken: string): Promise<string> {
    const query_params = new URLSearchParams({
      blob_signed_id: blobSignedId,
    })
    const response = await fetch(`/api/ocr/file?${query_params.toString()}`, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    })
    if (!response.ok) {
      throw new Error('Could not get file URL')
    }
    const data = await response.json()
    return data.image.url
  }

  handleUpload = (originalFile: File, ocrFile: File, token: string) => {
    Promise.all([
      // 原本のアップロード
      this.upload(originalFile, token),
      // OCR読み取り部分のアップロード
      ocrFile !== undefined && originalFile !== ocrFile ? this.upload(ocrFile, token) : undefined,
    ])
      .then((blobs) => {
        this.setOcr((prevState) => {
          return {
            ...prevState,
            blobSignedId: blobs[0].signedId,
            remoteFileKey: blobs[0].blobKey,
            remoteFileURL: blobs[0].imageUrl,
            blobOcrSignedId: blobs[1] ? blobs[1].signedId : blobs[0].signedId,
            remoteOcrFileKey: blobs[1] ? blobs[1].blobKey : blobs[0].blobKey,
            remoteOcrFileURL: blobs[1] ? blobs[1].imageUrl : blobs[0].imageUrl,
            jobStatus: OcrJobStatus.waiting,
          }
        })
      })
      .catch(() => {
        this.setOcr((prevState) => {
          return {
            ...prevState,
            jobStatus: OcrJobStatus.failed,
          }
        })
      })
  }

  async upload(uploadFile: File, csrfToken: string) {
    return new Promise((resolve, reject) => {
      const directUploadURI = '/rails/active_storage/direct_uploads'
      const resBlob = { signedId: '', blobKey: '', imageUrl: '' }
      new DirectUpload(uploadFile, directUploadURI, this).create(async (error, blob) => {
        if (error) {
          this.setOcr((prevState) => {
            return { ...prevState, jobStatus: OcrJobStatus.failed }
          })
          reject(error)
        } else {
          try {
            const url = await this.getUrl(blob.signed_id, csrfToken)
            resBlob['imageUrl'] = url
            resBlob['signedId'] = blob.signed_id
            resBlob['blobKey'] = blob.key
            resolve(resBlob)
          } catch (error) {
            this.setOcr((prevState) => {
              return { ...prevState, jobStatus: OcrJobStatus.failed }
            })
          }
        }
      })
    })
  }
}
