import * as React from 'react'
import Base from '@/components/Layout/Base'
import type { User } from '@/types/user'
import { Input } from '@/components/Input'
import { Button, AnchorButton } from '@/components/Button'
import { Breadcrumb } from '@/components/Breadcrumb'
import { ConfirmDialog } from '@/components/ConfirmDialog'
import { LoadingOverlay } from '@/components/Loading/LoadingOverlay'

type Props = {
  current_user: User
  authenticity_token: string
  error_messages: string[]
  error_messages_user: string[]
  rows_user: any[][]
  error_indices_user: number[]
  import_users?: boolean
  filename_user?: string
}

type ActionType = 'CheckUsers' | 'PostUsers' | 'PostPropertiesProposals' | 'PostMarketData'

const ImportIndexPage: React.FC<Props> = ({
  current_user,
  authenticity_token,
  error_messages,
  error_messages_user,
  rows_user,
  error_indices_user,
  import_users,
  filename_user,
}) => {
  const title = 'CSVインポート'
  const [fileNameProperty, setFileNameProperty] = React.useState('')
  const [fileNameMarketData, setFileNameMarketData] = React.useState('')
  const [fileNameUser, setFileNameUser] = React.useState(filename_user)
  const actionDefault: ActionType = import_users
    ? 'PostUsers'
    : error_messages_user.length > 0
    ? 'CheckUsers'
    : 'PostPropertiesProposals'
  const [action, setAction] = React.useState<ActionType>(actionDefault)
  const [buttonText, setButtonText] = React.useState('インポート')
  const formRef = React.useRef<HTMLFormElement>(null)
  const filePropertyRef = React.useRef<HTMLInputElement>(null)
  const fileMarketDataRef = React.useRef<HTMLInputElement>(null)
  const fileUserRef = React.useRef<HTMLInputElement>(null)
  const [disabled, setDisabled] = React.useState(false)
  const [postUsersDialog, setPostUsersDialog] = React.useState<boolean>(false)

  function toFormUrl(actionType: ActionType) {
    switch (actionType) {
      case 'CheckUsers':
        return '/imports/users_confirm'
      case 'PostUsers':
        return '/imports/users'
      case 'PostPropertiesProposals':
        return '/imports/properties_proposals'
      case 'PostMarketData':
        return '/imports/market_data'
    }
  }

  function updateFileDetails(actionType: ActionType, fileName: string) {
    // アクションタイプごとでファイル名をセットするステートを変更する
    const userFileNameInfo = actionType === 'CheckUsers' ? fileName : ''
    const marketDataFileNameInfo = actionType === 'PostMarketData' ? fileName : ''
    const propertyFileNameInfo = actionType === 'PostPropertiesProposals' ? fileName : ''
    // ユーザーインポートの時のみ(確認)末尾につく
    const buttonTextInfo = actionType === 'CheckUsers' ? 'インポート（確認）' : 'インポート'

    setFileNameUser(userFileNameInfo)
    setFileNameMarketData(marketDataFileNameInfo)
    setFileNameProperty(propertyFileNameInfo)
    setButtonText(buttonTextInfo)
    setAction(actionType)
  }

  function checkFileSize(fileSize: number) {
    if (fileSize > 100 * 1024 * 1024) {
      setFileNameProperty('')
      setFileNameMarketData('')
      setFileNameProperty('')
      alert('ファイルサイズは100MB以下にしてください')
      return false
    } else {
      return true
    }
  }

  const onClosePostUsersDialog = React.useCallback((result: boolean) => {
    setPostUsersDialog(false)
    if (result) {
      setDisabled(true)
      formRef.current.submit()
    }
  }, [])

  const [isLoading, setIsLoading] = React.useState(false)

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['CSVインポート']} />}
    >
      <LoadingOverlay isOpen={isLoading} text="取り込み中" />
      <form
        className="mt-8"
        encType="multipart/form-data"
        action={toFormUrl(action)}
        acceptCharset="UTF-8"
        method="post"
        ref={formRef}
      >
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <div className="relative overflow-x-auto h-[calc(100vh_-_182px)] md:h-[calc(100vh_-_195px)]">
          <table className="w-full text-sm text-left text-black-base">
            <tbody className="table-row-group">
              <tr className="bg-gray-light table-row">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
                >
                  <span className="font-semibold">物件</span>のCSVファイル選択
                </th>
                <td className="py-2 px-4 md:w-3/4 table-cell">
                  <div className="md:flex">
                    <Input
                      className="mb-4 md:mb-0 md:w-[246px] md:mr-6 pointer-events-none"
                      placeholder="選択されていません"
                      readOnly
                      value={fileNameProperty}
                    />
                    <label htmlFor="properties_proposals_file" className={styles.fileButton}>
                      <input
                        type="file"
                        accept="text/csv"
                        name="properties_proposals_file[]"
                        id="properties_proposals_file"
                        className="absolute w-0 h-0 opacity-0 hidden"
                        ref={filePropertyRef}
                        onChange={(e) => {
                          const file = e.target.files[0]

                          // Nginxの設定で100MBより大きいファイルは処理できない
                          const isSizeCheck = checkFileSize(file.size)
                          if (!isSizeCheck) {
                            filePropertyRef.current.value = ''
                            return
                          }

                          const fileName = file ? file.name : ''
                          updateFileDetails('PostPropertiesProposals', fileName)
                          fileUserRef.current.value = ''
                          fileMarketDataRef.current.value = ''
                        }}
                      />
                      ファイルをアップロード
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="w-full text-sm text-left text-black-base">
            <tbody className="table-row-group">
              <tr className="bg-gray-light table-row">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
                >
                  <span className="font-semibold">周辺事例</span>のCSVファイル選択
                </th>
                <td className="py-2 px-4 md:w-3/4 table-cell">
                  <div className="md:flex">
                    <Input
                      className="mb-4 md:mb-0 md:w-[246px] md:mr-6 pointer-events-none"
                      placeholder="選択されていません"
                      readOnly
                      value={fileNameMarketData}
                    />
                    <label htmlFor="market_data_file" className={styles.fileButton}>
                      <input
                        type="file"
                        accept="text/csv"
                        name="market_data_file[]"
                        id="market_data_file"
                        className="absolute w-0 h-0 opacity-0 hidden"
                        ref={fileMarketDataRef}
                        onChange={(e) => {
                          const file = e.target.files[0]

                          // Nginxの設定で100MBより大きいファイルは処理できない
                          const isSizeCheck = checkFileSize(file.size)
                          if (!isSizeCheck) {
                            fileMarketDataRef.current.value = ''
                            return
                          }

                          const fileName = file ? file.name : ''
                          updateFileDetails('PostMarketData', fileName)
                          filePropertyRef.current.value = ''
                          fileUserRef.current.value = ''
                        }}
                      />
                      ファイルをアップロード
                    </label>
                  </div>
                </td>
              </tr>
              <tr className="bg-gray-light table-row">
                <th
                  scope="row"
                  className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
                ></th>
                <td className="text-xs py-2 px-4 md:w-3/4 table-cell text-gray-500">
                  対応しているファイルの文字コードはUTF-8のみです
                </td>
              </tr>
              {error_messages.length > 0 && (
                <tr className="bg-white table-row">
                  <th
                    scope="row"
                    className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
                  ></th>
                  <td className="pt-2 px-4 md:w-3/4 table-cell">
                    <ul className="text-sm text-red">
                      {error_messages.map((message, i) => (
                        <li key={i} className="mb-2 break-all">
                          {message}
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {import_users && error_messages.length === 0 && (
            <input type="hidden" name="rows" id="rows" value={JSON.stringify(rows_user)} />
          )}
          <table className="w-full text-sm text-left text-black-base mt-10">
            <tbody className="table-row-group">
              <tr className="bg-gray-light table-row">
                <th
                  scope="row"
                  className="py-5 px-4 text-right whitespace-nowrap text-black-base font-medium w-1/4 table-cell"
                >
                  <span className="font-semibold">ユーザー</span>のCSVファイル選択
                </th>
                <td className="py-2 px-4 md:w-3/4 table-cell">
                  <div className="md:flex">
                    <Input
                      className="mb-4 md:mb-0 md:w-[246px] md:mr-6 pointer-events-none"
                      placeholder="選択されていません"
                      readOnly
                      value={fileNameUser}
                    />
                    <label htmlFor="users_file" className={styles.fileButton}>
                      <input
                        type="file"
                        accept="text/csv"
                        name="users_file[]"
                        id="users_file"
                        className="absolute w-0 h-0 opacity-0 hidden"
                        ref={fileUserRef}
                        onChange={(e) => {
                          const file = e.target.files[0]

                          // Nginxの設定で100MBより大きいファイルは処理できない
                          const isSizeCheck = checkFileSize(file.size)

                          if (!isSizeCheck) {
                            fileUserRef.current.value = ''
                            return
                          }

                          const fileName = file ? file.name : ''

                          updateFileDetails('CheckUsers', fileName)
                          filePropertyRef.current.value = ''
                          fileMarketDataRef.current.value = ''
                        }}
                      />
                      ファイルをアップロード
                    </label>
                  </div>
                </td>
              </tr>
              <tr className="bg-gray-light table-row">
                <th
                  scope="row"
                  className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
                ></th>
                <td className="text-xs py-2 px-4 md:w-3/4 table-cell text-gray-500">
                  対応しているファイルの文字コードはUTF-8のみです
                </td>
              </tr>
              <ErrorMessagesUser
                error_messages_user={error_messages_user}
                rows_user={rows_user}
                error_indices_user={error_indices_user}
              />
              {error_messages_user.length === 0 && import_users && (
                <tr className="bg-white table-row">
                  <th
                    scope="row"
                    className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
                  ></th>
                  <td className="py-2 px-4 md:w-3/4 table-cell">
                    <p className="py-2 text-sm">{`全${rows_user.length}件中エラーはありません`}</p>
                    <p className="py-2 text-sm">
                      このままインポートを行う場合は「
                      <span className="font-semibold">インポート</span>
                      」ボタンを押してください
                    </p>
                  </td>
                </tr>
              )}
              {(import_users || error_indices_user.length > 0) && (
                <tr className="bg-white table-row">
                  <td className="table-cell" colSpan={2}>
                    <table className="w-full mb-4">
                      <thead className="bg-gray-150 text-sm">
                        <tr className="whitespace-nowrap">
                          {['No', '部署', '氏名', '氏名（カナ）', 'メールアドレス', '権限'].map(
                            (text) => (
                              <th className="py-2 px-4 font-medium">{text}</th>
                            )
                          )}
                        </tr>
                      </thead>
                      <tbody className="whitespace-nowrap">
                        {rows_user.map((row, index) => (
                          <tr
                            key={index}
                            className={`${
                              error_indices_user.includes(index) ? 'bg-amber-50' : ''
                            } hover:bg-targetLine`}
                          >
                            <td className="py-2 px-4 text-sm">{index + 1}</td>
                            {row.map((item, i) => (
                              <td key={i} className="py-2 px-4 text-sm">
                                {item}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            variant="text"
            outline
            href="/imports"
          >
            リセット
          </AnchorButton>
          <Button
            className="w-full md:w-40 md:h-auto"
            disabled={disabled}
            onClick={async (e) => {
              e.preventDefault()
              setIsLoading(true)
              try {
                if (action == 'PostUsers') {
                  setPostUsersDialog(true)
                  return
                }
                setDisabled(true)
                setTimeout(() => {
                  formRef.current.submit()
                }, 100)
              } catch (error) {
                console.error(error)
              }
            }}
          >
            {buttonText}
          </Button>
        </div>
      </form>

      {/* ユーザーインポート確認のダイアログ */}
      <ConfirmDialog open={postUsersDialog} onClose={onClosePostUsersDialog}>
        <div className="text-black-base text-sm text-center">
          <p>インポート完了後、ユーザー設定から確認メールを送信してください。</p>
          <br />
          <p>処理を実施してよろしいですか？</p>
        </div>
      </ConfirmDialog>
    </Base>
  )
}

export default ImportIndexPage

const ErrorMessagesUser: React.FC<{
  error_messages_user: string[]
  rows_user: any[][]
  error_indices_user: number[]
}> = ({ error_messages_user, rows_user, error_indices_user }) => {
  if (error_messages_user.length === 0) {
    return null
  }

  if (error_indices_user.length > 0) {
    return (
      <tr className="bg-white table-row">
        <th
          scope="row"
          className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
        ></th>
        <td className="py-2 px-4 md:w-3/4 table-cell">
          <p className="py-2 text-sm text-red">{`全${rows_user.length}件中${error_messages_user.length}件のエラーがあります`}</p>
          <ErrorMessageList error_messages={error_messages_user} />
        </td>
      </tr>
    )
  } else {
    return (
      <tr className="bg-white table-row">
        <th
          scope="row"
          className="p-0 w-0 md:py-5 px-4 md:text-right whitespace-nowrap text-black-base font-medium md:w-1/4 block md:table-cell"
        ></th>
        <td className="py-2 px-4 md:w-3/4 table-cell">
          <ErrorMessageList error_messages={error_messages_user} />
        </td>
      </tr>
    )
  }
}

const ErrorMessageList: React.FC<{
  error_messages: string[]
}> = ({ error_messages }) => {
  return (
    <ul className="text-sm text-red">
      {error_messages.map((message, i) => (
        <li key={i} className="mb-2 break-all">
          {message}
        </li>
      ))}
    </ul>
  )
}

const styles = {
  fileButton: `
    flex
    md:inline-flex
    align
    items-center
    justify-center
    gap-1.5
    rounded-sm
    leading-none
    duration-200
    transition-opacity
    hover:opacity-80
    rounded-sm
    border
    box-border
    border-primary-font
    text-primary-font
    py-[5px]
    md:py-[7px]
    px-4
    text-sm
    cursor-pointer
  `,
}
