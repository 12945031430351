import * as React from 'react'
import { DndContext, DragOverEvent, closestCenter } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import Base from '@/components/Layout/Base'
import { Breadcrumb } from '@/components/Breadcrumb'
import { Button, AnchorButton } from '@/components/Button'
import { FileUpload, FileUploadHandle } from '@/components/FileUpload'
import { Select } from '@/components/Select'
import { Input } from '@/components/Input'
import { RadioButton } from '@/components/RadioButton'
import { DetailCard } from '@/components/SortableList/PropertyDocumentFormat/DetailCard'
import type { PropertyDocumentFormat } from '@/types/propertyDocumentFormat'
import type { PropertyDocumentFormatDetail } from '@/types/propertyDocumentFormatDetail'
import { User } from '@/types/user'
import * as PropertyDocumentFormatAction from '@/actions/PropertyDocumentFormatAction'
import { cover_enterprise_pricing_type } from '@/utils/policy'
import { redirect } from '@/utils/errors'

const defaultFormatDetailData = {
  column_name_1: '',
  column_name_2: '',
  element_name: '',
}

type Props = {
  current_user: User
  property_document_format: PropertyDocumentFormat
  footer_image?: string
  format_details?: PropertyDocumentFormatDetail
  column_list: { value: string; text: string }[]
  authenticity_token: string
  file_data: FileData
}

const PropertyDocumentFormatsDetailPage: React.FC<Props> = ({
  current_user,
  property_document_format,
  footer_image,
  format_details,
  column_list,
  authenticity_token,
  file_data,
}) => {
  const title = '詳細設定'
  const [formatData, setFormatData] = React.useState<PropertyDocumentFormat>(property_document_format)
  const [formatDetailData, setFormatDetailData] = React.useState<PropertyDocumentFormatDetail>(
    format_details?.length > 0 ? format_details : [defaultFormatDetailData]
  )
  const formatDetailIds = React.useMemo(() => formatDetailData.map((f) => f.id), [formatDetailData])

  const handleFormatDetailData = (value, index, column) => {
    const updatedData = [...formatDetailData];
    updatedData[index] = {
      ...updatedData[index],
      [column]: value,
    }
    setFormatDetailData(updatedData)
  }
  const handleDeleteFormatDetailData = (e, index) => {
    e.preventDefault()
    const newFormatDetailData = [...formatDetailData]
    newFormatDetailData.splice(index, 1)
    setFormatDetailData(newFormatDetailData)
  }
  const [fileName, setFileName] = React.useState('')
  const fileRef = React.useRef<HTMLInputElement>(null)
  const fileRefCreateForm = React.useRef<FileUploadHandle>(null)
  const [footerImage, setFooterImage] = React.useState<File[]>()
  const [previewImagePath, setPreviewImagePath] = React.useState<string>();
  React.useEffect(() => {
    const fileRef = fileRefCreateForm
    if (file_data && file_data.file_base64_data && file_data.file_name) {
      fileRef.current?.setFile(file_data?.file_base64_data, file_data?.file_name)
    }
  })
  const acceptableMimeTypes = {
  'image/jpeg': [],
  'image/png': [],
  'image/gif': [],
  }

  const onDropFile = (file) => {
    setFooterImage(file)
    const dataUrl = URL.createObjectURL(file)
    setPreviewImagePath(dataUrl)
  }

  const footerImageUpload = async () => {
    const formData = buildFormData(footerImage)
    try {
      const response = await PropertyDocumentFormatAction.uploadFooterImage(property_document_format.id, formData)
      alert('画像アップロードしました。')
    } catch(error) {
      redirect(error, `/property_document_formats/${property_document_format.id}/detail_with_notice`,
               '画像アップロードに失敗しました。')
    }
  }

  const buildFormData = (file?: File[]) => {
    if (!file) {
      return new FormData();
    }
    const formData = new FormData();
    formData.append('footer_image', file)
    return formData;
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      setFormatDetailData((formatDetailData) => {
        const oldIndex = formatDetailData.findIndex((user) => user.id === active.id)
        const newIndex = formatDetailData.findIndex((user) => user.id === over.id)
        return arrayMove(formatDetailData, oldIndex, newIndex)
      })
    }
  }

  return (
    <Base
      current_user={current_user}
      title={title}
      breadcrumb={<Breadcrumb link_texts={['物件概要書フォーマット設定', '詳細設定']} />}
      submenuCategory="settings"
    >
      <form
        className="mt-8"
        action={`/property_document_formats/${property_document_format.id}/upsert_detail`}
        acceptCharset="UTF-8"
        method="post"
      >
        <input type="hidden" name="_method" value="post" />
        <input type="hidden" name="authenticity_token" value={authenticity_token} />

        <div>
          <div>物件概要書フォーマット設定名：{property_document_format.name}</div>
          <div className="py-6">
            <div className="flex flex-col gap-1">
              <DndContext collisionDetection={closestCenter} onDragOver={handleDragOver}>
                <SortableContext items={formatDetailIds}>
                  {formatDetailData.map((data, index) => (
                    <DetailCard
                      key={data.id}
                      index={index}
                      id={data.id}
                      column_name_1={data.column_name_1}
                      column_name_2={data.column_name_2}
                      element_name={data.element_name}
                      column_list={column_list}
                      formatDetailData={formatDetailData}
                      onChangeFormatDetailData={handleFormatDetailData}
                      onClickDeleteFormatDetailData={handleDeleteFormatDetailData}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>
            <div className="flex gap-2 mt-2">
              <Button
                outline
                size="small"
                onClick={(e) => {
                  e.preventDefault()
                  const last = formatDetailIds.sort((a, b) => a - b).pop()
                  const newItem = { ...defaultFormatDetailData, id: last + 1 }
                  setFormatDetailData([...formatDetailData, newItem])
                }}
              >
                追加
              </Button>
            </div>
          </div>
        </div>
        <div className="py-6">
          フッター表示
          {previewImagePath ?
            <div><img src={previewImagePath} /></div> :
            footer_image && <div><img src={footer_image} /></div>
          }
          <div className="text-sm mb-4 mt-6">
            <p>枠内にファイルをドラッグ＆ドロップしてからアップロードボタンを押してください</p>
            <p>※JPEG, PNG, GIFのファイル形式のみ対応しています。
            {cover_enterprise_pricing_type(current_user) ? (
              <>&nbsp;(添付のファイルサイズは500.0MB以内でお願いします)</>
            ) : (
              <>&nbsp;(添付のファイルサイズは10.0MB以内でお願いします)</>
            )}
            </p>
          </div>
          <FileUpload
            className={'mt-4 w-full'}
            accept={acceptableMimeTypes}
            onDrop={(file: File) => onDropFile(file)}
            filenameInitial={file_data?.file_name}
            ref={fileRefCreateForm}
          />
          <Button
            className="w-full md:w-40 md:h-auto mt-3"
            disabled={!footerImage}
            onClick={footerImageUpload}
            type="button"
          >
            アップロード
          </Button>
        </div>
        <div className="bg-white flex justify-center items-center w-full left-0 md:gap-4 border-t border-gray-250 pt-2 mb-3">
          <AnchorButton
            className="w-full md:w-40 md:h-auto text-primary-font border-white"
            outline
            variant="text"
            href="/property_document_formats"
          >
            キャンセル
          </AnchorButton>
          <Button className="w-full md:w-40 md:h-auto">編集を保存</Button>
        </div>
      </form>
    </Base>
  )
}

export default PropertyDocumentFormatsDetailPage
