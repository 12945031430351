import * as React from 'react'
import { usageOptions, firePreventionAreaOptions, altitudeAreaOptions } from './usage'

type Props = {
  usageList
}


const UsageDatas3: React.FC<Props> = ({
    usageList,
}) => {
  const rows = ['領域面積', '用途地域', '防火地域', '高度地域', '日影規制', '建ペイ率', '容積率', '採用建ペイ率（平均)', '採用容積率（平均)']
  const columns = ['area', 'usage', 'firePreventionArea', 'altitudeArea', 'shadow', 'buildingRatio', 'volumeRatio', 'avgBuildingRatio', 'avgVolumeRatio']
  const getMappedText = (key: string, value: string | number) => {
    const optionsMap: { [key: string]: { text: string; value: string }[] } = {
      usage: usageOptions,
      firePreventionArea: firePreventionAreaOptions,
      altitudeArea: altitudeAreaOptions,
    };

    const options = optionsMap[key];
    if (!options) return value;

    const matchedOption = options.find((option) => option.value === String(value));
    return matchedOption ? matchedOption.text : value;
  };
  return (
      <>
        <thead>
          <tr>
            <th className='bg-[#eff9ff]' style={{ position: "sticky", left: 0, width: '200px' }}></th>
            {usageList.map((_, index) => (
                <th key={index} style={{width: '220px'}}>領域{index + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
        {columns.map((key, rowIndex) => (
            <tr key={rowIndex}>
              <td className='bg-[#eff9ff]' style={{
                position: "sticky",
                left: 0,
                fontWeight: "bold",
                width: '200px',
                border: "1px solid #ddd",
              }}>{rows[rowIndex]}</td>
              {usageList.map((item, colIndex) => (
                  <td key={colIndex} style={{width: '220px', border: "1px solid #ddd",}}>
                    {getMappedText(key, item[key])}
                  </td>
              ))}
            </tr>
        ))}
        </tbody>
      </>
  )
}

export default UsageDatas3
