/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as React from 'react'

import Chart from 'chart.js'
import 'chartjs-plugin-colorschemes'
import 'chartjs-plugin-datalabels'
import { setupChartSize, handleChartResize } from './chartSizeUtils'
import { CHART_ID, CHART_CONTAINER_SELECTOR, ChartType } from './chartSizeConstants'

type Props = {
  year_from: number
  month_from: number
  year_to: number
  month_to: number
  source_department_id: number
  source_user_id: number
  target_period_type: string
  property_type: string
  business_type_id: number
}

const AnalysesDepartmentsMonthlyChart: React.FC<Props> = ({
  year_from,
  month_from,
  year_to,
  month_to,
  source_department_id,
  source_user_id,
  target_period_type,
  property_type,
  business_type_id,
}) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null)
  const chartRef = React.useRef<Chart | null>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    function buildQuery(type) {
      const params = {
        format: 'json',
        type: type,
        year_from: year_from.toString(),
        month_from: month_from.toString(),
        year_to: year_to.toString(),
        month_to: month_to.toString(),
        source_department_id: source_department_id?.toString() ?? '',
        source_user_id: source_user_id?.toString() ?? '',
        target_period_type: target_period_type,
        property_type: property_type ?? '',
        business_type_id: business_type_id ?? '',
      }
      const query = new URLSearchParams(params)
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `/analyses?${query}`
    }

    async function init() {
      const res = await fetch(buildQuery('departments_monthly'))
      const json = await res.json()

      const chartDataMonthly = json.body

      const labels = Object(chartDataMonthly[0].name).map((department) => {
        return department
      })

      if (containerRef.current) {
        setupChartSize(
          containerRef.current,
          labels,
          ChartType.HORIZONTAL_BAR,
          CHART_CONTAINER_SELECTOR
        )
      }

      const chartjsPluginTotalLabels = {
        /**
         * 各項目の合計を取得
         */
        calcSums: function (datasets) {
          const sums = []
          datasets.forEach(function (dataset) {
            /**
            // 非表示の項目は処理しない
            if (dataset._meta[0].hidden) {
              return;
            }
            */
            dataset.data.forEach(function (value, i) {
              if (typeof sums[i] === 'undefined') {
                sums[i] = 0
              }
              sums[i] += value
            })
          })

          return sums
        },

        /**
         * 各棒最後の項目のメタ情報を取得
         * (非表示のものは除く)
         */
        getLastMeta: function (chart) {
          let i = chart.data.datasets.length - 1
          let meta = undefined
          do {
            meta = chart.getDatasetMeta(i)
            i--
          } while (meta.hidden && i >= 0)

          return meta
        },

        /**
         * ラベル情報を取得
         */
        makeLabels: function (meta, sums) {
          const labels = []
          sums.forEach(function (sum, i) {
            const lastModel = meta.data[i]._model
            labels.push({
              value: sum !== 0 ? sum.toString() : '',
              x: lastModel.x,
              y: lastModel.y,
            })
          })

          return labels
        },

        /**
         * 書式設定
         */
        setTextStyle: function (ctx) {
          const fontSize = 14
          const fontStyle = 'bold'
          const fontFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
          ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily)
          ctx.fillStyle = '#666'
          ctx.textAlign = 'left'
          ctx.textBaseline = 'middle'

          return ctx
        },
      }

      // 上記プラグインの有効化
      Chart.plugins.register(chartjsPluginTotalLabels)

      const datasets_monthly = chartDataMonthly.map((data) => {
        return {
          label: data.label,
          data: Object.values(data.data),
          datalabels: {
            font: {
              weight: 'bold',
            },
          },
          stack: 'proposal',
        }
      })

      const data_monthly = {
        labels,
        datasets: datasets_monthly,
      }

      const config_monthly = {
        type: 'horizontalBar',
        data: data_monthly,
        options: {
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 20,
            },
          },
          responsive: true,
          scales: {
            xAxes: [
              {
                display: true,
                position: 'bottom',
                ticks: {
                  beginAtZero: true,
                  percision: 0,
                },
              },
              {
                type: 'linear',
                display: true,
                position: 'top',
                gridLines: {
                  display: false,
                },
                ticks: {
                  percision: 0,
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            position: 'right',
            reverse: true,
          },
          plugins: {
            colorschemes: {
              scheme: 'brewer.YlGn3',
            },
            datalabels: {
              formatter: function (value, context) {
                if (value != 0) {
                  return value
                } else {
                  return ''
                }
              },
            },
          },
        },
      }

      if (chartRef.current) {
        chartRef.current.destroy()
      }

      chartRef.current = new Chart(
        document.getElementById(CHART_ID.DEPARTMENTS_MONTHLY),
        config_monthly
      )
      chartRef.current.options.scales.xAxes[1].ticks.max = chartRef.current.scales['x-axis-0'].max
      chartRef.current.update()
    }

    void init()

    const resizeHandler = () =>
      handleChartResize(chartRef, containerRef, ChartType.HORIZONTAL_BAR, CHART_CONTAINER_SELECTOR)
    window.addEventListener('resize', resizeHandler)

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
      window.removeEventListener('resize', resizeHandler)
    }
  }, [
    year_from,
    month_from,
    year_to,
    month_to,
    source_department_id,
    source_user_id,
    target_period_type,
    property_type,
    business_type_id,
  ])

  return (
    <div className="w-full h-full overflow-visible" ref={containerRef}>
      <canvas id={CHART_ID.DEPARTMENTS_MONTHLY} ref={canvasRef} />
    </div>
  )
}

export default AnalysesDepartmentsMonthlyChart
