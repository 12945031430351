export const ParcelUseOptions = [
  { value: '宅地', text: '宅地' },
  { value: '田', text: '田' },
  { value: '畑', text: '畑' },
  { value: '牧場', text: '牧場' },
  { value: '原野', text: '原野' },
  { value: '塩田', text: '塩田' },
  { value: '鉱泉地', text: '鉱泉地' },
  { value: '池沼', text: '池沼' },
  { value: '山林', text: '山林' },
  { value: '墓地', text: '墓地' },
  { value: '境内地', text: '境内地' },
  { value: '運河用地', text: '運河用地' },
  { value: '水道用地', text: '水道用地' },
  { value: '用悪水路', text: '用悪水路' },
  { value: 'ため池', text: 'ため池' },
  { value: '堤', text: '堤' },
  { value: '井溝', text: '井溝' },
  { value: '保安林', text: '保安林' },
  { value: '公衆用道路', text: '公衆用道路' },
  { value: '公園', text: '公園' },
  { value: '鉄道用地', text: '鉄道用地' },
  { value: '学校用地', text: '学校用地' },
  { value: '雑種地', text: '雑種地' },
]

export const ParcelUseForSearch = [
  { value: 1, text: '宅地' },
  { value: 2, text: '田' },
  { value: 3, text: '畑' },
  { value: 4, text: '牧場' },
  { value: 5, text: '原野' },
  { value: 6, text: '塩田' },
  { value: 7, text: '鉱泉地' },
  { value: 8, text: '池沼' },
  { value: 9, text: '山林' },
  { value: 10, text: '墓地' },
  { value: 11, text: '境内地' },
  { value: 12, text: '運河用地' },
  { value: 13, text: '水道用地' },
  { value: 14, text: '用悪水路' },
  { value: 15, text: 'ため池' },
  { value: 16, text: '堤' },
  { value: 17, text: '井溝' },
  { value: 18, text: '保安林' },
  { value: 19, text: '公衆用道路' },
  { value: 20, text: '公園' },
  { value: 21, text: '鉄道用地' },
  { value: 22, text: '学校用地' },
  { value: 23, text: '雑種地' },
]

export const ParcelRightsOptions = [
  { value: '所有権', text: '所有権' },
  { value: '借地権', text: '借地権' },
  { value: '定期地上権', text: '定期地上権' },
  { value: '普通賃借権', text: '普通賃借権' },
  { value: '定期賃借権', text: '定期賃借権' },
  { value: '地役権', text: '地役権' },
  { value: '抵当権', text: '抵当権' },
  { value: '底地権', text: '底地権' },
  { value: '敷地権', text: '敷地権' },
  { value: '信託受益権', text: '信託受益権' },
]

export const ParcelRightsForSearch = [
  { value: 1, text: '所有権' },
  { value: 2, text: '借地権' },
  { value: 3, text: '定期地上権' },
  { value: 4, text: '普通賃借権' },
  { value: 5, text: '定期賃借権' },
  { value: 6, text: '地役権' },
  { value: 7, text: '抵当権' },
  { value: 8, text: '底地権' },
  { value: 9, text: '敷地権' },
  { value: 10, text: '信託受益権' },
]
