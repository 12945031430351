import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Button, AnchorButton } from '@/components/Button'
import ClearIcon from '@material-ui/icons/Clear'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import KeyIcon from '@mui/icons-material/Key'



type Props = {
  PASS: string;
}


export const PasswordModal: React.FC<Props> = ({
    PASS,

}) => {
  const [isAlertOpen, setAlertOpen] = React.useState(true)
  const closeAlertModal = () => {
    setAlertOpen(false);
  };

  const openAlertModal = () => {
    setAlertOpen(true);
  };

  const [showCopiedMessage, setShowCopiedMessage] = React.useState(false);
  const [copiedMessageValue, setCopiedMessageValue] = React.useState<string>("");

  const handleCopyClick = (text: string, message: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setShowCopiedMessage(true);
        setCopiedMessageValue(message);
        setTimeout(() => setShowCopiedMessage(false), 2000); // 2秒後に非表示
      })
      .catch(() => alert("コピーに失敗しました"));
  };

  return (
    <>
      <div className="flex justify-end">
        <Button
          className="text-primary-font m-0 bg-white mb-2.5"
          outline
          size="small"
          variant="primary"
          prefix={<KeyIcon fontSize="small" />}
          onClick={openAlertModal}
        ><span className="hidden md:inline">解凍パスワードを表示</span></Button>
      </div>
      <Transition appear show={isAlertOpen} as={React.Fragment}>
        <Dialog className="relative z-10" onClose={closeAlertModal}>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={React.Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-screen md:max-w-[625px] transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">

                  {/* モーダルタイトル */}
                  <Dialog.Title className="text-base font-medium p-4 leading-6 text-gray-700">
                    ダウンロード完了
                    <ClearIcon className="float-right cursor-pointer" onClick={closeAlertModal} />
                  </Dialog.Title>

                  <hr />

                  {/* パスワード */}
                  <div
                    className="flex justify-center items-center text-center m-4 border cursor-pointer hover:bg-[rgb(97,140,165)] hover:text-[white] active:bg-[rgb(101,119,130)] p-4 rounded-[8px]"
                    onClick={() => handleCopyClick(PASS, "パスワード")}
                  >
                    <p>パスワード: {PASS}</p>
                    <button
                      className="bg-[rgb(9_104_157)] text-[white] p-[5px] m-[5px] flex items-center gap-4 rounded-[50px]"
                    >
                      <ContentCopyIcon />
                    </button>
                  </div>

                  {/* コピー成功メッセージ */}
                  {showCopiedMessage && (
                    <div className="bg-[rgb(9_104_157)] text-center">
                      <p className="text-[white]">
                        {copiedMessageValue} をコピーしました
                      </p>
                    </div>
                  )}

                  {/* 確認ボタン（外部リンク付き） */}
                  <div className="text-center m-4">
                    <Button
                      className="text-white w-[120px]"
                      size="small"
                      variant="primary"
                      onClick={closeAlertModal} // ✅ ボタンを押すとモーダルを閉じる
                    >
                      確認しました
                    </Button>
                  </div>

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

export default PasswordModal