import * as React from 'react'
import { Card } from './Card'
import type { User } from '@/types/user'
import { Checkbox } from '@/components/Checkbox'
import { Table } from '@/components/Table'
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'

export interface ContainerState {
  current_user: User
  cards: User[]
}

type Props = {
  current_user: User
  users: User[]
  setUsers: React.Dispatch<React.SetStateAction<User[]>>
  setShowConfirmationButton: React.Dispatch<React.SetStateAction<boolean>>
  confirmationUserIds: number[]
  setConfirmationUserIds: (userIds: number[]) => void
  clearAfterConfirmation: boolean
  setClearAfterConfirmation: React.Dispatch<React.SetStateAction<boolean>>
  topScrollbar?: boolean
  authenticity_token: string
}

export const Container: React.FC<Props> = ({
  current_user,
  users,
  setUsers,
  setShowConfirmationButton,
  confirmationUserIds,
  setConfirmationUserIds,
  clearAfterConfirmation,
  setClearAfterConfirmation,
  topScrollbar = false,
  authenticity_token,
}) => {
  {
    const [checked, setChecked] = React.useState<boolean>(false)

    const unsentUserIds = users
      .filter(
        (user) =>
          user.company_id === current_user.company_id &&
          !user.confirmed_at &&
          !user.confirmation_sent_at
      )
      .map((user) => user.id as number)

    const handleDragEnd = (event: DragEndEvent) => {
      const { active, over } = event
      if (!over || active.id === over.id) return

      const activeId = Number(active.id)
      const overId = Number(over.id)

      const activeUser = users.find((user) => user.id === activeId)
      const overUser = users.find((user) => user.id === overId)

      const updatedUsers = [...users]
      const oldIndex = updatedUsers.findIndex((user) => user.id === activeId)
      const newIndex = updatedUsers.findIndex((user) => user.id === overId)

      if (oldIndex === newIndex) return

      const reorderedUsers = arrayMove(updatedUsers, oldIndex, newIndex)

      const otherCompanyUserInvolved =
        activeUser?.company_id !== current_user.company_id ||
        overUser?.company_id !== current_user.company_id

      if (otherCompanyUserInvolved) {
        alert('他社ユーザーの順序変更は保存されません。自社ユーザーの順序のみ更新されます。')
      }

      setUsers(reorderedUsers)

      void updateUserOrder(reorderedUsers).catch((error) => {
        alert('ユーザー順序の更新に失敗しました')
        console.error('更新処理中にエラーが発生しました:', error)
        setUsers(updatedUsers)
      })
    }

    const updateUserOrder = (updatedUsers: User[]): Promise<void> => {
      const userOrders = updatedUsers
        .filter((user) => user.company_id === current_user.company_id)
        .map((user, index) => ({
          id: user.id,
          order: index + 1,
        }))

      return fetch('/users/update_order', {
        method: 'PATCH',
        body: JSON.stringify({ user_orders: userOrders }),
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': authenticity_token,
          Accept: 'application/json',
        },
      })
        .then(async (response) => {
          if (!response.ok) {
            const errorText = await response.text()
            console.error('Response error:', errorText)
            throw new Error(`Server error: ${response.status}`)
          }
          return response.json()
        })
        .then((data) => {
          console.log('すべてのユーザー順序が更新されました', data)
        })
        .catch((error) => {
          console.error('更新エラー:', error)
          throw error
        })
    }

    const renderCard = React.useCallback(
      (card: User, index: number, current_user: User, checked: boolean) => {
        return (
          <Card
            key={card.id}
            index={index}
            id={card.id}
            user={card}
            current_user={current_user}
            checkedDefault={checked}
            confirmationUserIds={confirmationUserIds}
            setConfirmationUserIds={setConfirmationUserIds}
            clearAfterConfirmation={clearAfterConfirmation}
          />
        )
      },
      [checked, confirmationUserIds, clearAfterConfirmation, current_user]
    )

    React.useEffect(() => {
      if (clearAfterConfirmation) {
        // メール送信後にチェック状態をクリアする
        setChecked(false)
        setShowConfirmationButton(false)

        // 送信後にクリア状態にするフラグをリセットする
        setClearAfterConfirmation(false)
      } else if (confirmationUserIds.length > 0) {
        setShowConfirmationButton(true)
      } else if (!checked) {
        setShowConfirmationButton(false)
      }
    }, [
      confirmationUserIds,
      clearAfterConfirmation,
      setShowConfirmationButton,
      setClearAfterConfirmation,
    ])

    return (
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={users.map((user) => user.id.toString())}>
          <Table
            className={
              window.innerHeight > 920
                ? 'h-[760px] md:h-[760px]'
                : 'h-[calc(100vh_-_145px)] md:h-[calc(100vh_-_155px)]'
            }
            borderLine={false}
            topScrollbar={topScrollbar}
          >
            <thead className="bg-gray-150 text-sm sticky top-0">
              <tr className="whitespace-nowrap">
                {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
                  <th scope="col" className="py-2 px-4 font-medium"></th>
                )}
                <th scope="col" className="py-2 px-4 font-medium">
                  所属会社
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  部署
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  氏名
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  氏名（カナ）
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  メールアドレス
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  登録日時
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  更新日時
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  権限
                </th>
                <th scope="col" className="py-2 px-4 font-medium">
                  volume check
                </th>
                {(current_user.role === 'system_admin' || current_user.role === 'admin') && (
                  <>
                    <th scope="col" className="py-2 px-4 font-medium">
                      アカウントロック
                    </th>
                    <th scope="col" className="py-2 px-4 font-medium">
                      <span className="flex justify-between items-center">
                        <span className="mr-2">確認メール</span>
                        <span>
                          <Checkbox
                            border={'gray-700'}
                            classNameBox={'m-0-i'}
                            checked={checked}
                            onChange={() => {
                              const nextState = !checked

                              setChecked(nextState)

                              // 確認メール送信ボタンを表示/非表示
                              setShowConfirmationButton(nextState)

                              if (nextState) {
                                const userIds = new Set(confirmationUserIds.concat(unsentUserIds))
                                setConfirmationUserIds([...userIds])
                              } else {
                                setConfirmationUserIds([])
                              }
                            }}
                          />
                        </span>
                      </span>
                    </th>
                  </>
                )}
                <th scope="col" className="py-2 px-4 font-medium"></th>
              </tr>
            </thead>
            <tbody className="whitespace-nowrap">
              {users.map((card, i) => renderCard(card, i, current_user, checked))}
            </tbody>
          </Table>
        </SortableContext>
      </DndContext>
    )
  }
}
